import React, {useEffect} from 'react';

import {Typography} from '@material-ui/core';
import {useTitle} from 'shared/hooks';

export const Document: React.FC = () => {
  const {setTitle} = useTitle();

  useEffect(() => {
    setTitle('Document');
  }, [setTitle]);

  return <Typography variant="h1">Document</Typography>;
};
