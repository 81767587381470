import { Api } from "shared/services/axiosConfig";
import { IFunnel, IFunnelGet, IFunnelList, IFunnelPost, IFunnelPut } from "./types"
import { IRequestResult } from "shared/interfaces/IRequestResult";

interface IRequestResultFunnel {
  success: boolean;
  messages?: string[];
  funnelId: string;
}

const getAllFunnels = async (
  ativo: number,
  campoDePesquisa = '',
  take = 10,
  skip = 1,
): Promise<IFunnelGet> => {
  try {
    const response = await Api.get(
      `/funil/paginado?ativo=${ativo == 0 ? '' : ativo === 1 ? true : false}&campoDePesquisa=${campoDePesquisa}&take=${take}&skip=${skip}`,
    );
    return response.data;
  } catch (error: any) {
    if (error.mensagens) {
      throw new Error(error.mensagens);
    } else if (error.data.data) {
      throw new Error(error.data.data);
    } else {
      throw new Error(error)
    }
  }
}

const getFunnelsById = async (
  idEstabelecimento = '', 
  nivelEstabelecimentoDono= '', 
  idCampanha = ''
): Promise<IFunnel[]> => {
  try {
    const response = await Api.get(
      `/funil?IdEstabelecimento=${idEstabelecimento}&nivelEstabelecimentoDono=${nivelEstabelecimentoDono}&IdCampanha=${idCampanha}&ativo=${true}`,
    );
    return response.data;
  } catch (error: any) {
    if (error.mensagens) {
      throw new Error(error.mensagens);
    } else {
      throw new Error(error)
    }
  }
}

const postFunnel = async (payload: IFunnelPost): Promise<IRequestResultFunnel> => {
  try {
    const response = await Api.post('/funil', payload);

    return {success: true, funnelId: response.data.idFunil};
  } catch (error: any) {
    throw new Error(error.data.data);
  }
};

const updateFunnel = async (payload: IFunnelPut): Promise<IRequestResult> => {

  try {
    await Api.put(`/funil/${payload.id}`, {
      nome: payload.nome,
      desativar: payload.desativar,
    });

    return {success: true};
  } catch (error: any) {
    throw new Error(error.data.data);
  }
};

const deleteFunnel = async (id: string): Promise<IRequestResult> => {
  try {
    await Api.delete(`/funil/${id}`);

    return {success: true};
  } catch (error: any) {
    throw new Error(error.data.data);
  }
};

export const FunnelService = {
  getAllFunnels,
  getFunnelsById,
  postFunnel,
  updateFunnel,
  deleteFunnel
}