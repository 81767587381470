import React, {useState, useCallback} from 'react';
import {
  Icon,
  Button,
  Dialog,
  Typography,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';
import {format} from 'date-fns';
import {ptBR} from 'date-fns/locale';
import {Group, Cached, Person, Subject, WatchLater} from '@material-ui/icons';

import {ModalConfirm} from 'shared/components';
import {ButtonList} from './components/ButtonList';
import {feedback} from 'shared/services/alertService';
import {
  IEvent,
  CalendarAvailabilityService,
} from 'shared/services/api/CalendarAvailabilityService';
import {errorResponse} from 'shared/utils/errorResponse';
import {isSameOrAfter} from 'shared/utils/isSameOrAfter';

import './styles.css';

interface IProps {
  event: IEvent;
  setRecurrences: (value:boolean) => void;
  opened: boolean;
  loading: boolean;
  canEdit: boolean;
  canDelete: boolean;
  setEventOpen(): void;
  handleGetAvailabilities(): void;
  setLoading(value: boolean): void;
  setActionType(value: number): void;
}

export const EventDialog: React.FC<IProps> = ({
  event,
  setRecurrences,
  opened,
  loading,
  canEdit,
  canDelete,
  setLoading,
  setEventOpen,
  setActionType,
  handleGetAvailabilities,
}) => {
  const [deletedId, setDeleteId] = useState('');
  const [deletedActionType, setDeleteActionType] = useState(0);
  const [openModalConfirmDelete, setOpenModalConfirmDelete] = useState(false);

  const handleConfirmAvailabilityDelete = useCallback(
    (id: string | undefined, type: number) => {
      setOpenModalConfirmDelete(true);

      id && setDeleteId(id);
      setDeleteActionType(type);
    },
    [],
  );

  const handleDeleteAvailability = useCallback(async () => {
    try {
      setLoading(true);
      await CalendarAvailabilityService.deleteAvailabilities(
        deletedId,
        deletedActionType,
      );

      await handleGetAvailabilities();

      feedback('Disponibilidade excluída com sucesso', 'success');
      setOpenModalConfirmDelete(false);
      setEventOpen();
      setDeleteId('');
      setDeleteActionType(0);
    } catch (error) {
       
      feedback(errorResponse(error), 'error');
    } finally {
      setLoading(false);
    }
  }, [
    setLoading,
    deletedId,
    deletedActionType,
    handleGetAvailabilities,
    setEventOpen,
  ]);

  return (
    <>
      <ModalConfirm
        loading={loading}
        opened={openModalConfirmDelete}
        onClick={handleDeleteAvailability}
        onClose={() => {
          setOpenModalConfirmDelete(false),
            setDeleteId(''),
            setDeleteActionType(0);
        }}
      />

      <Dialog onClose={setEventOpen} open={opened} maxWidth="xs">
        <DialogContent style={{minWidth: 350, padding: 0}}>
          <DialogContentText className="dc-text">
            <Typography
              variant="h6"
              display="block"
              gutterBottom
              style={{color: '#005E5A'}}>
              <Person className="icon-person" />
              {event.nomeEntrevistador}
            </Typography>

            <div className="div-dialog">
              <Typography variant="caption" className="dialog-text">
                <Icon fontSize="default" className="dialog-icon">
                  campaign
                </Icon>
                {event.nomeCampanha}
              </Typography>

              {event.idTipoDeRecorrencia === 2 && (
                <Typography variant="caption" className="dialog-text">
                  Série
                  <Cached className="dialog-icon-muted" />
                </Typography>
              )}
            </div>

            <Typography variant="caption" className="dialog-text-capitalize">
              <WatchLater className="dialog-icon" />
              {event.iniciaEm &&
                event.terminaEm &&
                `${format(new Date(event.iniciaEm), 'ccc dd/MM/yyyy H:mm', {
                  locale: ptBR,
                })} -
              ${format(new Date(event.terminaEm), 'H:mm')}`}
            </Typography>

            <Typography variant="caption" className="dialog-text-2">
              <div className="div-dialog-text">
                <div className="div-dialog-text-2">
                  {event.qtdeDeLeadsPendentesNestaAgenda}
                </div>
                /{event.maximoDeLeads}
              </div>
              Entrevistados
            </Typography>

            <Typography variant="caption" className="dialog-text-2">
              <Group className="dialog-icon" />
              {event.identificacaoLeadsVinculadosNestaAgenda}
            </Typography>
          </DialogContentText>

          {event.observacao && (
            <DialogContentText className="dialog-text-area">
              <Typography variant="caption" className="dialog-text">
                <Subject className="dialog-icon" />
                {event.observacao}
              </Typography>
            </DialogContentText>
          )}
        </DialogContent>

        <DialogActions
          style={{background: '#eefffe', justifyContent: 'flex-start'}}>
          {event.idTipoDeRecorrencia === 1 ? (
            <>
              <Button
                color="primary"
                disabled={
                  !canEdit ||
                  !isSameOrAfter(new Date(event.iniciaEm), new Date())
                }
                onClick={() => setActionType(1)}>
                Editar
              </Button>
              <Button
                color="primary"
                disabled={!canDelete}
                onClick={() =>
                  handleConfirmAvailabilityDelete(
                    event.idDisponibilidadeDeAgenda,
                    1,
                  )
                }>
                Excluir
              </Button>
            </>
          ) : (
            <>
              <ButtonList
                label="Editar"
                setRecurrences={setRecurrences}
                disabled={
                  !canEdit ||
                  !isSameOrAfter(new Date(event.iniciaEm), new Date())
                }
                onClick={(value) => setActionType(value)}
              />
              <ButtonList
                label="Excluir"
                setRecurrences={setRecurrences}
                disabled={!canDelete}
                onClick={(value) =>
                  handleConfirmAvailabilityDelete(
                    event.idDisponibilidadeDeAgenda,
                    value,
                  )
                }
              />
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
