import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  Box,
  useTheme,
  TableRow,
  TableCell,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import {Delete, Edit} from '@material-ui/icons';

import {useStyles} from './styles';

import {useNavigate} from 'react-router-dom';
import {feedback} from 'shared/services/alertService';
import {useTitle, useTerm, useUser, useDebounce} from 'shared/hooks';
import {errorResponse} from 'shared/utils/errorResponse';
import {Header, ModalConfirm, Table, TableSkeleton} from 'shared/components';
import {
  IFunnel,
  IFunnelGet,
  IFunnelHandler,
  IFunnelList,
} from 'shared/services/api/FunnelService/types';
import {FunnelService} from 'shared/services/api/FunnelService';
import {ModalCreateFunnel} from './components/ModalCreateFunnel';
import {FUNNEL_DEFAULT, FUNNEL_HANDLER_DEFAULT} from './constants';

export const Funnel: React.FC = () => {
  const [filter, setFilter] = useState('');
  const [loading, setLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [idDeleted, setIdDeleted] = useState('');
  const [funnels, setFunnels] = useState<IFunnelGet>({
    data: [],
    qtdTotalDeRegistros: 0,
  });
  const [editModal, setEditModal] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [tableFiltered, setTableFiltered] = useState(0);
  const [openModalConfirmExclude, setOpenModalConfirmExclude] = useState(false);

  const [funnel, setFunnel] = useState<IFunnel>(FUNNEL_DEFAULT);
  const [funnelHandler, setFunnelHandler] = useState(FUNNEL_HANDLER_DEFAULT);

  const [take, setTake] = useState(10);
  const [skip, setSkip] = useState(1);
  const [funnelsCount, setFunnelsCount] = useState(funnels.qtdTotalDeRegistros);

  const title = {
    isEdit: 'Editar Funil',
    default: 'Cadastro do Funil',
  };
  const [modalTitle, setModalTitle] = useState(title.default);

  const theme = useTheme();
  const {terms} = useTerm();
  const classes = useStyles();
  const navigate = useNavigate();
  const {setTitle} = useTitle();
  const {data: user} = useUser();
  const {debounce} = useDebounce();

  const permissions = useMemo(
    () => user?.permissoes.filter((i) => i.nome.includes('Funil')),
    [user?.permissoes],
  );

  const canDelete = useMemo(
    () => !!permissions?.find((i) => i.nome.includes('Excluir')),
    [permissions],
  );

  const canRegister = useMemo(
    () => !!permissions?.find((i) => i.nome.includes('Incluir')),
    [permissions],
  );

  const canEdit = useMemo(
    () => !!permissions?.find((i) => i.nome.includes('Editar')),
    [permissions],
  );

  const handleVerifyNameFunnel = useCallback(() => {
    if (editModal) {
      return (
        funnels.data
          .filter((i) => i.id !== funnel.id)
          .filter(
            (i) =>
              i.nome.toLowerCase().trim() === funnel.nome.toLowerCase().trim(),
          ).length > 0
      );
    }
    return (
      funnels.data.filter(
        (i) => i.nome.toLowerCase().trim() === funnel.nome.toLowerCase().trim(),
      ).length > 0
    );
  }, [editModal, funnel.id, funnel.nome, funnels]);

  const handleGetFunnel = useCallback(async () => {
    setLoading(true);
    debounce(async () => {
      try {
        const response = await FunnelService.getAllFunnels(
          tableFiltered,
          filter,
          take,
          skip,
        );

        if (response) {
          setFunnels(response);
          setFunnelsCount(response.qtdTotalDeRegistros);
          setDataLoaded(true);
        }
      } catch (error) {
        feedback(errorResponse(error), 'error');
      } finally {
        setLoading(false);
      }
    });
  }, [tableFiltered, filter, take, skip, debounce]);

  const handleCreateFunnel = useCallback(
    async (funnelHandler: IFunnelHandler) => {
      setLoading(true);
      try {
        if (funnelHandler) {
          await FunnelService.postFunnel({
            nome: funnelHandler.nome,
            idEstabelecimento: funnelHandler.idEstabelecimento ?? '',
            nivelEstabelecimentoDono:
              funnelHandler.nivelEstabelecimentoDono ?? -1,
          });

          feedback(`Funil adicionado com sucesso`, 'success');
        }
      } catch (error) {
        feedback(errorResponse(error), 'error');
      }
      setLoading(false);
      setOpenModal(false);
      setFunnelHandler(FUNNEL_HANDLER_DEFAULT);
      setSubmitted(false);
      handleGetFunnel();
    },
    [handleGetFunnel, terms.termoLead],
  );

  const handleDeleteFunnel = useCallback((id: string) => {
    setOpenModalConfirmExclude(true);

    setIdDeleted(id);
  }, []);

  const handleConfirmFunnelDeleted = useCallback(async () => {
    try {
      setLoading(true);
      await FunnelService.deleteFunnel(idDeleted);

      await handleGetFunnel();

      feedback('Funil deletado com sucesso', 'success');
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
    setLoading(false);
    setIdDeleted('');
    setOpenModalConfirmExclude(false);
  }, [handleGetFunnel, idDeleted]);

  useEffect(() => {
    setTitle(`Funil`);
    handleGetFunnel();
  }, []);

  useEffect(() => {
    handleGetFunnel();
  }, [filter, tableFiltered, skip, take]);

  useEffect(() => {
    if (openModal && !editModal) {
      setFunnelHandler(FUNNEL_HANDLER_DEFAULT);
    }
  }, [openModal, editModal]);

  const handleCloseModal = () => {
    if (editModal) {
      setEditModal(false);
      setModalTitle(title.isEdit);
      setFunnelHandler(FUNNEL_HANDLER_DEFAULT);
    }
    setSubmitted(false);

    setOpenModal(false);
    setFunnel(FUNNEL_DEFAULT);
  };

  const handleOpenFunnelSteps = (
    funnelId: string,
    funnelHandler: IFunnelList,
  ) => {
    navigate('/funil/etapas', {
      state: {
        funnelId,
        funnelHandler,
      },
    });
  };

  return (
    <>
      <Header
        filter={filter}
        setFilter={setFilter}
        disabledButton={!canRegister}
        tableFiltered={tableFiltered}
        setTableFiltered={setTableFiltered}
        onClick={() => (
          setOpenModal((state) => !state), setModalTitle(title.default)
        )}
      />

      <Box marginTop={5} />

      {loading ? (
        <TableSkeleton btnAct={2} columns={5} rowsBody={10} rectWidth="50%" />
      ) : (
        <Table
          head={[
            {label: 'Ações', width: '120px'},
            {label: `Nome`},
            {label: `Utilizado?`, width: '160px'},
            {label: 'Estabelecimento', width: '30%'},
            {label: 'Status', width: '140px'},
          ]}
          pagination={{
            count: Math.ceil(funnelsCount / take),
            page: skip,
          }}
          length={funnelsCount}
          loading={loading}
          take={take}
          rowsPerPage={funnels.data.length}
          onChangeTake={(value: number) => setTake(value)}
          onChangePage={(value: number) => setSkip(value)}>
          {funnels.data.map((item) => (
            <TableRow key={item.id}>
              <TableCell>
                <IconButton
                  color="primary"
                  onClick={() => handleDeleteFunnel(item.id)}
                  disabled={loading || !canDelete}>
                  <Delete />
                  {loading && idDeleted === item.id && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </IconButton>

                <IconButton
                  style={{marginLeft: theme.spacing(1)}}
                  color="primary"
                  onClick={() => {
                    handleOpenFunnelSteps(item.id, {
                      ...item,
                    });
                    setFunnel(item);
                  }}
                  data-funnel-id={item.id}
                  disabled={loading || !canEdit}>
                  <Edit />
                </IconButton>
              </TableCell>
              <TableCell>{item.nome}</TableCell>
              <TableCell>{item.utilizado}</TableCell>
              <TableCell>{item.estabelecimento}</TableCell>
              <TableCell>{item.status}</TableCell>
            </TableRow>
          ))}
        </Table>
      )}

      <ModalConfirm
        opened={openModalConfirmExclude}
        onClick={handleConfirmFunnelDeleted}
        onClose={() => {
          setOpenModalConfirmExclude(false), setIdDeleted('');
        }}
        loading={loading}
      />
      {funnel && (
        <ModalCreateFunnel
          openModal={openModal}
          loading={loading}
          submitted={submitted}
          edit={editModal}
          title={modalTitle}
          nameVerify={handleVerifyNameFunnel()}
          onClose={handleCloseModal}
          onClick={() => handleCreateFunnel(funnelHandler)}
          funnel={funnel}
          funnelHandler={funnelHandler}
          setFunnelHandler={setFunnelHandler}
        />
      )}
    </>
  );
};
