import React, {
  ChangeEvent,
  useCallback,
  DragEvent,
  useEffect,
  useState,
} from 'react';
import {
  Box,
  Grid,
  Slider,
  Tooltip,
  useTheme,
  Typography,
} from '@material-ui/core';
import Cropper from 'react-easy-crop';
import {Area} from 'react-easy-crop/types';
import {Add, Remove} from '@material-ui/icons';

import getCroppedImg from './cropImage';
import {Button} from 'shared/components';
import {feedback} from 'shared/services/alertService';

interface IUploadImageProps {
  setImage(value: string): void;

  aspect: number | undefined;
  imagePreview: string;

  onClose(): void;

  height: number;
  width: number;
  id: string;
}

export const UploadImage: React.FC<IUploadImageProps> = ({
  imagePreview,
  setImage,
  onClose,
  aspect,
  height,
  width,
  id,
}) => {
  const [zoom, setZoom] = useState(1);
  const [crop, setCrop] = useState({x: 0, y: 0});
  const [previewUrl, setPreviewUrl] = useState(imagePreview);
  const [croppedImage, setCroppedImage] = useState<string | null>(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);

  const theme = useTheme();

  useEffect(() => {
    croppedImage && setImage(croppedImage);
  }, [croppedImage, setImage]);

  const onCropComplete = useCallback((_, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  function onZoomChange(zoom: number | number[]) {
    if (!Array.isArray(zoom)) {
      setZoom(zoom);
    }
  }

  const saveCroppedImage = useCallback(async () => {
    if (croppedAreaPixels != null && previewUrl) {
      try {
        const cropImg = await getCroppedImg(previewUrl, croppedAreaPixels);
        setCroppedImage(cropImg);
        onClose();
      } catch (error: any) {
        feedback(`Formato do arquivo ${error.message} não suportado.`, 'error');
      }
    }
  }, [croppedAreaPixels, onClose, previewUrl]);

  const dropImage = useCallback((event: DragEvent<HTMLInputElement>) => {
    event.preventDefault();

    const imageDropped = event.dataTransfer.items[0];
    const supportedFileTypes = ['image/png', 'image/jpg', 'image/jpeg'];

    if (
      supportedFileTypes.includes(imageDropped.type) &&
      imageDropped.getAsFile() !== null
    ) {
      setPreviewUrl(
        URL.createObjectURL(imageDropped.getAsFile() as Blob | MediaSource),
      );
    } else {
      feedback(
        `Formato do arquivo ${imageDropped.type} não suportado.`,
        'error',
      );
    }
  }, []);

  const imageChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setPreviewUrl(URL.createObjectURL(file));
    }
    event.target.value = '';
  }, []);

  return (
    <>
      <input
        onChange={imageChange}
        accept="image/*"
        id={id}
        type="file"
        hidden
      />
      {previewUrl ? (
        <>
          <Box position="relative" width="100%" height="300px">
            <Cropper
              crop={crop}
              zoom={zoom}
              aspect={aspect}
              image={previewUrl}
              onCropChange={setCrop}
              restrictPosition={false}
              onCropComplete={onCropComplete}
              // zoomWithScroll={true}
              // cropSize={{width: width, height: height}}
            />
          </Box>
          <Box p={1}>
            <Typography align="center" variant="body2" component={Box}>
              Arraste para posicionar
            </Typography>
          </Box>
          <Box p={1}>
            <Box display="flex" justifyContent="center">
              <Box maxWidth="500px" width="100%">
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <Remove />
                  </Grid>
                  <Grid item xs>
                    <Slider
                      defaultValue={1}
                      aria-labelledby="discrete-slider"
                      step={0.1}
                      min={1}
                      max={5}
                      onChange={(e, zoom) => onZoomChange(zoom)}
                    />
                  </Grid>
                  <Grid item>
                    <Add />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <label htmlFor={id}>
          <Tooltip
            title={`Dimensão mínima recomendada: ${width}px x ${height}px`}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="400px"
              bgcolor="#00000041"
              borderColor={theme.palette.primary.main}
              style={{
                cursor: 'pointer',
              }}
              onDrop={dropImage}
              onDragOver={(e) => e.preventDefault()}>
              <Typography
                variant="subtitle1"
                style={{
                  userSelect: 'none',
                  color: theme.palette.primary.contrastText,
                }}>
                Arraste e solte uma imagem
              </Typography>
            </Box>
          </Tooltip>
        </label>
      )}
      <Box display="flex" p={3} justifyContent="flex-end" gridGap={24}>
        <Button label="Cancelar" variant="outlined" onClick={onClose} />
        <Button label="Adicionar" onClick={saveCroppedImage} />
      </Box>
    </>
  );
};
