import React, {useCallback, useEffect, useState} from 'react';
import {
  Box,
  Grid,
  MenuItem,
  TableRow,
  TextField,
  IconButton,
  Typography,
} from '@material-ui/core';
import {Edit, Event} from '@material-ui/icons';
import {DatePicker} from '@material-ui/pickers';
import {EditModal} from './components/EditModal';
import {Skeleton, TableCell} from '@mui/material';
import {Footer, Table, TableSkeleton} from 'shared/components';
import {useTitle} from 'shared/hooks';
import {useNavigate, useParams} from 'react-router-dom';
import {feedback} from 'shared/services/alertService';
import {NumberFormatValues, NumericFormat} from 'react-number-format';
import {errorResponse} from 'shared/utils/errorResponse';
import {
  FeeService,
  IConfigTaxa,
  IFeeOfersData,
  IPut,
} from '../../shared/services/api/BillingFee';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import {format} from 'date-fns';
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date';
import {
  IParcelas,
  ParcelaService,
} from '../../shared/services/api/BillingFee/Parcelas';
import {formatCurrency} from 'shared/utils/moneyFormat';

export const BillingFee: React.FC = () => {
  const navigate = useNavigate();
  const {setTitle} = useTitle();
  const {id = '0'} = useParams<'id'>();
  const [loading, setLoading] = useState(false);
  const [inativo, setInativo] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [ofers, setOfers] = useState<IFeeOfersData[]>([]);
  const [ofertaId, setOfertaId] = useState('');
  const [modalTitulo, setModalTitulo] = useState('');
  const [configTaxa, setConfigTaxa] = useState<IConfigTaxa>({
    competencia: [],
    dataVencimento: '',
    dataVigenciaFinal: '',
    dataVigenciaInicial: '',
    id: '',
    nome: '',
    numeroDiasVencimento: 0,
    valorCampanha: 0,
    vencimento: [],
  });
  const [take, setTake] = useState(5);
  const [skip, setSkip] = useState(1);
  const [possuiPix, setPossuiPix] = useState(false);
  const [possuiCartao, setPossuiCartao] = useState(false);
  const [OfferCount, setCOfferCount] = useState(10);

  const [oferValue, setOferValue] = useState('');
  const [idSituacaoValorOferta, setIdSituacaoValorOferta] = useState('');
  const [parcela, setParcela] = useState<IParcelas>({
    situacoesDasParcelas: [
      {
        codigoSituacaoParcela: '',
        descricao: '',
        selecionado: false,
      },
    ],
    tipoDeParcelas: [
      {codigoTipoParcela: '', descricao: '', selecionado: false},
    ],
  });

  const [put, setPut] = useState<IPut>({
    codigoSituacaoParcela: '',
    codigoTipoParcela: '',
    dataVencimento: '',
    idConfiguracaoTaxa: '',
    idTipoCompetencia: 0,
    idTipoVencimento: 0,
    numeroDiasVencimento: 0,
    valorCampanha: 0,
  });

  useEffect(() => setTitle('Cadastro de taxa'), [setTitle]);

  const [vencimentoDate, setVencimentoDate] = useState<string | undefined>(
    format(Date.now(), "yyyy-MM-dd'T'HH:mm:ss"),
  );

  useEffect(() => {
    setLoading(true);
    async function getFeeConfiguration() {
      setLoading(true);
      try {
        const response = await FeeService.getConfiguracaoTaxa(id);
        setConfigTaxa(response);
        setVencimentoDate(response.dataVencimento);
        setPut(
          (state) =>
            (state = {
              ...state,
              idConfiguracaoTaxa: response.id,
              idTipoVencimento:
                response.vencimento.find((item) => item.selecionado)
                  ?.idTipoVencimento || 0,
              idTipoCompetencia:
                response.competencia.find((item) => item.selecionado)
                  ?.idTipoCompetencia || 0,
              valorCampanha: response.valorCampanha,
              numeroDiasVencimento: response.numeroDiasVencimento,
            }),
        );
      } catch (error) {
        feedback(errorResponse(error), 'error');
      } finally {
        ``;
      }
    }
    getFeeConfiguration();
  }, [id]);

  useEffect(() => {
    setLoading(true);
    async function getOfertas() {
      setLoading(true);
      try {
        const response = await FeeService.getOfertasTaxa(id, skip, take);
        setOfers(response.data);
        setCOfferCount(response.qtdTotalDeRegistros);
      } catch (error) {
        feedback(errorResponse(error), 'error');
      } finally {
        setLoading(false);
      }
    }
    getOfertas();
  }, [id, skip, take, isOpenModal]);

  useEffect(() => {
    setLoading(true);

    async function getConfigParcela() {
      setLoading(true);
      try {
        const response = await ParcelaService.getConfigParcela(configTaxa?.id);
        setParcela(response);
        setPut(
          (state) =>
            (state = {
              ...state,
              codigoTipoParcela:
                response.tipoDeParcelas.find((item) => item.selecionado)
                  ?.codigoTipoParcela || '',
              codigoSituacaoParcela:
                response.situacoesDasParcelas.find((item) => item.selecionado)
                  ?.codigoSituacaoParcela || '',
            }),
        );
      } catch (error) {
        feedback('Carregando', 'loading');
      } finally {
        setLoading(false);
        feedback('Carregado', 'success');
      }
    }

    getConfigParcela();
  }, [configTaxa.id]);

  useEffect(() => {
    setPut(
      (state) =>
        (state = {
          ...state,
          dataVencimento: vencimentoDate ? vencimentoDate : '',
        }),
    );
  }, [vencimentoDate, put.dataVencimento]);

  useEffect(() => {
    setPut(
      (state) =>
        (state = {
          ...state,
          numeroDiasVencimento:
            put.idTipoVencimento === 1 ? 0 : put.numeroDiasVencimento,
        }),
    );
  }, [put.idTipoVencimento, put.numeroDiasVencimento]);

  const handleStringToNumberMapper = (value: string) => {
    return Number(value.replace(',', '.'));
  };

  const putConfig = useCallback(async () => {
    setIsSaving(true);
    feedback('Carregando', 'loading');
    try {
      await FeeService.putConfigTaxa(
        {
          ...put,
          dataVencimento:
            put.numeroDiasVencimento === 0
              ? put.dataVencimento === undefined
                ? ''
                : put.dataVencimento
              : '',
          valorCampanha: handleStringToNumberMapper(
            put.valorCampanha.toString(),
          ),
        },
        put.idConfiguracaoTaxa,
      );
      feedback('Salvo', 'success');
    } catch (error) {
      feedback(errorResponse(error), 'error');
    } finally {
      setIsSaving(false);
    }
  }, [id, put]);

  const [valor, setValor] = useState('0');

  const formatarParaBRL = (valor: any) => {
    if (valor === '') {
      return 'R$ 0,00';
    } else {
      return valor.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });
    }
  };

  useEffect(() => {
    setValor(formatarParaBRL(put.valorCampanha));
  }, [valor, put.valorCampanha]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid>
          <Box marginBottom={1} marginTop={1}>
            <Typography variant="h4" color="secondary">
              <b>Dados da campanha</b>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12}></Grid>

        <Grid container xs={6}>
          {loading ? (
            <Skeleton variant="rectangular" height={36} />
          ) : (
            <Box width={'100%'} marginLeft={1}>
              <TextField
                InputProps={{readOnly: true}}
                fullWidth
                label="Campanha"
                disabled
                variant="outlined"
                value={configTaxa?.nome || ''}
              />
            </Box>
          )}
          <Box marginBottom={3} />
        </Grid>

        <Grid container xs={12} item>
          <Grid item xs={3}>
            {loading ? (
              <Skeleton variant="rectangular" height={36} />
            ) : (
              <TextField
                fullWidth
                InputProps={{
                  readOnly: true,
                  endAdornment: <Event color="disabled" />,
                }}
                label="Vigência inicial"
                variant="outlined"
                disabled
                value={
                  configTaxa?.dataVigenciaInicial
                    .toString()
                    .substring(0, 10)
                    .split('-')
                    .reverse()
                    .join('/') || ''
                }
              />
            )}
            <Box marginBottom={1} />
          </Grid>
          <Grid item xs={3}>
            {loading ? (
              <Skeleton variant="rectangular" height={36} />
            ) : (
              <Box marginLeft={1}>
                <TextField
                  fullWidth
                  disabled
                  label="Vigência final"
                  InputProps={{
                    readOnly: true,
                    endAdornment: <Event color="disabled" />,
                  }}
                  variant="outlined"
                  value={
                    configTaxa?.dataVigenciaFinal
                      .toString()
                      .substring(0, 10)
                      .split('-')
                      .reverse()
                      .join('/') || ''
                  }
                />
              </Box>
            )}
            <Box marginBottom={1} />
          </Grid>
        </Grid>

        <Grid item xs>
          <Box marginBottom={3} marginTop={2}>
            <Typography variant="h5">
              <b>Configuração de valores</b>
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container xs={3}>
        {loading ? (
          <Skeleton variant="rectangular" height={36} />
        ) : (
          <NumericFormat
            fullWidth
            variant="outlined"
            label="Valor geral da campanha"
            value={put?.valorCampanha}
            onValueChange={(a: NumberFormatValues) =>
              setPut((prev) => ({
                ...prev,
                valorCampanha: a.formattedValue.toString(),
              }))
            }
            customInput={TextField}
            InputProps={{
              startAdornment: 'R$',
            }}
            decimalScale={2}
            decimalSeparator=","
            allowNegative={false}
            fixedDecimalScale={true}
          />
        )}
        <Box marginBottom={1} />
      </Grid>

      <Grid item xs={12} sm={12}>
        {loading ? (
          <TableSkeleton btnAct={2} columns={4} rowsBody={4} colStatus />
        ) : (
          <Box marginTop={6} marginBottom={4}>
            <Table
              head={[
                {label: 'Ações', width: '5%'},
                {label: 'Oferta', width: '60%'},
                {label: 'Valor Oferta', width: '30%'},
                {label: 'Estabelecimento', width: '30%'},
                {label: 'Complemento1', width: '100%'},
                {label: 'Complemento2', width: '100%'},
                {label: 'Situação', width: '10%'},
              ]}
              pagination={{
                count: Math.ceil(OfferCount / take),
                page: skip,
              }}
              take={take}
              length={OfferCount}
              rowsPerPage={OfferCount}
              onChangePage={(value: number) => setSkip(value)}
              onChangeTake={(value: number) => {
                setTake(value);
                setSkip(1);
              }}>
              {ofers.length === 0 ? (
                <TableRow></TableRow>
              ) : (
                ofers.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>
                      <IconButton
                        color="primary"
                        //disabled={loading || !isEditing}
                        onClick={() => {
                          setOferValue(String(item.valorOferta));
                          setIdSituacaoValorOferta(
                            String(item.idSituacaoValorOferta),
                          );
                          setPossuiPix(item.possuiPix);
                          setPossuiCartao(item.possuiCartaoCredito),
                            setIsOpenModal(true),
                            setOfertaId(item.id),
                            setInativo(item.ativo);
                          setModalTitulo(
                            `${item?.nomeCurso} 
                            ${item?.nomeCiclo === null ? '' : item?.nomeCiclo}
                            
                            ${item?.nomeTurno != null ? item?.nomeTurno : ''}
                            `,
                          );
                        }}>
                        <Edit />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      {item.nomeCurso}
                      {item.nomeCiclo ? ` - ${item.nomeCiclo}` : ''}
                      {item.nomeTurno ? ` - ${item.nomeTurno}` : ''}
                    </TableCell>
                    <TableCell>
                      {item.idSituacaoValorOferta === 0 ? (
                        <Typography>
                          {valor.includes('R$') ? valor : 'R$' + ' ' + valor}
                        </Typography>
                      ) : item.idSituacaoValorOferta === 2 ? (
                        <Typography>Isento</Typography>
                      ) : item.idSituacaoValorOferta === null ? (
                        <Typography>Não configurado</Typography>
                      ) : (
                        <Typography>
                          {formatCurrency(Number(item.valorOferta))}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell>{item.nomeEstabelecimento}</TableCell>
                    <TableCell>
                      {item.complementos1DaOferta
                        .map((item) => item.nome)
                        .join(',')
                        .replaceAll(',', ', ')}
                    </TableCell>
                    <TableCell>
                      {item.complementos2DaOferta
                        .map((item) => item.nome)
                        .join(',')
                        .replaceAll(',', ', ')}
                    </TableCell>
                    <TableCell>{item.ativo ? 'Ativo' : 'Inativo'}</TableCell>
                  </TableRow>
                ))
              )}
            </Table>
          </Box>
        )}
      </Grid>
      <Grid item xs>
        <Box marginBottom={3} marginTop={1}>
          <Typography variant="h5">
            <b>Configuração da parcela</b>
          </Typography>
        </Box>
      </Grid>

      <Grid container item xs spacing={3}>
        <Grid item xs>
          {loading ? (
            <Skeleton variant="rectangular" height={36} />
          ) : (
            <TextField
              fullWidth
              label="Tipo de parcela"
              variant="outlined"
              value={put.codigoTipoParcela || ''}
              select>
              {parcela?.tipoDeParcelas?.map((item) => (
                <MenuItem
                  key={item.codigoTipoParcela}
                  value={item.codigoTipoParcela}
                  onClick={() => {
                    setPut(
                      (state) =>
                        (state = {
                          ...state,
                          codigoTipoParcela: item.codigoTipoParcela,
                        }),
                    );
                  }}>
                  {item.descricao}
                </MenuItem>
              ))}
            </TextField>
          )}
          <Box marginBottom={1} />
        </Grid>

        <Grid item xs>
          {loading ? (
            <Skeleton variant="rectangular" height={36} />
          ) : (
            <TextField
              fullWidth
              label="Situação da parcela"
              variant="outlined"
              value={put.codigoSituacaoParcela || ''}
              select>
              {parcela?.situacoesDasParcelas?.map((item) => (
                <MenuItem
                  key={item.codigoSituacaoParcela}
                  value={item.codigoSituacaoParcela}
                  onClick={() => {
                    setPut(
                      (state) =>
                        (state = {
                          ...state,
                          codigoSituacaoParcela: item.codigoSituacaoParcela,
                        }),
                    );
                  }}>
                  {item.descricao}
                </MenuItem>
              ))}
            </TextField>
          )}
          <Box marginBottom={1} />
        </Grid>
      </Grid>

      <Grid item xs>
        <Box marginBottom={3} marginTop={4}>
          <Typography variant="h5">
            <b>Vencimento</b>
          </Typography>
        </Box>
      </Grid>

      <Grid container item xs spacing={3}>
        <Grid item xs>
          {loading ? (
            <Skeleton variant="rectangular" height={36} />
          ) : (
            <TextField
              fullWidth
              label="Tipo de vencimento"
              variant="outlined"
              value={put.idTipoVencimento || ''}
              select>
              {configTaxa?.vencimento.map((item) => (
                <MenuItem
                  key={item.idTipoVencimento}
                  value={item.idTipoVencimento}
                  onClick={() => {
                    setPut(
                      (state) =>
                        (state = {
                          ...state,
                          idTipoVencimento: item.idTipoVencimento,
                        }),
                    );
                  }}>
                  {item.nomeTipoVencimento}
                </MenuItem>
              ))}
            </TextField>
          )}
          <Box marginBottom={1} />
        </Grid>

        <Grid item xs={3}>
          {loading ? (
            <Skeleton variant="rectangular" height={36} />
          ) : (
            <Box>
              {loading ? (
                <Skeleton variant="rectangular" height={36} />
              ) : (
                <DatePicker
                  autoOk
                  fullWidth
                  disablePast
                  disabled={put.idTipoVencimento != 1}
                  label="Data de vencimento"
                  format="dd/MM/yyyy"
                  animateYearScrolling
                  inputVariant="outlined"
                  value={
                    vencimentoDate === null ? null : vencimentoDate || undefined
                  }
                  InputProps={{
                    endAdornment: <Event color="primary" />,
                  }}
                  minDateMessage={'A data não deve ser anterior à data atual'}
                  onChange={(value: MaterialUiPickersDate) => {
                    setVencimentoDate(
                      format(new Date(value as Date), "yyyy-MM-dd'T'HH:mm:ss"),
                    );
                  }}
                />
              )}
            </Box>
          )}
        </Grid>

        <Grid item xs={3}>
          {loading ? (
            <Skeleton variant="rectangular" height={36} />
          ) : (
            <TextField
              fullWidth
              label="Nº de dias"
              disabled={
                !(put.idTipoVencimento != 1) || put.idTipoVencimento === 0
              }
              variant="outlined"
              value={put.numeroDiasVencimento || ''}
              onChange={({target}) =>
                setPut(
                  (state) =>
                    (state = {
                      ...state,
                      numeroDiasVencimento: Number(target.value),
                    }),
                )
              }
            />
          )}
          <Box marginBottom={1} />
        </Grid>
      </Grid>

      <Grid item xs>
        <Box marginBottom={2} marginTop={2}>
          <Typography variant="h5">
            <b>Competência</b>
          </Typography>
        </Box>
      </Grid>

      <Grid container item xs spacing={3}>
        <Grid item xs={6}>
          {loading ? (
            <Skeleton variant="rectangular" height={36} />
          ) : (
            <TextField
              fullWidth
              label="Tipo de competência"
              variant="outlined"
              value={put.idTipoCompetencia || ''}
              select>
              {configTaxa?.competencia.map((item) => (
                <MenuItem
                  key={item.idTipoCompetencia}
                  value={item.idTipoCompetencia}
                  onClick={() =>
                    setPut(
                      (state) =>
                        (state = {
                          ...state,
                          idTipoCompetencia: item.idTipoCompetencia,
                        }),
                    )
                  }>
                  {item.nomeCompetencia}
                </MenuItem>
              ))}
            </TextField>
          )}
          <Box marginBottom={1} />
        </Grid>
      </Grid>

      <EditModal
        inativo={inativo}
        idSituacaoValorOferta={Number(idSituacaoValorOferta)}
        offerValue={oferValue}
        possuiCartao={possuiCartao}
        possuiPix={possuiPix}
        id={ofertaId}
        titulo={modalTitulo}
        isOpen={isOpenModal}
        isLoading={loading}
        handleIsLoading={setLoading}
        handleClose={() => setIsOpenModal(false)}
      />

      <Footer
        disabled={isSaving}
        onSaveLabel={'Salvar'}
        onGoBack={() => {
          navigate('/taxa-de-cobranca');
        }}
        goBackStartIcone={<KeyboardBackspaceIcon />}
        goBackLabel="Voltar"
        onSave={() => putConfig()}></Footer>
    </>
  );
};
