import React from 'react';

import HorizontalBar from 'react-chartjs-2';

import {useStyles} from './styles';
import {IChartProps} from './ChartType';

export const HorizontalBarChart: React.FC<IChartProps> = ({
  labels,
  datasets,
  options,
  width,
  height,
  showDataLabels,
  showAsPercentage,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.chartWrapper}>
      <HorizontalBar
        data={{labels, datasets}}
        type="bar"
        width={width}
        height={height}
        options={{
          ...options,
          layout: {
            padding: {
              left: 1,
              right: 50,
              top: 20,
              bottom: 20,
            },
          },
          scales: {
            x: {
              grid: {
                display: false,
              },
            },
            y: {
              ticks: {
                autoSkip: false,
                font: {
                  size: 15,
                },
              },
              grid: {
                display: false,
              },
            },
          },
          indexAxis: 'y',
          elements: {
            bar: {
              borderWidth: 2,
            },
          },
          datasets: {
            bar: {
              barPercentage: 0.5,
              backgroundColor: [
                '#018781',
                '#00B9AD',
                '#4285F4',
                '#F4405C',
                '#54BDD5',
                '#EB5333',
              ],
              borderWidth: 1,
              borderColor: [
                '#018781',
                '#00B9AD',
                '#4285F4',
                '#F4405C',
                '#54BDD5',
                '#EB5333',
              ],
              hoverBackgroundColor: [
                '#018781',
                '#00B9AD',
                '#4285F4',
                '#F4405C',
                '#54BDD5',
                '#EB5333',
              ],
              hoverBorderColor: [
                '#018781',
                '#00B9AD',
                '#4285F4',
                '#F4405C',
                '#54BDD5',
                '#EB5333',
              ],
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            datalabels: {
              padding: {
                left: 0,
              },
              display: showDataLabels,
              color: '#414141',
              align: 'end',
              anchor: 'end',
              labels: {
                title: {
                  font: {
                    weight: 'normal',
                  },
                },
                value: {
                  color: 'green',
                },
              },
              formatter: (value) => (showAsPercentage ? `${value}%` : value),
            },
            tooltip: {
              callbacks: {
                label: (context) =>
                  showAsPercentage
                    ? `${context.dataset.label}: ${context.formattedValue}%`
                    : `${context.dataset.label}: ${context.formattedValue}`,
              },
            },
          },
        }}
      />
    </div>
  );
};
