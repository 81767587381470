import {
  Box,
  Grid,
  Dialog,
  Button,
  Typography,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import React, {useCallback, useEffect, useState} from 'react';
import CloseIcon from '@material-ui/icons/Close';
import {
  CRMDashboardService,
  IConversionRate,
  ICores,
  IPostConversionRate,
} from 'shared/services/api/CRMDashboard';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import {feedback} from 'shared/services/alertService';
import {Skeleton} from '@material-ui/lab';

interface IProps {
  openModal: boolean;
  onclose(): void;
}

export const LeadsWithoutConfig: React.FC<IProps> = ({openModal, onclose}) => {
  const [loading, setLoading] = useState(true);
  const [conversionRate, setConversionRate] = useState<IConversionRate>();

  const [vermelhoInicio, setVermelhoInicio] = useState<number>(0);
  const [vermelhoFim, setVermelhoFim] = useState<number>(0);

  const [verdeInicio, setVerdeInicio] = useState<number>(0);
  const [verdeFim, setVerdeFim] = useState<number>(0);

  const [amareloInicio, setAmareloInicio] = useState<number>(0);
  const [amareloFim, setAmareloFim] = useState<number>(0);

  const LeadsWithoutConfigList = useCallback(async () => {
    setLoading(true);
    CRMDashboardService.getLeadsWithoutConfig()
      .then((response) => {
        setConversionRate(response);
        setVerdeInicio(response.cores[0].inicio);
        setVerdeFim(response.cores[0].fim);
        setAmareloInicio(response.cores[1].inicio);
        setAmareloFim(response.cores[1].fim);
        setVermelhoInicio(response.cores[2].inicio);
        setVermelhoFim(response.cores[2].fim);
      })
      .catch(() => feedback('Erro ao buscar dados', 'error'))
      .finally(() => setLoading(false));
  }, []);

  const [putCores, setPutCores] = useState<IPostConversionRate>(
    {} as IPostConversionRate,
  );

  const [cores, setCores] = useState<ICores[]>([]);

  const handleVerdeInicio = (value: number) => {
    setVerdeInicio(value);
  };

  const handleVerdeFim = (value: number) => {
    setVerdeFim(value);
  };

  const handleAmareloInicio = (value: number) => {
    setAmareloInicio(value);
  };

  const handleAmareloFim = (value: number) => {
    setAmareloFim(value);
  };

  const handleVermelhoInicio = (value: number) => {
    setVermelhoInicio(value);
  };

  const handleVermelhoFim = (value: number) => {
    setVermelhoFim(value);
  };

  const sentPost = useCallback(async () => {
    try {
      const response = await CRMDashboardService.postTaxaConversao(
        putCores,
        'leadssematendimento',
      );
      if (response) {
        feedback('Salvo com sucesso', 'success');

        onclose();
      } else {
        feedback('Não foi possível salvar', 'error');
      }
    } catch (error: any) {
      feedback('Não foi possível salvar', 'error');
    }
  }, [onclose, putCores]);

  useEffect(() => {
    conversionRate &&
      setPutCores({
        chave: 'leadssematendimento',
        periodicidade: '',
        valor: 0,
        cores: cores,
      });
  }, [cores, conversionRate]);

  useEffect(() => {
    const coresArray: ICores[] = [
      {nome: 'verde', inicio: verdeInicio, fim: verdeFim},
      {nome: 'amarelo', inicio: amareloInicio, fim: amareloFim},
      {nome: 'vermelho', inicio: vermelhoInicio, fim: vermelhoFim},
    ];
    conversionRate && setCores(coresArray);
  }, [
    verdeInicio,
    verdeFim,
    vermelhoInicio,
    vermelhoFim,
    amareloInicio,
    amareloFim,
    conversionRate,
  ]);

  return (
    <>
      <Dialog
        maxWidth="xl"
        open={openModal}
        onClose={onclose}
        onEntering={LeadsWithoutConfigList}
        aria-labelledby="Leads Sem Atendimento Config"
        aria-describedby="Leads Sem Atendimento Config">
        <DialogTitle id="Leads Sem Atendimento Config">
          <Box width="100%" display={'flex'} justifyContent="space-between">
            <Box>
              <Typography variant="inherit" color="primary">
                Leads sem interação a mais de uma semana
              </Typography>
            </Box>
            <Box>
              <Button color="primary" onClick={onclose}>
                <CloseIcon />
              </Button>
            </Box>
          </Box>
        </DialogTitle>

        <DialogContent>
          <Grid container direction="column">
            <Box marginRight={2} marginBottom={2}>
              <Box marginLeft={4} marginRight={3} marginBottom={4}>
                <Box marginLeft={1}>
                  <Typography variant="subtitle1">
                    Defina os intervalos de valores do indicador para cor
                  </Typography>
                </Box>

                <Grid container item>
                  <Box display={'flex'} alignItems={'center'}>
                    <Grid item>
                      <Box marginTop={2} marginBottom={2}>
                        {loading ? (
                          <FormControl
                            disabled
                            fullWidth
                            key="min-verde-loading"
                            size="small"
                            variant="outlined">
                            <InputLabel>
                              <Skeleton variant="text" width={40} />
                            </InputLabel>
                            <OutlinedInput
                              label={<Skeleton variant="text" width={40} />}
                              type="string"
                            />
                          </FormControl>
                        ) : (
                          <FormControl
                            fullWidth
                            key="min-verde"
                            size="small"
                            variant="outlined">
                            <InputLabel>Mínimo verde</InputLabel>
                            <OutlinedInput
                              label="Mínimo verde"
                              type="string"
                              value={verdeInicio || 0}
                              onChange={(e) =>
                                handleVerdeInicio(Number(e.target.value))
                              }
                            />
                          </FormControl>
                        )}
                      </Box>
                    </Grid>
                    <Box marginLeft={1} marginRight={1}>
                      <Typography variant="body2"> a </Typography>
                    </Box>
                    <Grid item>
                      <Box marginTop={2} marginBottom={2}>
                        {loading ? (
                          <FormControl
                            disabled
                            fullWidth
                            key="max-verde-loading"
                            size="small"
                            variant="outlined">
                            <InputLabel>
                              <Skeleton variant="text" width={40} />
                            </InputLabel>
                            <OutlinedInput
                              label={<Skeleton variant="text" width={40} />}
                              type="string"
                            />
                          </FormControl>
                        ) : (
                          <FormControl
                            fullWidth
                            key="max-verde"
                            size="small"
                            variant="outlined">
                            <InputLabel>Máximo verde</InputLabel>
                            <OutlinedInput
                              label="Máximo verde"
                              type="string"
                              value={verdeFim || 0}
                              onChange={(e) =>
                                handleVerdeFim(Number(e.target.value))
                              }
                            />
                          </FormControl>
                        )}
                      </Box>
                    </Grid>
                  </Box>
                </Grid>
                <Grid container item>
                  <Box display={'flex'} alignItems={'center'}>
                    <Grid item>
                      <Box marginTop={2} marginBottom={2}>
                        {loading ? (
                          <FormControl
                            disabled
                            fullWidth
                            key="min-amarelo-loading"
                            size="small"
                            variant="outlined">
                            <InputLabel>
                              <Skeleton variant="text" width={40} />
                            </InputLabel>
                            <OutlinedInput
                              label={<Skeleton variant="text" width={40} />}
                              type="string"
                            />
                          </FormControl>
                        ) : (
                          <FormControl
                            fullWidth
                            key="min-amarelo"
                            size="small"
                            variant="outlined">
                            <InputLabel>Mínimo amarelo</InputLabel>
                            <OutlinedInput
                              label="Mínimo amarelo"
                              type="string"
                              value={amareloInicio || 0}
                              onChange={(e) =>
                                handleAmareloInicio(Number(e.target.value))
                              }
                            />
                          </FormControl>
                        )}
                      </Box>
                    </Grid>
                    <Box marginLeft={1} marginRight={1}>
                      <Typography variant="body2"> a </Typography>
                    </Box>
                    <Grid item>
                      <Box marginTop={2} marginBottom={2}>
                        {loading ? (
                          <FormControl
                            disabled
                            fullWidth
                            key="max-amarelo-loading"
                            size="small"
                            variant="outlined">
                            <InputLabel>
                              <Skeleton variant="text" width={40} />
                            </InputLabel>
                            <OutlinedInput
                              label={<Skeleton variant="text" width={40} />}
                              type="string"
                            />
                          </FormControl>
                        ) : (
                          <FormControl
                            fullWidth
                            key="max-amarelo"
                            size="small"
                            variant="outlined">
                            <InputLabel>Máximo amarelo</InputLabel>
                            <OutlinedInput
                              label="Máximo amarelo"
                              type="string"
                              value={amareloFim || 0}
                              onChange={(e) =>
                                handleAmareloFim(Number(e.target.value))
                              }
                            />
                          </FormControl>
                        )}
                      </Box>
                    </Grid>
                  </Box>
                </Grid>
                <Grid container item>
                  <Box display={'flex'} alignItems={'center'}>
                    <Grid item>
                      <Box marginTop={2} marginBottom={2}>
                        {loading ? (
                          <FormControl
                            disabled
                            fullWidth
                            key="min-vermelho-loading"
                            size="small"
                            variant="outlined">
                            <InputLabel>
                              <Skeleton variant="text" width={40} />
                            </InputLabel>
                            <OutlinedInput
                              label={<Skeleton variant="text" width={40} />}
                              type="string"
                            />
                          </FormControl>
                        ) : (
                          <FormControl
                            fullWidth
                            key="min-vermelho"
                            size="small"
                            variant="outlined">
                            <InputLabel>Mínimo vermelho</InputLabel>
                            <OutlinedInput
                              label="Mínimo vermelho"
                              type="string"
                              value={vermelhoInicio || 0}
                              onChange={(e) =>
                                handleVermelhoInicio(Number(e.target.value))
                              }
                            />
                          </FormControl>
                        )}
                      </Box>
                    </Grid>
                    <Box marginLeft={1} marginRight={1}>
                      <Typography variant="body2"> a </Typography>
                    </Box>

                    <Grid item>
                      <Box marginTop={2} marginBottom={2}>
                        {loading ? (
                          <FormControl
                            disabled
                            fullWidth
                            key="max-vermelho-loading"
                            size="small"
                            variant="outlined">
                            <InputLabel>
                              <Skeleton variant="text" width={40} />
                            </InputLabel>
                            <OutlinedInput
                              label={<Skeleton variant="text" width={40} />}
                              type="string"
                            />
                          </FormControl>
                        ) : (
                          <FormControl
                            fullWidth
                            key="max-vermelho"
                            size="small"
                            variant="outlined">
                            <InputLabel>Máximo vermelho</InputLabel>
                            <OutlinedInput
                              label="Máximo vermelho"
                              type="string"
                              value={vermelhoFim || 0}
                              onChange={(e) =>
                                handleVermelhoFim(Number(e.target.value))
                              }
                            />
                          </FormControl>
                        )}
                      </Box>
                    </Grid>
                  </Box>
                </Grid>
              </Box>

              <Box marginLeft={51}>
                <Button variant="outlined" color="primary" onClick={sentPost}>
                  Salvar
                </Button>
              </Box>
            </Box>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};
