import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Grid,
  Checkbox,
  TextField,
  Typography,
  FormControl,
  FormControlLabel,
} from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';

import { useUser } from 'shared/hooks';
import {
  IProfile,
  IPermission,
  UserProfileService,
} from 'shared/services/api/UserProfileService';
import { feedback } from 'shared/services/alertService';
import { Footer, InputSearch, TransferList } from 'shared/components';
import { errorResponse } from 'shared/utils/errorResponse';

export const NewUserProfile: React.FC = () => {
  const [edit, setEdit] = useState(false);
  const [filter, setFilter] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [left, setLeft] = useState<IPermission[]>([]);
  const [right, setRight] = useState<IPermission[]>([]);
  const [profiles, setProfiles] = useState<IProfile[]>([]);
  const [checked, setChecked] = useState<IPermission[]>([]);
  const [profile, setProfile] = useState<IProfile>({
    ativo: true,
    administrador: false,
    nome: '',
    permissaoPerfil: [] as IPermission[],
  } as IProfile);

  const navigate = useNavigate();
  const { id = '0' } = useParams<'id'>();
  const { data: user } = useUser();

  const permissions = useMemo(
    () => user?.permissoes.filter((i) => i.nome.includes('Perfil')),
    [user?.permissoes],
  );

  const canEdit = useMemo(
    () => !!permissions?.find((i) => i.nome.includes('Editar')),
    [permissions],
  );

  const canList = useMemo(
    () => !!permissions?.find((i) => i.nome.includes('Listar')),
    [permissions],
  );

  useEffect(() => {
    if (!canList) {
      navigate('/');
    }
  }, [canList, navigate]);


  const handleGetProfiles = useCallback(async () => {
    try {
      const data = await UserProfileService.getProfiles();
      if (data) {
        setProfiles(data);
      }
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
  }, []);

  const handleGetPermissionProfile = useCallback(async () => {
    try {
      let profileData: IProfile;
      const permissions = await UserProfileService.getPermissionForProfiles();

      if (id) {
        profileData = await UserProfileService.getProfile(id);

        if (!profileData.ativo) {
          profileData.ativo = true
        }

        if (profileData.permissaoPerfil) {
          const aux1 = permissions.map((i) => JSON.stringify(i));
          const aux2 = profileData.permissaoPerfil.map((i) =>
            JSON.stringify(i),
          );

          let aux: string[] = [];
          aux1.map((item) => {
            if (!aux2.includes(item)) aux = [...aux, item];
          });

          setLeft(aux.map((i) => JSON.parse(i)));
          setRight(profileData.permissaoPerfil);
          setProfile(profileData);
          setEdit(true);
        }
      } else {
        setLeft(permissions);
      }
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
  }, [id]);

  const handleActiveProfile = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setProfile((state) => (state = { ...state, ativo: event.target.checked }));
    },
    [],
  );

  const handleChangeNameProfile = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setProfile((state) => (state = { ...state, nome: event.target.value }));
    },
    [],
  );

  const handleVerifyNameProfile = useCallback(() => {
    if (edit) {
      return (
        profiles
          .filter((i) => i.id !== id)
          .filter(
            (i) =>
              i.nome?.toLowerCase().trim() ===
              profile.nome?.toLowerCase().trim(),
          ).length > 0
      );
    }
    return (
      profiles.filter(
        (i) =>
          i.nome?.toLowerCase().trim() === profile.nome?.toLowerCase().trim(),
      ).length > 0
    );
  }, [id, profile.nome, profiles, edit]);

  const handleCreateOrUpdateProfile = useCallback(
    async (next: boolean) => {
      if (!profile.nome || right.length === 0) {
        setSubmitted(true);
        return;
      }

      try {
        if (edit && id !== '0') {
          await UserProfileService.updateProfile(id, {
            id: id,
            nome: profile.nome,
            administrador: profile.administrador,
            ativo: profile.ativo,
            permissaoPerfil: right,
          });

          navigate(-1);
        } if (profile.nome && id === '0') {
          await UserProfileService.postProfile({
            nome: profile.nome,
            administrador: profile.administrador,
            ativo: profile.ativo,
            permissaoPerfil: right,
          });

          if (!next) {
            navigate(-1);
          } else {
            setProfile({
              ativo: true,
              administrador: false,
              nome: '',
              permissaoPerfil: [] as IPermission[],
            } as IProfile);
            setLeft((state) => [...state, ...right]);
            setRight([]);
            await handleGetProfiles();
          }
        }
      } catch (error) {
        feedback(errorResponse(error), 'error');
      }
    },
    [
      profile.nome,
      profile.administrador,
      profile.ativo,
      right,
      edit,
      id,
      navigate,
      handleGetProfiles,
      profiles
    ],
  );

  useEffect(() => {
    handleGetProfiles();
    handleGetPermissionProfile();
  }, [handleGetProfiles, handleGetPermissionProfile]);

  return (
    <>
      <Typography variant="h5" color="primary">
        <strong>{edit ? 'Editar' : 'Cria novo'} perfil do usuário</strong>
      </Typography>

      <Box marginTop={3} />

      <FormControl component="fieldset" fullWidth>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControlLabel
              label="Ativo"
              control={
                <Checkbox
                  checked={profile.ativo}
                  disabled={edit && !canEdit}
                  onChange={handleActiveProfile}
                />
              }
            />
          </Grid>

          <Grid item xs={12} sm={5}>
            <TextField
              fullWidth
              variant="outlined"
              value={profile.nome}
              label="Perfil do usuário *"
              disabled={edit && !canEdit}
              onChange={handleChangeNameProfile}
              error={(!profile.nome && submitted) || handleVerifyNameProfile()}
              helperText={
                !profile.nome && submitted
                  ? 'Campo requerido'
                  : handleVerifyNameProfile() &&
                  'O nome do perfil de usuário deve ser único'
              }
            />
            <Box marginBottom={3} />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">Aplicar permissões</Typography>
          </Grid>

          <Grid item xs={12} sm={5}>
            <InputSearch
              value={filter}
              variant="outlined"
              onChange={({ target }) => setFilter(target.value)}
              placeholder="Pesquisar permissões disponíveis e selecionadas"
            />
          </Grid>

          <Grid item xs={12}>
            <Box marginTop={2} />
            <TransferList
              checked={checked}
              setLeft={setLeft}
              filter={filter}
              left={left}
              setRight={setRight}
              right={right}
              setChecked={setChecked}
              disabled={edit && !canEdit}
              labelLeft="Permissões disponíveis"
              labelRight="Permissões selecionadas"
              error={submitted && right.length === 0}
              helperText="Adicione pelo menos uma permissão"
            />
          </Grid>
        </Grid>
      </FormControl>

      <Box marginY={14} />

      <Footer
        disabled={edit && !canEdit}
        onCancel={() => navigate(-1)}
        onSave={() => handleCreateOrUpdateProfile(false)}
        onSaveAndNew={
          edit ? undefined : () => handleCreateOrUpdateProfile(true)
        }
      />
    </>
  );
};
