import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableCell: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      textTransform: 'uppercase',
      color: theme.palette.grey[500],
      fontSize: 12,
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    iconDrag: {
      color: theme.palette.grey[500],
      opacity: 0,
      left: 8,
      top: 10,
      position: 'absolute',
      marginLeft: theme.spacing(-1),
      transition: 'opacity 0.3s',
      '&:hover': {
        opacity: 1,
      },
    },
    dragIn: {
      cursor: 'pointer',
    },
  }),
);
