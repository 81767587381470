import React, {useCallback, useEffect, useState} from 'react';
import {
  ChevronRight,
  ExpandMore,
  CheckBox,
  CheckBoxOutlineBlank,
} from '@material-ui/icons';
import {TreeItem, TreeView} from '@material-ui/lab';
import {
  Grid,
  Box,
  useTheme,
  TextField,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';

import {useTerm} from 'shared/hooks';
import {
  CourseService,
  ICourseAndCycle,
} from 'shared/services/api/CourseService';
import {
  IUnityEstablishment,
  EstablishmentService,
} from 'shared/services/api/EstablishmentService';
import {AutoComplete, Modal} from 'shared/components';
import {feedback} from 'shared/services/alertService';
import {IShift} from 'shared/services/api/ShiftService';
import {errorResponse} from 'shared/utils/errorResponse';
import {IMultiPostOffer, OfferService} from 'shared/services/api/OfferService';
import {IComplementary} from 'shared/services/api/ComplementaryService';

interface IProps {
  onClose(): void;
  campaignInfo: {
    nivelEstabelecimentoDono: number;
    idEstabelecimentoDono: string;
  };
  shifts: IShift[];
  openModal: boolean;
  idCampanha: string;
  idRegister?: string;
  complementaryOne: IComplementary[];
  complementaryTwo: IComplementary[];
}

export const ModalMultipleInclude: React.FC<IProps> = ({
  campaignInfo,
  shifts,
  onClose,
  openModal,
  idCampanha,
  idRegister,
  complementaryOne,
  complementaryTwo,
}) => {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [establishmentExpanded, setEstablishmentExpanded] = useState<string[]>(
    [],
  );
  const [establishmentSelected, setEstablishmentSelected] = useState<string[]>(
    [],
  );
  const [establishments, setEstablishments] = useState<IUnityEstablishment[]>(
    [],
  );
  const [course, setCourse] = useState<ICourseAndCycle[]>([]);
  const [courseSelected, setCourseSelected] = useState<string[]>([]);
  const [shiftsSelected, setShiftsSelected] = useState<string[]>([]);
  const [complementaryOneSelected, setComplementaryOneSelected] = useState<
    string[]
  >([]);
  const [complementaryTwoSelected, setComplementaryTwoSelected] = useState<
    string[]
  >([]);

  const [possuiControleDeVagas, setPossuiControleDeVagas] = useState(false);
  const [numeroDeVagas, setNumeroDeVagas] = useState(0);
  const [possuiListaDeEspera, setPossuiListaEspera] = useState(false);
  const [visivelNoForm, setVisivelNoForm] = useState(false);

  const theme = useTheme();
  const {terms} = useTerm();

  const handleToggleTreeEstablishment = (
    _: React.ChangeEvent<Record<string, unknown>>,
    nodeIds: string[],
  ) => {
    setEstablishmentExpanded(nodeIds);
  };

  const handleSelectTreeEstablishment = (
    _: React.ChangeEvent<Record<string, unknown>>,
    nodeIds: string[],
  ) => {
    setEstablishmentSelected((state) => {
      if (state.includes(nodeIds[0]))
        return state.filter((item) => item !== nodeIds[0]);
      return state
        .filter((item) => !establishmentExpanded.includes(item) && item)
        .concat(nodeIds);
    });
  };

  const handleGetCourse = useCallback(async () => {
    try {
      const response = await CourseService.getCoursesAndCycles(true);

      if (response) setCourse(response);
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
  }, []);

  const handleGetTreeEstablishment = useCallback(async () => {
    try {
      const response = await EstablishmentService.getTreeEstablishments(
        idRegister,
      );

      setEstablishments(response.estabelecimentos);
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
  }, [idRegister]);

  const handleResetForm = useCallback(() => {
    setEstablishmentExpanded([]);
    setEstablishmentSelected([]);
    setCourseSelected([]);
    setShiftsSelected([]);
    setComplementaryOneSelected([]);
    setComplementaryTwoSelected([]);
    setPossuiControleDeVagas(false);
    setNumeroDeVagas(0);
    setPossuiListaEspera(false);
    setVisivelNoForm(false);

    setSubmitted(false);
    onClose();
  }, [onClose]);

  const isEstablishmentDisabledVerifier = (
    idEstabelecimentoPai: string | null,
    id: string,
  ) => {
    if (
      idEstabelecimentoPai === null ||
      campaignInfo.nivelEstabelecimentoDono === 1
    ) {
      return false;
    }

    return !(id === campaignInfo.idEstabelecimentoDono);
  };

  const handleMultipleInclude = useCallback(async () => {
    if (establishmentSelected.length === 0 || courseSelected.length === 0) {
      setSubmitted(true);

      return;
    }

    setLoading(true);
    try {
      const payload: IMultiPostOffer = {
        idCampanha,
        idsEstabelecimentos: establishmentSelected,
        idsCursosComCiclos: courseSelected,
        idsTurnos: shiftsSelected,
        idsComplementares1: complementaryOneSelected,
        idsComplementares2: complementaryTwoSelected,
        controleVagas: possuiControleDeVagas
          ? {possuiListaDeEspera, visivelNoForm, numeroDeVagas}
          : null,
      };

      const response = await OfferService.postMultiOffers(payload);

      if (response.messages && response.messages.length > 0) {
        response.messages[0] !== ''
          ? feedback(response.messages[0], 'warning')
          : undefined;
      }

      handleResetForm();
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
    setLoading(false);
  }, [
    establishmentSelected,
    courseSelected,
    idCampanha,
    shiftsSelected,
    complementaryOneSelected,
    complementaryTwoSelected,
    possuiControleDeVagas,
    numeroDeVagas,
    possuiListaDeEspera,
    visivelNoForm,
    handleResetForm,
  ]);

  useEffect(() => {
    handleGetTreeEstablishment();
    handleGetCourse();
  }, [handleGetTreeEstablishment, handleGetCourse]);

  return (
    <Modal
      opened={openModal}
      labelSaveButton="Incluir"
      title="Dados múltiplas ofertas"
      maxWidth="md"
      onClick={handleMultipleInclude}
      onClose={handleResetForm}
      loading={loading}
      labelCloseButton="Cancelar">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <Box
            marginTop={3}
            marginBottom={0.3}
            style={{
              border: `1px solid ${
                establishmentSelected.length === 0 && submitted
                  ? theme.palette.error.main
                  : theme.palette.grey[500]
              }`,
              borderRadius: 4,
            }}>
            <Box
              style={{
                borderBottom: `1px solid ${
                  establishmentSelected.length === 0 && submitted
                    ? theme.palette.error.main
                    : theme.palette.grey[500]
                }`,
              }}
              padding={2}>
              <Typography>Estabelecimento</Typography>
            </Box>
            <Box padding={2} height={200} style={{overflow: 'auto'}}>
              <TreeView
                defaultCollapseIcon={<ExpandMore />}
                defaultExpandIcon={<ChevronRight />}
                multiSelect
                expanded={establishmentExpanded}
                selected={establishmentSelected}
                onNodeToggle={handleToggleTreeEstablishment}
                onNodeSelect={handleSelectTreeEstablishment}>
                {establishments?.map((itemN1) => (
                  <TreeItem
                    key={itemN1.id}
                    nodeId={itemN1.idEstabelecimento}
                    label={
                      <Box display="flex" alignItems="center" marginY={0.7}>
                        <Box paddingLeft={1}>{itemN1.idComNome}</Box>
                      </Box>
                    }>
                    {itemN1.unidades?.map((itemN2) =>
                      isEstablishmentDisabledVerifier(
                        itemN2.idEstabelecimentoPai,
                        itemN2.idEstabelecimento,
                      ) ? (
                        <></>
                      ) : (
                        <TreeItem
                          key={itemN2.id}
                          nodeId={itemN2.idEstabelecimento}
                          label={
                            <Box
                              display="flex"
                              alignItems="center"
                              marginY={0.7}
                              paddingLeft={1}>
                              {establishmentSelected.includes(
                                itemN2.idEstabelecimento,
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )}

                              <Box paddingLeft={1}>{itemN2.idComNome}</Box>
                            </Box>
                          }>
                          {itemN2?.unidades?.map((itemN3) => (
                            <TreeItem
                              key={itemN3.id}
                              nodeId={itemN3.idEstabelecimento}
                              label={
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  marginY={0.7}
                                  paddingLeft={1}>
                                  {establishmentSelected.includes(
                                    itemN3.idEstabelecimento,
                                  ) ? (
                                    <CheckBox />
                                  ) : (
                                    <CheckBoxOutlineBlank />
                                  )}
                                  <Box paddingLeft={1}>
                                    {itemN3.idEstabelecimento} {itemN3.nome}
                                  </Box>
                                </Box>
                              }
                            />
                          ))}
                        </TreeItem>
                      ),
                    )}
                  </TreeItem>
                ))}
              </TreeView>
            </Box>
          </Box>
          {establishmentSelected.length === 0 && submitted && (
            <Typography
              color="error"
              variant="caption"
              style={{
                marginLeft: theme.spacing(1.7),
              }}>
              Campo requerido
            </Typography>
          )}
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <AutoComplete
                multiple
                disableCloseOnSelect
                options={course}
                getOptionLabel={(option) => option.nome}
                filterSelectedOptions
                onChange={(_, value) =>
                  setCourseSelected(value.map((i: ICourseAndCycle) => i.id))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    error={courseSelected.length === 0 && submitted}
                    helperText={
                      courseSelected.length === 0 &&
                      submitted &&
                      'Campo requerido'
                    }
                    label={`${terms.termoCurso} - ${terms.termoCiclo}`}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <AutoComplete
                multiple
                disableCloseOnSelect
                options={shifts}
                getOptionLabel={(option) => option.nome}
                filterSelectedOptions
                onChange={(_, value) =>
                  setShiftsSelected(value.map((i: IShift) => i.id))
                }
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Turno" />
                )}
              />
            </Grid>
          </Grid>
        </Grid>

        {(terms.termoComplementar2 || terms.termoComplementar1) && (
          <Grid item xs={12}>
            <Typography variant="h6">Informações complementares</Typography>
          </Grid>
        )}

        <Grid item xs={12}>
          <Grid container spacing={3}>
            {terms.termoComplementar1 && (
              <Grid item xs={12}>
                <AutoComplete
                  multiple
                  disableCloseOnSelect
                  options={complementaryOne}
                  getOptionLabel={(option) => option.nome}
                  filterSelectedOptions
                  onChange={(_, value) =>
                    setComplementaryOneSelected(
                      value.map((i: IComplementary) => i.id),
                    )
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={terms.termoComplementar1}
                    />
                  )}
                />
              </Grid>
            )}

            {terms.termoComplementar2 && (
              <Grid item xs={12}>
                <AutoComplete
                  multiple
                  disableCloseOnSelect
                  options={complementaryTwo}
                  getOptionLabel={(option) => option.nome}
                  filterSelectedOptions
                  onChange={(_, value) =>
                    setComplementaryTwoSelected(
                      value.map((i: IComplementary) => i.id),
                    )
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={terms.termoComplementar2}
                    />
                  )}
                />
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">Controle de vagas</Typography>
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={possuiControleDeVagas}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setPossuiControleDeVagas(event.target.checked);
                }}
              />
            }
            label="Possui controle de vagas"
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            disabled={!possuiControleDeVagas}
            fullWidth
            type="number"
            variant="outlined"
            value={numeroDeVagas}
            InputProps={{inputProps: {min: 0}}}
            label="Número de vagas"
            onChange={(event) => setNumeroDeVagas(Number(event.target.value))}
          />
        </Grid>

        <Grid item xs={12}>
          <FormGroup>
            <FormControlLabel
              disabled={!possuiControleDeVagas}
              control={
                <Checkbox
                  checked={possuiListaDeEspera}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setPossuiListaEspera(event.target.checked);
                  }}
                />
              }
              label="Possui lista de espera"
            />
            <FormControlLabel
              disabled={!possuiControleDeVagas}
              control={
                <Checkbox
                  checked={visivelNoForm}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setVisivelNoForm(event.target.checked);
                  }}
                />
              }
              label="Visível no formulário"
            />
          </FormGroup>
        </Grid>
      </Grid>
    </Modal>
  );
};
