import React from 'react';
import {BrowserRouter} from 'react-router-dom';

import {Layout} from 'shared/layouts/Layout';

import {PrivateRoutes} from './private.routes';

export const Routes: React.FC = () => {
  return (
    <BrowserRouter>
      <Layout>
        <PrivateRoutes />
      </Layout>
    </BrowserRouter>
  );
};
