import React, {createContext, useEffect, useState} from 'react';
import {IUserContext, UserService} from 'shared/services/api/UserService';

export const UserContext = createContext<IUserContext>({} as IUserContext);

export const UserProvider: React.FC = ({children}) => {
  const [userContent, setUserContent] = useState<IUserContext>(
    {} as IUserContext,
  );

  useEffect(() => {
    UserService.getUser().then((data) => {
      if (data?.sucesso) {
        setUserContent(data);
      } else {
        setUserContent({sucesso: false, data: undefined});
      }
    });
  }, []);

  return (
    <UserContext.Provider value={userContent && userContent}>
      {children}
    </UserContext.Provider>
  );
};
