import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Box,
  useTheme,
  TableRow,
  TableCell,
  IconButton,
  CircularProgress,
  FormControlLabel,
  Typography,
  Checkbox,
  TextField,
} from '@material-ui/core';
import {Delete, Edit} from '@material-ui/icons';

import {useStyles} from './styles';
import {ModalSituation} from '../Modal';
import {useLocation, useNavigate} from 'react-router-dom';
import {
  ILead,
  ILeadCategory,
  ITarefa,
  LeadSituationService,
} from 'shared/services/api/LeadSituationService';
import {feedback} from 'shared/services/alertService';
import {useTitle, useTerm, useUser, useDebounce} from 'shared/hooks';
import {errorResponse} from 'shared/utils/errorResponse';
import {
  Footer,
  Header,
  ModalConfirm,
  Table,
  TableSkeleton,
} from 'shared/components';
import {IFunnelList} from 'shared/services/api/FunnelService/types';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import {Skeleton} from '@material-ui/lab';
import {FunnelService} from 'shared/services/api/FunnelService';

interface LocationState {
  funnelId: string;
  funnelHandler: IFunnelList;
}

export const FunnelSteps: React.FC = () => {
  const location = useLocation();
  const {funnelId, funnelHandler} = location.state as LocationState;
  const [filter, setFilter] = useState('');
  const [loading, setLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [idDeleted, setIdDeleted] = useState('');
  const [leads, setLeads] = useState<ILead[]>([]);
  const [editModal, setEditModal] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [tableFiltered, setTableFiltered] = useState(0);
  const [categories, setCategories] = useState<ILeadCategory[]>([]);
  const [openModalConfirmExclude, setOpenModalConfirmExclude] = useState(false);
  const [isNewRegister, setIsNewRegister] = useState(false);

  const [tasksList, setTasksList] = useState<ITarefa[]>([]);
  const LEAD_DEFAULT = {
    id: '',
    nome: '',
    categoria: {
      id: 0,
      nome: '',
    },
    idCategDeSituacaoDeLead: 0,
    ativo: false,
    usuario: '',
    ordem: 0,
    notificarLead: false,
    notificarAtendenteAtual: false,
    notificarTodosOsAtendentes: false,
    tarefas: [] as ITarefa[],
  };
  const [lead, setLead] = useState<ILead>(LEAD_DEFAULT);
  const [leadHandler, setLeadHandler] = useState(LEAD_DEFAULT);

  const [take, setTake] = useState(10);
  const [skip, setSkip] = useState(1);
  const [leadsCount, setLeadsCount] = useState(0);

  const [currentFunnel, setCurrentFunnel] = useState<IFunnelList>(
    {} as IFunnelList,
  );

  const title = {
    isEdit: 'Editar Etapa do Funil',
    default: 'Cadastro de Etapa do Funil',
  };
  const [modalTitle, setModalTitle] = useState(title.default);

  const theme = useTheme();
  const {terms} = useTerm();
  const classes = useStyles();
  const {setTitle} = useTitle();
  const {data: user} = useUser();
  const {debounce} = useDebounce();
  const navigate = useNavigate();

  const [isFunnelActive, setIsFunnelActive] = useState(true);
  const [funnelName, setFunnelName] = useState<string>('');

  const permissions = useMemo(
    () => user?.permissoes.filter((i) => i.nome.includes('Funil')),
    [user?.permissoes],
  );

  const canDelete = useMemo(
    () => !!permissions?.find((i) => i.nome.includes('Excluir')),
    [permissions],
  );

  const canRegister = useMemo(
    () => !!permissions?.find((i) => i.nome.includes('Incluir')),
    [permissions],
  );

  const canEdit = useMemo(
    () => !!permissions?.find((i) => i.nome.includes('Editar')),
    [permissions],
  );

  const handleVerifyNameLead = useCallback(() => {
    if (editModal) {
      return (
        leads
          .filter((i) => i.id !== lead.id)
          .filter(
            (i) =>
              i.nome.toLowerCase().trim() === lead.nome.toLowerCase().trim(),
          ).length > 0
      );
    }
    return (
      leads.filter(
        (i) => i.nome.toLowerCase().trim() === lead.nome.toLowerCase().trim(),
      ).length > 0
    );
  }, [editModal, lead.id, lead.nome, leads]);

  const handleResetDataModal = useCallback(() => {
    setLead({
      id: '',
      nome: '',
      categoria: {
        id: 0,
        nome: '',
      },
      idCategDeSituacaoDeLead: 0,
      ativo: false,
      usuario: '',
      ordem: 0,
      notificarLead: false,
      notificarAtendenteAtual: false,
      notificarTodosOsAtendentes: false,
      tarefas: [] as ITarefa[],
    } as ILead);

    setLeadHandler(LEAD_DEFAULT);

    setTimeout(() => {
      setEditModal(false);
    }, 1000);
  }, []);

  const handleGetLead = useCallback(async () => {
    setLoading(true);
    debounce(async () => {
      try {
        const response = await LeadSituationService.getLeadsPagination(
          tableFiltered,
          filter,
          take,
          skip,
          funnelId,
        );

        if (response) {
          setLeads(response.data);
          setLeadsCount(response.qtdTotalDeRegistros);
          setDataLoaded(true);
        }
      } catch (error) {
        feedback(errorResponse(error), 'error');
      } finally {
        setLoading(false);
      }
    });
  }, [tableFiltered, filter, take, skip, debounce, funnelId]);

  const handleGetCategory = useCallback(async () => {
    setLoading(true);
    try {
      const data = await LeadSituationService.getLeadCategory();

      if (data) {
        setCategories(data);
      }
    } catch (error) {
      feedback(errorResponse(error), 'error');
    } finally {
      setLoading(false);
    }
  }, []);

  const handleCreateLead = useCallback(
    async (leadHandler: ILead) => {
      if (handleVerifyNameLead()) return;
      setLoading(true);
      try {
        if (leadHandler)
          await LeadSituationService.postLead(
            {
              nome: leadHandler.nome,
              ativo: leadHandler.ativo,
              usuario: leadHandler.usuario,
              ordem: leadHandler.ordem,
              notificarLead: leadHandler.notificarLead,
              enviarNotificacao: leadHandler.enviarNotificacao,
              assuntoEmailNotificacao: leadHandler.assuntoEmailNotificacao,
              mensagemEmailNotificacao: leadHandler.mensagemEmailNotificacao,
              qtdeDiasAteEnvioNotificacao:
                leadHandler.qtdeDiasAteEnvioNotificacao,
              categoria: {
                id: leadHandler.idCategDeSituacaoDeLead,
                nome: leadHandler.categoria.nome,
              },
              idCategDeSituacaoDeLead: leadHandler.idCategDeSituacaoDeLead,
              notificarAtendenteAtual: leadHandler.notificarAtendenteAtual,
              notificarTodosOsAtendentes:
                leadHandler.notificarTodosOsAtendentes,
              id: leadHandler.id,
              tarefas: leadHandler.tarefas,
            },
            funnelId,
          );

        await handleGetLead();

        feedback(`Etapa do funil adicionada com sucesso`, 'success');
      } catch (error) {
        feedback(errorResponse(error), 'error');
      }
      setLoading(false);
      setOpenModal(false);
      setLeadHandler(LEAD_DEFAULT);
      setSubmitted(false);
      handleResetDataModal();
    },
    [
      handleVerifyNameLead,
      lead,
      handleResetDataModal,
      handleGetLead,
      terms.termoLead,
    ],
  );

  const handleUpdateLead = useCallback(
    async (leadHandler: ILead) => {
      if (handleVerifyNameLead()) return;

      if (
        leadHandler.nome.length === 0 ||
        leadHandler.idCategDeSituacaoDeLead === 0
      ) {
        setSubmitted(true);

        return;
      } else {
        setLoading(true);
        try {
          if (leadHandler)
            await LeadSituationService.updateLead(
              leadHandler.id,
              {
                nome: leadHandler.nome,
                ativo: leadHandler.ativo,
                usuario: leadHandler.usuario,
                ordem: leadHandler.ordem,
                enviarNotificacao: leadHandler.enviarNotificacao,
                assuntoEmailNotificacao: leadHandler.assuntoEmailNotificacao,
                mensagemEmailNotificacao: leadHandler.mensagemEmailNotificacao,
                qtdeDiasAteEnvioNotificacao:
                  leadHandler.qtdeDiasAteEnvioNotificacao,
                notificarLead: leadHandler.notificarLead,
                categoria: {
                  id: leadHandler.categoria.id,
                  nome: leadHandler.categoria.nome,
                },
                idCategDeSituacaoDeLead: leadHandler.categoria.id,
                notificarAtendenteAtual: leadHandler.notificarAtendenteAtual,
                notificarTodosOsAtendentes:
                  leadHandler.notificarTodosOsAtendentes,
                id: leadHandler.id,
                tarefas: leadHandler.tarefas,
              },
              funnelId,
            );

          await handleGetLead();

          feedback(`Etapa do funil atualizada com sucesso`, 'success');
        } catch (error) {
          feedback(errorResponse(error), 'error');
        } finally {
          setOpenModal(false);
          setLoading(false);
        }
        handleResetDataModal();
        setLeadHandler(LEAD_DEFAULT);
      }
      setSubmitted(false);
    },
    [
      handleVerifyNameLead,
      handleResetDataModal,
      leadHandler,
      handleGetLead,
      terms.termoLead,
    ],
  );

  const handleDeleteLead = useCallback((id: string) => {
    setOpenModalConfirmExclude(true);

    setIdDeleted(id);
  }, []);

  const handleConfirmLeadDeleted = useCallback(async () => {
    try {
      setLoading(true);
      await LeadSituationService.deleteLead(idDeleted, funnelId);

      await handleGetLead();

      feedback('Etapa do funil deletada com sucesso', 'success');
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
    setLoading(false);
    setIdDeleted('');
    setOpenModalConfirmExclude(false);
  }, [handleGetLead, idDeleted, funnelId]);

  const handleFunnelNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFunnelName(e.target.value);
  };

  const handleSaveFunnelChanges = useCallback(async () => {
    if (
      currentFunnel.status.toLowerCase() === 'ativo' &&
      isFunnelActive === true &&
      currentFunnel.nome === funnelName
    ) {
      feedback('Nenhuma alteração feita no status ou nome do funil', 'error');
    } else {
      setLoading(true);
      try {
        await FunnelService.updateFunnel({
          id: funnelHandler.id,
          nome: funnelName,
          desativar: isFunnelActive ? false : true,
        });

        feedback(`Funil atualizado com sucesso`, 'success');
      } catch (error) {
        feedback(errorResponse(error), 'error');
      } finally {
        await getUpdatedFunnel();
      }
    }
  }, [funnelHandler.id, funnelName, isFunnelActive]);

  const getUpdatedFunnel = useCallback(async () => {
    setLoading(true);
    try {
      const response = await FunnelService.getAllFunnels(0, '', 99, 1);

      if (response) {
        setCurrentFunnel(
          response.data.find((funnel) => funnel.id === funnelHandler.id) ||
            ({} as IFunnelList),
        );

        const getFunnel = response.data.find(
          (funnel) => funnel.id === funnelHandler.id,
        );

        if (getFunnel !== undefined) {
          setIsFunnelActive(
            getFunnel.status.toLowerCase() === 'ativo' ? true : false,
          );
          setFunnelName(getFunnel.nome);
        }
      }
    } catch (error) {
      feedback(errorResponse(error), 'error');
    } finally {
      setLoading(false);
    }
  }, [handleSaveFunnelChanges, funnelHandler.id]);

  useEffect(() => {
    setTitle(`Etapas do Funil`);
    handleGetLead();
    getUpdatedFunnel();
  }, []);

  useEffect(() => {
    const currentPath = location.pathname;
    setIsNewRegister(currentPath.split('/').includes('novo'));
  }, [location.pathname]);

  useEffect(() => {
    handleGetLead();
  }, [filter, tableFiltered, skip, take]);

  useEffect(() => {
    handleGetCategory();
  }, [handleGetCategory]);

  useEffect(() => {
    if (dataLoaded) {
      const sortedLeads = [...leads].sort((a, b) => a.ordem - b.ordem);
      setLeads(sortedLeads);
      setDataLoaded(false);
    }
  }, [dataLoaded, leads]);

  const handleCloseModal = () => {
    if (editModal) {
      setEditModal(false);
      setModalTitle(title.isEdit);
      setLeadHandler(LEAD_DEFAULT);
    }
    setSubmitted(false);
    setOpenModal(false);
    setLead(LEAD_DEFAULT);
  };

  return (
    <>
      <Header
        filter={filter}
        setFilter={setFilter}
        disabledButton={!canRegister}
        tableFiltered={tableFiltered}
        setTableFiltered={setTableFiltered}
        onClick={() => (
          setOpenModal((state) => !state), setModalTitle(title.default)
        )}
      />

      <Box marginTop={5} />

      <Box marginBottom={2}>
        {loading ? (
          <Skeleton variant="rect" height={30} width={80} />
        ) : (
          <FormControlLabel
            disabled={loading || leads.length === 0}
            label={
              <Typography variant="subtitle1">
                <strong>Ativo</strong>
              </Typography>
            }
            control={
              <Checkbox
                checked={isFunnelActive}
                onClick={() => {
                  setIsFunnelActive((prev) => !prev);
                }}
              />
            }
          />
        )}

        <Box marginBottom={2} marginTop={2} width={'50%'} marginRight={4}>
          {loading ? (
            <Skeleton variant="rect" height={36} />
          ) : (
            <TextField
              fullWidth
              disabled={loading || leads.length === 0}
              value={funnelName}
              label="Nome do Funil"
              variant="outlined"
              onChange={handleFunnelNameChange}
            />
          )}
        </Box>
      </Box>

      <Box marginTop={5} />

      {loading || leads.length === 0 ? (
        <TableSkeleton btnAct={2} columns={5} rowsBody={10} rectWidth="50%" />
      ) : (
        <Table
          head={[
            {label: 'Ações', width: '160px'},
            {label: `Ordem`},
            {label: `Funil`},
            {label: 'Situação Lead'},
            {label: 'Status', width: '140px'},
          ]}
          pagination={{
            count: Math.ceil(leadsCount / take),
            page: skip,
          }}
          length={leadsCount}
          loading={loading}
          take={take}
          rowsPerPage={leads.length}
          onChangeTake={(value: number) => setTake(value)}
          onChangePage={(value: number) => setSkip(value)}>
          {leads.map((item) => (
            <TableRow key={item.id}>
              <TableCell>
                <IconButton
                  color="primary"
                  onClick={() => handleDeleteLead(item.id)}
                  disabled={loading || !canDelete}>
                  <Delete />
                  {loading && idDeleted === item.id && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </IconButton>

                <IconButton
                  style={{marginLeft: theme.spacing(1)}}
                  color="primary"
                  onClick={() => {
                    setLeadHandler(LEAD_DEFAULT);
                    setOpenModal(true),
                      setModalTitle(title.isEdit),
                      setEditModal(true),
                      setLead(item);
                  }}
                  disabled={loading || !canEdit}>
                  <Edit />
                </IconButton>
              </TableCell>
              <TableCell>{item.ordem}</TableCell>
              <TableCell>{item.nome}</TableCell>
              <TableCell>{item.categoria.nome}</TableCell>
              <TableCell>{item.ativo ? 'Ativo' : 'Inativo'}</TableCell>
            </TableRow>
          ))}
        </Table>
      )}

      <Box marginY={14} />

      <Footer
        goBackStartIcone={<KeyboardBackspaceIcon />}
        goBackLabel="Voltar para a lista"
        onSave={handleSaveFunnelChanges}
        onGoBack={() => navigate('/funil')}
        onSaveLabel={'Salvar'}
        disabled={loading || leads.length === 0}
      />

      <ModalConfirm
        opened={openModalConfirmExclude}
        onClick={handleConfirmLeadDeleted}
        onClose={() => {
          setOpenModalConfirmExclude(false), setIdDeleted('');
        }}
        loading={loading}
      />

      {lead && (
        <ModalSituation
          openModal={openModal}
          loading={loading}
          submitted={submitted}
          edit={editModal}
          title={modalTitle}
          nameVerify={handleVerifyNameLead()}
          onClose={handleCloseModal}
          onClick={() =>
            editModal
              ? handleUpdateLead(leadHandler)
              : handleCreateLead(leadHandler)
          }
          categories={categories}
          lead={lead}
          leadHandler={leadHandler}
          setLeadHandler={setLeadHandler}
        />
      )}
    </>
  );
};
