import {
  Box,
  Grid,
  Button,
  Dialog,
  Typography,
  DialogTitle,
  DialogContent,
  TableContainer,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
  CRMDashboardService,
  ILeadsSemAtendimento,
} from 'shared/services/api/CRMDashboard';
import React, {useCallback, useState} from 'react';

import {
  DataGrid,
  GridColDef,
  GridColumnsPanel,
  GridToolbarExport,
  GridToolbarContainer,
} from '@mui/x-data-grid';
import {feedback} from 'shared/services/alertService';

interface IProps {
  openModal: boolean;
  onclose(): void;
  selectedPeriod: string;
  selectedEstablishment: string;
  selectedCampaing: string;
}

export const LeadsWhitout: React.FC<IProps> = ({
  openModal,
  onclose,
  selectedPeriod,
  selectedEstablishment,
  selectedCampaing,
}) => {
  const [loading, setLoading] = useState(true);
  const [leadsWithout, setLeadsWithout] = useState<ILeadsSemAtendimento[]>([]);

  const LeadsWithoutList = useCallback(async () => {
    setLoading(true);
    CRMDashboardService.getLeadsSemAtendimento(
      selectedPeriod,
      selectedEstablishment,
      selectedCampaing,
    )
      .then((response) => {
        setLeadsWithout(response);
      })
      .catch(() => feedback('Erro ao buscar dados', 'error'))
      .finally(() => setLoading(false));
  }, [selectedCampaing, selectedEstablishment, selectedPeriod]);

  const columns: GridColDef[] = [
    {
      field: 'lead',
      headerName: 'Lead',
      flex: 1,
    },
    {
      field: 'tempo',
      headerName: 'Dias desde a última interação',
      flex: 1,
    },
    {
      field: 'ultimoAtendente',
      headerName: 'Último atendente',
      flex: 1,
    },
    {
      field: 'ultimoAtendimento',
      headerName: 'Última interação',
      flex: 1,
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  return (
    <Dialog
      fullScreen
      fullWidth
      maxWidth="xl"
      open={openModal}
      onClose={onclose}
      onEntering={LeadsWithoutList}
      aria-labelledby="Leads sem interação"
      aria-describedby="Leads sem interação">
      <DialogTitle id="Leads sem interação">
        <Box width="100%" display={'flex'} justifyContent="space-between">
          <Box>
            <Typography variant="inherit" color="primary">
              Leads sem interação
            </Typography>
          </Box>
          <Box>
            <Button color="primary" onClick={onclose}>
              <CloseIcon />
            </Button>
          </Box>
        </Box>
      </DialogTitle>

      <TableContainer>
        <Grid container>
          <DialogContent>
            <DataGrid
              loading={loading}
              columns={columns}
              rows={leadsWithout}
              getRowId={(row) => row.idLead}
              autoHeight
              pageSize={7}
              components={{
                Toolbar: CustomToolbar,
                ColumnsPanel: GridColumnsPanel,
              }}
            />
          </DialogContent>
        </Grid>
      </TableContainer>
    </Dialog>
  );
};
