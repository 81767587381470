import React, {useEffect, useState} from 'react';
import {
  Fade,
  Grid,
  Theme,
  makeStyles,
  Typography,
  LinearProgress,
} from '@material-ui/core';

import SplashLogo from 'shared/assets/LogoSplash.png';
import {useUser} from 'shared/hooks';
import {Environment} from 'shared/env';

const useStyles = makeStyles((theme: Theme) => {
  return {
    logo: {
      width: '280px',
      position: 'relative',
      transition: 'transform .2s',
      '&:hover': {transform: 'scale(1.25)'},
    },
    bar: {
      width: theme.spacing(35),
      marginTop: theme.spacing(6),
      colorPrimary: theme.palette.primary.main,
    },
    text: {
      marginTop: theme.spacing(3),
      color: theme.palette.text.primary,
    },
  };
});

interface ISplashScreen {
  setIsLoading: (isLoading: boolean) => void;
}

export const SplashScreen: React.FC<ISplashScreen> = ({setIsLoading}) => {
  const [progress, setProgress] = useState(0);

  const {bar, logo, text} = useStyles();
  const {sucesso} = useUser();

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 100);
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (sucesso && progress === 100) {
      setIsLoading(false);
    } else if (sucesso === false) {
      window.location.href = `${Environment.API_GATEWAY}/SignIn?callback_url=${window.location.href}&origin_debug=${Environment.URL_ORIGIN_DEBUG}`;
    }
  }, [progress, setIsLoading, sucesso]);

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{minHeight: '100vh'}}>
      <Fade in>
        <Grid item>
          <img alt="Logo GVdasa" src={SplashLogo} className={logo} />
        </Grid>
      </Fade>
      <Grid item>
        <Fade in>
          <LinearProgress
            variant="determinate"
            value={progress}
            className={bar}
          />
        </Fade>
      </Grid>
      <Fade in>
        <Grid item className={text}>
          <Typography variant="subtitle1">Carregando...</Typography>
        </Grid>
      </Fade>
    </Grid>
  );
};
