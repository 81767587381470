import React, {useEffect, useState, useCallback} from 'react';
import {Box, MenuItem, TextField, Typography, Grid, makeStyles} from '@material-ui/core';
import {useTitle} from 'shared/hooks';
import {FilterListRounded, MessageRounded} from '@material-ui/icons';
import {Button, Footer, Modal} from 'shared/components';
import {CampaignService, ICampaign} from 'shared/services/api/CampaignService';
import {SearchLeadService, GridRowDef} from 'shared/services/api/SearchLead';
import {feedback} from 'shared/services/alertService';
import {errorResponse} from 'shared/utils/errorResponse';
import {DataGrid, GridColDef, GridToolbar, GridColumnsPanel} from '@mui/x-data-grid';

const useStyles = makeStyles(() => {
  return {
    dataGridColumnPanel: {
      '& .MuiGridPanelFooter-root': {
        display: 'none !important'
      }
    },
    dataGrid: {
      '& .lead-row--ativo': {
        backgroundColor: 'rgba(200, 226, 227, 0.25) !important'
      },
      '& .lead-row--cancelado': {
        backgroundColor: 'rgba(247, 51, 51, 0.25) !important'
      },
      '& .lead-row--inscrito': {
        backgroundColor: 'rgba(200, 226, 227, 0.3) !important'
      },
      '& .lead-row--integrado': {
        backgroundColor: 'rgba(51, 129, 121, 0.25) !important'
      },
      '& .lead-row--novoAtendimento': {
        backgroundColor: 'rgba(77, 185, 173, 0.25) !important'
      },
      '& .lead-row--documentacaoSolicitada': {
        backgroundColor: 'rgba(243, 172, 51, 0.25) !important'
      },
      '& .lead-row--reagendado': {
        backgroundColor: 'rgba(243, 172, 51, 0.25) !important'
      }
    }
  }
});

interface GridRow {
  [key: string]: string;
}

export const SearchLead: React.FC = () => {
  const {setTitle} = useTitle();
  const [campaign, setCampaign] = useState<ICampaign[]>([]);
  const [loading, setLoading] = useState(false);
  const [currentCampaign, setCurrentCampaign] = useState('0');
  const [leadColumns, setLeadColumns] = useState<GridColDef[]>([]);
  const [leadRows, setLeadRows] = useState<GridRow[]>([]);
  const [selectedLeadsToSend, setSelectedLeadsToSend] = useState<string[]>([]);
  const [dataGridPageSize, setDataGridPageSize] = useState(10);
  const [leadMessages, setLeadMessages] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const classes = useStyles();

  const camelCaseText = (str: string) => {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word: string, index: number) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, '');
  };

  const normalizeText = useCallback((value: string) => {
    if(value) {
      const resultFilter = value
        .toLocaleLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
  
      return camelCaseText(resultFilter);
    }

    return '';
  }, []);

  const manageRowsToCorrectFormat = (rows: GridRowDef[]) => {
    const finalRowsArray: GridRow[] = [];

    rows.forEach((row) => {
      const completeRow: GridRow = {
        id: row.idLead,
      };

      // colunas fixas
      completeRow['IdLead'] = row.idLead;
      completeRow['StatusLead'] = row.status;
      completeRow['Integrado'] = row.integrado ? 'Sim' : 'Não';

      // colunas dinâmicas
      row.linha.forEach((rowContent) => {
        completeRow[rowContent.field] = rowContent.valor;
      });

      finalRowsArray.push(completeRow);
    });

    return finalRowsArray;
  };

  const getCampaigns = useCallback(async () => {
    try {
      setLoading(true);
      const data = await CampaignService.getCampaigns({});

      if (data) {
        setCampaign(data);
      }
    } catch (error) {
      feedback(errorResponse(error), 'error');
    } finally {
      setLoading(false);
    }
  }, []);

  const getLeadsByCampaignId = useCallback(async () => {
    setLeadMessages('');
    setSelectedLeadsToSend([]);
    feedback('Carregando leads da campanha...', 'loading');
    if (currentCampaign === '0') {
      feedback('Selecione uma campanha.', 'error');
      return;
    }

    try {
      setLoading(true);
      const data = await SearchLeadService.getLeadsByCampaignId(
        currentCampaign,
      );

      if (data) {
        if (data.colunas) {
          const columnsWithWidth = data.colunas.map((col) => {
            if (col.headerName === 'Id') {
              return {
                ...col,
                width: 150,
                disableColumnMenu: true,
                editable: false,
                hideable: false
              };
            }
            return {...col, width: 150};
          });
          setLeadColumns(columnsWithWidth);
        }
        if (data.linhas) {
          setLeadRows(manageRowsToCorrectFormat(data.linhas));
        }
        if(data.mensagemIntegracaoLeads) {
          setLeadMessages(data.mensagemIntegracaoLeads);
        }

        feedback('Leads carregados.', 'success');
      }
    } catch (error) {
      feedback(errorResponse(error), 'error');
    } finally {
      setLoading(false);
    }
  }, [currentCampaign]);

  useEffect(() => {
    setTitle('Busca Personalizada de Leads');
  }, [setTitle]);

  const saveColConfig = useCallback(
    async (field: string, hideConfig: boolean) => {
      feedback('Salvando configuração de colunas.', 'loading');
      if (currentCampaign === '0') {
        feedback(
          'Selecione uma campanha para que a configuração seja armazenada.',
          'error',
        );
        return;
      }
      try {
        const putObject: GridColDef = {
          field: field,
          hide: hideConfig,
        };
        const data = await SearchLeadService.saveColumnsConfig(
          currentCampaign,
          putObject,
        );
        if (data.status === 204 || data.status === 200) {
          feedback('Configuração salva.', 'success');
        }
      } catch (error) {
        feedback(errorResponse(error), 'error');
      }
    },
    [currentCampaign],
  );

  useEffect(() => {
    getCampaigns();
  }, [getCampaigns]);

  const handleSendLeadsToIntegration = useCallback(async () => {
    if (selectedLeadsToSend.length === 0) {
      feedback('Selecione leads para a integração.', 'error');
      return;
    }
    setLoading(true);
    const allIdsToIntegrate = selectedLeadsToSend;
    try {
      const response = await SearchLeadService.patchCollegeIntegrateMultipleIds(currentCampaign, allIdsToIntegrate);

      if (response) {
        feedback(`Tudo certo por aqui! Verifique as mensagens dos leads`, 'warning');
        setLeadMessages(response);
      }

    } catch (error) {
      feedback(errorResponse(error), 'error');
    } finally {
      setLoading(false);
    }
  }, [selectedLeadsToSend, currentCampaign]);

  const handleGetLeads = () => {
    getLeadsByCampaignId();
  };

  return (
    <>
      <Box>
        <Typography variant="h5" color="primary" gutterBottom>
          <strong>Campanhas disponíveis</strong>
        </Typography>
        <Box marginTop={3} marginBottom={2}>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            Selecione uma campanha para ver os leads correspondentes.
          </Typography>
        </Box>
        <Grid container>
          <Grid container item xs={8}>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={9}>
                <TextField
                  disabled={loading}
                  size="small"
                  variant="outlined"
                  label="Campanha *"
                  value={currentCampaign}
                  onChange={({target}) => {
                    setCurrentCampaign(target.value);
                  }}
                  fullWidth
                  select>
                  <MenuItem key={'0'} value={'0'}>
                    <em>Selecione</em>
                  </MenuItem>
                  {campaign?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.nomeCompleto}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={3}>
                <Button
                  disabled={loading || currentCampaign === '0'}
                  fullWidth
                  onClick={handleGetLeads}
                  label="Filtrar"
                  startIcon={<FilterListRounded />}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={4}>
            <Grid item xs={12}>
              <Box width={'100%'} textAlign={'right'}>
                <Button
                  size='medium'
                  onClick={() => {setModalOpen(true)}}
                  label="Situação leads"
                  startIcon={<MessageRounded />}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box>
        {leadRows.length > 0 && leadColumns.length > 0 ? (
          <Grid container>
            <Grid item xs={12}>
              <Box marginTop={3}>
                <DataGrid
                  className={classes.dataGrid}
                  loading={loading}
                  columns={leadColumns}
                  rows={leadRows}
                  pageSize={dataGridPageSize}
                  getRowClassName={(rowParams) => `lead-row--${normalizeText(rowParams.row.StatusLead)}`}
                  rowsPerPageOptions={[10, 20, 30, 50]}
                  onPageSizeChange={(pageSize) => {
                    setDataGridPageSize(pageSize);
                  }}
                  checkboxSelection
                  autoHeight
                  onSelectionModelChange={(ids) => {
                    const selectedIDs = new Set(ids);
                    const selectedRowData = leadRows.filter((row) =>
                      selectedIDs.has(row.IdLead.toString()),
                    );
                    setSelectedLeadsToSend(
                      selectedRowData.map((row) => row.IdLead),
                    );
                  }}
                  onColumnVisibilityChange={(colParams) => {
                    if(colParams.colDef.headerName !== 'Id') {
                      saveColConfig(colParams.field, !colParams.isVisible);
                    }
                  }}
                  components={{
                    Toolbar: GridToolbar,
                    ColumnsPanel: GridColumnsPanel
                  }}
                  componentsProps={{
                    panel: {
                      className: classes.dataGridColumnPanel
                    },
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        ) : (
          <Box marginTop={3}>
            <Typography variant="body1" color="primary" gutterBottom>
              Nenhum registro encontrado...
            </Typography>
          </Box>
        )}
      </Box>

      <Modal
        maxWidth="md"
        labelSaveButton='Fechar'
        title={'Situação dos leads da campanha'}
        loading={loading}
        opened={modalOpen}
        onClick={() => {setModalOpen(false)}}
        onClose={() => {setModalOpen(false)}}>
        <Box>
          {leadMessages ? (
            <Box>
              <pre>
                {leadMessages}
              </pre>
            </Box>
          ) : (
            <Box>
              <Typography>Nenhuma mensagem pós integração disponível.</Typography>
            </Box>
          )}
        </Box>
      </Modal>

      <Footer
        onSaveLabel={'Integrar leads'}
        onSave={handleSendLeadsToIntegration}
        disabled={
          loading ||
          currentCampaign === '0' ||
          leadRows.length === 0 ||
          selectedLeadsToSend.length === 0
        }
      />
    </>
  );
};
