import React from 'react';
import {Close} from '@material-ui/icons';
import {Box, Dialog, IconButton} from '@material-ui/core';

import {Environment} from 'shared/env';

interface IProps {
  openModal: boolean;
  idCampaign: string;
  onClose(): void;
}

export const ModalNewLead: React.FC<IProps> = ({
  openModal,
  idCampaign,
  onClose,
}) => {
  return (
    <Dialog open={openModal} maxWidth="md" fullWidth>
      <Box
        position="absolute"
        borderRadius={50}
        width={48}
        height={48}
        style={{backgroundColor: '#fff'}}
        boxShadow={6}
        right={10}
        top={10}>
        <IconButton aria-label="Fechar modal" onClick={onClose} size="medium">
          <Close />
        </IconButton>
      </Box>
      <iframe
        src={`${Environment.URL_FORM}/formulario-interno/${idCampaign}`}
        frameBorder="0"
        style={{overflow: 'hidden', height: 1000, width: '100%'}}
        height="1000px"
        width="100%"
      />
    </Dialog>
  );
};
