export function imageExtension(image: string): string {
  if (image.slice(0, 25).includes('jpeg')) {
    return 'jpeg';
  } else if (image.slice(0, 25).includes('jpg')) {
    return 'jpg';
  } else if (image.slice(0, 25).includes('png')) {
    return 'png';
  }
  return '';
}
