import {Api} from 'shared/services/axiosConfig';

export interface IMacro {
  title: string;
  value: string;
}

const getMacrosEmail = async (): Promise<IMacro[]> => {
  try {
    const response = await Api.get(`/macroemail`);

    return response.data;
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

export const MacrosService = {
  getMacrosEmail,
};
