import { Menu } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { styled } from '@material-ui/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      height: theme.spacing(8),
      boxShadow: 'none',
    },
    rightInfo: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      gap: theme.spacing(4),
    },
    menuButton: {
      marginRight: theme.spacing(0.5),
    },
    listItem: {
      height: theme.spacing(7),
      color: theme.palette.secondary.contrastText,
    },
    iconMenu: {
      color: theme.palette.secondary.contrastText,
    },
    imgLogo: {
      height: 30,
      objectFit: 'contain',
      marginRight: theme.spacing(2),
    },
  }),
);

export const MenuUser = styled(Menu)({
  width: 197,
  height: 290,
})