import React, {useCallback, useEffect, useState} from 'react';
import {
  ListItemIcon,
  Typography,
  IconButton,
  ListItem,
  useTheme,
  Toolbar,
  AppBar,
  Avatar,
  Grid,
  List,
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  MenuItem,
  Divider,
} from '@material-ui/core';
import {Cached, Https, Menu} from '@material-ui/icons';
import {Skeleton} from '@material-ui/lab';
import {CountdownCircleTimer} from 'react-countdown-circle-timer';
import {feedback} from 'shared/services/alertService';
import {useStyles, MenuUser} from './styles';
import LogoCRM from 'shared/assets/CRMLOGO.png';
import {useAuth, useTitle, useUser} from 'shared/hooks';
import {UserProfileService} from 'shared/services/api/UserProfileService';
import EventIcon from '@material-ui/icons/Event';

import {ModalFeedBack} from 'pages/FeedBack/ModalFeedBack';
import {EditLeadService} from 'shared/services/api/EditLeadService';

interface IProps {
  drawerOpen: boolean;
  buttonActive: boolean;
  handleDrawerOpen(): void;
  setButtonActive(value: boolean): void;
}

export const AppBarComponent: React.FC<IProps> = ({
  drawerOpen,
  buttonActive,
  setButtonActive,
  handleDrawerOpen,
}) => {
  const theme = useTheme();
  const {title} = useTitle();
  const classes = useStyles();
  const {signOut} = useAuth();
  const {data: user} = useUser();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [openD, setOpenD] = React.useState(false);
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const [context, setContext] = useState();

  const handleClose = () => {
    setAnchorEl(null);
    setOpenD(false);
  };

  const handleOpenMenu = useCallback(() => {
    handleDrawerOpen();

    setButtonActive(true);
  }, [handleDrawerOpen, setButtonActive]);

  const handleCloseMenu = useCallback(() => {
    handleDrawerOpen();

    setButtonActive(false);
  }, [handleDrawerOpen, setButtonActive]);

  const handleClearCache = useCallback(async () => {
    const {message} = await UserProfileService.clearUserChache();
    setOpenD(false);
    setAnchorEl(null);
    return feedback(message, 'success');
  }, []);

  const handleUpdatePassword = useCallback(async () => {
    const {data} = await UserProfileService.updateUserPassword();
    window.location.href = data;
  }, []);

  const makeAvatar = () => {
    if (user) {
      const first = user.nome.slice()[0].toUpperCase();
      const last = user.nome.split(' ').slice(-1)[0][0].toUpperCase();

      return first + last;
    }
  };

  useEffect(() => {
    async function getContgext() {
      try {
        const response = await EditLeadService.getLeadContext();
        setContext(response.data.proximoAgendamentoUsuarioLogado);
      } catch (error: any) {
        feedback(error.Mensagem, 'error');
      }
    }
    getContgext();
  }, []);

  const openDialog = useCallback(() => {
    return (
      <Dialog
        style={{display: 'flex', justifyContent: 'space-between'}}
        open={openD}>
        <div
          style={{
            display: 'flex',
            alignContent: 'center',
            padding: '10%',
            width: '100%',
          }}>
          <DialogContent>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                paddingLeft: '10%',
              }}>
              <Typography variant="h6" component="div">
                Você tem certeza que deseja efetuar
              </Typography>
              <Typography
                variant="h6"
                component="div"
                style={{paddingLeft: '20%'}}>
                a limpeza do cache?
              </Typography>
            </div>
          </DialogContent>
        </div>
        <DialogActions>
          <div
            style={{
              width: '100%',
              display: 'flex',
              paddingBottom: '6%',
              justifyContent: 'space-evenly',
            }}>
            <div>
              <Button
                variant="contained"
                color="inherit"
                style={{minWidth: 100}}
                onClick={handleClose}>
                não
              </Button>
            </div>
            <div>
              <Button
                variant="contained"
                color="primary"
                style={{minWidth: 100, paddingRight: '7%'}}
                onClick={handleClearCache}>
                sim
              </Button>
            </div>
          </div>
        </DialogActions>
      </Dialog>
    );
  }, [handleClearCache, openD]);

  const noAccessUser = useCallback(() => {
    return (
      <Dialog open={true} maxWidth="xs" fullWidth>
        <Typography
          variant="h6"
          color="primary"
          style={{padding: '16px 16px 0'}}>
          <strong>Atenção!</strong>
        </Typography>
        <DialogContent>
          <Typography>Usuário sem acesso à Central de Conversão.</Typography>
        </DialogContent>
        <DialogActions style={{padding: 16}}>
          <Button
            variant="contained"
            color="primary"
            style={{minWidth: 100}}
            endIcon={
              <CountdownCircleTimer
                isPlaying
                duration={5}
                onComplete={signOut}
                strokeWidth={2}
                size={30}
                colors={[['#005E5A', 1]]}>
                {({remainingTime}) => remainingTime}
              </CountdownCircleTimer>
            }>
            Sair
          </Button>
        </DialogActions>
      </Dialog>
    );
  }, [signOut]);

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar style={{paddingLeft: 0}}>
        <Grid container>
          <Grid item xs={6}>
            <List>
              <ListItem className={classes.listItem}>
                <ListItemIcon>
                  <IconButton
                    onClick={drawerOpen ? handleCloseMenu : handleOpenMenu}
                    edge="start"
                    className={classes.menuButton}
                    style={{
                      marginLeft: theme.spacing(0.5),
                      backgroundColor: buttonActive
                        ? theme.palette.divider
                        : 'transparent',
                    }}>
                    <Menu fontSize="default" className={classes.iconMenu} />
                  </IconButton>
                </ListItemIcon>
                <img
                  src={LogoCRM}
                  alt="Logo gvdasa"
                  className={classes.imgLogo}
                />

                <Box marginLeft={2} />

                <Typography
                  variant="h6"
                  style={{
                    overflow: 'hidden',
                    width: '100%',
                    maxWidth: '470px',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}>
                  <strong>{title}</strong>
                </Typography>
              </ListItem>
            </List>
          </Grid>

          <Grid item xs={6} className={classes.rightInfo}>
            {user ? (
              <>
                {context === '' ? (
                  <></>
                ) : (
                  <>
                    <Box display={'flex'} marginRight={2}>
                      <Box marginRight={1}>
                        <EventIcon color="action" />
                      </Box>

                      <Typography color="textPrimary">{context}</Typography>
                    </Box>
                  </>
                )}

                <ModalFeedBack />

                <Typography style={{color: '#616161'}}>
                  Olá, <strong>{user.nome}</strong>
                </Typography>
                <Grid>
                  <IconButton color="inherit" onClick={handleMenu}>
                    <Avatar style={{backgroundColor: '#616161'}}>
                      {makeAvatar()}
                    </Avatar>
                  </IconButton>
                  <Grid style={{display: 'flex', width: '100%'}}>
                    <div>
                      <MenuUser
                        style={{
                          display: 'flex',
                          width: '100%',
                          padding: '20%',
                          flexWrap: 'wrap',
                        }}
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}>
                        <Typography
                          style={{
                            width: '100%',
                            marginLeft: '8%',
                            paddingBottom: '5%',
                          }}
                          variant="h6"
                          component="h3">
                          {user.nome}
                        </Typography>
                        <Divider style={{width: '100%', marginBottom: '5%'}} />
                        <Grid
                          style={{
                            display: 'flex',
                            width: '100%',
                            flexWrap: 'wrap',
                          }}>
                          <MenuItem
                            style={{width: '100%', paddingLeft: '7%'}}
                            onClick={() => handleUpdatePassword()}>
                            <span
                              style={{paddingRight: '7%', paddingTop: '1px'}}>
                              <Https />
                            </span>
                            Alterar Senha
                          </MenuItem>
                          <MenuItem
                            style={{width: '100%', paddingLeft: '7%'}}
                            onClick={() => setOpenD(true)}>
                            <span
                              style={{paddingRight: '7%', paddingTop: '3px'}}>
                              <Cached />
                            </span>
                            Limpar cache
                          </MenuItem>
                        </Grid>
                      </MenuUser>
                    </div>
                  </Grid>
                </Grid>
                {openD ? openDialog() : null}
              </>
            ) : (
              <>
                <Skeleton
                  variant="rect"
                  width={200}
                  height={24}
                  animation="wave"
                />
                <Skeleton
                  variant="circle"
                  width={40}
                  height={40}
                  animation="wave"
                />

                {noAccessUser()}
              </>
            )}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
