import {IRequestResult} from 'shared/interfaces/IRequestResult';
import {Api} from 'shared/services/axiosConfig';

export interface ISourceRecord {
  idOrigemMatricula: number;
  nome: string;
  nomeReduzido: string;
}

export interface IFilter {
  idCampanha: string;
  idEstabelecimento: string;
}

export interface IBid {
  idOferta: string | null;
  idEntidadeDeIntegracao: number | null;
  nomeCurso: string | null;
  idCurso: string | null;
  nomeCiclo: string | null;
  idCiclo: string | null;
  nomeTurno: string | null;
  idTurno: string | null;
  idSistemaExterno: number | null;
  identificacaoNoSistemaExterno: string | null;
}

export interface IClass {
  id: string;
  nome: string;
}

export interface IOfferEquivalence {
  idOferta?: string | null;
  idSistemaExterno?: number | null;
  identificacaoNoSistemaExterno?: string | null;
}

export interface IEquivalencePost {
  idOrigemMatricula?: number;
  idCampanha?: string;
  idEstabelecimento?: string;
  ofertas?: IOfferEquivalence[];
}

const getSourceRecord = async (): Promise<ISourceRecord[]> => {
  try {
    const {data} = await Api.get(`/OrigemMatricula/GVCollege`);

    return data;
  } catch (error: any) {
    throw new Error(`Erro ao listar. ${error.mensagens}`);
  }
};

const getClass = async (filter: IFilter): Promise<IClass[]> => {
  try {
    const {data} = await Api.get(
      `/Turma/GVCollegeParaEquivalenciaOfertas?idCampanha=${filter.idCampanha}&idEstabelecimento=${filter.idEstabelecimento}`,
    );

    return data;
  } catch (error: any) {
    throw new Error(`Erro ao listar. ${error.mensagens}`);
  }
};

const getOffers = async (filter: IFilter): Promise<IBid[]> => {
  try {
    const {data} = await Api.get(
      `/Equivalencia/Ofertas?idCampanha=${filter.idCampanha}&idEstabelecimento=${filter.idEstabelecimento}`,
    );

    return data;
  } catch (error: any) {
    throw new Error(`Erro ao listar. ${error.mensagens}`);
  }
};

const postEquivalences = async (
  payload: IEquivalencePost,
): Promise<IRequestResult> => {
  try {
    await Api.post(`/Equivalencia/GVCollege`, payload);

    return {success: true};
  } catch (error: any) {
    throw new Error(`Erro ao salvar. ${error.mensagens}`);
  }
};

export const EquivalenciasService = {
  getClass,
  getOffers,
  getSourceRecord,
  postEquivalences,
};
