import React from 'react';
import {
  Step,
  StepButton,
  StepLabel,
  Stepper as MuiStepper,
} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';

interface IProps {
  steps: {label: string; onClick?(): void}[];
  activeStep: number;
  loading?: boolean;
  isDisabled?: boolean;
  errorStep?: number;
}

export const Stepper: React.FC<IProps> = ({
  steps,
  activeStep,
  loading = false,
  isDisabled = false,
  errorStep,
}) => {
  return (
    <MuiStepper nonLinear activeStep={activeStep}>
      {steps.map((item, index) => {
        const stepProps: {completed?: boolean} = {};
        const labelProps: {optional?: React.ReactNode} = {};
        return loading ? (
          <Step
            key={item.label}
            {...stepProps}
            completed={activeStep > index}
            disabled={isDisabled}>
            <StepLabel>
              <Skeleton variant="rect" width={100} height={16} />
            </StepLabel>
          </Step>
        ) : (
          <Step
            key={item.label}
            {...stepProps}
            completed={activeStep > index}
            disabled={isDisabled}>
            {item.onClick ? (
              <StepButton color="inherit" onClick={item.onClick}>
                <StepLabel error={errorStep === index}>{item.label}</StepLabel>
              </StepButton>
            ) : (
              <StepLabel error={errorStep === index} {...labelProps}>
                {item.label}
              </StepLabel>
            )}
          </Step>
        );
      })}
    </MuiStepper>
  );
};
