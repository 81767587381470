import React, {useState, useRef} from 'react';
import {
  Grow,
  Paper,
  Button,
  Popper,
  MenuList,
  MenuItem,
  ButtonGroup,
  ClickAwayListener,
} from '@material-ui/core';
import {ArrowDropUp} from '@material-ui/icons';

interface IProps {
  label: string;
  setRecurrences: (value:boolean) => void;
  disabled: boolean;
  onClick(value: number): void;
}

export const ButtonList: React.FC<IProps> = ({label, disabled, onClick, setRecurrences}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => setOpen((prevOpen) => !prevOpen);

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup ref={anchorRef} variant="text">
        <Button
          size="small"
          color="primary"
          disabled={disabled}
          onClick={handleToggle}>
          {label}
          <ArrowDropUp />
        </Button>
      </ButtonGroup>
      <Popper open={open} anchorEl={anchorRef.current} transition disablePortal>
        {({TransitionProps}) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  <MenuItem onClick={() => (onClick(1), setRecurrences(false))}>Esta agenda</MenuItem>
                  <MenuItem onClick={() => (onClick(2), setRecurrences(false))}>
                    Esta e as agendas seguintes
                  </MenuItem>
                  <MenuItem onClick={() => (onClick(3), setRecurrences(true))}>
                    Todas as agendas da série
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
