import React from 'react';

import {makeStyles, Theme} from '@material-ui/core';
import {Autocomplete, AutocompleteProps} from '@material-ui/lab';

const useStyles = makeStyles((theme: Theme) => ({
  tag: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    '& svg': {
      color: theme.palette.primary.light,
      '&:hover': {
        color: theme.palette.primary.dark,
      },
    },
  },
}));
// eslint-disable-next-line
export const AutoComplete: React.FC<AutocompleteProps<any, any, any, any>> = ({
  ...props
}) => {
  return <Autocomplete classes={useStyles()} {...props} />;
};
