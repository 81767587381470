import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  Box,
  TableRow,
  useTheme,
  TableCell,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import {useNavigate} from 'react-router-dom';
import {Delete, Edit} from '@material-ui/icons';

import {feedback} from 'shared/services/alertService';
import {useTitle, useTerm, useUser, useDebounce} from 'shared/hooks';
import {errorResponse} from 'shared/utils/errorResponse';
import {CourseService, ICourse} from 'shared/services/api/CourseService';
import {Header, ModalConfirm, Table} from 'shared/components';

import {useStyles} from './styles';

export const Course: React.FC = () => {
  const [openModalConfirmExclude, setOpenModalConfirmExclude] = useState(false);
  const [tableFiltered, setTableFiltered] = useState(0);
  const [idDeleted, setIdDeleted] = useState('');
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState('');
  const [courses, setCourses] = useState<ICourse[]>([]);
  const [take, setTake] = useState(10);
  const [skip, setSkip] = useState(1);
  const [coursesCount, setCoursesCount] = useState(0);

  const theme = useTheme();
  const {terms} = useTerm();
  const classes = useStyles();
  const navigate = useNavigate();
  const {setTitle} = useTitle();
  const {data: user} = useUser();
  const {debounce} = useDebounce();

  const permissions = useMemo(
    () => user?.permissoes.filter((i) => i.nome.includes('Curso')),
    [user?.permissoes],
  );

  const canDelete = useMemo(
    () => !!permissions?.find((i) => i.nome.includes('Excluir')),
    [permissions],
  );

  const canRegister = useMemo(
    () => !!permissions?.find((i) => i.nome.includes('Incluir')),
    [permissions],
  );

  const canList = useMemo(
    () => !!permissions?.find((i) => i.nome.includes('Listar')),
    [permissions],
  );

  useEffect(() => {
    if (!canList) {
      navigate('/');
    }
  }, [canList, navigate]);

  const handleGetCourses = useCallback(() => {
    setLoading(true);
    debounce(async () => {
      try {
        const data = await CourseService.getCoursesPagination(
          tableFiltered,
          filter,
          take,
          skip,
        );
        if (data) {
          setCourses(data.data);
          setCoursesCount(data.qtdTotalDeRegistros);
        }
      } catch (error) {
        feedback(errorResponse(error), 'error');
      } finally {
        setLoading(false);
      }
    });
  }, [tableFiltered, filter, take, skip, debounce]);

  const handleDelete = useCallback((id: string) => {
    setOpenModalConfirmExclude(true);

    setIdDeleted(id);
  }, []);

  const handleConfirmDeleted = useCallback(async () => {
    try {
      setLoading(true);
      await CourseService.deleteCourse(idDeleted);

      await handleGetCourses();

      feedback(`${terms.termoCurso} excluído com sucesso`, 'success');
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
    setLoading(false);
    setIdDeleted('');
    setOpenModalConfirmExclude(false);
  }, [terms.termoCurso, handleGetCourses, idDeleted]);

  useEffect(() => setTitle(terms.termoCurso), [setTitle, terms.termoCurso]);

  useEffect(() => {
    handleGetCourses();
  }, [handleGetCourses]);

  return (
    <>
      <Header
        filter={filter}
        setFilter={setFilter}
        disabledButton={!canRegister}
        tableFiltered={tableFiltered}
        setTableFiltered={setTableFiltered}
        onClick={() => navigate('/curso/novo')}
      />

      <Box marginTop={5} />

      <Table
        head={[
          {label: 'Ações', width: '140px'},
          {label: terms.termoCurso},
          {label: 'Status', width: '140px'},
        ]}
        pagination={{
          count: Math.ceil(coursesCount / take),
          page: skip,
        }}
        loading={loading}
        take={take}
        onChangeTake={(value: number) => setTake(value)}
        length={coursesCount}
        rowsPerPage={courses.length}
        onChangePage={(value: number) => setSkip(value)}>
        {courses.map((item) => (
          <TableRow key={item.id}>
            <TableCell>
              <IconButton
                color="primary"
                onClick={() => handleDelete(item.id)}
                disabled={loading || !canDelete}>
                <Delete />
                {loading && idDeleted === item.id && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </IconButton>

              <IconButton
                style={{marginLeft: theme.spacing(1)}}
                color="primary"
                onClick={() => navigate(`/curso/editar/${item.id}`)}
                disabled={loading}>
                <Edit />
              </IconButton>
            </TableCell>
            <TableCell>{item.nome}</TableCell>
            <TableCell>{item.ativo ? 'Ativo' : 'Inativo'}</TableCell>
          </TableRow>
        ))}
      </Table>

      <ModalConfirm
        opened={openModalConfirmExclude}
        onClick={handleConfirmDeleted}
        onClose={() => {
          setOpenModalConfirmExclude(false), setIdDeleted('');
        }}
        loading={loading}
      />
    </>
  );
};
