import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import {useEffect, useState} from 'react';
import {feedback} from 'shared/services/alertService';
import {
  EditLeadService,
  IAtendimentosLead,
} from 'shared/services/api/EditLeadService';
import EventIcon from '@material-ui/icons/Event';
import PersonIcon from '@material-ui/icons/Person';
import FilterListIcon from '@material-ui/icons/FilterList';
import {Skeleton} from '@material-ui/lab';

interface Iprops {
  idLead: string;
  trigger: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    card: {
      backgroundColor: '#e6e6e6',
    },
  }),
);

export const Atendimentos: React.FC<Iprops> = ({idLead, trigger}) => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(true);

  const [atendimento, setAtendimentos] = useState<IAtendimentosLead>();

  useEffect(() => {
    async function getAtendimentos() {
      setIsLoading(true);
      try {
        const response = await EditLeadService.getAtendimentos(idLead);
        setAtendimentos(response);
        setIsLoading(false);
      } catch (error: any) {
        feedback(error.Mensagem, 'error');
      }
    }
    getAtendimentos();
  }, [idLead, trigger]);

  return (
    <Grid container direction="column">
      <Grid container xs>
        <Grid item xs>
          {isLoading ? (
            <>
              <Box marginBottom={2}>
                <Skeleton variant="rect" height={130} />
              </Box>
              <Box marginBottom={2}>
                <Skeleton variant="rect" height={130} />
              </Box>
              <Box marginBottom={2}>
                <Skeleton variant="rect" height={130} />
              </Box>
              <Box marginBottom={2}>
                <Skeleton variant="rect" height={130} />
              </Box>
            </>
          ) : (
            <>
              {atendimento?.atendimentos.map((item, index) => (
                <Box key={index} marginBottom={2}>
                  <Card elevation={3}>
                    <CardContent>
                      <Grid container direction="column">
                        <Grid item>
                          <Box
                            display={'flex'}
                            alignItems={'center'}
                            marginBottom={1}>
                            <FilterListIcon
                              style={{marginRight: 8, color: '#6e6e6e'}}
                            />
                            <Typography>
                              <b>
                                {item.nomeSituacaoDoLead}
                                {item.nomeSituacaoDeAgenda && ' - '}
                                {item.nomeSituacaoDeAgenda}
                                {item.dataAgenda && ' - '}
                                {item.dataAgenda &&
                                  item.dataAgenda
                                    .substring(0, 10)
                                    .split('-')
                                    .reverse()
                                    .join('/')
                                    .substring(0, 10)
                                    .split('-')
                                    .reverse()
                                    .join('/')
                                  }
                              </b>
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box
                            display={'flex'}
                            alignItems={'center'}
                            marginBottom={1}>
                            <PersonIcon
                              style={{marginRight: 8, color: '#6e6e6e'}}
                            />
                            <Typography>{item.nomeAtendente}</Typography>
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box
                            display={'flex'}
                            alignItems={'center'}
                            marginBottom={1}>
                            <EventIcon
                              style={{marginRight: 8, color: '#6e6e6e'}}
                            />
                            <Typography>
                              {item.dataAtendimento
                                .substring(0, 10)
                                .split('-')
                                .reverse()
                                .join('/')
                                .substring(0, 10)
                                .split('-')
                                .reverse()
                                .join('/')}
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid item>
                          <Box marginTop={2} marginLeft={6}>
                            {item.atendimento?.length > 0 ? (
                              <>
                                <Card
                                  style={{width: '90%', height: '60%'}}
                                  elevation={0}
                                  className={classes.card}>
                                  <CardContent>
                                    <Box marginTop={1}>
                                      <Typography variant="body2">
                                        {item.atendimento}
                                      </Typography>
                                    </Box>
                                  </CardContent>
                                </Card>
                              </>
                            ) : (
                              <></>
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Box>
              ))}
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
