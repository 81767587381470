import React from 'react';

import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  TextField,
} from '@material-ui/core';
import {Modal} from 'shared/components';
import {useTerm} from 'shared/hooks';
import {IComplementary} from 'shared/services/api/ComplementaryService';

interface IProps {
  openModal: boolean;
  loading: boolean;
  submitted: boolean;
  nameVerify: boolean;
  title: string;
  onClose(): void;
  onClick(): void;
  comp: IComplementary;
  setComp(value: IComplementary): void;
}

export const ModalComplementaryTwo: React.FC<IProps> = ({
  openModal,
  title,
  loading,
  nameVerify,
  submitted,
  onClose,
  onClick,
  comp,
  setComp,
}) => {
  const {terms} = useTerm();

  return (
    <Modal
      opened={openModal}
      maxWidth="sm"
      labelSaveButton="Salvar"
      labelCloseButton="Cancelar"
      title={title}
      loading={loading}
      onClose={onClose}
      onClick={onClick}>
      <FormControl component="fieldset" fullWidth>
        <FormControlLabel
          control={
            <Checkbox
              checked={comp.ativo}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setComp({...comp, ativo: event.target.checked});
              }}
            />
          }
          label="Ativo"
        />

        <Box marginTop={2} />

        <TextField
          variant="outlined"
          error={(!comp.nome && submitted) || nameVerify}
          helperText={
            !comp.nome && submitted
              ? 'Campo requerido'
              : nameVerify &&
                `O nome da ${terms.termoComplementar2} deve ser único`
          }
          value={comp.nome}
          label={`${terms.termoComplementar2} *`}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setComp({...comp, nome: event.target.value});
          }}
          fullWidth
        />
      </FormControl>
    </Modal>
  );
};
