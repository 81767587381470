import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  Box,
  TableRow,
  useTheme,
  TableCell,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import {useNavigate} from 'react-router-dom';
import {Delete, SwapHoriz} from '@material-ui/icons';

import {useTitle, useUser, useDebounce} from 'shared/hooks';
import {
  IUserProfile,
  UserProfileService,
} from 'shared/services/api/UserProfileService';
import {feedback} from 'shared/services/alertService';
import {errorResponse} from 'shared/utils/errorResponse';
import {Header, ModalConfirm, Table } from 'shared/components';

import {useStyles} from './styles';

export const UserPermission: React.FC = () => {
  const [filter, setFilter] = useState('');
  const [loading, setLoading] = useState(false);
  const [idDeleted, setIdDeleted] = useState<number>();
  const [tableFiltered, setTableFiltered] = useState(0);
  const [userPermissions, setUserPermissions] = useState<IUserProfile[]>([]);
  const [openModalConfirmExclude, setOpenModalConfirmExclude] = useState(false);
  const [take, setTake] = useState(10);
  const [skip, setSkip] = useState(1);
  const [userPermissionCount, setUserPermissionCount] = useState(0);

  const theme = useTheme();
  const classes = useStyles();
  const {setTitle} = useTitle();
  const navigate = useNavigate();
  const {data: user} = useUser();
  const {debounce} = useDebounce();

  const permissions = useMemo(
    () => user?.permissoes.filter((i) => i.nome.includes('Permissão')),
    [user?.permissoes],
  );

  const canDelete = useMemo(
    () => !!permissions?.find((i) => i.nome.includes('Excluir')),
    [permissions],
  );

  const canLink = useMemo(
    () => !!permissions?.find((i) => i.nome.includes('Vincular')),
    [permissions],
  );

  const canList = useMemo(
    () => !!permissions?.find((i) => i.nome.includes('Listar')),
    [permissions],
  );
  const handleGetUserPermissions = useCallback( () => {
    setLoading(true);

    debounce(async ()=> {
      try {
        const data = await UserProfileService.getUserPermissions(filter,tableFiltered, take, skip);
        if (data) {
          setUserPermissions(data.data);
          setUserPermissionCount(data.qtdTotalDeRegistros);
        }
      } catch (error) {
        feedback(errorResponse(error), 'error');
      } finally {
        setLoading(false);
      }

    })
  }, [filter,tableFiltered,take,skip, debounce]);

  const handleDelete = useCallback((id: number) => {
    setOpenModalConfirmExclude(true);

    setIdDeleted(id);
  }, []);

  const handleConfirmDeleted = useCallback(async () => {
    try {
      if (idDeleted) {
        setLoading(true);

        await UserProfileService.deleteUserPermission(idDeleted);

        await handleGetUserPermissions();

        feedback('Perfil do usuário deletado com sucesso', 'success');
      }
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
    setLoading(false);
    setIdDeleted(undefined);
    setOpenModalConfirmExclude(false);
  }, [handleGetUserPermissions, idDeleted]);

  useEffect(() => setTitle('Permissão do usuário'), [setTitle]);

  useEffect(() => {
    if (!canList) {
      navigate('/');
    }
  }, [canList, navigate]);

  useEffect(() => {
    handleGetUserPermissions();
  }, [handleGetUserPermissions]);


  return (
    <>
      <Header
        filter={filter}
        setFilter={setFilter}
        startIcon={<SwapHoriz />}
        disabledButton={!canLink || loading}
        tableFiltered={tableFiltered}
        labelButton="Vincular permissão"
        setTableFiltered={setTableFiltered}
        onClick={() => navigate('/permissoes-do-usuario/novo')}></Header>

      <Box marginTop={5} />

        <Table
          head={[
            {label: 'Ações', width: '140px'},
            {label: 'Usuário', width: '50%'},
            {label: 'Login', width: '50%'},
            {label: 'Status', align: 'right'},
          ]}
          pagination={{
            count: Math.ceil(userPermissionCount / take),
            page: skip,
          }}
          loading={loading}
          onChangeTake={(value:number) => setTake(value)}
          take={take}
          length={userPermissionCount}
          rowsPerPage={userPermissions.length}
          onChangePage={ (value: number) => setSkip(value)}>
          {
            userPermissions.map((item) => (
              <TableRow key={item.id}>
                <TableCell>
                  <IconButton
                    color="primary"
                    disabled={loading || !canDelete}
                    onClick={() => handleDelete(item.id)}>
                    <Delete />
                    {loading && idDeleted === item.id && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </IconButton>

                  <IconButton
                    color="primary"
                    disabled={loading}
                    style={{marginLeft: theme.spacing(1)}}
                    onClick={() =>
                      navigate(`/permissoes-do-usuario/editar/${item.id}`)
                    }>
                    <SwapHoriz />
                  </IconButton>
                </TableCell>
                <TableCell>{item.nome}</TableCell>
                <TableCell>{item.login}</TableCell>
                <TableCell align="right">
                  {item.ativo ? 'Ativo' : 'Inativo'}
                </TableCell>
              </TableRow>
            ))}
        </Table>

      <ModalConfirm
        loading={loading}
        onClick={handleConfirmDeleted}
        opened={openModalConfirmExclude}
        onClose={() => {
          setOpenModalConfirmExclude(false), setIdDeleted(undefined);
        }}
      />
    </>
  );
};
