import React from 'react';

import {Fade, IconButton} from '@material-ui/core';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';

import {CustomArrowProps} from 'react-slick';

import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconButton: {
      boxShadow: theme.shadows[1],
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      zIndex: 1,
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      [theme.breakpoints.down('md')]: {
        padding: 0,
      },
    },

    buttonLeft: {
      left: -30,
      [theme.breakpoints.down('lg')]: {
        left: -24,
      },
      [theme.breakpoints.down('md')]: {
        left: -12,
      },
    },

    buttonRight: {
      right: -30,
      [theme.breakpoints.down('lg')]: {
        right: -24,
      },
      [theme.breakpoints.down('md')]: {
        right: -12,
      },
    },
  }),
);

interface IArrowProps extends CustomArrowProps {
  direction: 'back' | 'next';
}

export const Arrow: React.FC<IArrowProps> = ({direction, onClick}) => {
  const styles = useStyles();

  return (
    <Fade in={!!onClick}>
      <IconButton
        onClick={onClick}
        size="medium"
        className={clsx(styles.iconButton, {
          [styles.buttonLeft]: direction === 'back',
          [styles.buttonRight]: direction === 'next',
        })}>
        {direction === 'back' ? (
          <ChevronLeft fontSize="large" color="primary" />
        ) : (
          <ChevronRight fontSize="large" color="primary" />
        )}
      </IconButton>
    </Fade>
  );
};
