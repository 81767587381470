import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  Box,
  Grid,
  useTheme,
  TableRow,
  MenuItem,
  TableCell,
  TextField,
  Typography,
  ListSubheader,
} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
import {useNavigate} from 'react-router-dom';
import {FilterList} from '@material-ui/icons';

import {useTitle, useUser} from 'shared/hooks';
import {
  IBid,
  IClass,
  IFilter,
  ISourceRecord,
  IEquivalencePost,
  EquivalenciasService,
} from 'shared/services/api/EquivalenciasService';
import {
  IUnityEstablishment,
  EstablishmentService,
} from 'shared/services/api/EstablishmentService';
import {feedback} from 'shared/services/alertService';
import {errorResponse} from 'shared/utils/errorResponse';
import {Button, Footer, Table, TableSkeleton} from 'shared/components';
import {SourceRecordService} from 'shared/services/api/SourceRecordService';
import {CampaignService, ICampaign} from 'shared/services/api/CampaignService';

export const Equivalence: React.FC = () => {
  const [sent, setSent] = useState(true);
  const [loading, setLoading] = useState(false);
  const [offers, setOffers] = useState<IBid[]>([]);
  const [verifyField, setVerifyField] = useState('');
  const [classes, setClasses] = useState<IClass[]>([]);
  const [campaign, setCampaign] = useState<ICampaign[]>([]);
  const [filter, setFilter] = useState<IFilter>({} as IFilter);
  const [sourceRecord, setSourceRecord] = useState<ISourceRecord[]>([]);
  const [equivalences, setEquivalences] = useState<IEquivalencePost>({
    idOrigemMatricula: 0,
  } as IEquivalencePost);
  const [establishments, setEstablishments] = useState<
    Omit<IUnityEstablishment[], 'unidades'>
  >([]);


  const theme = useTheme();
  const navigate = useNavigate();
  const {setTitle} = useTitle();
  const {data: user} = useUser();

  const permissions = useMemo(
    () => user?.permissoes.filter((i) => i.nome?.includes('Equivalência')),
    [user?.permissoes],
  );

  const canEdit = useMemo(
    () => !!permissions?.find((i) => i.nome?.includes('Editar')),
    [permissions],
  );

  const canList = useMemo(
    () => !!permissions?.find((i) => i.nome?.includes('Listar')),
    [permissions],
  );

  const permissionList = useMemo(
    () => permissions?.find((i) => i.nome?.includes('Listar')),
    [permissions],
  );

  const getIntegrationCollege = useCallback(async () => {
    setLoading(true);
    try {
      const response = await SourceRecordService.getIntegrationCollege();

      if (response) {
        setEquivalences(response);
      }
    } catch (error) {
      feedback(errorResponse(error), 'error');
    } finally {
      setLoading(false);
    }
  }, []);

  const getSourceRecord = useCallback(async () => {
    setLoading(true);
    try {
      const response = await EquivalenciasService.getSourceRecord();

      if (response) {
        setSourceRecord(response);
      }
    } catch (error) {
      feedback(errorResponse(error), 'error');
    } finally {
      setLoading(false);
    }
  }, []);

  const getCampaign = useCallback(async () => {
    setLoading(true);
    try {
      const response = await CampaignService.getCampaigns({
        IdTipoIntegracao: 2, // [{"id": 1,"nome": "Não integra"},{"id": 2,"nome": "GVCollege"}]
        idPermissao: permissionList?.id,
      });

      if (response) {
        setCampaign(response);
      }
    } catch (error) {
      feedback(errorResponse(error), 'error');
    } finally {
      setLoading(false);
    }
  }, [permissionList?.id]);

  const getEstablishments = useCallback(async () => {
    setLoading(true);
    try {
      const response = await EstablishmentService.getEstablishments(
        permissionList?.id,
      );

      if (response) {
        setEstablishments(response.estabelecimentos);
      }
    } catch (error) {
      feedback(errorResponse(error), 'error');
    } finally {
      setLoading(false);
    }
  }, [permissionList?.id]);

  const getClass = useCallback(async () => {
    setLoading(true);
    try {
      const response = await EquivalenciasService.getClass(filter);

      if (response) {
        setClasses(response);
      }
    } catch (error) {
      feedback(errorResponse(error), 'error');
    } finally {
      setLoading(false);
    }
  }, [filter]);

  const getOffers = useCallback(async () => {
    setLoading(true);
    try {
      const response = await EquivalenciasService.getOffers(filter);

      if (response) {
        setOffers(response);
        setEquivalences((state) => (state = {...state, ofertas: response}));
      }
    } catch (error) {
      feedback(errorResponse(error), 'error');
    } finally {
      setLoading(false);
    }
  }, [filter]);

  const handleGetContent = useCallback(() => {
    setEquivalences(
      (state) =>
        (state = {
          ...state,
          idCampanha: filter.idCampanha,
          idEstabelecimento: filter.idEstabelecimento,
        }),
    );

    getClass();
    getOffers();
  }, [filter, getClass, getOffers]);

  const handleChangeTurma = useCallback(
    async (index: number, value: number) => {
      const updatedOffers = offers;

      const selected = classes.filter((item) => Number(item.id) === value);

      updatedOffers[index].idSistemaExterno = value;
      updatedOffers[index].identificacaoNoSistemaExterno = selected[0]?.nome;

      setOffers([...updatedOffers]);
      setEquivalences((state) => (state = {...state, ofertas: updatedOffers}));
    },
    [classes, offers],
  );

  const handleCheckMenuItem = useCallback(
    (value) => {
      if (value.idSistemaExterno) {
        const filtered = classes.filter(
          (item) => item.id === value.idSistemaExterno,
        );
        if (filtered.length === 0) {
          return (
            <MenuItem value={value.idSistemaExterno}>
              {value.identificacaoNoSistemaExterno} - Não encontrado
            </MenuItem>
          );
        }
      }
    },
    [classes],
  );

  const handleSave = useCallback(async () => {
    if (equivalences.idOrigemMatricula) {
      setSent(true);
      setLoading(true);
      try {
        await EquivalenciasService.postEquivalences(equivalences);

        feedback('Equivalências salvas com sucesso!', 'success');
      } catch (error) {
        feedback(errorResponse(error), 'error');
      } finally {
        setLoading(false);
      }
    } else {
      setSent(false);
    }
  }, [equivalences]);

  useEffect(() => setTitle('Equivalências GVCollege'), [setTitle]);

  useEffect(() => {
    if (!canList) navigate('/');
  }, [canList, navigate]);

  useEffect(() => {
    getCampaign();
    getSourceRecord();
    getEstablishments();
    getIntegrationCollege();
  }, [getCampaign, getSourceRecord, getEstablishments, getIntegrationCollege]);


  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h6" color="primary">
            <strong>Geral</strong>
          </Typography>
        </Grid>

        <Grid item xs={12} sm={5}>
          {loading ? (
            <Skeleton variant="rect" height={40} animation="wave" />
          ) : (
            <TextField
              select
              fullWidth
              size="small"
              variant="outlined"
              label="Origem de matrícula *"
              disabled={loading || !canEdit}
              value={equivalences?.idOrigemMatricula}
              error={!equivalences.idOrigemMatricula && !sent}
              helperText={
                !equivalences.idOrigemMatricula && !sent && 'Campo obrigatório'
              }
              onChange={({target}) =>
                setEquivalences(
                  (state) =>
                    (state = {...state, idOrigemMatricula: +target.value}),
                )
              }>
              {sourceRecord?.map((item) => (
                <MenuItem
                  key={item.idOrigemMatricula}
                  value={item.idOrigemMatricula}>
                  {item.nome}
                </MenuItem>
              ))}
            </TextField>
          )}
          <Box marginTop={2} />
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h6" color="primary">
            <strong>Ofertas</strong>
          </Typography>
        </Grid>

        <Grid item xs={12} sm={5}>
          {loading ? (
            <Skeleton variant="rect" height={40} animation="wave" />
          ) : (
            <TextField
              select
              fullWidth
              size="small"
              disabled={loading || !canEdit}
              variant="outlined"
              value={filter.idCampanha}
              label="Campanha de captação *"
              onChange={({target}) =>
                setFilter(
                  (state) => (state = {...state, idCampanha: target.value}),
                )
              }>
              {campaign?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.nomeCompleto}
                </MenuItem>
              ))}
            </TextField>
          )}
        </Grid>

        <Grid item xs={12} sm={5}>
          {loading ? (
            <Skeleton variant="rect" height={40} animation="wave" />
          ) : (
            <TextField
              select
              fullWidth
              size="small"
              variant="outlined"
              disabled={loading || !canEdit}
              label="Estabelecimento *"
              value={filter.idEstabelecimento}
              onChange={({target}) =>
                setFilter(
                  (state) =>
                    (state = {...state, idEstabelecimento: target.value}),
                )
              }>
              {establishments?.map((item) => {
                if (item.idEstabelecimentoPai) {
                  return (
                    <MenuItem
                      key={item.idEstabelecimento}
                      value={item.idEstabelecimento}
                      style={{paddingLeft: theme.spacing(3)}}>
                      {item.idComNome}
                    </MenuItem>
                  );
                } else {
                  return (
                    <ListSubheader key={item.idEstabelecimento}>
                      {item.idComNome}
                    </ListSubheader>
                  );
                }
              })}
            </TextField>
          )}
        </Grid>

        <Grid item xs={12} sm={2}>
          <Button
            disabled={
              loading || !(filter.idCampanha && filter.idEstabelecimento)
            }
            fullWidth
            label="Filtrar"
            startIcon={<FilterList />}
            onClick={handleGetContent}
          />
        </Grid>
      </Grid>

      <Box marginTop={5} />

      {loading ? (
        <TableSkeleton columns={2} rowsBody={10} rectWidth="40%" />
      ) : (
        <Table
          head={[
            {label: 'Oferta', width: '50%'},
            {label: 'Turma', width: '50%'},
          ]}
          length={-1}
          rowsPerPage={10}>
          {offers.map((item, index) => (
            <TableRow key={item.idOferta}>
              <TableCell>
                {item.nomeCurso} {item.nomeCiclo && ` - ${item.nomeCiclo}`}
                {item.nomeTurno && ` - ${item.nomeTurno}`}
              </TableCell>
              <TableCell>
                <TextField
                  select
                  fullWidth
                  size="small"
                  disabled={loading || !canEdit}
                  variant="outlined"
                  defaultValue={item.idSistemaExterno}
                  onChange={({target}) => {
                    handleChangeTurma(index, Number(target.value));
                    setVerifyField(String(target.value));
                  }}>
                  <MenuItem value=""> &nbsp;</MenuItem>
                  {handleCheckMenuItem(item)}
                  {classes?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.nome}
                    </MenuItem>
                  ))}
                </TextField>
              </TableCell>
            </TableRow>
          ))}
        </Table>
      )}

      <Footer
        onCancel={() => navigate(-1)}
        onSave={handleSave}
        disabled={
          loading || offers.length === 0 || !canEdit || verifyField.length <= 0
        }
      />
    </>
  );
};
