import {Environment} from 'shared/env';

const signOut = async (): Promise<boolean> => {
  try {
    if (Environment.URL_ORIGIN_DEBUG.length > 0) {
      window.location.href = `${Environment.API_GATEWAY}/SignOut?callback_url=${window.location.href}&origin_debug=${Environment.URL_ORIGIN_DEBUG}`;
    } else {
      window.location.href = `${Environment.API_GATEWAY}/SignOut?callback_url=${window.location.href}`;
    }

    return true;
  } catch (error) {
    return false;
  }
};

export const SignOutService = {
  signOut,
};
