import React from 'react';

import {Box, Paper, Divider} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';

export const LatestSubscriberSkeleton: React.FC = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      height="100%"
      paddingX={3.5}
      paddingY={4}
      component={Paper}>
      <Skeleton variant="text" width="85%" height={28} animation="wave" />
      <Box marginY={3}>
        <Box marginBottom={2}>
          <Skeleton variant="text" width="100%" height={26} animation="wave" />
        </Box>
        <Skeleton variant="text" width="100%" height={26} animation="wave" />
      </Box>
      <Divider />
      <Box marginY={3}>
        <Box marginBottom={2}>
          <Skeleton variant="text" width="100%" height={24} animation="wave" />
        </Box>
        <Box marginBottom={2}>
          <Skeleton variant="text" width="100%" height={24} animation="wave" />
        </Box>
        <Skeleton variant="text" width="100%" height={24} animation="wave" />
      </Box>
      <Divider />
      <Box marginTop={3}>
        <Skeleton variant="text" width="100%" height={24} animation="wave" />
      </Box>
    </Box>
  );
};
