import React from 'react';

import {
  DialogActions,
  DialogContent,
  Dialog,
  Typography,
  CircularProgress,
  Box,
} from '@material-ui/core';

import {Button} from '../Button';
import {useStyles} from './styles';

interface IProps {
  loading?: boolean;
  disabled?: boolean;
  opened: boolean;
  onClose(): void;
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;
  title: string;
  labelCloseButton?: string;
  labelSaveButton: string;
  onClick(): void;
}

export const Modal: React.FC<IProps> = ({
  opened,
  loading = false,
  disabled = false,
  onClose,
  maxWidth,
  title,
  children,
  labelCloseButton,
  labelSaveButton,
  onClick,
}) => {
  const classes = useStyles();

  return (
    <Dialog open={opened} onClose={onClose} maxWidth={maxWidth} fullWidth>
      <Typography
        variant="h6"
        color="primary"
        style={{
          padding: '32px 24px 0',
        }}>
        <strong>{title}</strong>
      </Typography>
      <DialogContent>{children}</DialogContent>
      <DialogActions
        style={{
          padding: '48px 24px 32px',
        }}>
        {labelCloseButton && (
          <Button
            variant="outlined"
            disabled={loading}
            label={labelCloseButton}
            onClick={onClose}
          />
        )}
        <Box marginLeft={1} />
        {
          labelSaveButton.length > 0 && (
            <Button
              label={labelSaveButton}
              onClick={onClick}
              disabled={loading || disabled}>
              {loading && (
                <CircularProgress size={24} className={classes.buttonProgress} />
              )}
            </Button>
          )
        }
      </DialogActions>
    </Dialog>
  );
};
