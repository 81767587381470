import {useMemo, useState} from 'react';
import {
  Box,
  Card,
  makeStyles,
  Typography,
  CardContent,
  createStyles,
  CardActionArea,
} from '@material-ui/core';

import {HorizontalBarChart} from '../charts';
import {ConversionByCampaing as ConversionByCampaingModal} from './../modals';

import {IConversaoPorCampanha} from 'shared/services/api/CRMDashboard';

const useStyle = makeStyles(() =>
  createStyles({
    card: {display: 'flex', flexDirection: 'column', height: '100%'},
    fullHeight: {height: '100%'},
    cardSubtitle: {
      fontSize: 16,
    },
  }),
);

const BASE_HEIGHT = 50;
const ITEM_HEIGHT = 40;

interface IProps {
  conversaoPorCampanha: IConversaoPorCampanha[];
  selectedPeriod: string;
  selectedEstablishment: string;
  selectedCampaing: string;
}

export const ConversionByCampaing: React.FC<IProps> = ({
  conversaoPorCampanha,
  selectedPeriod,
  selectedEstablishment,
  selectedCampaing,
}) => {
  const [openModal, setOpenModal] = useState(false);

  const classes = useStyle();

  const labels = useMemo(
    () => conversaoPorCampanha.map((item) => item.campanha),
    [conversaoPorCampanha],
  );
  const data = useMemo(
    () => conversaoPorCampanha.map((item) => item.valor),
    [conversaoPorCampanha],
  );

  const height = useMemo(
    () => BASE_HEIGHT + ITEM_HEIGHT * data.length,
    [data.length],
  );

  return (
    <>
      <Card elevation={3} className={classes.card}>
        <Box marginTop={2} marginLeft={2} width="100%" textAlign="left">
          <Typography variant="h6">Conversão por campanha</Typography>
          <Typography className={classes.cardSubtitle}>
            Percentual de conversão por campanha
          </Typography>
        </Box>
        <CardActionArea
          onClick={() => setOpenModal(true)}
          className={classes.fullHeight}>
          <CardContent>
            <HorizontalBarChart
              showDataLabels
              showAsPercentage
              width="100%"
              height={height}
              labels={labels}
              datasets={[
                {
                  label: 'Respondido',
                  barPercentage: 0.55,
                  data: data,
                },
              ]}
              options={{
                maintainAspectRatio: false,
              }}
            />
          </CardContent>
        </CardActionArea>
      </Card>

      <ConversionByCampaingModal
        openModal={openModal}
        onclose={() => setOpenModal(false)}
        selectedPeriod={selectedPeriod}
        selectedEstablishment={selectedEstablishment ?? '0'}
        selectedCampaing={selectedCampaing ?? ''}
      />
    </>
  );
};
