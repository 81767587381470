import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@material-ui/core';
import React, {useCallback, useState} from 'react';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import {DatePicker} from '@material-ui/pickers';
import {Event} from '@material-ui/icons';
import {EditLeadService} from 'shared/services/api/EditLeadService';
import {feedback} from 'shared/services/alertService';
import {errorResponse} from 'shared/utils/errorResponse';

interface Iprops {
  idLead: string;
  trigger?: boolean;
  setTrigger: (trigger: boolean) => void;
}

export const ModalNovaTarefa: React.FC<Iprops> = ({
  idLead,
  trigger,
  setTrigger,
}) => {
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    new Date(),
  );

  const [descricao, setDescricao] = useState({
    descricao: '',
  });

  const [openModal, setOpenModal] = useState(false);

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);

    setSelectedDate(new Date());

    setDescricao({
      ...descricao,
      descricao: '',
    });
  };

  const postSalvar = useCallback(async () => {
    try {
      await EditLeadService.postSalvarTarefa(
        {
          idLead: idLead,
          nome: descricao.descricao,
          venceEm: selectedDate,
        },
        idLead,
      );
      feedback('Tarefa criada com sucesso', 'success');
    } catch (error) {
      feedback(errorResponse(error), 'error');
    } finally {
      handleCloseModal();
      setTimeout(() => {
        setTrigger(!trigger);
      }, 100);
    }
  }, [idLead, selectedDate, descricao, setTrigger, trigger]);

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleOpenModal()}>
        <Box display={'flex'} alignItems={'center'}>
          <Box marginRight={1} marginTop={1}>
            <AddCircleIcon />
          </Box>
          <Typography>Nova Tarefa</Typography>
        </Box>
      </Button>

      <Dialog open={openModal} maxWidth="xl">
        <DialogTitle>
          <Typography variant="h6" color="primary">
            Nova tarefa
          </Typography>
        </DialogTitle>

        <DialogContent>
          <Grid container direction="column" style={{width: 600}}>
            <Grid item xs>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>Descrição da tarefa</InputLabel>
                <OutlinedInput
                  multiline
                  rows={3}
                  label="Descrição da terefa"
                  type="string"
                  value={descricao.descricao}
                  onChange={({target}) =>
                    setDescricao({
                      ...descricao,
                      descricao: target.value,
                    })
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs>
              <Box>
                <DatePicker
                  inputVariant="outlined"
                  fullWidth
                  InputProps={{endAdornment: <Event color="primary" />}}
                  format="dd/MM/yyyy"
                  margin="normal"
                  label="Data vencimento"
                  value={selectedDate}
                  onChange={handleDateChange}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box padding={2} marginBottom={1}>
            <Button variant="outlined" onClick={() => handleCloseModal()}>
              cancelar
            </Button>
            <Button
              style={{marginLeft: 12}}
              color="primary"
              variant="contained"
              onClick={() => postSalvar()}>
              Salvar
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};
