import React, {useCallback, useState} from 'react';
import {
  Box,
  Button,
  Dialog,
  Typography,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
  DataGrid,
  GridColDef,
  GridColumnsPanel,
  GridToolbarExport,
  GridRenderCellParams,
  GridToolbarContainer,
} from '@mui/x-data-grid';

import {feedback} from 'shared/services/alertService';

import {
  CRMDashboardService,
  IActiveCampaing,
} from 'shared/services/api/CRMDashboard';


interface IProps {
  openModal: boolean;
  onclose(): void;
  establishment: string;
}

export const ActiveCampaing: React.FC<IProps> = ({
  openModal,
  onclose,
  establishment,
}) => {
  const [loading, setLoading] = useState(true);
  const [activeCampain, setActiveCampain] = useState<IActiveCampaing[]>([]);

  const activeCampainList = useCallback(async () => {
    setLoading(true);
    CRMDashboardService.getActiveCampaing(establishment)
      .then((response) => {
        setActiveCampain(response);
      })
      .catch(() => feedback('Erro ao buscar dados', 'error'))
      .finally(() => setLoading(false));
  }, [establishment]);

  const columns: GridColDef[] = [
    {
      field: 'campanha',
      headerName: 'Campanha',
      flex: 1,
    },
    {
      field: 'estabelecimento',
      headerName: 'Estabelecimento Dono',
      flex: 1,
    },
    {
      field: 'dataInicial',
      headerName: 'Vigência',
      flex: 1,

      renderCell: (params: GridRenderCellParams) =>
        `${String(params.row.dataInicial)
          .substring(0, 10)
          .split('-')
          .reverse()
          .join('/')} à ${String(params.row.dataFinal)
          .substring(0, 10)
          .split('-')
          .reverse()
          .join('/')}`,
    },
    {
      field: 'situacao',
      headerName: 'Situação',
      flex: 1,
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  return (
    <>
      <Dialog
        fullScreen
        maxWidth="md"
        fullWidth
        open={openModal}
        onClose={onclose}
        onEntering={activeCampainList}
        aria-labelledby="Campanhas ativas"
        aria-describedby="Campanhas ativas">
        <DialogTitle id="Campanhas ativas">
          <Box width="100%" display={'flex'} justifyContent="space-between">
            <Box>
              <Typography variant="inherit" color="primary">
                Campanhas ativas
              </Typography>
            </Box>
            <Box>
              <Button color="primary" onClick={onclose}>
                <CloseIcon />
              </Button>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DataGrid
            loading={loading}
            columns={columns}
            rows={activeCampain}
            getRowId={(row) => row.id}
            autoHeight
            pageSize={7}
            components={{
              Toolbar: CustomToolbar,
              ColumnsPanel: GridColumnsPanel,
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
