import React from 'react';

import {Box, useTheme} from '@material-ui/core';

interface IProps {
  color?: string;
}

export const ColorImage: React.FC<IProps> = ({color}) => {
  const theme = useTheme();

  return (
    <Box
      position="relative"
      width="100%"
      height={216}
      borderRadius={4}
      display="flex"
      alignItems="center"
      justifyContent="center"
      padding={2}
      style={{backgroundColor: 'white'}}>
      <Box
        display="flex"
        alignItems="flex-end"
        justifyContent="center"
        style={{border: `4px solid ${theme.palette.grey[800]}`}}
        width="75%"
        height="100px"
        borderRadius={4}
        marginBottom={4}>
        <Box
          width="40%"
          height="15%"
          borderRadius={2}
          style={{backgroundColor: color}}
        />
      </Box>

      <Box
        position="absolute"
        display="flex"
        alignItems="flex-end"
        justifyContent="center"
        style={{
          border: `4px solid ${theme.palette.grey[800]}`,
          backgroundColor: 'white',
        }}
        width="29%"
        height="70px"
        top="40%"
        left="22%"
        borderRadius={4}
        marginBottom={4}>
        <Box
          width="50%"
          height="15%"
          borderRadius={2}
          style={{backgroundColor: color}}
        />
      </Box>

      <Box
        position="absolute"
        display="flex"
        alignItems="flex-end"
        justifyContent="center"
        style={{backgroundColor: theme.palette.grey[800]}}
        width="29%"
        height="35px"
        top="72%"
        left="22%"
        borderRadius={4}
        marginBottom={4}
      />

      <Box
        position="absolute"
        display="flex"
        alignItems="flex-end"
        justifyContent="center"
        style={{
          border: `4px solid ${theme.palette.grey[800]}`,
          borderBottom: `12px solid ${theme.palette.grey[800]}`,
          backgroundColor: 'white',
        }}
        width="13%"
        height="75px"
        top="45%"
        right="23%"
        borderRadius={4}
        marginBottom={4}>
        <Box
          width="70%"
          height="15%"
          borderRadius={2}
          style={{backgroundColor: color}}
        />
        <Box
          width="40%"
          position="absolute"
          height="2px"
          borderRadius={4}
          bottom={-8}
          left={13}
          style={{backgroundColor: 'white'}}
        />
      </Box>
    </Box>
  );
};
