import {
  FormControl,
  TextField,
  Box,
  FormControlLabel,
  Typography,
  Grid,
  Checkbox,
} from '@material-ui/core';
import React, {useState} from 'react';
import {InfoTooltip, Modal, RichText} from 'shared/components';
import {ILossReasons} from 'shared/services/api/LossReasonsService';

interface IProps {
  openModal: boolean;
  loading: boolean;
  title: string;
  reasonHandler: ILossReasons;
  setReasonHandler(value: ILossReasons): void;
  onClose(): void;
  onClick(): void;
}

export const ModalLossReasons: React.FC<IProps> = ({
  openModal,
  title,
  loading,
  reasonHandler,
  setReasonHandler,
  onClose,
  onClick,
}) => {
  const [isVerified, setIsVerified] = useState(true);
  const handleCreateConfirm = () => {
    reasonHandler.descricao ? onClick() : setIsVerified(false);
  };

  return (
    <Modal
      maxWidth="md"
      opened={openModal}
      title={title}
      loading={loading}
      onClick={handleCreateConfirm}
      onClose={onClose}
      labelSaveButton="Salvar"
      labelCloseButton="Cancelar">
      <FormControl component="fieldset" fullWidth>
        <Box marginTop={2} />
        <TextField
          value={reasonHandler.descricao}
          variant="outlined"
          label="Descrição"
          error={!isVerified}
          helperText={!isVerified && 'Campo requerido'}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setReasonHandler({
              id: reasonHandler.id,
              descricao: event.target.value,
              assuntoEmailNotificacao: reasonHandler.assuntoEmailNotificacao,
              enviarNotificacao: reasonHandler.enviarNotificacao,
              mensagemEmailNotificacao: reasonHandler.mensagemEmailNotificacao,
              qtdeDiasAteEnvioNotificacao:
                reasonHandler.qtdeDiasAteEnvioNotificacao,
            });
            setIsVerified(true);
          }}
          fullWidth
        />
      </FormControl>
      <Grid item>
        <Box marginLeft={1} marginTop={4}>
          <Typography variant="subtitle1" color="primary">
            Envio de e-mail
          </Typography>
        </Box>
        <Box display="flex" alignItems={'center'}>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={reasonHandler.enviarNotificacao}
                  onChange={() => {
                    setReasonHandler({
                      ...reasonHandler,
                      enviarNotificacao: !reasonHandler.enviarNotificacao,
                    });
                  }}
                  name="checked3"
                  color="primary"
                />
              }
              label="Enviar e-mail"
            />
          </Box>
          <InfoTooltip title="O e-mail será disparado quando lead chegar nesta etapa" />
        </Box>

        <Box display={'flex'} marginTop={1} marginBottom={1} width={'35%'}>
          <TextField
            label="Quantidade de dias"
            variant="outlined"
            fullWidth
            value={reasonHandler.qtdeDiasAteEnvioNotificacao}
            type="number"
            onChange={({target}) => {
              setReasonHandler({
                ...reasonHandler,
                qtdeDiasAteEnvioNotificacao: Number(target.value),
              });
            }}
            InputProps={{inputProps: {min: 0}}}
          />
          <InfoTooltip title="Quantidade de dias antes do agendamento que o e-mail deve ser enviado" />
        </Box>

        <Box marginTop={2} marginBottom={2}>
          <TextField
            fullWidth
            label="Assunto"
            variant="outlined"
            value={reasonHandler.assuntoEmailNotificacao}
            onChange={({target}) => {
              setReasonHandler({
                ...reasonHandler,
                assuntoEmailNotificacao: target.value,
              });
            }}
          />
        </Box>
      </Grid>
      <Grid item>
        <RichText
          value={reasonHandler.mensagemEmailNotificacao || ''}
          onChange={(value) =>
            setReasonHandler({
              ...reasonHandler,
              mensagemEmailNotificacao: value || '',
            })
          }
        />

        <Box marginBottom={3} />
      </Grid>
    </Modal>
  );
};
