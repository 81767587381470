import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  Box,
  TableRow,
  useTheme,
  TableCell,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import {Delete, Edit} from '@material-ui/icons';

import {
  IComplementary,
  ComplementaryService,
} from 'shared/services/api/ComplementaryService';
import {feedback} from 'shared/services/alertService';
import {useTitle, useTerm, useUser, useDebounce} from 'shared/hooks';
import {errorResponse} from 'shared/utils/errorResponse';
import {Header, ModalConfirm, Table} from 'shared/components';

import {useStyles} from './styles';
import {ModalComplementaryTwo} from './Modal';
import {useNavigate} from 'react-router-dom';

export const ComplementaryTwo: React.FC = () => {
  const [filter, setFilter] = useState('');
  const [loading, setLoading] = useState(false);
  const [idDeleted, setIdDeleted] = useState('');
  const [editModal, setEditModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [tableFiltered, setTableFiltered] = useState(0);
  const [complementary, setComplementary] = useState<IComplementary[]>([]);
  const [openModalConfirmExclude, setOpenModalConfirmExclude] = useState(false);
  const [comp, setComp] = useState<IComplementary>({
    ativo: true,
    nome: '',
  } as IComplementary);
  const [take, setTake] = useState(10);
  const [skip, setSkip] = useState(1);
  const [complementaryCount, setComplementaryCount] = useState(0);

  const theme = useTheme();
  const {terms} = useTerm();
  const classes = useStyles();
  const navigate = useNavigate();
  const {setTitle} = useTitle();
  const {data: user} = useUser();
  const {debounce} = useDebounce();

  const permissions = useMemo(
    () => user?.permissoes.filter((i) => i.nome.includes('Complementar2')),
    [user?.permissoes],
  );

  const canEdit = useMemo(
    () => !!permissions?.find((i) => i.nome.includes('Editar')),
    [permissions],
  );

  const canDelete = useMemo(
    () => !!permissions?.find((i) => i.nome.includes('Excluir')),
    [permissions],
  );

  const canRegister = useMemo(
    () => !!permissions?.find((i) => i.nome.includes('Incluir')),
    [permissions],
  );

  const canList = useMemo(
    () => !!permissions?.find((i) => i.nome.includes('Listar')),
    [permissions],
  );

  useEffect(() => {
    if (!canList) {
      navigate('/');
    }
  }, [canList, navigate]);

  const handleVerifyNameComp = useCallback(() => {
    if (editModal) {
      return (
        complementary
          .filter((i) => i.id !== comp.id)
          .filter(
            (i) =>
              i.nome.toLowerCase().trim() === comp.nome.toLowerCase().trim(),
          ).length > 0
      );
    }
    return (
      complementary.filter(
        (i) => i.nome.toLowerCase().trim() === comp.nome.toLowerCase().trim(),
      ).length > 0
    );
  }, [comp.id, comp.nome, complementary, editModal]);

  const handleGetComplementary = useCallback(() => {
    setLoading(true);
    debounce( async () => {
      try {
        const data = await ComplementaryService.getComplementaryTwoPagination(
          tableFiltered,
          filter,
          skip,
          take
        );
        if (data) {
          setComplementary(data.data);
          setComplementaryCount(data.qtdTotalDeRegistros);
        }
      } catch (error) {
        feedback(errorResponse(error), 'error');
      } finally {
        setLoading(false);
      }
    })
  }, [tableFiltered, filter, skip, take, debounce]);

  const handleDelete = useCallback((id: string) => {
    setOpenModalConfirmExclude(true);

    setIdDeleted(id);
  }, []);

  const handleConfirmDeleted = useCallback(async () => {
    try {
      setLoading(true);
      await ComplementaryService.deleteComplementaryTwo(idDeleted);

      await handleGetComplementary();

      feedback(`${terms.termoComplementar2} deletado com sucesso`, 'success');
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
    setLoading(false);
    setIdDeleted('');
    setOpenModalConfirmExclude(false);
  }, [terms.termoComplementar2, handleGetComplementary, idDeleted]);

  const handleResetDataModal = useCallback(() => {
    setComp({
      ativo: true,
      nome: '',
    } as IComplementary);
    setTimeout(() => {
      setEditModal(false);
    }, 1000);
  }, []);

  const handleCreateComp = useCallback(async () => {
    if (handleVerifyNameComp()) return;

    if (comp.nome.length === 0) {
      setSubmitted(true);

      return;
    } else {
      setLoading(true);
      try {
        await ComplementaryService.postComplementaryTwo({
          ativo: comp.ativo,
          nome: comp.nome,
        });

        await handleGetComplementary();

        feedback(
          `${terms.termoComplementar2} adicionado com sucesso`,
          'success',
        );
      } catch (error) {
        feedback(errorResponse(error), 'error');
      }
      setLoading(false);
      setOpenModal(false);
      handleResetDataModal();
    }
    setSubmitted(false);
  }, [
    handleVerifyNameComp,
    comp.nome,
    comp.ativo,
    handleResetDataModal,
    handleGetComplementary,
    terms.termoComplementar2,
  ]);

  const handleUpdateComp = useCallback(async () => {
    if (handleVerifyNameComp()) return;

    if (comp.nome.length === 0) {
      setSubmitted(true);

      return;
    } else {
      setLoading(true);
      try {
        await ComplementaryService.updateComplementaryTwo(comp.id, {
          ativo: comp.ativo,
          nome: comp.nome,
        });

        await handleGetComplementary();

        feedback(
          `${terms.termoComplementar2} atualizado com sucesso`,
          'success',
        );
      } catch (error) {
        feedback(errorResponse(error), 'error');
      }
      setLoading(false);
      setOpenModal(false);
      handleResetDataModal();
      setSubmitted(false);
    }
  }, [
    handleVerifyNameComp,
    comp.nome,
    comp.id,
    comp.ativo,
    handleResetDataModal,
    handleGetComplementary,
    terms.termoComplementar2,
  ]);

  useEffect(() => {
    setTitle(terms.termoComplementar2);
  }, [terms.termoComplementar2, setTitle]);

  useEffect(() => {
    handleGetComplementary();
  }, [handleGetComplementary]);

  return (
    <>
      <Header
        filter={filter}
        setFilter={setFilter}
        disabledButton={!canRegister}
        tableFiltered={tableFiltered}
        setTableFiltered={setTableFiltered}
        onClick={() => setOpenModal((state) => !state)}
      />

      <Box marginTop={5} />

        <Table
          head={[
            {label: 'Ações', width: '140px'},
            {label: terms.termoComplementar2},
            {label: 'Status', width: '140px'},
          ]}
          pagination={{
            count: Math.ceil(complementaryCount / take),
            page: skip,
          }}
          loading={loading}
          take={take}
          onChangeTake={(value:number) => setTake(value)}
          length={complementaryCount}
          rowsPerPage={complementary.length}
          onChangePage={(value:number) => setSkip(value)}>
          {
            complementary.map((item) => (
              <TableRow key={item.id}>
                <TableCell>
                  <IconButton
                    color="primary"
                    disabled={loading || !canDelete}
                    onClick={() => handleDelete(item.id)}>
                    <Delete />
                    {loading && idDeleted === item.id && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </IconButton>

                  <IconButton
                    color="primary"
                    disabled={loading || !canEdit}
                    style={{marginLeft: theme.spacing(1)}}
                    onClick={() => {
                      setOpenModal(true), setEditModal(true), setComp(item);
                    }}>
                    <Edit />
                  </IconButton>
                </TableCell>
                <TableCell>{item.nome}</TableCell>
                <TableCell>{item.ativo ? 'Ativo' : 'Inativo'}</TableCell>
              </TableRow>
            ))}
        </Table>

      <ModalConfirm
        opened={openModalConfirmExclude}
        onClick={handleConfirmDeleted}
        onClose={() => {
          setOpenModalConfirmExclude(false), setIdDeleted('');
        }}
        loading={loading}
      />

      <ModalComplementaryTwo
        openModal={openModal}
        loading={loading}
        title={`${editModal ? 'Editar' : 'Cadastro de'} ${
          terms.termoComplementar2
        }`}
        submitted={submitted}
        nameVerify={handleVerifyNameComp()}
        onClose={() => {
          setOpenModal((state) => !state),
            handleResetDataModal(),
            setSubmitted(false);
        }}
        onClick={editModal ? handleUpdateComp : handleCreateComp}
        comp={comp}
        setComp={setComp}
      />
    </>
  );
};
