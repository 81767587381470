import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  Box,
  Grid,
  Checkbox,
  useTheme,
  MenuItem,
  TableRow,
  TableCell,
  TextField,
  IconButton,
  Typography,
  FormControl,
  FormControlLabel,
} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
import {useNavigate, useParams} from 'react-router-dom';
import {AddCircleRounded, Delete} from '@material-ui/icons';

import {useTitle, useUser} from 'shared/hooks';
import {
  IProfile,
  IPostUserProfile,
  UserProfileService,
} from 'shared/services/api/UserProfileService';
import {
  IUnityEstablishment,
  EstablishmentService,
} from 'shared/services/api/EstablishmentService';
import {feedback} from 'shared/services/alertService';
import {errorResponse} from 'shared/utils/errorResponse';
import {IAdmUser, UserService} from 'shared/services/api/UserService';
import {
  Table,
  Button,
  Footer,
  AutoComplete,
  TableSkeleton,
} from 'shared/components';

export const NewUserPermission: React.FC = () => {
  const [edit, setEdit] = useState(false);
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [users, setUsers] = useState<IAdmUser[]>([]);
  const [profiles, setProfiles] = useState<IProfile[]>([]);
  const [establishments, setEstablishments] = useState<
    Omit<IUnityEstablishment[], 'unidades'>
  >([]);
  const [userPermission, setUserPermission] = useState<IPostUserProfile>(
    {} as IPostUserProfile,
  );
  const [permissions, setPermissions] = useState([
    {
      id: '-1',
      idEstabelecimento: '',
      idPerfil: '',
      ativo: true,
    },
  ]);

  const theme = useTheme();
  const navigate = useNavigate();
  const {setTitle} = useTitle();
  const params = useParams<'id'>();
  const {data: user} = useUser();

  const permission = useMemo(
    () => user?.permissoes.filter((i) => i.nome.includes('Permissão')),
    [user?.permissoes],
  );

  const canLink = useMemo(
    () => !!permission?.find((i) => i.nome.includes('Vincular')),
    [permission],
  );

  const canList = useMemo(
    () => !!permission?.find((i) => i.nome.includes('Listar')),
    [permission],
  );

  const handleGetData = useCallback(async () => {
    try {
      setLoading(true);
      const resUsers = await UserService.getAdmUser();
      const resUserProfile = await UserProfileService.getProfiles();
      const resEstablishment = await EstablishmentService.getEstablishments();

      setProfiles(resUserProfile);
      setUsers(resUsers.filter((i) => i.ativo));
      setEstablishments(resEstablishment.estabelecimentos);
      if (params.id) {
        const resUserPermission = await UserProfileService.getUserPermission(
          params.id,
        );

        setUserPermission({
          idUsuario: Number(params.id),
          perfisUsuario: resUserPermission,
        });
        setPermissions(
          resUserPermission.map((item, index) => {
            return {
              id: `g${index}`,
              ativo: item.ativo,
              idEstabelecimento: item.idEstabelecimento,
              idPerfil: item.idPerfil,
            };
          }),
        );
        setEdit(true);
      }
    } catch (error) {
      feedback('Erro ao buscar os dados', 'error');
    } finally {
      setLoading(false);
    }
  }, [params.id]);

  const handleChangeNameUserPermission = useCallback((value: IAdmUser) => {
    setUserPermission(
      (state) => (state = {...state, idUsuario: value.idUsuarioAuth}),
    );
  }, []);

  const handleChangeIdEstablishment = useCallback(
    (event, index) =>
      setPermissions((state) => {
        return state.map((oldState, oldStateIndex) => {
          if (index === oldStateIndex) {
            oldState.idEstabelecimento = event.target.value;
          }
          return oldState;
        });
      }),
    [],
  );

  const handleChangeIdProfile = useCallback(
    (event, index) =>
      setPermissions((state) => {
        return state.map((oldState, oldStateIndex) => {
          if (index === oldStateIndex) {
            oldState.idPerfil = event.target.value;
          }
          return oldState;
        });
      }),
    [],
  );

  const handleDeletePermission = useCallback((index) => {
    setPermissions((state) => {
      return state.filter((_, stateIndex) => stateIndex !== index);
    });
  }, []);

  const handleActivePermission = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, index) =>
      setPermissions((state) => {
        return state.map((oldState, oldStateIndex) => {
          if (index === oldStateIndex) {
            oldState.ativo = event.target.checked;
          }
          return oldState;
        });
      }),
    [],
  );

  const handleAddPermission = useCallback((index) => {
    setPermissions((state) => [
      ...state,
      {
        id: `${index}`,
        idEstabelecimento: '',
        idPerfil: '',
        ativo: true,
      },
    ]);
  }, []);

  const handleCreatePermission = useCallback(
    async (newCourse: boolean) => {
      if (
        !userPermission.idUsuario &&
        userPermission.perfisUsuario.length === 0
      ) {
        setSubmitted(true);
        return;
      }

      if (
        userPermission.perfisUsuario.filter(
          (item) => item.idEstabelecimento === '' || item.idPerfil === '',
        ).length > 0
      ) {
        setSubmitted(true);
        return;
      }
      try {
        await UserProfileService.postUserProfile(userPermission);
        feedback('Perfil adicionado com sucesso', 'success');
        if (newCourse) {
          setPermissions([
            {
              id: '-1',
              idEstabelecimento: '',
              idPerfil: '',
              ativo: true,
            },
          ]);

          setUserPermission({} as IPostUserProfile);
        } else {
          navigate('/permissoes-do-usuario');
        }
      } catch (error) {
        feedback(errorResponse(error), 'error');
      } finally {
        setSubmitted(false);
      }
    },
    [navigate, userPermission],
  );

  useEffect(() => setTitle('Permissão do usuário'), [setTitle]);

  useEffect(() => {
    if (!canList) {
      navigate('/');
    }
  }, [canList, navigate]);

  useEffect(() => {
    setUserPermission(
      (state) =>
        (state = {
          ...state,
          perfisUsuario: permissions.map((item) => {
            return {
              ativo: item.ativo,
              idEstabelecimento: item.idEstabelecimento,
              idPerfil: item.idPerfil,
            };
          }),
        }),
    );
  }, [permissions]);

  useEffect(() => {
    handleGetData();
  }, [handleGetData]);

  useEffect(() => {
    if (userPermission.idUsuario)
      setActive(
        users.find((item) => item.idUsuarioAuth === userPermission.idUsuario)
          ?.ativo || false,
      );
  }, [users, userPermission.idUsuario]);

  return (
    <>
      <Typography variant="h5" color="primary">
        <strong>{edit ? 'Editar' : 'Vincular'} perfil</strong>
      </Typography>

      <Box marginTop={3} />

      <FormControl component="fieldset" fullWidth>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {loading ? (
              <Skeleton width={250} height={45} />
            ) : (
              <FormControlLabel
                label="Ativo no sistema de origem"
                control={<Checkbox checked={active} disabled />}
              />
            )}
          </Grid>

          <Grid item xs={12} sm={5}>
            {loading ? (
              <Skeleton width={500} height={70} />
            ) : (
              <AutoComplete
                disabled={edit}
                options={users}
                disableClearable
                filterSelectedOptions
                getOptionLabel={(option) => option.nome}
                onChange={(_, value) => handleChangeNameUserPermission(value)}
                value={
                  userPermission.idUsuario
                    ? users.filter(
                        (i) => i.idUsuarioAuth === userPermission.idUsuario,
                      )[0]
                    : null
                }
                getOptionSelected={(option, value) =>
                  option.idUsuarioAuth === value.idUsuarioAuth
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Usuário *"
                    variant="outlined"
                    error={!userPermission.idUsuario && submitted}
                    helperText={
                      !userPermission.idUsuario &&
                      submitted &&
                      'Campo requerido'
                    }
                  />
                )}
              />
            )}
            <Box marginBottom={3} />
          </Grid>
        </Grid>
      </FormControl>

      {loading ? (
        <TableSkeleton btnAct={1} columns={3} colStatus />
      ) : (
        userPermission.idUsuario && (
          <Table
            head={[
              {label: 'Ações'},
              {label: 'Perfil', width: '50%'},
              {label: 'Estabelecimento', width: '50%'},
              {label: 'Ativo', align: 'right'},
            ]}
            pagination={{
              count: Math.ceil(1 / 1),
              page: 1,
            }}
            length={1}
            rowsPerPage={1}
            footer={
              <TableCell align="right">
                <Button
                  variant="text"
                  disabled={!canLink}
                  textTransform="uppercase"
                  label="ADICIONAR PERMISSÃO"
                  onClick={handleAddPermission}
                  startIcon={<AddCircleRounded />}
                />
              </TableCell>
            }>
            {permissions?.map((item, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Box display="flex" justifyContent="space-between">
                    <IconButton
                      color="primary"
                      disabled={!canLink}
                      onClick={() => handleDeletePermission(index)}>
                      <Delete />
                    </IconButton>
                  </Box>
                </TableCell>

                <TableCell width="50%">
                  <TextField
                    select
                    fullWidth
                    size="small"
                    variant="outlined"
                    disabled={!canLink}
                    value={item.idPerfil}
                    onChange={(event) => handleChangeIdProfile(event, index)}
                    error={
                      (!item.idPerfil && submitted) ||
                      permissions
                        .filter((_, i) => i !== index)
                        .filter(
                          (i) =>
                            i.idEstabelecimento === item.idEstabelecimento &&
                            i.idPerfil === item.idPerfil,
                        ).length > 0
                    }
                    helperText={
                      !item.idPerfil && submitted
                        ? 'Campo requerido'
                        : permissions
                            .filter((_, i) => i !== index)
                            .filter(
                              (i) =>
                                i.idEstabelecimento ===
                                  item.idEstabelecimento &&
                                i.idPerfil === item.idPerfil,
                            ).length > 0 &&
                          'A combinação de perfil e estabelecimento deve ser única por usuário.'
                    }>
                    {profiles
                      .filter((item) => item.ativo)
                      .map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.nome}
                        </MenuItem>
                      ))}
                  </TextField>
                </TableCell>
                <TableCell width="50%">
                  <TextField
                    select
                    fullWidth
                    size="small"
                    variant="outlined"
                    disabled={!canLink}
                    value={item.idEstabelecimento}
                    onChange={(event) =>
                      handleChangeIdEstablishment(event, index)
                    }
                    error={
                      (!item.idEstabelecimento && submitted) ||
                      permissions
                        .filter((_, i) => i !== index)
                        .filter(
                          (i) =>
                            i.idEstabelecimento === item.idEstabelecimento &&
                            i.idPerfil === item.idPerfil,
                        ).length > 0
                    }
                    helperText={
                      !item.idEstabelecimento && submitted
                        ? 'Campo requerido'
                        : permissions
                            .filter((_, i) => i !== index)
                            .filter(
                              (i) =>
                                i.idEstabelecimento ===
                                  item.idEstabelecimento &&
                                i.idPerfil === item.idPerfil,
                            ).length > 0 &&
                          'A combinação de perfil e estabelecimento deve ser única por usuário.'
                    }>
                    {establishments?.map((item) => {
                      if (item.idEstabelecimentoPai) {
                        return (
                          <MenuItem
                            key={item.idEstabelecimento}
                            value={item.idEstabelecimento}
                            style={{paddingLeft: theme.spacing(3)}}>
                            {item.idComNome}
                          </MenuItem>
                        );
                      } else {
                        return (
                          <MenuItem
                            key={item.idEstabelecimento}
                            value={item.idEstabelecimento}
                            style={{
                              color: theme.palette.primary.main,
                              fontWeight: 'bold',
                              fontSize: 16,
                            }}>
                            {item.idComNome}
                          </MenuItem>
                        );
                      }
                    })}
                  </TextField>
                </TableCell>

                <TableCell align="right">
                  <Checkbox
                    disabled={!canLink}
                    checked={item.ativo}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handleActivePermission(event, index)
                    }
                  />
                </TableCell>
              </TableRow>
            ))}
          </Table>
        )
      )}

      <Box marginY={14} />

      <Footer
        disabled={!canLink}
        onCancel={() => navigate(-1)}
        onSave={() => handleCreatePermission(false)}
        onSaveAndNew={edit ? undefined : () => handleCreatePermission(true)}
      />
    </>
  );
};
