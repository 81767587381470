import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  Box,
  Grid,
  Table,
  Paper,
  Checkbox,
  MenuItem,
  TableRow,
  TableHead,
  TextField,
  TableCell,
  TableBody,
  IconButton,
  Typography,
  TableContainer,
  FormControlLabel,
} from '@material-ui/core';
import {format} from 'date-fns';
import {Skeleton, Pagination} from '@material-ui/lab';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {Add, ArrowBack, Delete, Edit} from '@material-ui/icons';

import {
  AvailableSchedule,
  Button,
  Modal,
  ModalConfirm,
  TableSkeleton,
} from 'shared/components';
import {
  ICall,
  LeadService,
  ICustomerService,
  IScheduleSituations,
} from 'shared/services/api/LeadService';
import {
  ILead,
  LeadSituationService,
} from 'shared/services/api/LeadSituationService';
import {feedback} from 'shared/services/alertService';
import {useTitle, useTerm, useUser} from 'shared/hooks';
import {
  AvailabilitiesService,
  IScheduleAvailability,
} from 'shared/services/api/ScheduleAvailabilityService';
import {errorResponse} from 'shared/utils/errorResponse';

import {useStyles} from '../../styles';

interface LocationState {
  funnelId: string;
}

export const MeetLead: React.FC = () => {
  const [sent, setSent] = useState(true);
  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(false);
  const [idToDelete, setIdToDelete] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [leadsCall, setLeadsCall] = useState<ICall[]>([]);
  const [modalOpenConfirm, setModalOpenConfirm] = useState(false);
  const [leadSituations, setLeadSituations] = useState<ILead[]>([]);
  const [scheduleSituations, setScheduleSituations] = useState<
    IScheduleSituations[]
  >([]);
  const [schedule, setSchedule] = useState<IScheduleAvailability[]>([]);
  const [scheduleAvailability, setScheduleAvailability] =
    useState<IScheduleAvailability>();
  const [customerService, setCustomerService] = useState<ICustomerService>({
    usaAgendamento: false,
  });

  const location = useLocation();
  const { funnelId } = location.state as LocationState;

  const {terms} = useTerm();
  const navigate = useNavigate();
  const {setTitle} = useTitle();
  const {data: user} = useUser();
  const {
    nomeLead,
    idLead = '0',
    idOferta = '0',
  } = useParams<'nomeLead' | 'idLead' | 'idOferta'>();
  const {noWrap, headAct, baseboard, rowDisabled, displayNone} = useStyles();

  const permissions = useMemo(
    () => user?.permissoes.filter((i) => i.nome.includes('Lead')),
    [user?.permissoes],
  );

  const canList = useMemo(
    () => !!permissions?.find((i) => i.nome.includes('Listar')),
    [permissions],
  );

  const userTimezone = useMemo(
    () => (user?.timezone ? user.timezone : -3),
    [user?.timezone],
  );

  const handleClose = () => setModalOpen(false);

  const getLeadsCall = useCallback(async () => {
    setLoading(true);
    try {
      const {atendimentos} = await LeadService.getLeadsCall(
        idLead,
        userTimezone,
      );

      if (atendimentos.length) {
        setLeadsCall(atendimentos);
      } else {
        feedback(
          `Este ${terms.termoLead} ainda não possui atendimentos.`,
          'warning',
        );
      }
    } catch (error) {
       
      feedback(errorResponse(error), 'error');
    } finally {
      setLoading(false);
    }
  }, [idLead, terms.termoLead, userTimezone]);

  const getScheduleAvailability = useCallback(
    async (idDisponibilidadeDeAgenda) => {
      setLoading(true);
      try {
        const response = await AvailabilitiesService.getScheduleAvailability(
          idDisponibilidadeDeAgenda,
          userTimezone,
        );

        if (response) {
          setScheduleAvailability(response);
        }
      } catch (error) {
         
        feedback(errorResponse(error), 'error');
      } finally {
        setLoading(false);
      }
    },
    [userTimezone],
  );

  const checkInclusion = useCallback(() => {
    if (leadsCall[0].idSituacaoDeAgenda !== 1) {
      return true;
    } else {
      return false;
    }
  }, [leadsCall]);

  const handleIncludeCall = useCallback(() => {
    if (checkInclusion()) {
      setScheduleAvailability(undefined);
      setCustomerService({
        idLead: idLead,
        idSituacaoDeAgenda: leadsCall[0].idSituacaoDeAgenda,
        usaAgendamento: false,
      });
      setModalOpen(true);
    } else {
      feedback(
        `O atendimento atual possui agenda pendente.
        É necessário realizar, reagendar ou cancelar esta agenda para incluir
        um novo atendimento.`,
        'warning',
      );
    }
  }, [checkInclusion, idLead, leadsCall]);

  const handleEditCall = useCallback(
    (service: ICall) => {
      setEditing(true);
      if (service.idDisponibilidadeDeAgenda) {
        getScheduleAvailability(service.idDisponibilidadeDeAgenda);
      } else {
        setScheduleAvailability(undefined);
      }

      setCustomerService({
        id: service.idAtendimentoDoLead,
        idSituacaoDeLead: service.idSituacaoDoLead,
        idSituacaoDeAgenda: service.idSituacaoDeAgenda,
        idDisponibilidadeDeAgenda: service.idDisponibilidadeDeAgenda,
        atendimento: service.atendimento,
        usaAgendamento: service.usaAgendamento,
      });
      setModalOpen(true);
    },
    [getScheduleAvailability],
  );

  const handleDeleteCall = useCallback(async () => {
    setLoading(true);

    try {
      await LeadService.deleteMeetLead(idToDelete);

      feedback('Atendimento excluído com sucesso', 'success');
    } catch (error) {
       
      feedback(errorResponse(error), 'error');
    } finally {
      setModalOpenConfirm(false);
      setLoading(false);
      getLeadsCall();
    }
  }, [idToDelete, getLeadsCall]);

  const getScheduleSituations = useCallback(async () => {
    try {
      const response = await LeadService.getScheduleSituations();

      if (response) {
        setScheduleSituations(response);
      }
    } catch (error) {
       
      feedback(errorResponse(error), 'error');
    }
  }, []);

  const getAvailabilities = useCallback(async () => {
    try {
      const response = await AvailabilitiesService.getAvailabilities(
        idOferta,
        userTimezone,
      );

      if (response) {
        setSchedule(response);
      }
    } catch (error) {
       
      feedback(errorResponse(error), 'error');
    }
  }, [idOferta, userTimezone]);

  const getLeadSituations = useCallback(async () => {
    try {
      const response = await LeadSituationService.getLeads(funnelId, true);

      if (response) {
        setLeadSituations(response);
      }
    } catch (error) {
       
      feedback(errorResponse(error), 'error');
    }
  }, []);

  const handleCreateLeadsCall = useCallback(async () => {
    if (customerService.idSituacaoDeLead) {
      if (
        !customerService.usaAgendamento ||
        (customerService.usaAgendamento &&
          customerService.idDisponibilidadeDeAgenda)
      ) {
        setSent(true);
        setLoading(true);
        try {
          await LeadService.postLeadsCall(customerService);

          feedback('Atendimento criado com sucesso', 'success');
        } catch (error) {
           
          feedback(errorResponse(error), 'error');
        } finally {
          setModalOpen(false);
          setLoading(false);
          getLeadsCall();
        }
      } else {
        setSent(false);
      }
    } else {
      setSent(false);
    }
  }, [customerService, getLeadsCall]);

  const handleAlterLeadsCall = useCallback(async () => {
    setLoading(true);

    try {
      await LeadService.putLeadsCall(customerService);

      feedback('Atendimento alterado com sucesso', 'success');
      setModalOpen(false);
      getLeadsCall();
    } catch (error) {
       
      feedback(errorResponse(error), 'error');
    } finally {
      setLoading(false);
    }
  }, [customerService, getLeadsCall]);

  const skeleton = () => (
    <>
      <Grid container justify="space-between" alignItems="center">
        <Grid item>
          <Skeleton variant="circle" width={30} height={30} />
        </Grid>

        <Grid item>
          <Skeleton variant="rect" width={160} height={36} />
        </Grid>
      </Grid>
      <Box marginTop={3} />
      <TableSkeleton rectWidth="30%" columns={5} rowsBody={3} />
    </>
  );

  useEffect(() => setTitle(`Lead - ${nomeLead}`), [setTitle, nomeLead]);
  useEffect(() => {
    if (!canList) navigate('/');
  }, [canList, navigate]);

  useEffect(() => {
    getLeadsCall();
    getLeadSituations();
    getScheduleSituations();
    getAvailabilities();
  }, [
    getLeadsCall,
    getLeadSituations,
    getScheduleSituations,
    getAvailabilities,
  ]);

  return (
    <>
      <Modal
        opened={modalOpen}
        maxWidth="sm"
        labelSaveButton="Salvar"
        labelCloseButton="Cancelar"
        title={`Adicionar atendimento`}
        loading={loading}
        onClose={handleClose}
        onClick={editing ? handleAlterLeadsCall : handleCreateLeadsCall}>
        <Box marginTop={2} />

        <Grid container component={Box} spacing={2}>
          <Grid item xs={12}>
            <TextField
              select
              fullWidth
              variant="outlined"
              disabled={loading || editing}
              label={`Situação ${terms.termoLead}`}
              value={customerService.idSituacaoDeLead}
              error={!customerService.idSituacaoDeLead && !sent}
              helperText={
                !customerService.idSituacaoDeLead &&
                !sent &&
                'Campo obrigatório'
              }
              onChange={({target}) => {
                setCustomerService({
                  ...customerService,
                  idSituacaoDeLead: target.value,
                });
              }}>
              {leadSituations?.map((item) => (
                <MenuItem
                  className={
                    item.categoria.id !== 1 && item.categoria.id !== 2
                      ? displayNone
                      : ''
                  }
                  key={item.id}
                  value={item.id}>
                  {item.nome}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              label="Efetuar agendamento"
              control={
                <Checkbox
                  disabled={
                    loading || customerService.usaAgendamento ? true : false
                  }
                  checked={customerService.usaAgendamento}
                  onChange={({target}) => {
                    setCustomerService({
                      ...customerService,
                      usaAgendamento: target.checked,
                    });
                  }}
                />
              }
            />
          </Grid>

          {customerService.usaAgendamento && (
            <>
              <Grid item xs={12}>
                <AvailableSchedule
                  label="Agenda"
                  disabled={loading}
                  availabilities={schedule}
                  defaultValue={scheduleAvailability}
                  error={
                    !(
                      customerService.usaAgendamento &&
                      customerService.idDisponibilidadeDeAgenda
                    ) && !sent
                  }
                  helperText={
                    !(
                      customerService.usaAgendamento &&
                      customerService.idDisponibilidadeDeAgenda
                    ) &&
                    !sent &&
                    `Campo obrigatório`
                  }
                  onChange={(value) => (
                    setScheduleAvailability(value),
                    setCustomerService({
                      ...customerService,
                      idDisponibilidadeDeAgenda:
                        value.idDisponibilidadeDeAgenda,
                      idSituacaoDeAgenda:
                        customerService.id &&
                        customerService.idDisponibilidadeDeAgenda !==
                          value.idDisponibilidadeDeAgenda
                          ? 2
                          : customerService.idSituacaoDeAgenda,
                    })
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  select
                  fullWidth
                  variant="outlined"
                  disabled={loading || customerService.idSituacaoDeAgenda === 2}
                  label={`Situação do agendamento`}
                  value={customerService.idSituacaoDeAgenda}
                  onChange={({target}) =>
                    setCustomerService({
                      ...customerService,
                      idSituacaoDeAgenda: Number(target.value),
                    })
                  }>
                  {scheduleSituations?.map((item) => (
                    <MenuItem
                      key={item.id}
                      value={item.id}
                      disabled={item.id === 2}>
                      {item.nome}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <TextField
              rows={3}
              multiline
              fullWidth
              variant="outlined"
              label="Atendimento"
              error={!customerService.atendimento && !sent}
              helperText={
                !customerService.atendimento && !sent && 'Campo obrigatório'
              }
              value={customerService.atendimento}
              onChange={({target}) =>
                setCustomerService({
                  ...customerService,
                  atendimento: target.value,
                })
              }
            />
          </Grid>
        </Grid>
      </Modal>

      <ModalConfirm
        loading={loading}
        opened={modalOpenConfirm}
        onClick={() => handleDeleteCall()}
        onClose={() => (setModalOpenConfirm(false), setIdToDelete(''))}
      />

      {loading ? (
        skeleton()
      ) : (
        <>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <IconButton color="primary" onClick={() => navigate(-1)}>
                <ArrowBack />
              </IconButton>
            </Grid>

            <Grid item>
              <Box width={160}>
                <Button
                  fullWidth
                  label="Incluir"
                  disabled={loading}
                  startIcon={<Add style={{color: '#F8F8F8'}} />}
                  onClick={() => (
                    handleIncludeCall(), setSent(true), setEditing(false)
                  )}
                />
              </Box>
            </Grid>
          </Grid>

          <Box marginTop={3} />

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={headAct}>Ações</TableCell>
                  <TableCell>Data</TableCell>
                  <TableCell>Atendente</TableCell>
                  <TableCell>Situação</TableCell>
                  <TableCell>Agenda</TableCell>
                  <TableCell>Atendimento</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {leadsCall.map((item, index) => (
                  <TableRow key={item.idAtendimentoDoLead}>
                    <TableCell>
                      <IconButton
                        color="primary"
                        onClick={() => (
                          setModalOpenConfirm(true),
                          setIdToDelete(item.idAtendimentoDoLead)
                        )}
                        disabled={index > 0 || leadsCall.length === 1}>
                        <Delete />
                      </IconButton>

                      <IconButton
                        color="primary"
                        disabled={index > 0}
                        onClick={() => handleEditCall(item)}>
                        <Edit />
                      </IconButton>
                    </TableCell>
                    <TableCell className={index > 0 ? rowDisabled : noWrap}>
                      {format(
                        new Date(item.dataAtendimento),
                        'dd/MM/yyyy HH:mm',
                      )}
                    </TableCell>

                    <TableCell className={index > 0 ? rowDisabled : noWrap}>
                      {item.nomeAtendente}
                    </TableCell>
                    <TableCell className={index > 0 ? rowDisabled : noWrap}>
                      {item.nomeSituacaoDoLead}
                    </TableCell>
                    <TableCell className={index > 0 ? rowDisabled : noWrap}>
                      {item.dataAgenda
                        ? `${format(
                            new Date(item.dataAgenda),
                            'dd/MM/yyyy HH:mm',
                          )} - ${item.nomeSituacaoDeAgenda}`
                        : ''}
                    </TableCell>
                    <TableCell className={index > 0 ? rowDisabled : ''}>
                      {item.atendimento}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box className={baseboard}>
            <Pagination count={1} />
            <Typography variant="caption" style={{color: '#9E9E9E'}}>
              {leadsCall.length < 21 ? leadsCall.length : 20} linhas
            </Typography>
          </Box>
        </>
      )}
    </>
  );
};
