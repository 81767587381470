import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  Box,
  Grid,
  useTheme,
  MenuItem,
  TextField,
  ListSubheader,
} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
import {useNavigate} from 'react-router-dom';
import {FilterList} from '@material-ui/icons';
import {format, addHours, endOfWeek, startOfWeek} from 'date-fns';

import {Button} from 'shared/components';
import {Calendar} from './components/Calendar';
import {
  IInterviewer,
  InterviewerService,
} from 'shared/services/api/InterviewerService';
import {
  IUnityEstablishment,
  EstablishmentService,
} from 'shared/services/api/EstablishmentService';
import {feedback} from 'shared/services/alertService';
import {useTerm, useTitle, useUser} from 'shared/hooks';
import {
  IAvailability,
  IFilterCalendar,
  CalendarAvailabilityService,
} from 'shared/services/api/CalendarAvailabilityService';
import {errorResponse} from 'shared/utils/errorResponse';
import {CampaignService, ICampaign} from 'shared/services/api/CampaignService';

export const CalendarAvailability: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [establishments, setEstablishments] = useState<
    Omit<IUnityEstablishment[], 'unidades'>
  >([]);
  const [campaigns, setCampaigns] = useState<ICampaign[]>([]);
  const [interviewers, setInterviewers] = useState<IInterviewer[]>([]);
  const [getAvailabilities, setGetAvailabilities] = useState(false);

  const [filter, setFilter] = useState<IFilterCalendar>({
    start: format(startOfWeek(new Date()), "yyyy/MM/dd'T'HH:mm:ss"),
    end: format(endOfWeek(new Date()), "yyyy/MM/dd'T'HH:mm:ss"),
  } as IFilterCalendar);
  const [events, setEvents] = useState<IAvailability>({
    disponibilidades: [],
    permiteEditar: false,
    permiteIncluir: false,
    permiteExcluir: false,
  });

  const theme = useTheme();
  const {terms} = useTerm();
  const navigate = useNavigate();
  const {setTitle} = useTitle();
  const {data: user} = useUser();

  const permissions = useMemo(
    () => user?.permissoes.filter((i) => i.nome.includes('Disponibilidade')),
    [user?.permissoes],
  );

  const canList = useMemo(
    () => !!permissions?.find((i) => i.nome.includes('Listar')),
    [permissions],
  );
  const permissionList = useMemo(
    () => permissions?.find((i) => i.nome.includes('Listar')),
    [permissions],
  );

  const userTimezone = useMemo(() => {
    return user?.timezone ? user.timezone : -3;
  }, [user?.timezone]);

  const getEstablishments = useCallback(async () => {
    try {
      const {estabelecimentos} = await EstablishmentService.getEstablishments(
        permissionList?.id,
      );

      if (estabelecimentos) {
        setEstablishments(estabelecimentos);
      }
    } catch (error) {
       
      feedback(errorResponse(error), 'error');
    }
  }, [permissionList?.id]);

  const getCampaigns = useCallback(async () => {
    setLoading(true);
    try {
      const response = await CampaignService.getCampaigns({
        idEstabelecimentoDaOferta: filter.establishment,
        usaAgendamento: true,
      });

      if (response) {
        setCampaigns(response);
      }
    } catch (error) {
       
      feedback(errorResponse(error), 'error');
    } finally {
      setLoading(false);
    }
  }, [filter.establishment]);

  const getInterviewers = useCallback(async () => {
    setLoading(true);
    try {
      const response = await InterviewerService.getInterviewers({
        idEstabelecimento: filter.establishment,
      });

      if (response) {
        setInterviewers(response);

        delete filter.interviewers;

        if (response.length === 1)
          setFilter(
            (state) => (state = {...state, interviewers: response[0].id}),
          );
      }
    } catch (error) {
      setInterviewers([]);
       
      feedback(errorResponse(error), 'error');
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.establishment]);

  const handleGetAvailabilities = useCallback(async () => {
    setLoading(true);
    try {
      const response = await CalendarAvailabilityService.getAvailabilities(
        filter,
      );

      if (response) {
        if (response.disponibilidades.length > 0) {
          setEvents({
            disponibilidades: response.disponibilidades.map((item) => ({
              ...item,
              start: item.start
                ? format(
                    addHours(new Date(item.start), userTimezone),
                    "yyyy-MM-dd'T'HH:mm:ss",
                  )
                : item.start,
              end: item.end
                ? format(
                    addHours(new Date(item.end), userTimezone),
                    "yyyy-MM-dd'T'HH:mm:ss",
                  )
                : item.end,
              color: item.possuiLeadVinculado ? '#005E5A' : '#00B9AD',
            })),
            permiteEditar: response.permiteEditar,
            permiteIncluir: response.permiteIncluir,
            permiteExcluir: response.permiteExcluir,
          });
          setGetAvailabilities(false);
        } else {
          feedback(
            `Não existem disponibilidades para este periodo.`,
            'warning',
          );
          setEvents({
            disponibilidades: response.disponibilidades,
            permiteEditar: response.permiteEditar,
            permiteIncluir: response.permiteIncluir,
            permiteExcluir: response.permiteExcluir,
          });
        }
      }
    } catch (error) {
       
      feedback(errorResponse(error), 'error');
    } finally {
      setShowCalendar(true);
      setLoading(false);
    }
  }, [filter, userTimezone]);

  useEffect(() => setTitle('Disponibilidade de Agenda'), [setTitle]);

  useEffect(() => {
    if (!canList) navigate('/');
  }, [canList, navigate]);

  useEffect(() => {
    setEvents({
      disponibilidades: [],
      permiteEditar: false,
      permiteIncluir: false,
      permiteExcluir: false,
    });
    setShowCalendar(false);
  }, [filter.establishment, filter.campaign, filter.interviewers]);

  useEffect(() => {
    getCampaigns();
    getInterviewers();
  }, [getCampaigns, filter.establishment, getInterviewers]);

  useEffect(() => {
    getEstablishments();
  }, [getEstablishments]);

  useEffect(() => {
    if (getAvailabilities) handleGetAvailabilities();
  }, [filter.start, getAvailabilities, handleGetAvailabilities]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          {loading ? (
            <Skeleton variant="rect" height={40} animation="wave" />
          ) : (
            <TextField
              select
              fullWidth
              size="small"
              variant="outlined"
              label="Estabelecimento *"
              disabled={loading}
              value={filter?.establishment}
              onChange={({target}) =>
                setFilter(
                  (state) => (state = {...state, establishment: target.value}),
                )
              }>
              {establishments?.map((item) => {
                if (item.idEstabelecimentoPai) {
                  return (
                    <MenuItem
                      key={item.idEstabelecimento}
                      value={item.idEstabelecimento}
                      style={{paddingLeft: theme.spacing(3)}}>
                      {item.idComNome}
                    </MenuItem>
                  );
                } else {
                  return (
                    <ListSubheader key={item.idEstabelecimento}>
                      {item.idComNome}
                    </ListSubheader>
                  );
                }
              })}
            </TextField>
          )}
        </Grid>

        <Grid item xs={12} sm={3}>
          {loading ? (
            <Skeleton variant="rect" height={40} animation="wave" />
          ) : (
            <TextField
              select
              fullWidth
              size="small"
              label="Campanha"
              variant="outlined"
              defaultValue={0}
              disabled={loading || !filter.establishment}
              value={filter?.campaign}
              onChange={({target}) =>
                setFilter(
                  (state) => (state = {...state, campaign: target.value}),
                )
              }>
              <MenuItem value={0}>Todas</MenuItem>
              {campaigns?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.nomeCompleto}
                </MenuItem>
              ))}
            </TextField>
          )}
        </Grid>

        <Grid item xs={12} sm={3}>
          {loading ? (
            <Skeleton variant="rect" height={40} animation="wave" />
          ) : (
            <TextField
              select
              fullWidth
              size="small"
              variant="outlined"
              label={`${terms.termoEntrevistador}`}
              disabled={loading || !filter.establishment}
              SelectProps={{
                multiple: true,
                value: !filter.interviewers
                  ? [0]
                  : filter.interviewers.split(','),
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onChange: (event: any, child: any) => {
                  setFilter(
                    (state) =>
                      (state = {
                        ...state,
                        interviewers:
                          child.props.value === 0
                            ? ''
                            : event.target.value
                                .filter((i: number) => i !== 0)
                                .join(),
                      }),
                  );
                },
              }}>
              <MenuItem value={0}>Todos</MenuItem>
              {interviewers?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.nomeUsuario}
                </MenuItem>
              ))}
            </TextField>
          )}
        </Grid>

        <Grid item xs={12} sm={2}>
          <Button
            disabled={!filter.establishment || loading}
            fullWidth
            label="Filtrar"
            startIcon={<FilterList style={{color: '#F8F8F8'}} />}
            onClick={handleGetAvailabilities}
          />
        </Grid>
      </Grid>

      <Box marginTop={5} />

      {filter.establishment && showCalendar && (
        <Calendar
          filter={filter}
          loading={loading}
          campaigns={campaigns}
          setFilter={setFilter}
          interviewers={interviewers}
          userTimezone={userTimezone}
          canEdit={events.permiteEditar}
          events={events.disponibilidades}
          canDelete={events.permiteExcluir}
          canRegister={events.permiteIncluir}
          setLoading={(value) => setLoading(value)}
          handleGetAvailabilities={handleGetAvailabilities}
          setGetAvailabilities={(value) => setGetAvailabilities(value)}
        />
      )}
    </>
  );
};
