import React, {useEffect, useState} from 'react';
import {
  Box,
  Grid,
  Paper,
  Table,
  MenuItem,
  TableRow,
  TextField,
  TableBody,
  TableCell,
  TableHead,
  IconButton,
  TableFooter,
  ListSubheader,
  TableContainer,
} from '@material-ui/core';
import {KeyboardDatePicker} from '@material-ui/pickers';
import {AddCircleRounded, Delete} from '@material-ui/icons';

import {
  ITokenField,
  IValidationField,
  IValidationCondition,
} from 'shared/services/api/FormService';
import {Button} from 'shared/components';

interface IProps {
  field: ITokenField;
  submitted: boolean;
  isEditing?: boolean;
  addValidation(): void;
  validations: IValidationField[];
  deleteValidation(value: number): void;
  config(e: unknown, value: number): void;
  condition(e: unknown, value: number): void;
  validationConditions: IValidationCondition[];
  errorMessage(e: unknown, value: number): void;
  additionalConfig(e: unknown, value: number): void;
  dateConfig(date: Date | null, value: number): void;
  dateAdditionalConfig(date: Date | null, value: number): void;
}

export const IsErrors: React.FC<IProps> = ({
  field,
  config,
  submitted,
  condition,
  dateConfig,
  validations,
  errorMessage,
  addValidation,
  additionalConfig,
  deleteValidation,
  isEditing = false,
  dateAdditionalConfig,
  validationConditions,
}) => {
  const [predefined, setPredefined] = useState<IValidationCondition[]>([]);
  const [fieldSize, setFieldSize] = useState<IValidationCondition[]>([]);
  const [value, setValue] = useState<IValidationCondition[]>([]);

  useEffect(() => {
    setPredefined(
      validationConditions.filter((i) => i.classificacao === 'Pré-definida'),
    );
    setFieldSize(
      validationConditions.filter(
        (i) => i.classificacao === 'Tamanho de campo',
      ),
    );
    setValue(validationConditions.filter((i) => i.classificacao === 'Valor'));
  }, [validationConditions]);

  return (
    <Grid item xs={12}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width="80px" height="42px">
                Ações
              </TableCell>
              <TableCell width="200px">Condição</TableCell>
              <TableCell>Configuração</TableCell>
              <TableCell width="300px">Mensagem de erro</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {validations?.map((item, index) => (
              <TableRow key={index}>
                <TableCell>
                  <IconButton
                    color="primary"
                    onClick={() => deleteValidation(index)}>
                    <Delete />
                  </IconButton>
                </TableCell>
                <TableCell>
                  <TextField
                    select
                    fullWidth
                    size="small"
                    variant="outlined"
                    disabled={!isEditing}
                    value={item.idCondicaoParaValidacao}
                    onChange={(e) => condition(e, index)}
                    error={!item.idCondicaoParaValidacao && submitted}
                    helperText={
                      !item.idCondicaoParaValidacao &&
                      submitted &&
                      'Campo obrigatório'
                    }>
                    {field.idTipoDeCampo === 9 && (
                      <ListSubheader>Pré-definida</ListSubheader>
                    )}

                    {field.idTipoDeCampo === 9 &&
                      predefined?.map((i) => (
                        <MenuItem key={i.id} value={i.id}>
                          {i.condicao}
                        </MenuItem>
                      ))}

                    {(field.idTipoDeCampo === 9 ||
                      field.idTipoDeCampo == 10) && (
                      <ListSubheader>Tamanho de campo</ListSubheader>
                    )}

                    {(field.idTipoDeCampo === 9 || field.idTipoDeCampo == 10) &&
                      fieldSize?.map((i) => (
                        <MenuItem key={i.id} value={i.id}>
                          {i.condicao}
                        </MenuItem>
                      ))}

                    <ListSubheader>Valor</ListSubheader>
                    {value?.map((i) => {
                      if (
                        (i.condicao === 'Contém' ||
                          i.condicao === 'Não contém') &&
                        !(
                          field.idTipoDeCampo === 9 ||
                          field.idTipoDeCampo === 10
                        )
                      ) {
                        return;
                      } else {
                        return (
                          <MenuItem key={i.id} value={i.id}>
                            {i.condicao}
                          </MenuItem>
                        );
                      }
                    })}
                    <MenuItem value={0} disabled />
                  </TextField>
                </TableCell>

                <TableCell>
                  {validations[index].idCondicaoParaValidacao === 13 ||
                  validations[index].idCondicaoParaValidacao === 14 ? (
                    field.idTipoDeCampo === 3 ? (
                      <Box display="flex" gridGap={16}>
                        <KeyboardDatePicker
                          disableToolbar
                          fullWidth
                          disabled={!isEditing}
                          inputVariant="outlined"
                          error={!item.validacaoInicial && submitted}
                          helperText={
                            !item.validacaoInicial &&
                            submitted &&
                            'Campo obrigatório'
                          }
                          variant="inline"
                          format="dd/MM/yyyy"
                          size="small"
                          value={new Date(item.validacaoInicial || new Date())}
                          onChange={(date: Date | null) =>
                            dateConfig(date, index)
                          }
                        />

                        <KeyboardDatePicker
                          disableToolbar
                          fullWidth
                          disabled={!isEditing}
                          inputVariant="outlined"
                          size="small"
                          error={!item.validacaoFinal && submitted}
                          helperText={
                            !item.validacaoFinal &&
                            submitted &&
                            'Campo obrigatório'
                          }
                          variant="inline"
                          format="dd/MM/yyyy"
                          value={new Date(item.validacaoFinal || new Date())}
                          onChange={(date: Date | null) =>
                            dateAdditionalConfig(date, index)
                          }
                        />
                      </Box>
                    ) : (
                      <Box display="flex" gridGap={16}>
                        <TextField
                          variant="outlined"
                          size="small"
                          disabled={!isEditing}
                          fullWidth
                          error={!item.validacaoInicial && submitted}
                          helperText={
                            !item.validacaoInicial &&
                            submitted &&
                            'Campo obrigatório'
                          }
                          value={item.validacaoInicial}
                          onChange={(e) => config(e, index)}
                        />
                        <TextField
                          variant="outlined"
                          size="small"
                          disabled={!isEditing}
                          fullWidth
                          error={!item.validacaoFinal && submitted}
                          helperText={
                            !item.validacaoFinal &&
                            submitted &&
                            'Campo obrigatório'
                          }
                          value={item.validacaoFinal}
                          onChange={(e) => additionalConfig(e, index)}
                        />
                      </Box>
                    )
                  ) : validations[index].idCondicaoParaValidacao === 1 ||
                    validations[index].idCondicaoParaValidacao === 2 ? (
                    <></>
                  ) : field.idTipoDeCampo === 3 ? (
                    <KeyboardDatePicker
                      disableToolbar
                      fullWidth
                      disabled={!isEditing}
                      inputVariant="outlined"
                      variant="inline"
                      format="dd/MM/yyyy"
                      size="small"
                      error={!item.validacaoInicial && submitted}
                      helperText={
                        !item.validacaoInicial &&
                        submitted &&
                        'Campo obrigatório'
                      }
                      value={new Date(item.validacaoInicial || new Date())}
                      onChange={(date: Date | null) => dateConfig(date, index)}
                    />
                  ) : field.idTipoDeCampo === 8 ? (
                    <TextField
                      fullWidth
                      size="small"
                      type="number"
                      variant="outlined"
                      disabled={!isEditing}
                      inputProps={{step: '0.010'}}
                      value={item.validacaoInicial}
                      onChange={(e) => config(e, index)}
                      error={!item.validacaoInicial && submitted}
                      helperText={
                        !item.validacaoInicial &&
                        submitted &&
                        'Campo obrigatório'
                      }
                    />
                  ) : field.idTipoDeCampo === 7 ? (
                    <TextField
                      fullWidth
                      size="small"
                      type="number"
                      variant="outlined"
                      disabled={!isEditing}
                      inputProps={{step: '1'}}
                      value={item.validacaoInicial}
                      onChange={(e) => config(e, index)}
                      error={!item.validacaoInicial && submitted}
                      helperText={
                        !item.validacaoInicial &&
                        submitted &&
                        'Campo obrigatório'
                      }
                    />
                  ) : (
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      disabled={!isEditing}
                      value={item.validacaoInicial}
                      onChange={(e) => config(e, index)}
                      error={!item.validacaoInicial && submitted}
                      helperText={
                        !item.validacaoInicial &&
                        submitted &&
                        'Campo obrigatório'
                      }
                    />
                  )}
                </TableCell>

                <TableCell align="right">
                  <TextField
                    variant="outlined"
                    disabled={!isEditing}
                    size="small"
                    fullWidth
                    error={!item.mensagem && submitted}
                    helperText={
                      !item.mensagem && submitted && 'Campo obrigatório'
                    }
                    value={item.mensagem}
                    onChange={(e) => errorMessage(e, index)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Table>
          <TableFooter>
            <TableCell style={{position: 'relative'}}>
              <Button
                variant="text"
                disabled={!isEditing}
                textTransform="uppercase"
                startIcon={<AddCircleRounded />}
                label="Adicionar validação"
                onClick={addValidation}
              />
            </TableCell>
          </TableFooter>
        </Table>
      </TableContainer>
    </Grid>
  );
};
