import {
  Box,
  Grid,
  Button,
  Dialog,
  Typography,
  DialogTitle,
  DialogContent,
  TableContainer,
} from '@material-ui/core';
import React, {useCallback, useState} from 'react';
import CloseIcon from '@material-ui/icons/Close';
import {
  CRMDashboardService,
  IServiceTimeAverage,
} from 'shared/services/api/CRMDashboard';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import {
  DataGrid,
  GridColDef,
  GridColumnsPanel,
  GridRenderCellParams,
  GridToolbarContainer,
  GridToolbarExport,
} from '@mui/x-data-grid';
import {feedback} from 'shared/services/alertService';

interface IProps {
  openModal: boolean;
  onclose(): void;
  selectedPeriod: string;
  selectedEstablishment: string;
  selectedCampaing: string;
}

export const ServiceTimeAverage: React.FC<IProps> = ({
  openModal,
  onclose,
  selectedPeriod,
  selectedEstablishment,
  selectedCampaing,
}) => {
  const [loading, setLoading] = useState(true);
  const [serviceTimeAverage, setServiceTimeAverage] = useState<
    IServiceTimeAverage[]
  >([]);

  const ServiceTimeAverageList = useCallback(async () => {
    setLoading(true);
    CRMDashboardService.getServiceTimeAverage(
      selectedPeriod,
      selectedEstablishment,
      selectedCampaing,
    )
      .then((response) => {
        setServiceTimeAverage(response);
      })
      .catch(() => feedback('Erro ao salvar os dados', 'error'))
      .finally(() => setLoading(false));
  }, [selectedPeriod, selectedEstablishment, selectedCampaing]);

  const columns: GridColDef[] = [
    {
      field: 'lead',
      headerName: 'Lead',
      flex: 1,
    },
    {
      field: 'tempo',
      headerName: 'Tempo de atendimento',
      flex: 1,
    },
    {
      field: 'situacao',
      headerName: 'Situação',
      flex: 1,
    },

    {
      field: 'emRelacaoAMedia',
      headerName: 'Em relação a média',
      flex: 1,
      renderCell: (params: GridRenderCellParams) =>
        params.row.emRelacaoAMedia === 'Abaixo' ? (
          <Box display={'flex'} alignItems={'center'}>
            <ArrowDownwardIcon fontSize="small" color="error" />

            <Box marginLeft={0.5}>{params.row.emRelacaoAMedia}</Box>
          </Box>
        ) : params.row.emRelacaoAMedia === 'Acima' ? (
          <Box display={'flex'} alignItems={'center'}>
            <ArrowUpwardIcon fontSize="small" color="primary" />

            <Box marginLeft={0.5}>{params.row.emRelacaoAMedia}</Box>
          </Box>
        ) : params.row.emRelacaoAMedia === 'Na média' ? (
          <Box display={'flex'} alignItems={'center'}>
            <CheckBoxOutlineBlankIcon
              fontSize="small"
              style={{color: '#ffc46d'}}
            />

            <Box marginLeft={0.5}>{params.row.emRelacaoAMedia}</Box>
          </Box>
        ) : (
          ''
        ),
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  return (
    <Dialog
      fullScreen
      maxWidth="xl"
      open={openModal}
      onClose={onclose}
      onEntering={ServiceTimeAverageList}
      aria-labelledby="Tempo médio de Atendimento"
      aria-describedby="Tempo médio de Atendimento">
      <DialogTitle id="Tempo médio de Atendimento">
        <Box width="100%" display={'flex'} justifyContent="space-between">
          <Box>
            <Typography variant="inherit" color="primary">
              Tempo médio de atendimento
            </Typography>
          </Box>
          <Box>
            <Button color="primary" onClick={onclose}>
              <CloseIcon />
            </Button>
          </Box>
        </Box>
      </DialogTitle>

      <TableContainer>
        <Grid container>
          <DialogContent>
            <DataGrid
              loading={loading}
              columns={columns}
              rows={serviceTimeAverage}
              getRowId={(row) => row.idLead}
              autoHeight
              pageSize={7}
              components={{
                Toolbar: CustomToolbar,
                ColumnsPanel: GridColumnsPanel,
              }}
            />
          </DialogContent>
        </Grid>
      </TableContainer>
    </Dialog>
  );
};
