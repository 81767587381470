import {useContext} from 'react';

import {
  ThemeContext,
  ThemesProvider,
  IThemeContext,
} from 'shared/contexts/Theme';

function useTheme(): IThemeContext {
  const context = useContext(ThemeContext);

  if (!context) {
    throw new Error('useTheme must be used within an ThemeProvider');
  }

  return context;
}

export {ThemesProvider, useTheme};
