import React from 'react';

import {Box} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';

export const SkeletonMenu: React.FC = () => {
  return (
    <Box paddingX={1}>
      <Skeleton
        variant="rect"
        width="100%"
        height={54}
        component={Box}
        marginTop={1}
      />
      <Skeleton
        variant="rect"
        width="100%"
        height={54}
        component={Box}
        marginTop={1}
      />
      <Skeleton
        variant="rect"
        width="100%"
        height={54}
        component={Box}
        marginTop={1}
      />
      <Skeleton
        variant="rect"
        width="100%"
        height={54}
        component={Box}
        marginTop={1}
      />
      <Skeleton
        variant="rect"
        width="100%"
        height={54}
        component={Box}
        marginTop={1}
      />
      <Skeleton
        variant="rect"
        width="100%"
        height={54}
        component={Box}
        marginTop={1}
      />
      <Skeleton
        variant="rect"
        width="100%"
        height={54}
        component={Box}
        marginTop={1}
      />
      <Skeleton
        variant="rect"
        width="100%"
        height={54}
        component={Box}
        marginTop={1}
      />
      <Skeleton
        variant="rect"
        width="100%"
        height={54}
        component={Box}
        marginTop={1}
      />
      <Skeleton
        variant="rect"
        width="100%"
        height={54}
        component={Box}
        marginTop={1}
      />
    </Box>
  );
};
