import React, {createContext, useEffect, useState} from 'react';
import {IMacro, MacrosService} from 'shared/services/api/MacrosService';

export interface IMacrosContextData {
  macros: IMacro[];
}
export const MacrosContext = createContext<IMacrosContextData>(
  {} as IMacrosContextData,
);

export const MacrosProvider: React.FC = ({children}) => {
  const [macros, setMacros] = useState<IMacro[]>([]);

  useEffect(() => {
    async function getMacros() {
      try {
        const response = await MacrosService.getMacrosEmail();

        setMacros(response);
      } catch {
        ('');
      }
    }
    getMacros();
  }, []);

  return (
    <MacrosContext.Provider value={{macros}}>{children}</MacrosContext.Provider>
  );
};
