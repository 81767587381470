import React from 'react';

import {Box, Typography} from '@material-ui/core';

import {Modal} from '../Modal';

interface IProps {
  opened: boolean;
  onClick(): void;
  onClose(): void;
  loading: boolean;
  title?: string;
  description?: string;
  labelSaveButton?: string;
}

export const ModalConfirm: React.FC<IProps> = ({
  opened,
  onClick,
  onClose,
  loading,
  title = 'Confirmação de exclusão',
  description = 'Tem certeza que deseja excluir este registro?',
  labelSaveButton = 'Excluir',
  children,
}) => {
  return (
    <>
      <Modal
        opened={opened}
        onClick={onClick}
        maxWidth="xs"
        onClose={onClose}
        loading={loading}
        labelSaveButton={labelSaveButton}
        labelCloseButton="Cancelar"
        title={title}>
        <Box marginTop={1} marginBottom={3}>
          {children ? children : <Typography>{description}</Typography>}
        </Box>
      </Modal>
    </>
  );
};
