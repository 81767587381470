import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  Box,
  Grid,
  TableRow,
  useTheme,
  TableCell,
  IconButton,
} from '@material-ui/core';
import {Delete, Edit} from '@material-ui/icons';
import {useNavigate, useParams} from 'react-router-dom';

import {
  Table,
  Button,
  Footer,
  ModalConfirm,
  TableSkeleton,
} from 'shared/components';
import {
  IComplementary,
  ComplementaryService,
} from 'shared/services/api/ComplementaryService';
import {feedback} from 'shared/services/alertService';
import {useTerm, useTitle, useUser} from 'shared/hooks';
import {errorResponse} from 'shared/utils/errorResponse';
import {CampaignService} from 'shared/services/api/CampaignService';
import {IShift, ShiftService} from 'shared/services/api/ShiftService';
import {IGetOffer, OfferService} from 'shared/services/api/OfferService';
import {CourseService, ICourse} from 'shared/services/api/CourseService';
import {StepperCampaign} from 'pages/Campaign/components/StepperCampaign';

import {ModalInclude} from './ModalInclude';
import {ModalMultipleInclude} from './ModalMultipleInclude';

export const CampaignOffers: React.FC = () => {
  const [isEditing, setIsEditing] = useState(true);
  const [openModalMultipleInclude, setOpenModalMultipleInclude] =
    useState(false);
  const [openModalConfirmExclude, setOpenModalConfirmExclude] = useState(false);
  const [openModalInclude, setOpenModalInclude] = useState(false);
  const [isIntegrated, setIsIntegrated] = useState(false);
  const [campaignInfo, setCampaignInfo] = useState<{
    nivelEstabelecimentoDono: number;
    idEstabelecimentoDono: string;
  }>({
    idEstabelecimentoDono: '',
    nivelEstabelecimentoDono: 0,
  });
  const [nameCampaign, setNameCampaign] = useState('');
  const [editModal, setEditModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idDeleted, setIdDeleted] = useState('');

  const [course, setCourse] = useState<ICourse[]>([]);
  const [shifts, setShifts] = useState<IShift[]>([]);
  const [complementaryOne, setComplementaryOne] = useState<IComplementary[]>(
    [],
  );
  const [complementaryTwo, setComplementaryTwo] = useState<IComplementary[]>(
    [],
  );
  const [offers, setOffers] = useState<IGetOffer[]>([]);
  const [offer, setOffer] = useState<IGetOffer>({} as IGetOffer);

  const {setTitle} = useTitle();
  const navigate = useNavigate();
  const theme = useTheme();
  const {terms} = useTerm();
  const {id = '0'} = useParams<'id'>();
  const {data: user} = useUser();

  const permissions = useMemo(
    () => user?.permissoes.filter((i) => i.nome.includes('Campanha')),
    [user?.permissoes],
  );

  const canList = useMemo(
    () => !!permissions?.find((i) => i.nome.includes('Listar')),
    [permissions],
  );

  const idRegister = useMemo(
    () => permissions?.find((i) => i.nome.includes('Incluir'))?.id,
    [permissions],
  );

  useEffect(() => {
    if (!canList) {
      navigate('/');
    }
  }, [canList, navigate]);

  const handleGetOffers = useCallback(async () => {
    try {
      const data = await OfferService.getOfferForCampaign(id);

      if (data) {
        setOffers(data);
      }
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
  }, [id]);


  const handleGetComplementaryOne = useCallback(async () => {
    try {
      const response = await ComplementaryService.getComplementaryOne(true);

      if (response) setComplementaryOne(response);
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
  }, []);

  const handleGetComplementaryTwo = useCallback(async () => {
    try {
      const response = await ComplementaryService.getComplementaryTwo(true);

      if (response) setComplementaryTwo(response);
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
  }, []);

  const handleGetCourse = useCallback(async () => {
    try {
      const response = await CourseService.getCourses(true);

      if (response) setCourse(response);
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
  }, []);

  const handleGetShift = useCallback(async () => {
    try {
      const response = await ShiftService.getShifts(true);

      if (response) setShifts(response);
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
  }, []);

  const handleGetCampaign = useCallback(async () => {
    try {
      const response = await CampaignService.getCampaign({
        id: id,
        verificarPermissaoEditar: true,
      });

      if (response) {
        setIsEditing(response.permiteEditar);
        setIsIntegrated(response.idTipoDeIntegracao !== 1);
        setNameCampaign(response.nome);
        setCampaignInfo({
          idEstabelecimentoDono: response.idEstabelecimentoDono,
          nivelEstabelecimentoDono: response.nivelEstabelecimentoDono,
        });
      }
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
  }, [id]);

  const handleCloseModal = useCallback(() => {
    setOpenModalInclude(false);
    setOpenModalMultipleInclude(false);
    setEditModal(false);
    setOffer({} as IGetOffer);
    handleGetOffers();
  }, [handleGetOffers]);

  useEffect(() => {
    setTitle(`Campanha ${nameCampaign} | Ofertas`);
  }, [setTitle, nameCampaign]);

  const handleGets = useCallback(async () => {
    setLoading(true);
    try {
      await handleGetComplementaryOne();
      await handleGetComplementaryTwo();
      await handleGetShift();
      await handleGetCourse();
      await handleGetCampaign();
      await handleGetOffers();
    } catch (error) {
      feedback(errorResponse(error), 'error');
    } finally {
      setLoading(false);
    }
  }, [
    handleGetComplementaryOne,
    handleGetComplementaryTwo,
    handleGetShift,
    handleGetCourse,
    handleGetCampaign,
    handleGetOffers,
  ]);

  useEffect(() => {
    handleGets();
  }, [handleGets]);

  const handleDelete = useCallback((id: string) => {
    setOpenModalConfirmExclude(true);

    setIdDeleted(id);
  }, []);

  const handleConfirmDeleted = useCallback(async () => {
    try {
      setLoading(true);
      await OfferService.deleteOffer(idDeleted);

      await handleGetOffers();

      feedback('Oferta deletado com sucesso', 'success');
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
    setLoading(false);
    setIdDeleted('');
    setOpenModalConfirmExclude(false);
  }, [handleGetOffers, idDeleted]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StepperCampaign
            integrated={isIntegrated}
            loading={loading}
            activeStep={3}
            idCampaign={id}
          />
          <Box marginBottom={1} />
        </Grid>

        <Grid item xs={12} sm={12}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            gridGap={24}>
            <Box>
              <Button
                fullWidth
                disabled={loading || !isEditing}
                label="Adicionar múltiplos"
                variant="outlined"
                onClick={() => setOpenModalMultipleInclude(true)}
              />
            </Box>
            <Box width="160px">
              <Button
                label="Adicionar"
                disabled={loading || !isEditing}
                fullWidth
                onClick={() => setOpenModalInclude(true)}
              />
            </Box>
          </Box>
          <Box marginBottom={1} />
        </Grid>

        <Grid item xs={12} sm={12}>
          {loading ? (
            <TableSkeleton btnAct={2} columns={4} rowsBody={10} colStatus />
          ) : (
            <Table
              head={[
                {label: 'Ações', width: '140px'},
                {label: 'Oferta', width: '100%'},
                {label: 'Estabelecimento', width: '100%'},
                {label: terms.termoComplementar1, width: '100%'},
                {label: terms.termoComplementar2, width: '100%'},
                {label: 'Status', width: '100%'},
              ]}
              length={offers.length}
              rowsPerPage={offers.length}>
              {offers &&
                offers.length > 0 &&
                offers.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>
                      <IconButton
                        color="primary"
                        disabled={loading || !isEditing}
                        onClick={() => handleDelete(item.id)}>
                        <Delete />
                      </IconButton>

                      <IconButton
                        style={{marginLeft: theme.spacing(1)}}
                        color="primary"
                        onClick={() => {
                          setOpenModalInclude(true),
                            setEditModal(true),
                            setOffer(item);
                        }}
                        disabled={loading || !isEditing}>
                        <Edit />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      {item.nomeCurso}{' '}
                      {item.nomeCiclo && ` - ${item.nomeCiclo}`}{' '}
                      {item.nomeTurno && ` - ${item.nomeTurno}`}
                    </TableCell>
                    <TableCell>{item.nomeEstabelecimento}</TableCell>
                    <TableCell>
                      {item.complementos1DaOferta
                        ?.map((item) => item.nome)
                        .join(', ')}
                    </TableCell>
                    <TableCell>
                      {item.complementos2DaOferta
                        ?.map((item) => item.nome)
                        .join(', ')}
                    </TableCell>
                    <TableCell>{item.ativo ? 'Ativo' : 'Inativo'}</TableCell>
                  </TableRow>
                ))}
            </Table>
          )}
        </Grid>
      </Grid>

      <Box marginY={14} />

      <Footer
        onCancel={() => navigate('/campanha')}
        onSave={() => navigate(`/campanha/ficha-de-inscricao/${id}`)}
        onGoBack={() => navigate(`/campanha/funil/${id}/${campaignInfo.idEstabelecimentoDono}/${campaignInfo.nivelEstabelecimentoDono}`)}
        onSaveLabel={isEditing ? 'Salvar e Avançar' : 'Avançar'}
        disabled={loading}
      />

      <ModalInclude
        campaignInfo={campaignInfo}
        course={course}
        shifts={shifts}
        defaultOffer={offer}
        editModal={editModal}
        idCampanha={id}
        idRegister={idRegister}
        onClose={handleCloseModal}
        setEditModal={setEditModal}
        openModal={openModalInclude}
        complementaryOne={complementaryOne}
        complementaryTwo={complementaryTwo}
      />

      <ModalMultipleInclude
        campaignInfo={campaignInfo}
        shifts={shifts}
        idCampanha={id}
        idRegister={idRegister}
        onClose={handleCloseModal}
        complementaryOne={complementaryOne}
        complementaryTwo={complementaryTwo}
        openModal={openModalMultipleInclude}
      />

      <ModalConfirm
        opened={openModalConfirmExclude}
        onClick={handleConfirmDeleted}
        onClose={() => {
          setOpenModalConfirmExclude(false), setIdDeleted('');
        }}
        loading={loading}
      />
    </>
  );
};
