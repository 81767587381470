import React from 'react';
import {
  Box,
  Card,
  Button,
  makeStyles,
  Typography,
  CardContent,
  createStyles,
  CardActionArea,
} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
import Settings from '@material-ui/icons/Settings';

import {TColor} from 'shared/services/api/CRMDashboard';

interface IProps {
  title?: string;
  description?: string;
  color?: TColor;
  icon?: React.ReactNode;
  isLoading?: boolean;
  onClick?: () => void;
  onClickConfig?: () => void;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    cardRed: {
      backgroundColor: '#F5D1CC',
    },
    cardGreen: {
      backgroundColor: '#7EE2CB',
    },

    cardYellow: {
      backgroundColor: '#F4E8C4',
    },

    fontRed: {
      color: '#DF6556',
    },

    fontYellow: {
      color: '#D4A616',
    },

    fontGreen: {
      color: '#018781',
    },

    fontBlack: {
      color: theme.palette.text.primary,
    },
  }),
);

export const Overview: React.FC<IProps> = ({
  title,
  description,
  color,
  icon,
  isLoading = false,
  onClick,
  onClickConfig,
}) => {
  const classes = useStyles();

  if (isLoading) {
    return (
      <Card elevation={0}>
        <Box position="relative">
          <Box minHeight={150}>
            <Skeleton variant="rect" height={150} />
          </Box>
        </Box>
      </Card>
    );
  }

  return (
    <Card
      elevation={3}
      className={
        color === 'amarelo'
          ? classes.cardYellow
          : color === 'vermelho'
          ? classes.cardRed
          : classes.cardGreen
      }>
      <Box position="relative">
        <CardActionArea
          onClick={() => onClick && onClick()}
          disabled={!onClick}>
          <Box minHeight={150}>
            <CardContent>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                className={
                  color === 'amarelo'
                    ? classes.fontYellow
                    : color === 'vermelho'
                    ? classes.fontRed
                    : classes.fontGreen
                }>
                <div>
                  <Typography variant="h4">{title}</Typography>
                  <Typography className={classes.fontBlack}>
                    {description}
                  </Typography>
                </div>
                <Box marginLeft={3}>{icon}</Box>
              </Box>
            </CardContent>
          </Box>
        </CardActionArea>
        {onClickConfig && (
          <Box position="absolute" bottom={3} right={3}>
            <Button style={{minWidth: 0}} onClick={onClickConfig}>
              <Settings
                className={
                  color === 'amarelo'
                    ? classes.fontYellow
                    : color === 'vermelho'
                    ? classes.fontRed
                    : classes.fontGreen
                }
              />
            </Button>
          </Box>
        )}
      </Box>
    </Card>
  );
};
