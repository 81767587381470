import React from 'react';
import {Bar} from 'react-chartjs-2';
import {useStyles} from './styles';
import {IChartProps} from './ChartType';

export const BarChart: React.FC<IChartProps> = ({
  labels,
  datasets,
  options,
  width,
  height,
  showDataLabels,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.chartWrapper}>
      <Bar
        data={{labels, datasets}}
        width={width}
        height={height}
        options={{
          ...options,
          type: 'bar',
          datasets: {
            bar: {
              maxBarThickness: 30,
              barPercentage: 0.5,
            },
          },
          scales: {
            x: {
              grid: {
                display: true,
              },
            },
            y: {
              grid: {
                display: false,
              },
            },
          },
          plugins: {
            ...options.plugins,
            datalabels: {
              display: showDataLabels,
              color: '#33C7BD',
              align: 'top',
              anchor: 'end',
              padding: {
                right: 2,
                top: -48,
              },
              labels: {
                padding: {top: 10},
                title: {
                  font: {
                    weight: 'bold',
                  },
                },
                value: {
                  color: 'green',
                },
              },

              formatter: function (value: any) {
                return '\n' + value;
              },
            },
          },
        }}
      />
    </div>
  );
};
