import React from 'react';
import {
  Box,
  Grid,
  List,
  Paper,
  Divider,
  Checkbox,
  ListItem,
  useTheme,
  IconButton,
  Typography,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import {KeyboardArrowLeft, KeyboardArrowRight} from '@material-ui/icons';

import {IPermission} from 'shared/services/api/UserProfileService';

function not(a: IPermission[], b: IPermission[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: IPermission[], b: IPermission[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

interface IProps {
  error?: boolean;
  disabled?: boolean;
  labelLeft: string;
  labelRight: string;
  helperText?: string;
  left: IPermission[];
  right: IPermission[];
  checked: IPermission[];
  filter?: string;
  setLeft(value: IPermission[]): void;
  setRight(value: IPermission[]): void;
  setChecked(value: IPermission[]): void;
}

export const TransferList: React.FC<IProps> = ({
  left,
  right,
  checked,
  setLeft,
  setRight,
  labelLeft,
  labelRight,
  setChecked,
  error = false,
  helperText = '',
  filter = '',
  disabled = false,
}) => {
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const theme = useTheme();

  const handleToggle = (value: IPermission) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  const customList = (items: IPermission[], label: string, error = false) => (
    <Paper
      style={{
        border: error ? `1px solid ${theme.palette.error.main}` : 'none',
      }}>
      <List dense component="div" role="list">
        <ListItem style={{paddingLeft: 12, paddingBottom: 8}}>
          <Typography color="primary">{label}</Typography>
        </ListItem>
        <Divider />

        <Box
          style={{
            height: 250,
            overflowX: 'auto',
          }}>
          {items.map((value: IPermission, index) => {
            const labelId = `transfer-list-item-${value.idPermissao}-label`;

            return (
              <Box
                key={value.idPermissao}
                bgcolor={index % 2 === 0 && theme.palette.background.default}>
                <ListItem
                  button
                  disabled={disabled}
                  role="listitem"
                  style={{padding: 0}}
                  onClick={handleToggle(value)}>
                  <ListItemIcon>
                    <Checkbox
                      disableRipple
                      tabIndex={-1}
                      checked={checked.indexOf(value) !== -1}
                      inputProps={{'aria-labelledby': labelId}}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={value.nome} />
                </ListItem>
                <Divider />
              </Box>
            );
          })}
        </Box>
        <ListItem />
      </List>
    </Paper>
  );

  return (
    <Grid container spacing={2} justify="center" alignItems="center">
      <Grid item xs={5}>
        {customList(
          left
            .sort((a, b) => a.nome.localeCompare(b.nome))
            .filter((item) =>
              item.nome.toLowerCase().includes(filter.toLowerCase()),
            ),
          labelLeft,
          error,
        )}

        {helperText && (
          <Box paddingLeft={1.6} marginTop={0.2}>
            <Typography variant="caption" style={{color: 'transparent'}}>
              {helperText}
            </Typography>
          </Box>
        )}
      </Grid>

      <Grid item xs={1}>
        <Grid container direction="column" alignItems="center" justify="center">
          <IconButton
            onClick={handleAllRight}
            disabled={left.length === 0 || disabled}>
            <Box position="relative">
              <KeyboardArrowRight style={{color: 'transparent'}} />

              <KeyboardArrowRight
                style={{position: 'absolute', left: -3, top: 2}}
              />
              <KeyboardArrowRight
                style={{position: 'absolute', left: 3, top: 2}}
              />
            </Box>
          </IconButton>

          <IconButton
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0 || disabled}>
            <KeyboardArrowRight />
          </IconButton>

          <IconButton
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0 || disabled}>
            <KeyboardArrowLeft />
          </IconButton>

          <IconButton
            onClick={handleAllLeft}
            disabled={right.length === 0 || disabled}>
            <Box position="relative">
              <KeyboardArrowLeft style={{color: 'transparent'}} />

              <KeyboardArrowLeft
                style={{position: 'absolute', left: -3, top: 2}}
              />
              <KeyboardArrowLeft
                style={{position: 'absolute', left: 3, top: 2}}
              />
            </Box>
          </IconButton>
        </Grid>
      </Grid>

      <Grid item xs={5}>
        {customList(
          right
            .sort((a, b) => a.nome.localeCompare(b.nome))
            .filter((item) =>
              item.nome.toLowerCase().includes(filter.toLowerCase()),
            ),
          labelRight,
          error,
        )}

        {helperText && (
          <Box paddingLeft={1.6} marginTop={0.2}>
            <Typography
              variant="caption"
              color={error ? 'error' : 'textPrimary'}>
              {helperText}
            </Typography>
          </Box>
        )}
      </Grid>

      <Grid item xs={1} />
    </Grid>
  );
};
