import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -theme.spacing(1.5),
      marginLeft: -theme.spacing(1.5),
    },
    select: {
      minWidth: '30px',
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
    },
  }),
);
