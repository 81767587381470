import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  useTheme,
  TableRow,
  TableCell,
  IconButton,
} from '@material-ui/core';
import {useNavigate} from 'react-router-dom';
import {
  ILead,
  ILeadCategory,
  ITarefa,
  LeadSituationService,
} from 'shared/services/api/LeadSituationService';
import {feedback} from 'shared/services/alertService';
import {useTitle, useTerm, useUser, useDebounce} from 'shared/hooks';
import {errorResponse} from 'shared/utils/errorResponse';
import {Table, TableSkeleton} from 'shared/components';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ModalVisualization } from '../ModalVisualization';
interface FunnelStepsProps {
  funnelId: string;
  onLoadingChange: (isLoading: boolean) => void;
}

export const FunnelSteps: React.FC<FunnelStepsProps> = ({
  funnelId,
  onLoadingChange
}) => {
  const [loading, setLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [leads, setLeads] = useState<ILead[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [categories, setCategories] = useState<ILeadCategory[]>([]);
  const LEAD_DEFAULT = {
    id: '',
    nome: '',
    categoria: {
      id: 0,
      nome: '',
    },
    idCategDeSituacaoDeLead: 0,
    ativo: false,
    usuario: '',
    ordem: 0,
    notificarLead: false,
    notificarAtendenteAtual: false,
    notificarTodosOsAtendentes: false,
    tarefas: [] as ITarefa[],
  };
  const [lead, setLead] = useState<ILead>(LEAD_DEFAULT);

  const [take, setTake] = useState(10);
  const [skip, setSkip] = useState(1);
  const [leadsCount, setLeadsCount] = useState(0);

  const title = {
    isEdit: 'Editar situação de Lead',
    default: 'Cadastro de situação de Lead',
  };
  const [modalTitle, setModalTitle] = useState(title.default);

  const theme = useTheme();
  const {terms} = useTerm();
  const navigate = useNavigate();
  const {setTitle} = useTitle();
  const {data: user} = useUser();
  const {debounce} = useDebounce();
  
  const handleVerifyNameLead = useCallback(() => {
    return (
      leads.filter(
        (i) => i.nome.toLowerCase().trim() === lead.nome.toLowerCase().trim(),
      ).length > 0
    );
  }, [lead.id, lead.nome, leads]);

  const handleGetLead = useCallback(() => {
    if (funnelId) {
      setLoading(true);
      onLoadingChange(true)
      debounce(async () => {
        try {
          const response = await LeadSituationService.getLeadsPagination(
            1,
            '',
            take,
            skip,
            funnelId,
          );

          if (response) {
            setLeads(response.data);
            setLeadsCount(response.qtdTotalDeRegistros);
            setDataLoaded(true);
          }
        } catch (error) {
          feedback(errorResponse(error), 'error');
        } finally {
          setLoading(false);
          onLoadingChange(false)
        }
      });
    }
  }, [
    take, 
    skip, 
    debounce, 
    funnelId
  ]);

  const handleGetCategory = useCallback(async () => {
    setLoading(true);
    try {
      const data = await LeadSituationService.getLeadCategory();

      if (data) {
        setCategories(data);
      }
    } catch (error) {
      feedback(errorResponse(error), 'error');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    setTitle(`Situação ${terms.termoLead}`);
  }, [terms.termoLead]);

  useEffect(() => {
    handleGetLead();
  }, [handleGetLead]);

  useEffect(() => {
    handleGetCategory();
  }, [handleGetCategory]);

  useEffect(() => {
    if (dataLoaded) {
      const sortedLeads = [...leads].sort((a, b) => a.ordem - b.ordem);
      setLeads(sortedLeads);
      setDataLoaded(false);
    }
  }, [dataLoaded, leads]);

  const handleCloseModal = () => {
    setOpenModal(false);
    setLead(LEAD_DEFAULT);
  };

  return (
    <>
      {loading ? (
        <TableSkeleton btnAct={1} columns={4} rowsBody={5} colStatus />
      ) : (
        <Table
          head={[
            {label: 'Ações', width: '160px'},
            {label: `Ordem`},
            {label: `Funil`},
            {label: 'Situação Lead'},
            {label: 'Status', width: '140px'},
          ]}
          pagination={{
            count: Math.ceil(leadsCount / take),
            page: skip,
          }}
          length={leadsCount}
          loading={loading}
          rowsPerPage={leads.length}
          onChangePage={(value: number) => setSkip(value)}>
          {leads.map((item) => (
            <TableRow key={item.id}>
              <TableCell>
                <IconButton
                  style={{marginLeft: theme.spacing(1)}}
                  color="primary"
                  onClick={() => {
                    setOpenModal(true),
                    setModalTitle(title.isEdit),
                    setLead(item);
                  }}
                >
                  <VisibilityIcon />
                </IconButton>
              </TableCell>
              <TableCell>{item.ordem}</TableCell>
              <TableCell>{item.nome}</TableCell>
              <TableCell>{item.categoria.nome}</TableCell>
              <TableCell>{item.ativo ? 'Ativo' : 'Inativo'}</TableCell>
            </TableRow>
          ))}
        </Table>
      )}

      {lead && (
        <ModalVisualization
          openModal={openModal}
          loading={loading}
          title={modalTitle}
          nameVerify={handleVerifyNameLead()}
          onClose={handleCloseModal}
          categories={categories}
          lead={lead}
        />
      )}
    </>
  );
};
