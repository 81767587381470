import {useMemo, useState} from 'react';
import {
  Box,
  Card,
  Typography,
  CardContent,
  CardActionArea,
} from '@material-ui/core';

import {BarChart} from '../charts';
import {CallsConversions} from './../modals';
import {getDashboardData} from 'shared/utils/getDashboardData';
import {getDashboardLabels} from 'shared/utils/getDashboardLabels';

import {IAtendimentoXConversaoPorPeriodo} from 'shared/services/api/CRMDashboard';

interface IProps {
  atendimentoXConversaoPorPeriodo: IAtendimentoXConversaoPorPeriodo[];
  selectedPeriod: string;
  selectedEstablishment: string;
  selectedCampaing: string;
}

export const SubscriberConversion: React.FC<IProps> = ({
  atendimentoXConversaoPorPeriodo,
  selectedPeriod,
  selectedEstablishment,
  selectedCampaing,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [showInscritos, setShowInscritos] = useState(true);
  const [showConvertidos, setShowConvertidos] = useState(true);

  const labels = useMemo(
    () => getDashboardLabels(selectedPeriod, atendimentoXConversaoPorPeriodo),
    [atendimentoXConversaoPorPeriodo, selectedPeriod],
  );

  const data = useMemo(
    () => getDashboardData(atendimentoXConversaoPorPeriodo, selectedPeriod),
    [atendimentoXConversaoPorPeriodo, selectedPeriod],
  );
  return (
    <>
      <Card elevation={3}>
        <Box marginTop={2} marginLeft={2} width="100%" textAlign="left">
          <Typography variant="h6">Inscritos x Conversão</Typography>
        </Box>
        <Box display="flex" justifyContent="center" gridGap={8}>
          <Box
            gridGap={4}
            display="flex"
            alignItems="center"
            style={{cursor: 'pointer'}}
            onClick={() => setShowInscritos((state) => !state)}>
            <Box width={40} height={13} bgcolor="#00B9AD" />
            <Typography
              variant="caption"
              style={{textDecoration: showInscritos ? 'none' : 'line-through'}}>
              Inscritos
            </Typography>
          </Box>
          <Box
            gridGap={4}
            display="flex"
            alignItems="center"
            style={{cursor: 'pointer'}}
            onClick={() => setShowConvertidos((state) => !state)}>
            <Box width={40} height={13} bgcolor="#018781" />
            <Typography
              variant="caption"
              style={{
                textDecoration: showConvertidos ? 'none' : 'line-through',
              }}>
              Convertidos
            </Typography>
          </Box>
        </Box>
        <CardActionArea onClick={() => setOpenModal(true)}>
          <CardContent>
            <BarChart
              labels={labels}
              width="100%"
              height={30}
              datasets={[
                {
                  label: 'Inscritos',
                  backgroundColor: ['#00B9AD'],
                  borderColor: ['#00B9AD'],
                  borderWidth: 1,
                  hoverBackgroundColor: ['#00B9AD'],
                  hoverBorderColor: ['#00B9AD'],
                  data: data.atendidos.map(Math.floor) || 0,
                  hidden: !showInscritos,
                },
                {
                  label: 'Convertidos',
                  backgroundColor: ['#018781'],
                  borderColor: ['#018781'],
                  borderWidth: 1,
                  hoverBackgroundColor: ['#018781'],
                  hoverBorderColor: ['#018781'],
                  data: data.convertidos.map(Math.floor) || 0,
                  hidden: !showConvertidos,
                },
              ]}
              options={{
                plugins: {
                  legend: {
                    display: false,
                  },
                },
              }}
            />
          </CardContent>
        </CardActionArea>
      </Card>

      <CallsConversions
        openModal={openModal}
        onclose={() => setOpenModal(false)}
        selectedPeriod={selectedPeriod}
        selectedEstablishment={selectedEstablishment ?? '0'}
        selectedCampaing={selectedCampaing ?? ''}
      />
    </>
  );
};
