import {IRequestResult} from 'shared/interfaces/IRequestResult';
import {Api} from 'shared/services/axiosConfig';

export interface IComplementary {
  id: string;
  nome: string;
  ativo: boolean;
}

export interface IComplementaryPagination {
  qtdTotalDeRegistros: number;
  data: IComplementary[];
}

const postComplementaryOne = async (
  payload: Omit<IComplementary, 'id'>,
): Promise<IRequestResult> => {
  try {
    await Api.post('/complementar1', payload);

    return {success: true};
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const updateComplementaryOne = async (
  id: string,
  payload: Omit<IComplementary, 'id'>,
): Promise<IRequestResult> => {
  try {
    await Api.put(`/complementar1/${id}`, payload);

    return {success: true};
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const deleteComplementaryOne = async (id: string): Promise<IRequestResult> => {
  try {
    await Api.delete(`/complementar1/${id}`);

    return {success: true};
  } catch (error: any) {
    throw new Error(error.data.data);
  }
};

const getComplementaryOne = async (
  active?: boolean,
): Promise<IComplementary[] | undefined> => {
  try {
    const response = await Api.get(
      active ? '/complementar1?ativo=true' : '/complementar1',
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const getComplementaryOnePagination = async (
  ativo: number,
  campoDePesquisa: string,
  skip: number,
  take: number,
): Promise<IComplementaryPagination> => {
  try {
    const { data: response} = await Api.get(
      `/complementar1/paginado?ativo=
     ${ativo === 0 ? '' : ativo === 1 ? true : false}&campoDePesquisa=
     ${campoDePesquisa}&take=${take}&skip=${skip}`,
    );

    return response;
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const postComplementaryTwo = async (
  payload: Omit<IComplementary, 'id'>,
): Promise<IRequestResult> => {
  try {
    await Api.post('/complementar2', payload);

    return {success: true};
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const updateComplementaryTwo = async (
  id: string,
  payload: Omit<IComplementary, 'id'>,
): Promise<IRequestResult> => {
  try {
    await Api.put(`/complementar2/${id}`, payload);

    return {success: true};
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const deleteComplementaryTwo = async (id: string): Promise<IRequestResult> => {
  try {
    await Api.delete(`/complementar2/${id}`);

    return {success: true};
  } catch (error: any) {
    throw new Error(error.data.data);
  }
};

const getComplementaryTwo = async (
  active?: boolean,
): Promise<IComplementary[] | undefined> => {
  try {
    const response = await Api.get(
      active ? '/complementar2?ativo=true' : '/complementar2',
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};
const getComplementaryTwoPagination = async (
  ativo: number,
  campoDePesquisa: string,
  skip: number,
  take: number,
): Promise<IComplementaryPagination> => {
  try {
    const { data: response} = await Api.get(
      `/complementar2/paginado?ativo=
     ${ativo === 0 ? '' : ativo === 1 ? true : false}&campoDePesquisa=
     ${campoDePesquisa}&take=${take}&skip=${skip}`,
    );

    return response;
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

export const ComplementaryService = {
  deleteComplementaryOne,
  updateComplementaryOne,
  getComplementaryOne,
  getComplementaryOnePagination,
  postComplementaryOne,
  deleteComplementaryTwo,
  updateComplementaryTwo,
  getComplementaryTwo,
  getComplementaryTwoPagination,
  postComplementaryTwo,
};
