export function addTargetBlank(text: string) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(text, 'text/html');

  const links = doc.querySelectorAll('a');

  links.forEach(link => {
    link.setAttribute('target', '_blank');
  });

  return doc.body.innerHTML;
}