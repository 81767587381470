import React from 'react';
import {Routes, Route} from 'react-router-dom';

import {
  Home,
  Shift,
  Course,
  Campaign,
  CampaignGeneralData,
  CampaignOffers,
  CampaignApplicationForm,
  CampaignPersonalization,
  CampaignIntegration,
  CampaignFunnel,
  Document,
  Funnel,
  NewCourse,
  Equivalence,
  Lead,
  MeetLead,
  ExternalUrl,
  Interviewer,
  GeneralConfig,
  ComplementaryOne,
  ComplementaryTwo,
  CalendarAvailability,
  UserProfile,
  NewUserProfile,
  UserPermission,
  NewUserPermission,
  SearchLead,
  LossReasons,
  Financeiro,
  BillingFee,
  EmailConfig,
} from 'pages';
import {EditLead} from 'pages/Lead/pages/Edit';
import {FunnelSteps} from 'pages/Funnel/components/FunnelSteps';

export const PrivateRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="*" element={<Funnel />} />
      <Route path="/" element={<Home />} />

      <Route path="/taxa-de-cobranca/:id" element={<BillingFee />} />
      <Route path="/taxa-de-cobranca" element={<Financeiro />} />

      <Route path="/funil" element={<Funnel />} />
      <Route path="/funil/etapas" element={<FunnelSteps />} />

      <Route path="/configuracao-geral" element={<GeneralConfig />} />

      <Route path="/motivos-de-perda" element={<LossReasons />} />

      <Route path="/curso" element={<Course />} />
      <Route path="/curso/novo" element={<NewCourse />} />
      <Route path="/curso/editar/:id" element={<NewCourse />} />

      <Route path="/turno" element={<Shift />} />

      <Route path="/complementar1" element={<ComplementaryOne />} />

      <Route path="/complementar2" element={<ComplementaryTwo />} />

      <Route path="/campanha" element={<Campaign />} />
      <Route path="/campanha/dados-gerais" element={<CampaignGeneralData />} />
      <Route path="/campanha/editar/:id" element={<CampaignGeneralData />} />
      <Route path="/campanha/ofertas/:id" element={<CampaignOffers />} />
      <Route
        path="/campanha/funil/:id/:idEstabelecimento/:nivelEstabelecimentoDono"
        element={<CampaignFunnel />}
      />
      <Route
        path="/campanha/ficha-de-inscricao/:id"
        element={<CampaignApplicationForm />}
      />
      <Route
        path="/campanha/personalizacao/:id"
        element={<CampaignPersonalization />}
      />
      <Route
        path="/campanha/integracao/:id"
        element={<CampaignIntegration />}
      />
      <Route path="/campanha/editar/:id" element={<Campaign />} />
      <Route path="/campanha/email-config/:id" element={<EmailConfig />} />

      <Route path="/campanha/novo/email-config/:id" element={<EmailConfig />} />

      <Route
        path="/disponibilidade-agenda"
        element={<CalendarAvailability />}
      />

      <Route path="/manutencao-lead" element={<Lead />} />
      <Route
        path="/lead/atender/:nomeLead/:idLead/:idOferta"
        element={<MeetLead />}
      />

      <Route path="/equivalencia" element={<Equivalence />} />

      <Route path="/documento" element={<Document />} />

      <Route path="/entrevistador" element={<Interviewer />} />

      <Route path="/url-externa" element={<ExternalUrl />} />

      <Route path="/perfil-do-usuario" element={<UserProfile />} />
      <Route path="/perfil-do-usuario/novo" element={<NewUserProfile />} />
      <Route
        path="/perfil-do-usuario/editar/:id"
        element={<NewUserProfile />}
      />

      <Route path="/permissoes-do-usuario" element={<UserPermission />} />
      <Route
        path="/permissoes-do-usuario/novo"
        element={<NewUserPermission />}
      />
      <Route
        path="/permissoes-do-usuario/editar/:id"
        element={<NewUserPermission />}
      />

      <Route path="/busca-personalizada-lead" element={<SearchLead />} />

      <Route
        path="/editar-lead/:idLead/:idCampanha/:idOferta"
        element={<EditLead />}
      />
    </Routes>
  );
};
