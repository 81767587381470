import {useContext} from 'react';

import {
  CampaignContext,
  CampaignProvider,
  ICampaignContext,
} from 'shared/contexts/Campaign';

function useCampaign(): ICampaignContext {
  const context = useContext(CampaignContext);

  if (!context) {
    throw new Error('useCampaign must be used within an CampaignProvider');
  }

  return context;
}

export {CampaignProvider, useCampaign};
