import {ptBR} from '@mui/x-data-grid';
import {ptBR as CorePtBR} from '@material-ui/core/locale';
import {createMuiTheme} from '@material-ui/core/styles';

export const DarkTheme = createMuiTheme(
  {
    palette: {
      type: 'dark',
      primary: {
        main: '#018781',
        light: '#339F9A',
        dark: '#005E5A',
        contrastText: '#FFFFFF',
      },
      secondary: {
        main: '#01B9AD ',
        light: '#33C7BD',
        dark: '#008179',
        contrastText: '#FFFFFF',
      },
      error: {
        main: '#F50000',
        light: '#F73333',
        dark: '#AB0000',
      },
      warning: {
        main: '#FFAC33',
        light: '#FF9800',
        dark: '#B26A00',
      },
      success: {
        main: '#33B673',
        light: '#00A451',
        dark: '#007238',
      },
      info: {
        main: '#018781',
        light: '#339F9A',
        dark: '#005E5A',
      },
      text: {
        primary: '#414141',
      },
      background: {
        default: '#1d1d1d',
        paper: '#3e3e3e',
      },
    },
    overrides: {
      MuiMenuItem: {
        root: {
          '&:hover': {
            backgroundColor: '#01B9AD',
            color: '#FFFFFF',
          },
          '&$selected': {
            backgroundColor: '#EEEEEE',
            color: '#616161',
            '&:hover': {
              backgroundColor: '#33C7BD',
              color: '#FFFFFF',
            },
          },
        },
      },
      MuiAccordion: {
        root: {
          border: '1px solid rgba(0, 0, 0, .125)',
          boxShadow: 'none',
          '&:not(:last-child)': {
            borderBottom: 0,
          },
          '&:before': {
            display: 'none',
          },
          '&$expanded': {
            margin: 'auto',
            borderTop: '1px solid #018781',
          },
        },
      },
      MuiAccordionSummary: {
        root: {
          '&$expanded': {
            borderBottom: '1px solid rgba(0, 0, 0, .125)',
          },
        },
      },
      MuiTableHead: {
        root: {
          backgroundColor: '#E0E0E0',
        },
      },
      MuiTableRow: {
        root: {
          height: 48,
        },
      },
      MuiStepper: {
        root: {
          backgroundColor: '#F8F8F8',
        },
      },
    },
    props: {
      MuiCheckbox: {
        color: 'primary',
      },
      MuiButton: {
        size: 'small',
      },
      MuiIconButton: {
        size: 'small',
      },
      MuiListItem: {
        dense: true,
      },
      MuiFab: {
        size: 'small',
      },
      MuiTable: {
        size: 'small',
      },
      MuiToolbar: {
        variant: 'dense',
      },
      MuiIcon: {
        fontSize: 'small',
      },
    },
  },
  ptBR,
  CorePtBR
);
