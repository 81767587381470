import React from 'react';

import {
  Box,
  Container,
  LinearProgress,
  Paper,
  useTheme,
} from '@material-ui/core';
import {useDrawer} from 'shared/hooks';

import {Button} from '../Button';
import { Skeleton } from '@material-ui/lab';

interface IProps {
  loading?: boolean;
  disabled?: boolean;
  onSave(): Promise<void> | void;
  onSaveLabel?: string;
  goBackLabel?: string;
  goBackStartIcone?: JSX.Element;
  onSaveAndNew?(): void;
  onGoBack?(): void;
  onCancel?(): void;
  onLoseMark?(): void;
  hideSaveButton?: boolean;
  loadingStepper?: boolean;
  isLeadLoss?: boolean;
}

export const Footer: React.FC<IProps> = ({
  disabled,
  onSaveLabel = 'Salvar',
  goBackLabel = 'Voltar',
  goBackStartIcone,
  onSave,
  onSaveAndNew,
  onGoBack,
  onCancel,
  onLoseMark,
  loading,
  hideSaveButton = false,
  loadingStepper = false,
  isLeadLoss = false,
}) => {
  const {drawerOpen} = useDrawer();
  const theme = useTheme();

  return (
    <Box
      position="fixed"
      bottom={0}
      left={0}
      right={0}
      zIndex={theme.zIndex.appBar}
    >
        {(loading) && <LinearProgress color="secondary" />}
        <Paper elevation={4}>
          <Box
            marginLeft={drawerOpen ? 35 : 9}
            style={{
              transition: 'all 0.25s',
            }}>
            <Container>
              <Box
                height="78px"
                display="flex"
                alignItems="center"
                justifyContent="flex-end">
                {onCancel && (
                  <Box width={170}>
                    <Button
                      label="Cancelar"
                      variant="outlined"
                      onClick={onCancel}
                      fullWidth
                      size="large"
                    />
                  </Box>
                )}

                {onGoBack && (
                  <Box marginLeft={2} width={goBackStartIcone ? 180 : 170}>
                    <Button
                      startIcon={goBackStartIcone}
                      label={goBackLabel}
                      disabled={disabled}
                      onClick={onGoBack}
                      fullWidth
                      variant="text"
                      size="large"
                    />
                  </Box>
                )}

                {onLoseMark && (
                  <Box marginLeft={2} width={isLeadLoss ? 240 : 170}>
                    <Button
                      error
                      label={isLeadLoss ? "Atualizar Motivo da Perda" : "Marcar Perda"}
                      disabled={disabled}
                      onClick={onLoseMark}
                      fullWidth
                      size="large"
                      variant="outlined"
                    />
                  </Box>
                )}

                {
                  !hideSaveButton && (
                    <Box marginLeft={2} width={170}>
                      <Button
                        label={onSaveAndNew ? 'Salvar e Voltar' : onSaveLabel}
                        disabled={disabled || loading || loadingStepper || isLeadLoss}
                        onClick={onSave}
                        fullWidth
                        size="large"
                      />
                    </Box>
                  )
                }

                {onSaveAndNew && (
                  <Box marginLeft={2} width={170}>
                    <Button
                      label="Salvar e Novo"
                      disabled={disabled}
                      onClick={onSaveAndNew}
                      fullWidth
                      size="large"
                    />
                  </Box>
                )}
              </Box>
            </Container>
          </Box>
        </Paper>
    </Box>
  );
};
