import {IRequestResult} from 'shared/interfaces/IRequestResult';
import {Api} from 'shared/services/axiosConfig';

export interface IFilterCalendar {
  establishment: string;
  campaign?: string;
  interviewers?: string; //ids separados por virgulas
  start: string;
  end: string;
}

export interface IEvent {
  id?: string;
  nomeCampanha?: string;
  inicioRecorrencia?: string;
  nomeEntrevistador?: string;
  idDisponibilidadeDeAgenda?: string;
  idEstabelecimento?: string;
  idCampanha?: string;
  idEntrevistador?: string;
  title?: string;
  identificacaoLead?: string;
  start?: string;
  end?: string;
  iniciaEm: string;
  terminaEm: string;
  idTipoDeRecorrencia?: number;
  maximoDeLeads?: number;
  tipoEdicao?: number;
  repeteDomingo?: boolean;
  repeteSegundaFeira?: boolean;
  repeteTercaFeira?: boolean;
  repeteQuartaFeira?: boolean;
  repeteQuintaFeira?: boolean;
  repeteSextaFeira?: boolean;
  repeteSabado?: boolean;
  repetirAte?: string;
  identificacaoLeadsVinculadosNestaAgenda?: string;
  observacao?: string;
  qtdeDeLeadsPendentesNestaAgenda?: number;
  qtdeDeLeadsRealizadosNestaAgenda?: number;
  qtdeDeLeadsVinculadosTodasAgendas?: number;
  qtdeDeLeadsVinculadosNestaAgendaESeguintes?: number;
  possuiLeadVinculado?: boolean;
}

export interface IAvailability {
  disponibilidades: IEvent[];
  permiteEditar: boolean;
  permiteExcluir: boolean;
  permiteIncluir: boolean;
}

const getAvailabilities = async (
  payload: IFilterCalendar,
): Promise<IAvailability> => {
  try {
    const { data } = await Api.get(
      `/DisponibilidadeDeAgenda/disponibilidade?idEstabelecimento=${payload.establishment
      }&idCampanha=${payload.campaign ? payload.campaign : ''
      }&idsEntrevistadores=${payload.interviewers ? payload.interviewers : ''
      }&iniciaEm=${payload.start}&terminaEm=${payload.end}`,
    );

    return data;
  } catch (error: any) {
    throw new Error(`Erro ao listar. ${error.mensagens}`);
  }
};

const getAvailabilityById = async (
  idDisponibilidadeDeAgenda: string,
): Promise<IEvent> => {
  try {
    const { data } = await Api.get(
      `/DisponibilidadeDeAgenda/${idDisponibilidadeDeAgenda}`,
    );
    return data;
  } catch (error: any) {
    throw new Error(`Erro ao listar. ${error.data.data}`);
  }
};

const postAvailabilities = async (payload: IEvent): Promise<IRequestResult> => {
  try {
    await Api.post(`/DisponibilidadeDeAgenda`, payload);
    return {success: true};
  } catch (error: any) {
    throw new Error(`Erro ao salvar. ${error.data.data}`);
  }
};

const putAvailabilities = async (payload: IEvent): Promise<IRequestResult> => {
  try {
    await Api.put(
      `/DisponibilidadeDeAgenda/${payload.idDisponibilidadeDeAgenda}`,
      payload,
    );

    return {success: true};
  } catch (error: any) {
    throw new Error(`Erro ao editar. ${error.data.data}`);
  }
};

const deleteAvailabilities = async (
  idDisponibilidadeDeAgenda: string,
  idTipoExclusao: number,
): Promise<IRequestResult> => {
  try {
    await Api.delete(
      `/DisponibilidadeDeAgenda/${idDisponibilidadeDeAgenda}?idTipoExclusao=${idTipoExclusao}`,
    );

    return {success: true};
  } catch (error: any) {
    throw new Error(`Erro ao excluir. ${error.mensagens}`);
  }
};

export const CalendarAvailabilityService = {
  getAvailabilities,
  putAvailabilities,
  postAvailabilities,
  getAvailabilityById,
  deleteAvailabilities,
};
