import {
  Box,
  Dialog,
  Button,
  Typography,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

interface IProps {
  openModal: boolean;
  onclose(): void;
  possuiTermo: boolean;
  textoLgpd: string;
  termoUnidade: boolean;
}

export const ModalLGPD: React.FC<IProps> = ({
  openModal,
  onclose,
  textoLgpd,
  possuiTermo,
  termoUnidade,
}) => {
  return (
    <>
      <Dialog
        maxWidth="md"
        open={openModal}
        onClose={onclose}
        aria-labelledby="Modal LGPD"
        aria-describedby="Modal LGPD">
        <DialogTitle>
          <Box width="100%" display={'flex'} justifyContent="space-between">
            <Box>
              <Typography variant="inherit" color="primary">
                Termo de Aceite LGPD
              </Typography>
            </Box>
            <Box>
              <Button color="primary" onClick={onclose}>
                <CloseIcon />
              </Button>
            </Box>
          </Box>
        </DialogTitle>

        <DialogContent>
          {textoLgpd ===
            // eslint-disable-next-line no-useless-escape
            `<p><span></span><a href=\"https://transform.tools/json-to-typescript\"><span></span></a><span></span></p>` ||
          (!possuiTermo && !termoUnidade) ? (
            <Box
              width="100%"
              justifyContent="space-between"
              marginBottom={5}
              marginTop={2}>
              <Typography>Nenhum termo cadastrado...</Typography>
            </Box>
          ) : (
            <Box
              width="100%"
              justifyContent="space-between"
              marginBottom={5}
              marginTop={-2}>
              <Typography>
                {
                  <div
                    dangerouslySetInnerHTML={{
                      __html: textoLgpd,
                    }}
                  />
                }
              </Typography>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
