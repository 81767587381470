import React, {createContext, useCallback, useState} from 'react';

import {feedback} from 'shared/services/alertService';
import {CampaignService, ICampaign} from 'shared/services/api/CampaignService';
import {
  FormService,
  IFormSection,
  IOrderSection,
} from 'shared/services/api/FormService';
import {errorResponse} from 'shared/utils/errorResponse';
interface ISectionFiltered {
  idSecao: string;
  nomeSecao: string;
}

export interface ICampaignContext {
  getCampaigns(): Promise<void>;
  campaigns: ICampaign[];
  getSections(id: string): Promise<void>;
  sections: IFormSection[];
  sectionFiltered: ISectionFiltered[];
  orderSections: IOrderSection[];
  onDragEnd(result: unknown): void;
  setIsSchedule(value: boolean): void;
  isSchedule: boolean;
  hasSubscribers: boolean;
}

export const CampaignContext = createContext<ICampaignContext>(
  {} as ICampaignContext,
);

export const CampaignProvider: React.FC = ({children}) => {
  const [sectionFields, setSectionFields] = useState<IFormSection[]>([]);
  const [sectionFiltered, setSectionFiltered] = useState<ISectionFiltered[]>(
    [],
  );
  const [orderSections, setOrderSections] = useState<IOrderSection[]>([]);
  const [isSchedule, setIsSchedule] = useState(false);
  const [hasSubscribers, setHasSubscribers] = useState(false);
  const [campaigns, setCampaigns] = useState<ICampaign[]>([]);

  const handleGetCampaigns = useCallback(async () => {
    try {
      const data = await CampaignService.getCampaigns({});

      if (data) {
        setCampaigns(data);
      }
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
  }, []);

  const handleGetSections = useCallback(async (id: string) => {
    try {
      const response = await FormService.getFormSections(id);

      setSectionFields(response.secoes);
      setIsSchedule(response.campanhaUsaAgendamento);
      setHasSubscribers(response.campanhaPossuiInscritos);

      setSectionFiltered(
        response.secoes.map((item) => {
          return {
            idSecao: item.idSecao,
            nomeSecao: item.nomeSecao,
          };
        }),
      );
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
  }, []);

  const handleOnDragEnd = useCallback(
    (result) => {
      if (!result.destination) return;

      const items = Array.from(sectionFields);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      setSectionFields(items);

      setOrderSections(
        items.map((item, index) => {
          return {
            ordem: index + 1,
            idSecao: item.idSecao,
          };
        }),
      );
    },
    [sectionFields],
  );

  return (
    <CampaignContext.Provider
      value={{
        getCampaigns: handleGetCampaigns,
        campaigns,
        getSections: handleGetSections,
        sections: sectionFields,
        sectionFiltered,
        orderSections,
        onDragEnd: handleOnDragEnd,
        setIsSchedule,
        isSchedule,
        hasSubscribers,
      }}>
      {children}
    </CampaignContext.Provider>
  );
};
