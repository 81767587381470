import React, {ReactNode, useCallback} from 'react';

import {
  Box,
  Paper,
  MenuItem,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TextField,
  Typography,
  FormControl,
  TableFooter,
  TableContainer,
  Table as MuiTable,
} from '@material-ui/core';
import {Pagination} from '@material-ui/lab';
import {NoRegister, TableSkeleton} from 'shared/components';

import {useStyles} from './styles';

interface IHead {
  label: string;
  width?: string;
  align?: 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined;
}

interface IProps {
  loading?: boolean;
  head: IHead[];
  footer?: ReactNode;
  pagination?: {
    page: number;
    count: number;
  };
  take?: number;
  length: number;
  rowsPerPage: number;
  minWidth?: number;
  onChangeTake?: (value: number) => void;
  onChangePage?: (value: number) => void;
}

export const Table: React.FC<IProps> = ({
  head,
  take,
  footer,
  length,
  loading,
  pagination,
  rowsPerPage,
  minWidth,
  onChangePage,
  onChangeTake,
  children,
}) => {
  const classes = useStyles();

  const handleChangePage = useCallback(
    (event: React.ChangeEvent<unknown>, value: number) => {
      onChangePage && onChangePage(value);
    },
    [onChangePage],
  );

  return (
    <>
      {take && (
        <Box marginBottom={2} display="flex" alignItems="center">
          <FormControl component="fieldset">
            <TextField
              inputProps={{className: classes.select}}
              select
              variant="outlined"
              value={take}
              onChange={({target}) =>
                onChangeTake && onChangeTake(Number(target.value))
              }>
              {[5, 10, 15, 20, 50].map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
          <Box marginLeft={2}>Linhas por página</Box>
        </Box>
      )}
      <TableContainer component={Paper}>
        {loading ? (
          <TableSkeleton
            btnAct={3}
            columns={10}
            rowsBody={10}
            rectWidth="40%"
          />
        ) : (
          <MuiTable>
            <TableHead>
              <TableRow>
                {head.map((item) => (
                  <TableCell
                    width={item.width}
                    style={{minWidth: minWidth ? minWidth : 140}}
                    height="42px"
                    align={item.align}
                    key={item.label}>
                    {item.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>{children}</TableBody>
          </MuiTable>
        )}
        {footer && (
          <MuiTable>
            <TableFooter>{footer}</TableFooter>
          </MuiTable>
        )}

        {!loading && length === 0 && <NoRegister data={length} />}
      </TableContainer>

      <Box
        className={classes.baseboard}
        justifyContent={pagination ? 'space-between' : 'flex-end'}>
        {pagination && length > 0 && (
          <Pagination
            page={pagination.page}
            count={pagination.count}
            onChange={handleChangePage}
          />
        )}
        {length > 0 && (
          <Typography variant="caption" style={{color: '#9E9E9E'}}>
            {rowsPerPage} do total de {length} linhas
          </Typography>
        )}
      </Box>
    </>
  );
};
