import {Api} from 'shared/services/axiosConfig';
import {GridColDef} from '@mui/x-data-grid';

export interface RowDef {
  field: string;
  valor: string;
}

export interface GridRowDef {
  idLead: string;
  linha: RowDef[];
  status: string;
  integrado: boolean;
}

export interface ILeadsDataGrid {
  colunas: GridColDef[];
  linhas: GridRowDef[];
  mensagemIntegracaoLeads: string;
}

const getLeadsByCampaignId = async (
  idCampaign: string,
): Promise<ILeadsDataGrid> => {
  try {
    const {data} = await Api.get(`/Lead/BuscaPersonalizada/${idCampaign}`);

    return data;
  } catch (error: any) {
    throw new Error(`Erro ao listar. ${error.mensagens}`);
  }
};

const saveColumnsConfig = async (
  idCampaing: string,
  columns: GridColDef,
): Promise<any> => {
  try {
    const putObject = {...columns};

    const {data, status} = await Api.put(
      `/Lead/BuscaPersonalizada/gridconfiguracoes/${idCampaing}`,
      putObject,
    );

    return {data: data, status: status};
  } catch (error: any) {
    throw new Error(`Erro ao listar. ${error.mensagens}`);
  }
};

const patchCollegeIntegrateMultipleIds = async (
  idCampaign: string,
  ids: string[],
): Promise<any> => {
  try {
    const patchObject: any = {
      idCampanha: idCampaign,
      idsLeads: ids,
    };

    const {data} = await Api.patch(
      `/Lead/IntegrarComGVCollege/MultiplosLeads`,
      patchObject,
    );

    return data;
  } catch (error: any) {
    throw new Error(`Erro ao listar. ${error.mensagens}`);
  }
};

export const SearchLeadService = {
  getLeadsByCampaignId,
  saveColumnsConfig,
  patchCollegeIntegrateMultipleIds,
};
