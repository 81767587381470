import {Api} from 'shared/services/axiosConfig';

export interface ISchoolPeriod {
  id: string;
  nome: string;
  ordem: number;
}

const getSchoolPeriod = async (): Promise<ISchoolPeriod[]> => {
  try {
    const response = await Api.get('/PeriodoLetivo');

    return response.data;
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

export const SchoolPeriodService = {
  getSchoolPeriod,
};
