import React, {useEffect, useCallback, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import Slider, {Settings} from 'react-slick';

import {
  Box,
  Grid,
  Link,
  Paper,
  Checkbox,
  MenuItem,
  TextField,
  Typography,
  ListSubheader,
  FormControlLabel,
} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';

import AccessTimeIcon from '@material-ui/icons/AccessTime';
import PlaceIcon from '@material-ui/icons/Place';
import HowToRegIcon from '@material-ui/icons/HowToReg';

import {useTitle, useUser} from 'shared/hooks';
import {errorResponse} from 'shared/utils/errorResponse';
import {feedback} from 'shared/services/alertService';
import {NoRegister, NotFoundText} from 'shared/components';

import {Arrow} from './components/Arrow';
import {LatestSubscriberCard} from './components/LatestSubscriberCard';
import {LatestSubscriberSkeleton} from './components/LatestSubscriberSkeleton';

import BemVindoCC from 'shared/assets/bem-vindo-cc.svg';
import {useStyles} from './styles/styles';
import './styles/slickStyles.css';

import {
  IUnityEstablishment,
  EstablishmentService,
} from 'shared/services/api/EstablishmentService';
import {
  ILatestSubscribers,
  INextInterviews,
  HomeService,
} from 'shared/services/api/HomeService';
import {Dashboard} from './dashboard';

export const Home: React.FC = () => {
  const {setTitle} = useTitle();
  const {data: user} = useUser();
  const styles = useStyles();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [selectedEstablishment, setSelectedEstablishment] = useState('');
  const [establishments, setEstablishments] = useState<
    Omit<IUnityEstablishment[], 'unidades'>
  >([]);

  const [isLoadingData, setIsLoadingData] = useState(true);
  const [latestSubscribers, setLatestSubscribers] = useState<
    ILatestSubscribers[]
  >([]);

  const [isLoadingTable, setIsLoadingTable] = useState(true);
  const [isMyInterviewsOnly, setIsMyInterviewsOnly] = useState(false);
  const [nextInterviews, setNextInterviews] = useState<INextInterviews[]>([]);

  const permissions = useMemo(
    () => user?.permissoes.filter((i) => i.nome.includes('Página inicial')),
    [user?.permissoes],
  );

  const canList = useMemo(
    () => !!permissions?.find((i) => i.nome.includes('Listar')),
    [permissions],
  );

  const canListCalendarAvailability = useMemo(
    () =>
      user?.permissoes.find((i) =>
        i.nome.includes('Disponibilidade de agenda - Listar'),
      ),
    [user?.permissoes],
  );

  const idList = useMemo(
    () => permissions?.find((i) => i.nome.includes('Listar'))?.id,
    [permissions],
  );

  const getEstablishments = useCallback(async () => {
    try {
      const {estabelecimentos} = await EstablishmentService.getEstablishments(
        idList,
      );

      if (estabelecimentos) {
        setEstablishments(estabelecimentos);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      feedback(errorResponse(error), 'error');
    }
  }, [idList]);

  const getLatestSubscribers = useCallback(async () => {
    try {
      const response = await HomeService.getLatestSubscribers(
        selectedEstablishment,
        user?.timezone,
        idList,
      );

      if (response) {
        setLatestSubscribers(response);
      }
    } catch (error) {
      setLatestSubscribers([]);
      feedback(errorResponse(error), 'error');
    } finally {
      setIsLoadingData(false);
    }
  }, [idList, selectedEstablishment, user?.timezone]);

  const getNextInterviews = useCallback(async () => {
    try {
      const response = await HomeService.getNextInterviews(
        selectedEstablishment,
        isMyInterviewsOnly,
        user?.timezone,
        idList,
      );

      if (response) {
        setNextInterviews(response);
      }
    } catch (error) {
      setNextInterviews([]);
      feedback(errorResponse(error), 'error');
    } finally {
      setLoading(false);
      setIsLoadingTable(false);
    }
  }, [idList, isMyInterviewsOnly, selectedEstablishment, user]);

  useEffect(() => setTitle('Página inicial'), [setTitle]);

  useEffect(() => {
    getEstablishments();
    getLatestSubscribers();
    getNextInterviews();
  }, [getEstablishments, getLatestSubscribers, getNextInterviews]);

  const renderSkeleton = useCallback(() => {
    return (
      <Box
        display="flex"
        gridGap={8}
        paddingX={2}
        paddingTop={1.5}
        paddingBottom={2}>
        <Skeleton variant="rect" width="100%" height={27} animation="wave" />
        <Skeleton variant="rect" width="100%" height={27} animation="wave" />
        <Skeleton variant="rect" width="100%" height={27} animation="wave" />
        <Skeleton variant="rect" width="100%" height={27} animation="wave" />
      </Box>
    );
  }, []);

  const settings = useMemo(() => {
    const slickSettings: Settings = {
      className: styles.slick,
      dots: false,
      speed: 500,
      infinite: false,
      slidesToShow: 3,
      swipeToSlide: true,
      accessibility: true,
      prevArrow: <Arrow direction="back" />,
      nextArrow: <Arrow direction="next" />,
      responsive: [
        {
          breakpoint: 1150,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 650,
          settings: {
            dots: true,
            arrows: false,
            slidesToShow: 1,
          },
        },
      ],
    };

    return slickSettings;
  }, [styles.slick]);

  if (!canList) {
    return (
      <Box marginTop={12}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <Typography variant="h3" align="center" style={{fontWeight: 500}}>
              Bem-vindo!
            </Typography>
            <Box marginBottom={8} />
          </Grid>
          <Grid item xs={12} sm={6} md={5}>
            <img src={BemVindoCC} alt="Bem vindo" width="100%" />
          </Grid>
        </Grid>
      </Box>
    );
  }

  return (
    <Grid>
      <Box marginBottom={2}>
        <Dashboard />
      </Box>

      <Grid container item xs>
        <Grid container justifyContent="space-between">
          <Grid item xs={12}>
            <Box marginTop={3} marginBottom={1}>
              <Typography variant="h5" className={styles.fontMedium} />
              <Typography variant="h5">Últimos inscritos</Typography>
              <Box marginBottom={1} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            {loading ? (
              <Skeleton variant="rect" height={40} animation="wave" />
            ) : (
              <TextField
                fullWidth
                select
                size="small"
                value={selectedEstablishment}
                label="Estabelecimento *"
                variant="outlined"
                disabled={loading}
                onChange={({target}) => (
                  setSelectedEstablishment(target.value), setIsLoadingData(true)
                )}>
                <MenuItem value={'0'}>Todos</MenuItem>
                {establishments.map((establishment) => {
                  if (establishment.idEstabelecimentoPai) {
                    return (
                      <MenuItem
                        key={establishment.idEstabelecimento}
                        value={establishment.idEstabelecimento}
                        className={styles.menuItem}>
                        {establishment.idComNome}
                      </MenuItem>
                    );
                  }
                  return (
                    <ListSubheader key={establishment.idEstabelecimento}>
                      {establishment.idComNome}
                    </ListSubheader>
                  );
                })}
              </TextField>
            )}
          </Grid>
          {loading || isLoadingData ? (
            <>
              <Grid item xs={12} />
              <Grid item sm={6} md={6} lg={4} xl={4}>
                <Box marginY={1}>
                  <LatestSubscriberSkeleton />
                </Box>
              </Grid>
            </>
          ) : latestSubscribers.length > 0 ? (
            <Grid item xs={12}>
              <Slider {...settings}>
                {latestSubscribers.map((latestSubscriber, index) => {
                  return (
                    <LatestSubscriberCard
                      key={index}
                      latestSubscriber={latestSubscriber}
                    />
                  );
                })}
              </Slider>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <NotFoundText />
            </Grid>
          )}

          <Grid item xs={12}>
            <Box marginTop={3} component={Paper}>
              <Box className={styles.tableHeader}>
                <Box display="flex" alignItems="center">
                  <Box
                    bgcolor={'#edfaf9'}
                    display="flex"
                    padding={0.75}
                    borderRadius={11}>
                    <HowToRegIcon color="primary" />
                  </Box>
                  <Box marginLeft={1}>
                    <Typography variant="h5" className={styles.fontMedium} />
                    <Typography variant="h5">Próximas entrevistas</Typography>
                  </Box>
                </Box>
                <Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isMyInterviewsOnly}
                        className={styles.myInterviewsCheckBox}
                        onChange={(e) => (
                          setIsMyInterviewsOnly(e.target.checked),
                          setIsLoadingTable(true)
                        )}
                      />
                    }
                    label="Exibir apenas minhas entrevistas"
                  />
                  {canListCalendarAvailability && (
                    <Link
                      component="button"
                      variant="body2"
                      onClick={() => navigate('/disponibilidade-agenda')}>
                      <strong>Ver mais</strong>
                    </Link>
                  )}
                </Box>
              </Box>
              <Box
                gridGap={8}
                paddingX={5}
                paddingY={1}
                display="flex"
                bgcolor={'#d9eae9'}
                justifyContent="space-between">
                <Box width="100%">
                  <Typography variant="subtitle2">
                    <strong>Entrevistador</strong>
                  </Typography>
                </Box>
                <Box width="100%">
                  <Typography variant="subtitle2">
                    <strong>Agenda</strong>
                  </Typography>
                </Box>
                <Box width="100%">
                  <Typography variant="subtitle2">
                    <strong>Entrevistado e Oferta</strong>
                  </Typography>
                </Box>
              </Box>
              <Box width="100%" paddingX={2} paddingTop={1.5} paddingBottom={2}>
                {loading || isLoadingData || isLoadingTable ? (
                  renderSkeleton()
                ) : nextInterviews.length > 0 ? (
                  nextInterviews.map((item, index) => {
                    return (
                      <Box
                        key={index}
                        display="flex"
                        paddingX={1}
                        paddingY={1}
                        marginBottom={index === 2 ? 0 : 1.5}
                        alignItems="center"
                        component={Paper}>
                        <Box width="100%" display="flex" alignItems="center">
                          <Box
                            height="100%"
                            width={'4px'}
                            className={styles.columnLeft}
                          />
                          <Typography style={{flex: 1, paddingLeft: '12px'}}>
                            {item.nomeEntrevistador}
                          </Typography>
                        </Box>
                        <Box width="100%">
                          <Box
                            display="flex"
                            alignItems="center"
                            marginBottom={0.5}
                            className={styles.nextInterviews}>
                            <AccessTimeIcon fontSize="small" />
                            <Box marginLeft={1}>
                              <Typography>
                                Data/hora: <span>{item.iniciaEm}</span>
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            display="flex"
                            alignItems="center"
                            className={styles.nextInterviews}>
                            <PlaceIcon fontSize="small" />
                            <Box marginLeft={1}>
                              <Typography>
                                <span>{item.nomeEstabelecimento}</span>
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        <Box
                          width="100%"
                          display="flex"
                          flexDirection="column"
                          alignItems="flex-start"
                          paddingRight="8%">
                          {item.entrevistados.map((person) => {
                            return (
                              <>
                                <Typography
                                  key={person.nomeOferta}
                                  style={{paddingLeft: '10%'}}>
                                  <strong>
                                    <span
                                      style={{
                                        color: '#018781',
                                      }}>{`${person.identificacaoLead} -`}</span>
                                  </strong>
                                  {` ${person.nomeOferta}`}
                                </Typography>
                                <Typography
                                  key={person.nomeOferta}
                                  style={{paddingLeft: '10%'}}>
                                  <strong>Candidato: </strong>
                                  {person.nomeCandidato}
                                </Typography>
                              </>
                            );
                          })}
                        </Box>
                      </Box>
                    );
                  })
                ) : (
                  <NoRegister data={nextInterviews.length} />
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
