import {Box} from '@material-ui/core';
import {Typography} from '@mui/material';
import React from 'react';
import {IPaymentInfo} from 'shared/services/api/LeadService';

interface IProps {
  paymentInfo: IPaymentInfo | null;
}

interface ITest {
  label: string;
  value: any;
}

export const StripedList: React.FC<IProps> = ({paymentInfo}) => {
  let isGray = false;

  const formattedFields =
    paymentInfo?.valorPago != null
      ? [
          {label: 'Valor pago', value: paymentInfo?.valorPago},
          {label: 'Data de pagamento', value: paymentInfo?.dataPagamento},
          {label: 'Vencimento', value: paymentInfo?.vencimento},
          {label: 'Competência', value: paymentInfo?.competencia},
          {
            label: 'Forma de Pagamento',
            value: paymentInfo?.formaPagaLinkPagamentoTaxa,
          },
          {label: 'Conta bancária', value: paymentInfo?.contaBancaria},
          {label: 'Tipo de parcela', value: paymentInfo?.tipoParcela},
          {label: 'Evento', value: paymentInfo?.evento},
        ]
      : [
          {label: 'Valor a pagar', value: paymentInfo?.valor},
          {label: 'Valor pago', value: paymentInfo?.valorPago},
          {label: 'Data de pagamento', value: paymentInfo?.dataPagamento},
          {label: 'Vencimento', value: paymentInfo?.vencimento},
          {label: 'Competência', value: paymentInfo?.competencia},
          {
            label: 'Forma de Pagamento',
            value: paymentInfo?.formaPagaLinkPagamentoTaxa,
          },
          {label: 'Conta bancária', value: paymentInfo?.contaBancaria},
          {label: 'Tipo de parcela', value: paymentInfo?.tipoParcela},
          {label: 'Evento', value: paymentInfo?.evento},
        ];

  const responseValidations = (value: any) => {
    return value !== null;
  };

  const handleResponseFormatter = (field: string, resp?: any) => {
    if (!resp) {
      return '-';
    }
    if (field === 'Valor a pagar' || field === 'Valor pago') {
      if (!resp?.toString()?.includes('.')) {
        return `R$${resp},00`;
      } else {
        const valueResponse = resp?.toString().split('.');
        if (valueResponse) {
          const responseCents =
            Number(valueResponse[1]) < 10
              ? `${valueResponse[1]}0`
              : valueResponse[1];
          return `R$${valueResponse[0]},${responseCents}`;
        }
      }
    }

    if (
      (field === 'Vencimento' || field === 'Data de pagamento') &&
      undefined !== typeof field
    ) {
      const dateAndTime = resp?.toString().split(' ');
      if (dateAndTime) {
        const date = dateAndTime[0]?.toString() || '';
        return `${date}`;
      }
    }
    return resp;
  };

  return (
    <>
      {paymentInfo &&
        formattedFields.map((field) => {
          if (responseValidations(field.value)) {
            isGray = !isGray;
            return (
              <Box
                key={field.label}
                bgcolor={isGray ? '#f7f7f7' : '#e8e8e8'}
                marginBottom={1}
                padding={1}
                marginLeft={2}
                marginRight={2}
                width={'100%'}>
                <Box
                  marginLeft={2}
                  display={'flex'}
                  width={'100%'}
                  alignItems={'center'}
                  justifyContent={'space-between'}>
                  <Typography width={'50%'} variant="body1" fontWeight={'bold'}>
                    {field.label}
                  </Typography>
                  <Typography width={'50%'} variant="body1">
                    {handleResponseFormatter(field.label, field.value)}
                  </Typography>
                </Box>
              </Box>
            );
          }
        })}
      <Box marginTop={2} />
    </>
  );
};
