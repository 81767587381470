import {Api} from 'shared/services/axiosConfig';

export interface IUrlComposition {
  idCampanha: string;
  idComposicaoUri: number;
  urlBase: string;
}

export interface IUrlResponse {
  cidade: string;
  estabelecimento: string;
  curso: string;
  composicaoUri: number;
  uri: string;
}

const getExternalUrl = async (
  dataReq: IUrlComposition,
): Promise<IUrlResponse[]> => {
  try {
    const {data} = await Api.get(
      `ComposicaoUriExterna?idCampanha=${dataReq.idCampanha}&idComposicaoUri=${dataReq.idComposicaoUri}&urlBase=${dataReq.urlBase}`,
    );

    return data;
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

export const ExternalUrlService = {
  getExternalUrl,
};
