import {useContext} from 'react';

import {TermContext, TermProvider, IContextTerm} from 'shared/contexts/Terms';

function useTerm(): IContextTerm {
  const context = useContext(TermContext);

  if (!context) {
    throw new Error('useTerm must be used within an TermProvider');
  }

  return context;
}

export {TermProvider, useTerm};
