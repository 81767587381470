import {IRequestResult} from 'shared/interfaces/IRequestResult';
import {Api} from 'shared/services/axiosConfig';
import {format, addHours} from 'date-fns';
import {saveAs} from 'file-saver';

export interface ILeadCalls {
  id: string;
  idLead: string;
  identificacaoLead: string;
  emailLead: string;
  telefoneLead: string;
  idSituacaoDoLead: string;
  idFunil: string;
  nomeSituacaoDoLead: string;
  idEstabelecimento: string;
  nomeEstabelecimento: string;
  idCampanha: string;
  nomeCampanha: string;
  idOferta: string;
  nomeOferta: string;
  dataInscricao: string;
  dataAgenda: string;
  idEntrevistador: string;
  nomeEntrevistador: string;
  idUsuarioEntrevistador: string;
  candidatoLead: string;
  vendaPerdida: boolean;
  emControleTipoVaga: boolean;
  emControleTipoListaEspera: boolean;
  idTipoControleDeVagas: number;
  valorControleDeVagas: number;
}

export interface ILeadCallsWithPagination {
  leads: ILeadCalls[];
  qtdTotalDeRegistros: number;
}

export interface ILeadCallsFilters {
  idEstabelecimento: string | 0;
  idCampanha: string | 0;
  idSituacaoDoLead: string | 0;
  idCurso: string | 0;
  idCiclo: string | 0;
  idUsuarioAtendente: string | 0;
  idUsuarioEntrevistador: string | 0;
  inscricaoIniciaEm?: string | null;
  inscricaoTerminaEm?: string | null;
  agendamentoIniciaEm?: string | null;
  agendamentoTerminaEm?: string | null;
  campoDePesquisa?: string | null;
  isVendaPerdida: boolean;
  isControleTipoVaga: boolean;
  isControleTipoListaEspera: boolean;
}

export interface ICall {
  idAtendimentoDoLead: string;
  idSituacaoDoLead: string;
  nomeSituacaoDoLead: string;
  dataAtendimento: string;
  idAtendente?: number;
  nomeAtendente?: string;
  dataAgenda: string | null;
  idSituacaoDeAgenda?: number;
  nomeSituacaoDeAgenda?: string;
  idDisponibilidadeDeAgenda?: string;
  atendimento: string;
  usaAgendamento: boolean;
}

export interface ILeadsCall {
  atendimentos: ICall[];
  permiteEditar: boolean;
  permiteExcluir: boolean;
  permiteIncluir: boolean;
}

export interface IScheduleSituations {
  id: number;
  nome: string;
}

export interface ICustomerService {
  id?: string;
  idLead?: string;
  idSituacaoDeLead?: string;
  usaAgendamento?: boolean;
  idSituacaoDeAgenda?: number | null;
  idDisponibilidadeDeAgenda?: string;
  atendimento?: string;
}

export interface ILog {
  id: number;
  idStatus: number;
  incluidoEm: string;
  mensagem: string;
  nomeStatus: string;
}

export interface IIntegrationSituation {
  logs: ILog[];
  msgDetalhada: string;
  msgStatus: string;
  possuiIntegracao: boolean;
}

export interface ICollegeIntegrate {
  requisicao_id: number;
  status: string;
  sucesso: boolean;
}

export interface IPaymentInfo {
  linkPagamento: string | null;
  valor: number | null;
  valorPago: number | null;
  dataPagamento: string | null;
  vencimento: string;
  competencia: string;
  situacaoLinkPagamentoTaxa: number;
  contaBancaria: string | null;
  tipoParcela: string;
  evento: string;
  formaPagaLinkPagamentoTaxa: string | null;
}

const getLeadCalls = async (
  take: number,
  skip: number,
  filters: ILeadCallsFilters,
  userTimezone: number,
): Promise<ILeadCallsWithPagination> => {
  try {
    const {data} = await Api.get(
      `/Lead/atendimentos?IdEstabelecimento=${
        filters.idEstabelecimento ? filters.idEstabelecimento : ''
      }&IdCampanha=${
        filters.idCampanha ? filters.idCampanha : ''
      }&IdSituacaoDoLead=${
        filters.idSituacaoDoLead ? filters.idSituacaoDoLead : ''
      }&IdCurso=${filters.idCurso ? filters.idCurso : ''}&IdCiclo=${
        filters.idCiclo ? filters.idCiclo : ''
      }&IdUsuarioAtendente=${
        filters.idUsuarioAtendente ? filters.idUsuarioAtendente : ''
      }&IdUsuarioEntrevistador=${
        filters.idUsuarioEntrevistador ? filters.idUsuarioEntrevistador : ''
      }&InscricaoIniciaEm=${
        filters.inscricaoIniciaEm ? filters.inscricaoIniciaEm : ''
      }&InscricaoTerminaEm=${
        filters.inscricaoTerminaEm ? filters.inscricaoTerminaEm : ''
      }&AgendamentoIniciaEm=${
        filters.agendamentoIniciaEm ? filters.agendamentoIniciaEm : ''
      }&AgendamentoTerminaEm=${
        filters.agendamentoTerminaEm ? filters.agendamentoTerminaEm : ''
      }&CampoDePesquisa=${
        filters.campoDePesquisa ? filters.campoDePesquisa : ''
      }&VendaPerdida=${filters.isVendaPerdida || false}&EmControleTipoVaga=${
        filters.isControleTipoVaga || false
      }&EmControleTipoListaDeEspera=${
        filters.isControleTipoListaEspera || false
      }&Take=${take}&Skip=${skip}`,
    );

    const leads: ILeadCalls[] = data.leads.map(
      (leadCall: ILeadCalls) =>
        ({
          ...leadCall,
          dataAgenda: leadCall.dataAgenda
            ? format(
                addHours(new Date(leadCall.dataAgenda), userTimezone),
                'dd/MM/yyyy HH:mm',
              )
            : leadCall.dataAgenda,
          dataInscricao: leadCall.dataInscricao
            ? format(
                addHours(new Date(leadCall.dataInscricao), userTimezone),
                'dd/MM/yyyy HH:mm',
              )
            : leadCall.dataInscricao,
        } as ILeadCalls),
    );

    const response: ILeadCallsWithPagination = {
      ...data,
      leads,
    };

    return response;
  } catch (error: any) {
    throw new Error(`Erro ao listar. ${error.mensagens}`);
  }
};

const getExportLeadCalls = async (
  take: number,
  skip: number,
  filters: ILeadCallsFilters,
): Promise<boolean> => {
  try {
    const {data} = await Api.get(
      `/Lead/atendimentos/download?IdEstabelecimento=${
        filters.idEstabelecimento ? filters.idEstabelecimento : ''
      }&IdCampanha=${
        filters.idCampanha ? filters.idCampanha : ''
      }&IdSituacaoDoLead=${
        filters.idSituacaoDoLead ? filters.idSituacaoDoLead : ''
      }&IdCurso=${filters.idCurso ? filters.idCurso : ''}&IdCiclo=${
        filters.idCiclo ? filters.idCiclo : ''
      }&IdUsuarioAtendente=${
        filters.idUsuarioAtendente ? filters.idUsuarioAtendente : ''
      }&IdUsuarioEntrevistador=${
        filters.idUsuarioEntrevistador ? filters.idUsuarioEntrevistador : ''
      }&InscricaoIniciaEm=${
        filters.inscricaoIniciaEm ? filters.inscricaoIniciaEm : ''
      }&InscricaoTerminaEm=${
        filters.inscricaoTerminaEm ? filters.inscricaoTerminaEm : ''
      }&AgendamentoIniciaEm=${
        filters.agendamentoIniciaEm ? filters.agendamentoIniciaEm : ''
      }&AgendamentoTerminaEm=${
        filters.agendamentoTerminaEm ? filters.agendamentoTerminaEm : ''
      }&CampoDePesquisa=${
        filters.campoDePesquisa ? filters.campoDePesquisa : ''
      }&Take=${take}&Skip=${skip}`,
      {responseType: 'blob'},
    );

    const fileName = `listagem_leads_${format(
      new Date(),
      'dd_MM_yyyy HH_mm_ss',
    )}`;

    saveAs(data, fileName);

    return true;
  } catch (error: any) {
    throw new Error(`Erro ao listar. ${error.mensagens}`);
  }
};

const getLeadsCall = async (
  id: string,
  userTimezone: number,
): Promise<ILeadsCall> => {
  try {
    const {data} = await Api.get(`/Lead/atendimento/${id}`);

    const atendimentos: ICall[] = data.atendimentos.map(
      (call: ICall) =>
        ({
          ...call,
          dataAgenda: call.dataAgenda
            ? format(
                addHours(new Date(call.dataAgenda), userTimezone),
                "yyyy-MM-dd'T'HH:mm:ss",
              )
            : null,
          dataAtendimento: format(
            addHours(new Date(call.dataAtendimento), userTimezone),
            "yyyy-MM-dd'T'HH:mm:ss",
          ),
        } as ICall),
    );

    const response: ILeadsCall = {
      ...data,
      atendimentos,
    } as ILeadsCall;

    return response;
  } catch (error: any) {
    throw new Error(`Erro ao listar. ${error.mensagens}`);
  }
};

const deleteCall = async (id: string): Promise<IRequestResult> => {
  try {
    await Api.delete(`/Lead/${id}`);

    return {success: true};
  } catch (error: any) {
    throw new Error(error.data.data);
  }
};

const deleteMeetLead = async (id: string): Promise<IRequestResult> => {
  try {
    await Api.delete(`/Lead/atendimento/${id}`);

    return {success: true};
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const getScheduleSituations = async (): Promise<IScheduleSituations[]> => {
  try {
    const {data} = await Api.get(`/Lead/atendimento/situacoesdeagenda`);

    return data;
  } catch (error: any) {
    throw new Error(`Erro ao listar. ${error.mensagens}`);
  }
};

const getLeadFormulario = async (): Promise<IScheduleSituations[]> => {
  try {
    const {data} = await Api.get(`lead/formulario`);
    return data;
  } catch (error: any) {
    throw new Error(`Erro ao listar. ${error.mensagens}`);
  }
};

const postLeadsCall = async (
  payload: ICustomerService,
): Promise<IRequestResult> => {
  try {
    await Api.post(`/Lead/atendimento`, payload);

    return {success: true};
  } catch (error: any) {
    throw new Error(`Erro ao salvar. ${error.mensagens}`);
  }
};

const putLeadsCall = async (
  payload: ICustomerService,
): Promise<IRequestResult> => {
  try {
    await Api.put(`/Lead/atendimento/${payload.id}`, payload);

    return {success: true};
  } catch (error: any) {
    throw new Error(`Erro ao salvar. ${error.mensagens}`);
  }
};

const getLeadIntegrate = async (id: string): Promise<IIntegrationSituation> => {
  try {
    const {data} = await Api.get(`/Lead/SituacaoDeIntegracao/${id}`);

    return data;
  } catch (error: any) {
    throw new Error(`Erro ao listar. ${error.mensagens}`);
  }
};

const patchCollegeIntegrate = async (
  id: string,
): Promise<ICollegeIntegrate> => {
  try {
    const {data} = await Api.patch(`/Lead/IntegrarComGVCollege/${id}`);

    return data;
  } catch (error: any) {
    throw new Error(`Erro ao listar. ${error.mensagens}`);
  }
};

const postAccompanimentReport = async (): Promise<string> => {
  try {
    const {data} = await Api.post('/Lead/Acompanhamento/Impressao');
    return data;
  } catch (error: any) {
    throw new Error(`Erro ao fazer download. ${error.data.data}`);
  }
};

const postSendPaymentEmailByLeadId = async (
  idLead: string,
): Promise<string> => {
  try {
    const {data} = await Api.post(`/lead/${idLead}/pagamento/enviaremail`);
    return data;
  } catch (error: any) {
    throw new Error(`Erro ao enviar email. ${error.data.data}`);
  }
};

const getPaymentInfoByLeadId = async (
  idLead: string,
): Promise<IPaymentInfo> => {
  try {
    const {data} = await Api.get(`/lead/${idLead}/pagamento`);
    return data;
  } catch (error: any) {
    throw new Error(`Erro ao listar. ${error.data.data}`);
  }
};

const patchIncreaseFinalDateByLeadId = async (payload: {
  idLead: string;
  novaDataVencimento: string;
}): Promise<string> => {
  try {
    const {data} = await Api.patch(
      `/lead/${payload.idLead}/pagamento/extendervencimento`,
      payload,
    );
    return data;
  } catch (error: any) {
    throw new Error(`Erro ao realizar alteração. ${error.data.data}`);
  }
};

export const LeadService = {
  deleteCall,
  getLeadCalls,
  getExportLeadCalls,
  getLeadsCall,
  putLeadsCall,
  postLeadsCall,
  deleteMeetLead,
  getLeadIntegrate,
  getScheduleSituations,
  patchCollegeIntegrate,
  getLeadFormulario,
  postAccompanimentReport,
  postSendPaymentEmailByLeadId,
  getPaymentInfoByLeadId,
  patchIncreaseFinalDateByLeadId,
};
