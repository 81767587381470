import React from 'react';

import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Paper,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

type ITableSkeletonProps = {
  btnAct?: number;
  columns?: number;
  rowsBody?: number;
  rowsHeader?: number;
  rectHeight?: number;
  rectWidth?: number | string;
  colStatus?: boolean;
};

export const TableSkeleton: React.FC<ITableSkeletonProps> = ({
  columns = 1,
  btnAct = 0,
  rowsHeader = 1,
  rowsBody = 1,
  rectHeight = 24,
  rectWidth = '40%',
  colStatus = false,
}) => {
  if (colStatus) columns = columns - 1;

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          {Array(rowsHeader).fill(
            <TableRow>
              {!!btnAct && (
                <TableCell width={btnAct * 27 + 48} height="42px">
                  <Skeleton width={rectWidth} height={rectHeight} />
                </TableCell>
              )}

              {Array(columns).fill(
                <TableCell height="42px">
                  <Skeleton width={rectWidth} height={rectHeight} />
                </TableCell>,
              )}
              {colStatus && (
                <TableCell width="100px">
                  <Skeleton width="75%" height={rectHeight} />
                </TableCell>
              )}
            </TableRow>,
          )}
        </TableHead>
        <TableBody>
          {Array(rowsBody).fill(
            <TableRow>
              {!!btnAct && (
                <TableCell
                  style={{display: 'inline-flex'}}
                  width={btnAct * 27 + 48}
                  height="42px">
                  {Array(btnAct).fill(
                    <Skeleton
                      style={{marginLeft: `${btnAct > 0 && '8px'}`}}
                      variant="circle"
                      width={24}
                      height={24}
                    />,
                  )}
                </TableCell>
              )}

              {Array(columns).fill(
                <TableCell height="42px">
                  <Skeleton width={rectWidth} height={rectHeight} />
                </TableCell>,
              )}
              {colStatus && (
                <TableCell>
                  <Skeleton width="75%" height={rectHeight} />
                </TableCell>
              )}
            </TableRow>,
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
