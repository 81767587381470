import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@material-ui/core';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {AvailableSchedule} from 'shared/components';
import {useUser} from 'shared/hooks';
import {feedback} from 'shared/services/alertService';
import {
  ICustomerService,
  IScheduleSituations,
  LeadService,
} from 'shared/services/api/LeadService';
import {
  AvailabilitiesService,
  IScheduleAvailability,
} from 'shared/services/api/ScheduleAvailabilityService';
import {errorResponse} from 'shared/utils/errorResponse';
import AddCircleIcon from '@material-ui/icons/AddCircle';

interface Iprops {
  idOferta: string;
  idLead: string;
  isPendete: boolean;
  trigger?: boolean;
  setTrigger: (trigger: boolean) => void;
  idSituacaoLeadFunil: string | undefined;
}

export const ModalAgendamento: React.FC<Iprops> = ({
  idOferta,
  idLead,
  isPendete,
  trigger,
  setTrigger,
  idSituacaoLeadFunil,
}) => {
  const {data: user} = useUser();
  const userTimezone = useMemo(
    () => (user?.timezone ? user.timezone : -3),
    [user?.timezone],
  );
  const [loading, setLoading] = useState(false);

  const [schedule, setSchedule] = useState<IScheduleAvailability[]>([]);

  const [openModal, setOpenModal] = useState(false);

  const [scheduleSituations, setScheduleSituations] = useState<
    IScheduleSituations[]
  >([]);

  const [error, setError] = useState(false);
  const [scheduleAvailability, setScheduleAvailability] =
    useState<IScheduleAvailability>();

  const [customerService, setCustomerService] = useState<ICustomerService>({
    usaAgendamento: false,
  });

  const getAvailabilities = useCallback(async () => {
    try {
      const response = await AvailabilitiesService.getAvailabilities(
        idOferta,
        userTimezone,
      );

      if (response) {
        setSchedule(response);
      }
    } catch (error) {
       
      feedback(errorResponse(error), 'error');
    }
  }, [idOferta, userTimezone]);

  const handleCreateLeadsCall = useCallback(async () => {
    setError(false);
    if (
      !customerService.usaAgendamento ||
      (customerService.usaAgendamento && customerService.idSituacaoDeAgenda)
    ) {
      try {
        await LeadService.postLeadsCall(customerService);
        feedback('Atendimento criado com sucesso', 'success');
      } catch (error) {
         
        feedback(errorResponse(error), 'error');
      } finally {
        setLoading(false);
        handleCloseModal();
        setTimeout(() => {
          setTrigger(!trigger);
        }, 100);
        setCustomerService({
          ...customerService,
          usaAgendamento: false,
        });
      }
    } else {
      setError(true);
    }
    //eslint-disable-next-line
  }, [customerService, trigger, setTrigger]);

  const getScheduleSituations = useCallback(async () => {
    try {
      const response = await LeadService.getScheduleSituations();

      if (response) {
        setScheduleSituations(response);
      }
    } catch (error) {
       
      feedback(errorResponse(error), 'error');
    }
  }, []);

  useEffect(() => {
    getScheduleSituations();
    getAvailabilities();
  }, [getScheduleSituations, getAvailabilities]);

  useEffect(() => {
    customerService.idLead = idLead;
  }, [idLead, customerService]);

  const handleOpenModal = useCallback(() => {
    setOpenModal(true);
    setCustomerService({
      ...customerService,
      idSituacaoDeLead: idSituacaoLeadFunil,
    });
  }, [customerService, idSituacaoLeadFunil]);

  const handleCloseModal = () => {
    setError(false);

    setOpenModal(false);

    setCustomerService({
      ...customerService,
      usaAgendamento: false,
      idSituacaoDeAgenda: undefined,
      atendimento: '',
    });

    setScheduleAvailability(undefined);
  };

  return (
    <>
      <Grid>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpenModal()}>
          <Box display={'flex'} alignItems={'center'}>
            <Box marginRight={1} marginTop={1}>
              <AddCircleIcon />
            </Box>
            <Typography>Novo atendimento</Typography>
          </Box>
        </Button>
      </Grid>

      <Dialog open={openModal} onClose={handleCloseModal} maxWidth="xl">
        <DialogTitle id="Editar atendimento">
          <Typography color="primary" variant="h6">
            Adicionar Agendamento
          </Typography>
        </DialogTitle>

        <DialogContent>
          <Grid container direction="column" xs style={{width: 600}}>
            <Grid container item xs>
              <Grid item xs>
                <Box display={'flex'} alignItems={'center'}>
                  <FormControlLabel
                    label="Efetuar agendamento"
                    disabled={isPendete}
                    control={
                      <Checkbox
                        checked={customerService.usaAgendamento}
                        onChange={({target}) => {
                          setCustomerService({
                            ...customerService,
                            usaAgendamento: target.checked,
                          });
                        }}
                      />
                    }
                  />
                  {isPendete && (
                    <Typography variant="caption" style={{color: 'orange'}}>
                      <i>O atendimento atual possui agenda pendente.</i>
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>

            {customerService.usaAgendamento && (
              <>
                <Grid container item xs>
                  <Grid item xs>
                    <Box marginTop={2}>
                      <AvailableSchedule
                        label="Agenda"
                        disabled={loading}
                        availabilities={schedule}
                        defaultValue={scheduleAvailability}
                        onChange={(value) => (
                          setScheduleAvailability(value),
                          setCustomerService({
                            ...customerService,
                            idDisponibilidadeDeAgenda:
                              value.idDisponibilidadeDeAgenda,
                            idSituacaoDeAgenda:
                              customerService.id &&
                              customerService.idDisponibilidadeDeAgenda !==
                                value.idDisponibilidadeDeAgenda
                                ? 2
                                : customerService.idSituacaoDeAgenda,
                          })
                        )}
                      />
                    </Box>
                  </Grid>
                </Grid>

                <Grid container item xs>
                  <Grid item xs>
                    <Box marginTop={2}>
                      <FormControl
                        fullWidth
                        variant="outlined"
                        error={error && !customerService.idSituacaoDeAgenda}>
                        <InputLabel>Situação do Agendamento</InputLabel>
                        <Select
                          label="Situação do Agendamento *"
                          value={customerService.idSituacaoDeAgenda}
                          onChange={({target}) =>
                            setCustomerService({
                              ...customerService,
                              idSituacaoDeAgenda: Number(target.value),
                            })
                          }>
                          {scheduleSituations?.map((item) => (
                            <MenuItem
                              key={item?.id && item?.id}
                              value={item?.id && item?.id}
                              disabled={item?.id === 2 || item?.id === 4}>
                              {item?.nome}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {error && !customerService.idSituacaoDeAgenda && (
                        <FormHelperText>
                          <Typography color="error" variant="inherit">
                            Campo obrigatório
                          </Typography>
                        </FormHelperText>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </>
            )}

            <Grid container item xs>
              <Grid item xs>
                <Box marginTop={2} marginBottom={2}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Atendimento</InputLabel>
                    <OutlinedInput
                      multiline
                      rows={4}
                      label="Atendimento"
                      type="string"
                      value={customerService.atendimento}
                      onChange={({target}) =>
                        setCustomerService({
                          ...customerService,
                          atendimento: target.value,
                        })
                      }
                    />
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Box padding={2} marginBottom={1}>
            <Button variant="outlined" onClick={handleCloseModal}>
              cancelar
            </Button>
            <Button
              style={{marginLeft: 12}}
              color="primary"
              variant="contained"
              onClick={handleCreateLeadsCall}>
              Salvar
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};
