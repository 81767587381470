import React, {useState, useCallback, useEffect, useMemo} from 'react';
import {
  Box,
  Grid,
  Paper,
  Table,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TextField,
  IconButton,
  Typography,
  FormControl,
  TableFooter,
  TableContainer,
  FormControlLabel,
  Tooltip,
} from '@material-ui/core';
import {useNavigate, useParams} from 'react-router-dom';
import {AddCircleRounded, Delete, Info} from '@material-ui/icons';

import {useTerm, useUser} from 'shared/hooks';
import {
  ICycle,
  ICourse,
  CourseService,
} from 'shared/services/api/CourseService';
import {Button, Footer, InfoTooltip, ModalConfirm} from 'shared/components';
import {feedback} from 'shared/services/alertService';
import {errorResponse} from 'shared/utils/errorResponse';

export const NewCourse: React.FC = () => {
  const [edit, setEdit] = useState(false);
  const [course, setCourse] = useState<ICourse>({
    ativo: true,
    nome: '',
    usaCiclo: false,
  } as ICourse);
  const [submitted, setSubmitted] = useState(false);
  const [cycles, setCycles] = useState<ICycle[]>([]);
  const [courses, setCourses] = useState<ICourse[]>([]);

  const {terms} = useTerm();
  const params = useParams<'id'>();
  const navigate = useNavigate();
  const {data: user} = useUser();

  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [loading, _] = useState(false);

  const permissions = useMemo(
    () => user?.permissoes.filter((i) => i.nome.includes('Curso')),
    [user?.permissoes],
  );

  const canEdit = useMemo(
    () => !!permissions?.find((i) => i.nome.includes('Editar')),
    [permissions],
  );

  const canList = useMemo(
    () => !!permissions?.find((i) => i.nome.includes('Listar')),
    [permissions],
  );

  useEffect(() => {
    if (!canList) {
      navigate('/');
    }
  }, [canList, navigate]);

  const handleGetCourse = useCallback(
    async (id: string) => {
      try {
        const response = await CourseService.getCourse(id);

        if (response) {
          setCourse(response);
          setCycles(response.ciclos);
        }
      } catch (error) {
        feedback(errorResponse(error), 'error');

        setTimeout(() => {
          navigate(-1);
        }, 500);
        setEdit(false);
      }
    },
    [navigate],
  );

  const handleGetCourses = useCallback(async () => {
    try {
      const data = await CourseService.getCourses();

      if (data) {
        setCourses(data);
      }
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
  }, []);

  const handleVerifyNameCourse = useCallback(() => {
    if (edit) {
      return (
        courses
          .filter((i) => i.id !== course.id)
          .filter(
            (i) =>
              i.nome?.toLowerCase().trim() ===
              course?.nome?.toLowerCase().trim(),
          ).length > 0
      );
    }
    return (
      courses.filter(
        (i) =>
          i.nome?.toLowerCase().trim() === course?.nome?.toLowerCase().trim(),
      ).length > 0
    );
  }, [course.id, course.nome, courses, edit]);

  const handleAddCycle = useCallback(() => {
    setCycles((state) => [
      ...state,
      {
        id: '',
        nome: '',
        ativo: true,
      },
    ]);
  }, []);

  const handleActiveCourse = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setCourse((state) => (state = {...state, ativo: event.target.checked}));
    },
    [],
  );

  const handleActiveUseCycle = useCallback(
    (event: React.ChangeEvent<HTMLInputElement> | boolean) => {
      if (
        course.ciclos?.length > 0 &&
        course.usaCiclo === true &&
        typeof event !== 'boolean'
      ) {
        setOpenModalConfirm(true);
      } else if (course.ciclos?.length === 0 && typeof event !== 'boolean') {
        setCourse(
          (state) => (state = {...state, usaCiclo: event.target.checked}),
        );
      } else if (course.usaCiclo === true && typeof event === 'boolean') {
        setCycles([]);

        setCourse((state) => (state = {...state, usaCiclo: event}));
      }
    },
    [course],
  );

  const handleChangeNameCourse = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setCourse((state) => (state = {...state, nome: event.target.value}));
    },
    [],
  );

  const handleChangeNameCycle = useCallback(
    (event, index) =>
      setCycles((state) => {
        return state.map((oldState, oldStateIndex) => {
          if (index === oldStateIndex) {
            oldState.nome = event.target.value;
          }
          return oldState;
        });
      }),
    [],
  );

  const handleDeleteCycle = useCallback((index) => {
    setCycles((state) => {
      return state.filter((_, stateIndex) => stateIndex !== index);
    });
  }, []);

  const handleActiveCycle = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, index) =>
      setCycles((state) => {
        return state.map((oldState, oldStateIndex) => {
          if (index === oldStateIndex) {
            oldState.ativo = event.target.checked;
          }
          return oldState;
        });
      }),
    [],
  );

  const handleCreateCourse = useCallback(
    async (newCourse: boolean) => {
      if (handleVerifyNameCourse()) return;

      if (course.usaCiclo && course.ciclos.length === 0) {
        feedback(`Adicione um ${terms.termoCiclo}.`, 'warning');
        return;
      }

      if (
        course.usaCiclo &&
        cycles.filter((i) => i.nome.length === 0).length > 0
      ) {
        setSubmitted(true);

        return;
      }

      if (course.nome.length === 0) {
        setSubmitted(true);

        return;
      }
      try {
        await CourseService.postCourse(course);

        feedback(`${terms.termoCurso} adicionado com sucesso`, 'success');

        if (!newCourse) {
          navigate(-1);
        } else {
          setCourse({
            ativo: true,
            nome: '',
            usaCiclo: false,
          } as ICourse);
          setCycles([]);
        }
      } catch (error) {
        feedback(errorResponse(error), 'error');
      } finally {
        setSubmitted(false);
      }
    },
    [
      handleVerifyNameCourse,
      course,
      cycles,
      terms.termoCiclo,
      terms.termoCurso,
      navigate,
    ],
  );

  const handleUpdateCourse = useCallback(async () => {
    if (handleVerifyNameCourse()) return;

    if (
      course.usaCiclo &&
      cycles.filter((i) => i.nome.length === 0).length > 0
    ) {
      setSubmitted(true);

      return;
    }

    if (course.nome.length === 0) {
      setSubmitted(true);

      return;
    }
    try {
      await CourseService.updateCourse(course.id, course);

      feedback(`${terms.termoCurso} atualizado com sucesso`, 'success');

      navigate(-1);
    } catch (error) {
      feedback(errorResponse(error), 'error');
    } finally {
      setSubmitted(false);
    }
  }, [handleVerifyNameCourse, course, cycles, terms.termoCurso, navigate]);

  useEffect(() => {
    if (params.id) {
      handleGetCourse(params.id);
      setEdit(true);
    }
  }, [handleGetCourse, params]);

  useEffect(() => {
    setCourse(
      (state) =>
        (state = {
          ...state,
          ciclos: cycles,
        }),
    );
  }, [cycles]);

  useEffect(() => {
    handleGetCourses();
  }, [handleGetCourses]);

  return (
    <>
      <Typography variant="h5" color="primary">
        <strong>Dados do {terms?.termoCurso?.toLowerCase()}</strong>
      </Typography>

      <FormControl component="fieldset" fullWidth>
        <Grid container component={Box} gridGap={8} marginTop={3}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={edit && !canEdit}
                  checked={course.ativo}
                  onChange={handleActiveCourse}
                />
              }
              label="Ativo"
            />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={edit && !canEdit}
                  checked={course.usaCiclo}
                  onChange={handleActiveUseCycle}
                  name="cyclesActive"
                />
              }
              label={`Usa ${terms?.termoCiclo?.toLocaleLowerCase()}`}
            />
            <Box marginBottom={1.6} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant="outlined"
              disabled={edit && !canEdit}
              value={course.nome}
              label={`${terms.termoCurso} *`}
              onChange={handleChangeNameCourse}
              error={(!course.nome && submitted) || handleVerifyNameCourse()}
              helperText={
                !course.nome && submitted
                  ? 'Campo requerido'
                  : handleVerifyNameCourse() &&
                    `O nome do ${terms.termoCurso} deve ser único.`
              }
            />
            <Box marginBottom={3} />
          </Grid>

          <Grid item xs={12}>
            {course.usaCiclo && (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell width="100px">Ações</TableCell>
                      <TableCell>{terms.termoCiclo}</TableCell>
                      <TableCell width="100px">Ativo</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {cycles?.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Box display="flex" justifyContent="space-between">
                            <IconButton
                              color="primary"
                              disabled={edit && !canEdit}
                              onClick={() => handleDeleteCycle(index)}>
                              <Delete />
                            </IconButton>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <TextField
                            fullWidth
                            size="small"
                            value={item.nome}
                            variant="outlined"
                            disabled={edit && !canEdit}
                            onChange={(event) =>
                              handleChangeNameCycle(event, index)
                            }
                            error={
                              (!item.nome && submitted) ||
                              cycles
                                .filter((_, i) => i !== index)
                                .filter(
                                  (i) =>
                                    i.nome?.toLowerCase().trim() ===
                                    item.nome?.toLowerCase().trim(),
                                ).length > 0
                            }
                            helperText={
                              !item.nome && submitted
                                ? 'Campo requerido'
                                : cycles
                                    .filter((_, i) => i !== index)
                                    .filter(
                                      (i) =>
                                        i.nome?.toLowerCase().trim() ===
                                        item.nome?.toLowerCase().trim(),
                                    ).length > 0 &&
                                  `O nome do ${terms.termoCiclo} deve ser único por ${terms.termoCurso}.`
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <Box display="flex" justifyContent="space-between">
                            <Checkbox
                              disabled={edit && !canEdit}
                              checked={item.ativo}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>,
                              ) => handleActiveCycle(event, index)}
                            />
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>

                <Table>
                  <TableFooter>
                    <TableCell>
                      <Button
                        variant="text"
                        disabled={edit && !canEdit}
                        onClick={handleAddCycle}
                        textTransform="uppercase"
                        startIcon={<AddCircleRounded />}
                        label={`Adicionar ${terms.termoCiclo}`}
                      />
                    </TableCell>
                  </TableFooter>
                </Table>
              </TableContainer>
            )}
          </Grid>
        </Grid>
      </FormControl>

      <Box marginY={14} />

      <Footer
        disabled={edit && !canEdit}
        onCancel={() => navigate(-1)}
        onSaveAndNew={edit ? undefined : () => handleCreateCourse(true)}
        onSave={edit ? handleUpdateCourse : () => handleCreateCourse(false)}
      />

      <ModalConfirm
        title="Atenção!"
        loading={loading}
        opened={openModalConfirm}
        labelSaveButton="Continuar"
        onClose={() => setOpenModalConfirm(false)}
        onClick={() => (
          handleActiveUseCycle(false), setOpenModalConfirm(false)
        )}>
        <Typography>
          Ao desativar o uso de ciclos, todos os ciclos registrados abaixo serão
          deletados.
        </Typography>
        <br />
        <Typography>Deseja prosseguir mesmo assim?</Typography>
      </ModalConfirm>
    </>
  );
};
