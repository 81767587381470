import {IRequestResult} from 'shared/interfaces/IRequestResult';
import {Api} from 'shared/services/axiosConfig';

export interface ILossReasons {
  id: string;
  descricao: string;
  assuntoEmailNotificacao?: string;
  mensagemEmailNotificacao?: string;
  qtdeDiasAteEnvioNotificacao?: any;
  enviarNotificacao?: boolean;
}

const getLossReasons = async (): Promise<ILossReasons[]> => {
  try {
    const response = await Api.get('/MotivoPerda');
    return response.data;
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const postLossReason = async (
  newLossReason: Omit<ILossReasons, 'id'>,
): Promise<IRequestResult> => {
  try {
    await Api.post('/MotivoPerda', newLossReason);
    return {success: true};
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const putLossReason = async (
  lossReason: ILossReasons,
): Promise<IRequestResult> => {
  try {
    await Api.put(`MotivoPerda/${lossReason.id}`, lossReason);
    return {success: true};
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const deleteLossReason = async (
  LossReasonId: string,
): Promise<IRequestResult> => {
  try {
    await Api.delete(`MotivoPerda/${LossReasonId}`);
    return {success: true};
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

export const LossReasonsService = {
  getLossReasons,
  postLossReason,
  putLossReason,
  deleteLossReason,
};
