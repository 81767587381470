import React, {useEffect, useMemo, useState} from 'react';
import {NavLink} from 'react-router-dom';

import {
  InputAdornment,
  ListItemIcon,
  ListItemText,
  Typography,
  TextField,
  ListItem,
  useTheme,
  Drawer,
  Icon,
  List,
  Box,
} from '@material-ui/core';
import clsx from 'clsx';
import {useAuth} from 'shared/hooks';
import {feedback} from 'shared/services/alertService';
import {IMenuOption} from 'shared/services/api/MenuService';

import {SkeletonMenu} from '../SkeletonMenu';
import {useStyles} from './styles';

interface IProps {
  drawerOpen: boolean;
  buttonActive: boolean;
  menuOptions?: IMenuOption[];
  onMouseEnter: () => void | undefined;
  onMouseLeave: () => void | undefined;
}

export const DrawerComponent: React.FC<IProps> = ({
  drawerOpen,
  buttonActive,
  menuOptions,
  onMouseEnter,
  onMouseLeave,
}) => {
  const [filter, setFilter] = useState('');

  const classes = useStyles();
  const theme = useTheme();
  const {signOut} = useAuth();

  const filteredMenuOptions = useMemo(() => {
    if (menuOptions) {
      return menuOptions.filter((item) =>
        item.label.toLowerCase().includes(filter.toLowerCase()),
      );
    }
    feedback('Erro ao carregar as opções de menu.', 'error');
    return [];
  }, [filter, menuOptions]);

  return (
    <Drawer
      onMouseEnter={buttonActive ? undefined : onMouseEnter}
      onMouseLeave={buttonActive ? undefined : onMouseLeave}
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: drawerOpen,
        [classes.drawerClose]: !drawerOpen,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: drawerOpen,
          [classes.drawerClose]: !drawerOpen,
        }),
      }}>
      <List className={classes.list}>
        {!menuOptions && <SkeletonMenu />}
        {menuOptions && menuOptions.length === 0 && (
          <Box paddingX={1} display="flex" justifyContent="center">
            <Typography variant="caption">Não há opções de menu...</Typography>
          </Box>
        )}
        <ListItem
          className={clsx(classes.inputBoxSearch, {
            [classes.inputBoxSearchOpen]: drawerOpen,
            [classes.inputBoxSearchClose]: !drawerOpen,
          })}
          classes={{
            root: clsx({
              [classes.inputBoxSearchOpen]: drawerOpen,
              [classes.inputBoxSearchClose]: !drawerOpen,
            }),
          }}>
          {drawerOpen ? (
            <TextField
              fullWidth
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              variant="outlined"
              className={classes.inputSearch}
              placeholder="Buscar"
              size="small"
              InputProps={{
                classes: {
                  root: classes.inputSearch,
                  input: classes.input,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon
                      fontSize="default"
                      className={classes.iconInputSearch}>
                      search
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />
          ) : (
            <ListItemIcon className={classes.iconSearch}>
              <Icon fontSize="default" className={classes.icons}>
                search
              </Icon>
            </ListItemIcon>
          )}
        </ListItem>
        {drawerOpen && menuOptions && filteredMenuOptions.length === 0 && (
          <Box paddingX={1} display="flex" justifyContent="center">
            <Typography variant="caption" color="initial">
              Sem resultados para <strong>&quot;{filter}&quot;</strong>
            </Typography>
          </Box>
        )}
        <Box marginTop={6.8} />
        {menuOptions &&
          filteredMenuOptions?.map((item) => {
            return (
              <ListItem
                to={item.path}
                key={item.id}
                button
                component={NavLink as any}
                className={classes.listItem}
                style={
                  (({isActive}: any) =>
                    isActive
                      ? {
                          backgroundColor: theme.palette.divider,
                          color: theme.palette.getContrastText,
                          fontWeight: 'bold',
                          borderLeftWidth: 4,
                          borderLeftColor: theme.palette.getContrastText,
                          borderLeftStyle: 'solid',
                        }
                      : {}) as any
                }
                onClick={() => setFilter('')}>
                <ListItemIcon>
                  <Icon fontSize="default" className={classes.icons}>
                    {item.icon}
                  </Icon>
                </ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItem>
            );
          })}
        <List
          style={{
            height: '150px',
            width: '100%',
          }}></List>
        <ListItem
          button
          onClick={signOut}
          className={clsx(classes.listItemLogout, {
            [classes.listItemLogoutOpen]: drawerOpen,
            [classes.listItemLogoutClose]: !drawerOpen,
          })}
          classes={{
            root: clsx({
              [classes.listItemLogoutOpen]: drawerOpen,
              [classes.listItemLogoutClose]: !drawerOpen,
            }),
          }}>
          <ListItemIcon>
            <Icon fontSize="default" className={classes.icons}>
              logout
            </Icon>
          </ListItemIcon>
          <ListItemText primary="Sair" />
        </ListItem>
      </List>
    </Drawer>
  );
};
