import React, {useState, useCallback} from 'react';
import {Box, FormControl, MenuItem, TextField} from '@material-ui/core';
import {Modal} from 'shared/components';
import {ICampaign} from 'shared/services/api/CampaignService';

interface IProps {
  openModal: boolean;
  loading: boolean;
  onClose(): void;
  onClick(): void;
  campaigns: ICampaign[];
  idCampaign: string;
  setIdCampaign(value: string): void;
}

export const ModalSelectCampaign: React.FC<IProps> = ({
  openModal,
  loading,
  onClose,
  onClick,
  campaigns,
  idCampaign,
  setIdCampaign,
}) => {
  const [submitted, setSubmitted] = useState(false);

  const handleOnClick = useCallback(() => {
    setSubmitted(true);

    if (idCampaign) {
      onClick();
    }
  }, [idCampaign, onClick]);

  const handleOnClose = useCallback(() => {
    setSubmitted(false);
    setIdCampaign('');

    onClose();
  }, [onClose, setIdCampaign]);

  return (
    <Modal
      opened={openModal}
      maxWidth="xs"
      labelSaveButton="Avançar"
      labelCloseButton="Fechar"
      title="Selecione a campanha"
      loading={loading}
      onClose={handleOnClose}
      onClick={handleOnClick}>
      <Box marginTop={3} />
      <FormControl component="fieldset" fullWidth>
        <TextField
          variant="outlined"
          error={!idCampaign && submitted}
          helperText={!idCampaign && 'Selecione uma campanha'}
          value={idCampaign}
          label="Campanha"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setIdCampaign(event.target.value);
          }}
          fullWidth
          select>
          <MenuItem value="">Selecione aqui</MenuItem>
          {campaigns?.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.nomeCompleto}
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
    </Modal>
  );
};
