import React, {useCallback, useState} from 'react';

import {Container} from '@material-ui/core';
import {useTerm, useDrawer} from 'shared/hooks';

import {
  AlertComponent,
  AppBarComponent,
  ConfirmationDialogComponent,
  DrawerComponent,
} from './components';
import {useStyles} from './styles';

export const Layout: React.FC = ({children}) => {
  const {drawerOpen, setDrawerOpen} = useDrawer();
  const [buttonActive, setButtonActive] = useState(false);

  const {menuOptions} = useTerm();
  const classes = useStyles();

  const handleDrawerOpen = useCallback(() => {
    setDrawerOpen(!drawerOpen);
  }, [drawerOpen, setDrawerOpen]);

  return (
    <div className={classes.root}>
      <AppBarComponent
        handleDrawerOpen={handleDrawerOpen}
        drawerOpen={drawerOpen}
        buttonActive={buttonActive}
        setButtonActive={setButtonActive}
      />

      <DrawerComponent
        menuOptions={menuOptions}
        drawerOpen={drawerOpen}
        onMouseEnter={() => setDrawerOpen(true)}
        onMouseLeave={() => setDrawerOpen(false)}
        buttonActive={buttonActive}
      />

      <main className={classes.content}>
        <Container>
          <>{children}</>
        </Container>
      </main>

      <AlertComponent />
      <ConfirmationDialogComponent />
    </div>
  );
};
