import {ptBR} from '@mui/x-data-grid';
import {ptBR as CorePtBR} from '@material-ui/core/locale';
import {createMuiTheme} from '@material-ui/core/styles';

export const LightTheme = createMuiTheme(
  {
    palette: {
      primary: {
        main: '#00D4A7',
        light: '#339F9A',
        dark: '#005E5A',
        contrastText: '#ffff',
      },
      secondary: {
        main: '#01B9AD ',
        light: '#33C7BD',
        dark: '#008179',
        contrastText: '#616161',
      },
      error: {
        main: '#F50000',
        light: '#F73333',
        dark: '#AB0000',
      },
      warning: {
        main: '#FFAC33',
        light: '#FF9800',
        dark: '#B26A00',
      },
      success: {
        main: '#33B673',
        light: '#00A451',
        dark: '#007238',
      },
      info: {
        main: '#018781',
        light: '#339F9A',
        dark: '#005E5A',
      },
      text: {
        primary: '#414141',
      },
      background: {
        default: '#F8F8F8',
        paper: '#FFFFFF',
      },
    },
    overrides: {
      MuiMenuItem: {
        root: {
          '&:hover': {
            backgroundColor: '#01B9AD',
            color: '#FFFFFF',
          },
          '&$selected': {
            backgroundColor: '#EEEEEE',
            color: '#616161',
            '&:hover': {
              backgroundColor: '#33C7BD',
              color: '#FFFFFF',
            },
          },
        },
      },
      MuiAccordion: {
        root: {
          border: '1px solid rgba(0, 0, 0, .125)',
          boxShadow: 'none',
          '&:not(:last-child)': {
            borderBottom: 0,
          },
          '&:before': {
            display: 'none',
          },
          '&$expanded': {
            margin: 'auto',
            borderTop: '1px solid #018781',
          },
        },
      },
      MuiAccordionSummary: {
        root: {
          '&$expanded': {
            borderBottom: '1px solid rgba(0, 0, 0, .125)',
          },
        },
      },
      MuiTableHead: {
        root: {
          backgroundColor: '#E0E0E0',
          height: 40,
        },
      },
      MuiStepper: {
        root: {
          backgroundColor: 'transparent',
          padding: '24px 0',
        },
      },
      MuiSvgIcon: {
        root: {
          // color: '#018781',
        },
      },
      MuiListSubheader: {
        root: {
          color: '#018781',
          fontWeight: 'bold',
          fontSize: 16,
          backgroundColor: '#fff !important',
        },
      },
      MuiSlider: {
        rail: {
          height: 8,
          borderRadius: 4,
        },
        track: {
          height: 8,
          borderRadius: 4,
        },
        root: {
          height: 8,
        },
      },
    },
    props: {
      MuiCheckbox: {
        color: 'primary',
      },
      MuiButton: {
        size: 'small',
      },
      MuiIconButton: {
        size: 'small',
      },
      MuiListItem: {
        dense: true,
      },
      MuiFab: {
        size: 'small',
      },
      MuiTable: {
        size: 'small',
      },
      MuiToolbar: {
        variant: 'dense',
      },
      MuiIcon: {
        fontSize: 'small',
      },
    },
  },
  ptBR,
  CorePtBR
);
