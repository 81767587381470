import React, {useCallback, useEffect, useState} from 'react';
import {
  Box,
  Grid,
  Table,
  Paper,
  MenuItem,
  TableRow,
  TextField,
  TableCell,
  TableBody,
  Typography,
  IconButton,
  TableContainer,
} from '@material-ui/core';
import {TreeItem, TreeView} from '@material-ui/lab';
import {ExpandMore, ChevronRight, Edit} from '@material-ui/icons';

import {Modal, RichText} from 'shared/components';
import {
  IUnityEstablishment,
  EstablishmentService,
} from 'shared/services/api/EstablishmentService';
import {
  IEmailAccount,
  GeneralConfigService,
  IConfigByEstablishment,
} from 'shared/services/api/GeneralConfigService';
import {feedback} from 'shared/services/alertService';
import {errorResponse} from 'shared/utils/errorResponse';

interface IProps {
  canEdit: boolean;
  configByEstablishment: IConfigByEstablishment[];
  setConfigByEstablishment(value: IConfigByEstablishment[]): void;
}

export const EmailAccounts: React.FC<IProps> = ({
  canEdit,
  configByEstablishment,
  setConfigByEstablishment,
}) => {
  const [sent, setSent] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [emailAccounts, setEmailAccounts] = useState<IEmailAccount[]>([]);
  const [establishments, setEstablishments] = useState<IUnityEstablishment[]>(
    [],
  );
  const [currentConfig, setCurrentConfig] = useState<IConfigByEstablishment>(
    {} as IConfigByEstablishment,
  );

  const EMAIL_REGEX = /(<([^>]+)>)/gi;

  const getTreeEstablishments = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await EstablishmentService.getTreeEstablishments();

      if (response) {
        setEstablishments(response.estabelecimentos);
      }
    } catch (error) {
      feedback(errorResponse(error), 'error');
    } finally {
      setIsLoading(false);
    }
  }, []);

  const getConfigByEstablishment = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await GeneralConfigService.getConfigByEstablishment();

      if (response) {
        setConfigByEstablishment(response);
      }
    } catch (error) {
      feedback(errorResponse(error), 'error');
    } finally {
      setIsLoading(false);
    }
  }, [setConfigByEstablishment]);

  const getEmailAccounts = useCallback(async (idEstabelecimento: string) => {
    setIsLoading(true);
    try {
      const response = await GeneralConfigService.getEmailAccounts(
        idEstabelecimento,
      );

      if (response) {
        setEmailAccounts(response);
      }
    } catch (error) {
      feedback(errorResponse(error), 'error');
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleOpenModal = useCallback(
    async (establishment: IUnityEstablishment) => {
      await getEmailAccounts(establishment.idEstabelecimento);

      const isEqual = (emailAccountConfig: IConfigByEstablishment) =>
        emailAccountConfig.idEstabelecimento ===
        establishment.idEstabelecimento;

      const emailAccountConfig = configByEstablishment.find(isEqual);

      if (emailAccountConfig) {
        setCurrentConfig(emailAccountConfig);
      } else {
        setCurrentConfig({
          id: establishment.id,
          idEstabelecimento: establishment.idEstabelecimento,
          idEstruturadoEstabelecimento:
            establishment.idAlternativoDoEstabelecimento,
          nomeEstabelecimento: establishment.nome,
        } as IConfigByEstablishment);
      }
      setOpenModal(true);
    },
    [getEmailAccounts, configByEstablishment],
  );

  const handleEmailSelect = useCallback(
    (event) => {
      const isEqual = (emailAccount: IEmailAccount) =>
        emailAccount.idConta == event.target.value;

      const emailAccount = emailAccounts.find(isEqual);

      setCurrentConfig({
        ...currentConfig,
        idContaDeEmail: emailAccount ? emailAccount.idConta : 0,
        nomeContaDeEmail: emailAccount ? emailAccount.nomeConta : '',
      });
    },
    [currentConfig, emailAccounts],
  );

  const isTextoTermoLGPDVerifier = useCallback(
    (texo: string) => (texo?.replace(EMAIL_REGEX, '') === '' ? '' : texo),
    [EMAIL_REGEX],
  );

  const handleSave = useCallback(() => {
    if (currentConfig.idContaDeEmail) {
      const possuiTermoLgpd =
        currentConfig.textoTermoLgpd?.replace(EMAIL_REGEX, '') !== '';
      setSent(false);

      if (currentConfig.textoTermoLgpd === '') {
        currentConfig.textoTermoLgpd?.replace(EMAIL_REGEX, '');
      }

      const isEqual = (emailAccountConfig: IConfigByEstablishment) =>
        emailAccountConfig.idEstabelecimento ===
        currentConfig.idEstabelecimento;

      const index = configByEstablishment.findIndex(isEqual);

      if (index !== -1) {
        const list = configByEstablishment;
        list[index] = {
          ...currentConfig,
          possuiTermoLgpd: possuiTermoLgpd,
          textoTermoLgpd: isTextoTermoLGPDVerifier(
            currentConfig.textoTermoLgpd,
          ),
        };
        setConfigByEstablishment([...list]);
      } else {
        setConfigByEstablishment([...configByEstablishment, currentConfig]);
      }
      setOpenModal(!openModal);
    } else {
      setSent(true);
    }
  }, [
    currentConfig,
    EMAIL_REGEX,
    configByEstablishment,
    openModal,
    isTextoTermoLGPDVerifier,
    setConfigByEstablishment,
  ]);

  const renderEmail = useCallback(
    (idEstabelecimento: string) => {
      const isEqual = (emailAccountConfig: IConfigByEstablishment) =>
        emailAccountConfig.idEstabelecimento === idEstabelecimento;

      const emailAccountConfig = configByEstablishment.find(isEqual);

      if (emailAccountConfig && emailAccountConfig.nomeContaDeEmail !== '') {
        return (
          <Typography variant="body2">
            {emailAccountConfig.nomeContaDeEmail}
          </Typography>
        );
      } else {
        return <Typography variant="body2">-</Typography>;
      }
    },
    [configByEstablishment],
  );

  const renderTermo = useCallback(
    (idEstabelecimento: string) => {
      const isEqual = (termoAccountConfig: IConfigByEstablishment) =>
        termoAccountConfig.idEstabelecimento === idEstabelecimento;

      const termoAccountConfig = configByEstablishment.find(isEqual);

      if (
        termoAccountConfig &&
        termoAccountConfig.possuiTermoLgpd === true &&
        termoAccountConfig.textoTermoLgpd !== '<p><span></span></p>' &&
        termoAccountConfig.textoTermoLgpd !==
          // eslint-disable-next-line no-useless-escape
          `<p><span></span><a href=\"https://transform.tools/json-to-typescript\"><span></span></a><span></span></p>`
      ) {
        return <Typography variant="body2">Termo LGPD configurado</Typography>;
      } else {
        return <Typography variant="body2"></Typography>;
      }
    },
    [configByEstablishment],
  );

  const renderEstablishments = useCallback(
    (establishment: IUnityEstablishment) => {
      return (
        <TreeItem
          onLabelClick={(e) => {
            e.preventDefault();
          }}
          key={establishment.idEstabelecimento}
          nodeId={establishment.idEstabelecimento}
          label={
            <Box
              display="flex"
              alignItems="center"
              paddingTop={0.5}
              paddingBottom={0.5}>
              <Box width="100%">
                <Typography variant="body2">
                  {establishment.idComNome}
                </Typography>
              </Box>

              <Box width="100%">
                <Typography variant="body2">
                  {renderTermo(establishment.idEstabelecimento)}
                </Typography>
              </Box>

              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                minWidth="450px">
                <Box justifyContent={'space-between'} display={'flex'}>
                  {renderEmail(establishment.idEstabelecimento)}
                </Box>
                {establishment.numeroDoNivel && (
                  <IconButton onClick={() => handleOpenModal(establishment)}>
                    <Edit color="primary" />
                  </IconButton>
                )}
              </Box>
            </Box>
          }>
          {establishment.unidades.length > 0
            ? establishment.unidades.map((item) => renderEstablishments(item))
            : null}
        </TreeItem>
      );
    },
    [handleOpenModal, renderEmail, renderTermo],
  );

  useEffect(() => {
    getTreeEstablishments();
    getConfigByEstablishment();
  }, [getTreeEstablishments, getConfigByEstablishment]);

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell align="left">
                <TreeView
                  defaultCollapseIcon={<ExpandMore />}
                  defaultExpandIcon={<ChevronRight />}
                  disableSelection>
                  {isLoading ? (
                    'Carregando...'
                  ) : establishments && establishments.length > 0 ? (
                    establishments.map((item) => renderEstablishments(item))
                  ) : (
                    <Box padding={2}>
                      <Typography color="secondary">
                        Nenhum registro encontrado...
                      </Typography>
                    </Box>
                  )}
                </TreeView>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Modal
        maxWidth="md"
        opened={openModal}
        disabled={!canEdit}
        loading={isLoading}
        onClick={handleSave}
        title="Contato e LGPD"
        labelSaveButton="Salvar"
        labelCloseButton="Cancelar"
        onClose={() => (setOpenModal(!openModal), setSent(false))}>
        <Box marginTop={3} />

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              select
              fullWidth
              variant="outlined"
              label="Contas de e-mail *"
              onChange={handleEmailSelect}
              disabled={isLoading || !canEdit}
              value={currentConfig.idContaDeEmail}
              error={!currentConfig.idContaDeEmail && sent}
              helperText={
                !currentConfig.idContaDeEmail && sent && 'Campo obrigatório'
              }>
              {emailAccounts.length > 0
                ? emailAccounts.map((emailAccount) => (
                    <MenuItem
                      key={emailAccount.idConta}
                      value={emailAccount.idConta}>
                      {emailAccount.nomeConta}
                    </MenuItem>
                  ))
                : 'Não há e-mails.'}
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="E-mail"
              variant="outlined"
              value={currentConfig.email}
              disabled={isLoading || !canEdit}
              onChange={({target}) =>
                setCurrentConfig(
                  (state) => (state = {...state, email: target.value}),
                )
              }
              helperText={!currentConfig.email && sent && 'Campo obrigatório'}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="Telefone 1"
              disabled={isLoading || !canEdit}
              value={currentConfig.telefone1}
              onChange={({target}) =>
                setCurrentConfig(
                  (state) => (state = {...state, telefone1: target.value}),
                )
              }
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="Telefone 2"
              disabled={isLoading || !canEdit}
              value={currentConfig.telefone2}
              onChange={({target}) =>
                setCurrentConfig(
                  (state) => (state = {...state, telefone2: target.value}),
                )
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Site"
              variant="outlined"
              value={currentConfig.site}
              disabled={isLoading || !canEdit}
              onChange={({target}) =>
                setCurrentConfig(
                  (state) => (state = {...state, site: target.value}),
                )
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Typography color="primary" variant="h5">
              Termo LGPD
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <RichText
              disableMacros
              value={currentConfig.textoTermoLgpd}
              disabled={isLoading || !canEdit}
              onChange={(target) =>
                setCurrentConfig(
                  (state) => (state = {...state, textoTermoLgpd: target}),
                )
              }
            />
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};
