import React, {useCallback, useState, useEffect, useMemo} from 'react';
import cloneDeep from 'lodash/cloneDeep';
import {
  Box,
  Grid,
  Checkbox,
  Typography,
  FormControl,
  FormControlLabel,
  TextField,
  MenuItem,
  makeStyles,
  Select,
  Button,
  IconButton,
  ListSubheader,
} from '@material-ui/core';
import {Modal} from 'shared/components';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import {
  ILead,
  ILeadCategory,
  ITarefa,
} from 'shared/services/api/LeadSituationService';
import { IFunnel, IFunnelPost, IFunnelPut, IFunnelHandler } from 'shared/services/api/FunnelService/types';
import { useUser } from 'shared/hooks';
import { EstablishmentService, IUnityEstablishment } from 'shared/services/api/EstablishmentService';
import { feedback } from 'shared/services/alertService';
import { errorResponse } from 'shared/utils/errorResponse';
import { FUNNEL_HANDLER_DEFAULT } from 'pages/Funnel/constants';

interface IProps {
  openModal: boolean;
  loading: boolean;
  submitted: boolean;
  nameVerify: boolean;
  edit: boolean;
  title: string;
  onClose(): void;
  onClick(): void;
  funnel: IFunnel;
  funnelHandler: IFunnelHandler;
  setFunnelHandler: (prevState: IFunnelHandler) => void;
}

export const ModalCreateFunnel: React.FC<IProps> = ({
  openModal,
  title,
  loading,
  submitted,
  nameVerify,
  edit,
  onClose,
  onClick,
  funnel,
  funnelHandler,
  setFunnelHandler,
}) => {
  const {data: user} = useUser();
  const [establishments, setEstablishments] = useState<
  Omit<IUnityEstablishment[], "unidades">
  >([])

  const permissions = useMemo(
    () => user?.permissoes.filter((i) => i.nome.includes('Lead')),
    [user?.permissoes],
  );

  const permissionRegister = useMemo(
    () => permissions?.find((i) => i.nome.includes('Incluir')),
    [permissions],
  );

  const getEstablishments = useCallback(async () => {
    try {
      const response = await EstablishmentService.getEstablishments(
        permissionRegister?.id,
      );

      if (response) {
        setEstablishments(response.estabelecimentos);
      }
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
  }, [permissionRegister?.id]);

  useEffect(() => {
    getEstablishments();
  }, [getEstablishments])

  return (
    <Modal
      opened={openModal}
      maxWidth="sm"
      title={title}
      loading={loading}
      onClose={() => {
        setFunnelHandler(FUNNEL_HANDLER_DEFAULT);
        onClose(); 
      }}
      onClick={() => {
        setFunnelHandler(FUNNEL_HANDLER_DEFAULT);
        onClick();
      }}
      labelSaveButton={'Salvar'}
      labelCloseButton={'Cancelar'}
    >
      <FormControl component="fieldset" fullWidth>
        <Box marginTop={2} />

        <Grid container item>
          <Box width="100%" display={'flex'}>
            <Grid item xs>
              <Box marginLeft={2}>
                <FormControl size="small" variant="outlined" fullWidth>
                  <InputLabel>Nome do Funil</InputLabel>
                  <OutlinedInput
                    label="Nome do Funil"
                    type="string"
                    value={funnelHandler.nome}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setFunnelHandler({
                        ...funnelHandler,
                        nome: event.target.value,
                      });
                    }}
                  />
                </FormControl>
                <Box marginTop={3} />
                {!edit && (
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                    fullWidth
                    select
                    size="small"
                    value={`${funnelHandler.idEstabelecimento},${funnelHandler.nivelEstabelecimentoDono}`}
                    label="Estabelecimento"
                    variant="outlined"
                    disabled={loading}
                    onChange={({ target }) => {
                      setFunnelHandler({
                        ...funnelHandler,
                        idEstabelecimento: target.value.split(',')[0],
                        nivelEstabelecimentoDono: Number(target.value.split(',')[1])
                      });
                    }}>
                    {establishments.map((establishment, index) => {
                      if (establishment.idEstabelecimento) {
                        return (
                          <MenuItem
                            key={index}
                            value={`${establishment.idEstabelecimento},${establishment.numeroDoNivel}`}
                          >
                            {establishment.nome}
                          </MenuItem>
                        );
                      }
                      return (
                        <ListSubheader key={establishment.id}>
                          {establishment.nome}
                        </ListSubheader>
                      );
                    })}
                    </TextField>
                  </FormControl>
                )}
              </Box>
            </Grid>
          </Box>
        </Grid>
      </FormControl>
    </Modal>
  );
};
