import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Box, Grid, TableCell, TableRow, TextField} from '@material-ui/core';

import {useTitle, useUser} from 'shared/hooks';
import {feedback} from 'shared/services/alertService';
import {
  CampaignService,
  ICampaignIntegration,
  ICollegeIntegration,
  IListFieldsIntegration,
} from 'shared/services/api/CampaignService';
import {errorResponse} from 'shared/utils/errorResponse';
import {StepperCampaign} from 'pages/Campaign/components/StepperCampaign';
import {AutoComplete, Footer, Table, TableSkeleton} from 'shared/components';

export const CampaignIntegration: React.FC = () => {
  const {id = '0'} = useParams<'id'>();
  const {setTitle} = useTitle();
  const {data: user} = useUser();
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(true);
  const [loading, setLoading] = useState(false);
  const [nameCampaign, setNameCampaign] = useState('');
  const [data, setData] = useState<ICampaignIntegration[]>([]);
  const [options, setOptions] = useState<ICollegeIntegration[]>([]);

  const permissions = useMemo(
    () => user?.permissoes.filter((i) => i.nome.includes('Campanha')),
    [user?.permissoes],
  );

  const canList = useMemo(
    () => !!permissions?.find((i) => i.nome.includes('Listar')),
    [permissions],
  );

  const handleGetFormIntegration = useCallback(async () => {
    try {
      const responseData = await CampaignService.getCampaignIntegration(id);
      const responseOptions = await CampaignService.getCollegeIntegration();
      if (responseData && responseOptions) {
        setData(responseData);
        setOptions(responseOptions);
      }
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
  }, [id]);

  const dataLength = useMemo(() => {
    return data.length;
  }, [data.length]);

  const optionsFiltered = useMemo(() => {
    const optionsSelected = data.flatMap((item) => item.equivalencias);
    const optionsFiltered = options.filter((option) => {
      const isSelected = optionsSelected.some(
        (optionSelected) => optionSelected.nome === option.nome,
      );
      return !isSelected;
    });
    return optionsFiltered;
  }, [data, options]);

  const handleSetData = useCallback(
    (value: any, index: number) => {
      setData((state) =>
        [...state, (state[index].equivalencias = value)].slice(0, dataLength),
      );
    },
    [dataLength],
  );

  const handleGetCampaign = useCallback(async () => {
    try {
      const response = await CampaignService.getCampaign({
        id,
        verificarPermissaoEditar: true,
      });
      if (response) {
        setIsEditing(response.permiteEditar);
        setNameCampaign(response.nome);
      }
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
  }, [id]);

  const handleOnSubmit = useCallback(async () => {
    if (!isEditing) {
      navigate('/campanha');
    }
    setLoading(true);
    const listaDeCampos: IListFieldsIntegration[] = data.flatMap((item) => {
      let aux: IListFieldsIntegration[] = [];
      if (item.equivalencias?.length > 0) {
        item.equivalencias.map((i) => {
          aux = [
            ...aux,
            {
              idCampoDaFicha: item.idCampo,
              idCampoDeIntegracao: i.idCampoDeIntegracao,
              idSistemaExterno: i.idSistemaExterno,
              nomeCampoDeIntegracao: i.nome,
            },
          ];
        });
      }
      return aux;
    });
    try {
      await CampaignService.postCampaignIntegration({
        idCampanha: id,
        listaDeCampos,
      });
      navigate('/campanha');
    } catch (error) {
      feedback(errorResponse(error), 'error');
    } finally {
      setLoading(false);
    }
  }, [isEditing, data, navigate, id]);

  const handleGets = useCallback(async () => {
    setLoading(true);
    try {
      handleGetCampaign();
      await handleGetFormIntegration();
    } catch (error) {
      feedback(errorResponse(error), 'error');
    } finally {
      setLoading(false);
    }
  }, [handleGetCampaign, handleGetFormIntegration]);

  useEffect(() => {
    setTitle(`Campanha ${nameCampaign} | Integração com gvCollege`);
  }, [setTitle, nameCampaign]);

  useEffect(() => {
    if (!canList) {
      navigate('/');
    }
  }, [canList, navigate]);

  useEffect(() => {
    handleGets();
  }, [handleGets]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StepperCampaign
            integrated
            activeStep={6}
            idCampaign={id}
            loading={loading}
          />
          <Box marginBottom={1} />
        </Grid>
        <Grid item xs={12}>
          {loading ? (
            <TableSkeleton columns={3} rowsBody={10} />
          ) : (
            <Table
              head={[
                {label: 'Campo da ficha', width: '200px'},
                {label: 'Seção da ficha', width: '200px'},
                {label: 'Campos de integração'},
              ]}
              length={-1}
              rowsPerPage={10}>
              {data?.map((item, index) => (
                <TableRow key={item.idCampo}>
                  <TableCell>{item.nomeCampo}</TableCell>
                  <TableCell>{item.nomeSecao}</TableCell>
                  <TableCell>
                    <AutoComplete
                      defaultValue={item.equivalencias}
                      multiple
                      disableCloseOnSelect
                      disabled={!isEditing}
                      options={optionsFiltered}
                      getOptionLabel={(option) => option.nome}
                      // filterSelectedOptions
                      onChange={(_, value) => handleSetData(value, index)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          size="small"
                        />
                      )}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </Table>
          )}
        </Grid>
      </Grid>
      <Box marginY={14} />
      <Footer
        onCancel={() => navigate('/campanha')}
        onSave={handleOnSubmit}
        onGoBack={() => navigate(-1)}
        onSaveLabel="Concluir"
        disabled={loading}
      />
    </>
  );
};
