import {Drawer} from '@material-ui/core';
import {
  createStyles,
  Theme,
  withStyles,
  makeStyles,
} from '@material-ui/core/styles';

export const FDrawer = withStyles((theme: Theme) =>
  createStyles({
    paper: {
      top: 70,
      width: 400,
      height: '90%',
      marginRight: theme.spacing(1),
      borderRadius: theme.spacing(1),
    },
  }),
)(Drawer);

export const useStyles = makeStyles((theme: Theme) => ({
  filterHeader: {
    position: 'sticky',
    top: 0,
    display: 'flex',
    padding: theme.spacing(0, 2),
    justifyContent: 'space-between',
    boxShadow: '0px 0px 5px 0px #cccccc',
    background: '#FFF',
    zIndex: 'inherit',
  },
  filterFooter: {
    display: 'flex',
    padding: theme.spacing(2),
    justifyContent: 'space-between',
    boxShadow: '0px 0px 5px 0px #cccccc',
  },
  btnClose: {
    alignItems: 'center',
    display: 'flex',
  },
  body: {
    padding: theme.spacing(3),
    marginBottom: 'auto',
  },
}));
