import {IRequestResult} from 'shared/interfaces/IRequestResult';
import {Api} from 'shared/services/axiosConfig';

export interface IPermission {
  idPermissao: number;
  nome: string;
}

export interface IProfile {
  id: string;
  nome: string;
  administrador: boolean;
  ativo: boolean;
  permissaoPerfil: IPermission[];
}

export interface IProvilePagination {
  qtdTotalDeRegistros: number;
  data: IProfile[];
}

export interface IPostProfile {
  nome: string;
  administrador: boolean;
  ativo: boolean;
  permissaoPerfil: IPermission[];
}

export interface IUserPermission {
  id: string;
  idPerfil: string;
  idPermissao: number;
}

export interface IUserProfile {
  id: number;
  nome: string;
  login: string;
  ativo: boolean;
}

export interface IProfilesToUser {
  idEstabelecimento: string;
  idPerfil: string;
  ativo: boolean;
}

export interface IPostUserProfile {
  idUsuario: number;
  perfisUsuario: IProfilesToUser[];
}

export interface IUserProfilePagination {
  qtdTotalDeRegistros: number;
  data: IUserProfile[];
}

export interface IUserChache {
  message: string;
}

const getPermissionForProfiles = async (): Promise<IPermission[]> => {
  try {
    const response = await Api.get('/perfil/permissao');
    return response.data;
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const postProfile = async (payload: IPostProfile): Promise<IRequestResult> => {
  try {
    await Api.post('/Perfil', payload);

    return {success: true};
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const getProfiles = async (): Promise<IProfile[]> => {
  try {
    const response = await Api.get('/perfil');

    return response.data;
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const getProfilesPagination = async (
  campoDePesquisa: string,
  active: number,
  take: number,
  skip: number,
): Promise<IProvilePagination> => {
  try {
    const {data: response} =
      await Api.get(`/Perfil/paginado?campoDePesquisa=${campoDePesquisa}
    &ativo=${active === 0 ? '' : active === 1 ? true : false}
    &take=${take}&skip=${skip}`);

    return response;
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const getProfile = async (id: string): Promise<IProfile> => {
  try {
    const response = await Api.get(`/perfil/${id}`);

    return response.data;
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const updateProfile = async (
  id: string,
  payload: IProfile,
): Promise<IProfile> => {
  try {
    const response = await Api.put(`/perfil/${id}`, payload);

    return response.data;
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const deleteProfile = async (id: string): Promise<IRequestResult> => {
  try {
    await Api.delete(`/perfil/${id}`);

    return {success: true};
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const getUserPermissions = async (
  campoDePesquisa: string,
  ativo: number,
  take: number,
  skip: number,
): Promise<IUserProfilePagination> => {
  try {
    const {data: response} = await Api.get(
      `/Perfil/usuarioscompermissao?campoDePesquisa=
      ${campoDePesquisa}&ativo=${ativo === 0 ? '' : ativo === 1 ? true : false}
      &take=${take}&skip=${skip}`,
    );
    return response;
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const getUserPermission = async (
  idUsuario: string,
): Promise<IProfilesToUser[]> => {
  try {
    const {data: response} = await Api.get(
      `/Perfil/usuariocompermissao/${idUsuario}`,
    );

    return response;
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const postUserProfile = async (
  payload: IPostUserProfile,
): Promise<IRequestResult> => {
  try {
    await Api.post('/Perfil/perfildousuario', payload);

    return {success: true};
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const deleteUserPermission = async (
  id: string | number,
): Promise<IRequestResult> => {
  try {
    await Api.delete(`/Perfil/perfildousuario/${id}`);

    return {success: true};
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const clearUserChache = async (): Promise<IUserChache> => {
  try {
    await Api.post('/configuracaogeral/LimparCacheDoTenant');
    return {message: 'Limpeza de cache efetuada com sucesso'};
  } catch (error: any) {
    throw new Error(error.message);
  }
};

const updateUserPassword = async (): Promise<any> => {
  try {
    const {data} = await Api.get('/ContextoUsuario/UrlAlteracaoDeSenha');
    return {data};
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const UserProfileService = {
  getProfiles,
  getProfile,
  getPermissionForProfiles,
  getProfilesPagination,
  postProfile,
  updateProfile,
  deleteProfile,
  getUserPermissions,
  getUserPermission,
  postUserProfile,
  deleteUserPermission,
  clearUserChache,
  updateUserPassword,
};
