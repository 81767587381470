import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@material-ui/core';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import { useLocation } from 'react-router-dom';
import {AvailableSchedule} from 'shared/components';
import {useTerm, useUser} from 'shared/hooks';
import {feedback} from 'shared/services/alertService';
import {
  ICall,
  ICustomerService,
  IScheduleSituations,
  LeadService,
} from 'shared/services/api/LeadService';
import {
  ILead,
  LeadSituationService,
} from 'shared/services/api/LeadSituationService';
import {
  AvailabilitiesService,
  IScheduleAvailability,
} from 'shared/services/api/ScheduleAvailabilityService';
import {errorResponse} from 'shared/utils/errorResponse';

interface Iprops {
  idOferta: string;
  idLead: string;
  idDisponibilidade: string | null;
  trigger?: boolean;
  setTrigger: (trigger: boolean) => void;
}

interface LocationState {
  funnelId: string;
}

export const ModalEditAgendamento: React.FC<Iprops> = ({
  idOferta,
  idLead,
  idDisponibilidade,
  trigger,
  setTrigger,
}) => {
  const {data: user} = useUser();
  const userTimezone = useMemo(
    () => (user?.timezone ? user.timezone : -3),
    [user?.timezone],
  );
  const [loading, setLoading] = useState(false);

  const [schedule, setSchedule] = useState<IScheduleAvailability[]>([]);

  const [leadSituations, setLeadSituations] = useState<ILead[]>([]);

  const [openModal, setOpenModal] = useState(false);

  const [scheduleSituations, setScheduleSituations] = useState<
    IScheduleSituations[]
  >([]);

  const [scheduleAvailability, setScheduleAvailability] =
    useState<IScheduleAvailability>();

  const [customerService, setCustomerService] = useState<ICustomerService>({
    usaAgendamento: true,
  });

  const location = useLocation();
  const { funnelId } = location.state as LocationState;

  const getLeadSituations = useCallback(async () => {
    try {
      const response = await LeadSituationService.getLeads(funnelId, true);

      if (response) {
        setLeadSituations(response);
      }
    } catch (error) {
       
      feedback(errorResponse(error), 'error');
    }
  }, []);

  const getAvailabilities = useCallback(async () => {
    try {
      const response = await AvailabilitiesService.getAvailabilities(
        idOferta,
        userTimezone,
      );

      if (response) {
        setSchedule(response);
      }
    } catch (error) {
       
      feedback(errorResponse(error), 'error');
    }
  }, [idOferta, userTimezone]);

  const getScheduleSituations = useCallback(async () => {
    try {
      const response = await LeadService.getScheduleSituations();

      if (response) {
        setScheduleSituations(response);
      }
    } catch (error) {
       
      feedback(errorResponse(error), 'error');
    }
  }, []);

  /////////////////////////////////////////////////////////////////////////////////////////////////

  const [leadsCall, setLeadsCall] = useState<ICall[]>([]);
  const {terms} = useTerm();

  const getAtendimento = leadsCall?.find(
    (objeto) => objeto.idDisponibilidadeDeAgenda === idDisponibilidade,
  );

  const getLeadsCall = useCallback(async () => {
    setLoading(true);
    try {
      const {atendimentos} = await LeadService.getLeadsCall(
        idLead,
        userTimezone,
      );

      if (atendimentos.length) {
        setLeadsCall(atendimentos);
      } else {
        feedback(
          `Este ${terms.termoLead} ainda não possui atendimentos.`,
          'warning',
        );
      }
    } catch (error) {
       
      feedback(errorResponse(error), 'error');
    } finally {
      setLoading(false);
    }
  }, [idLead, terms.termoLead, userTimezone]);

  const getScheduleAvailability = useCallback(
    async (idDisponibilidadeDeAgenda) => {
      setLoading(true);
      try {
        const response = await AvailabilitiesService.getScheduleAvailability(
          idDisponibilidadeDeAgenda,
          userTimezone,
        );

        if (response) {
          setScheduleAvailability(response);
        }
      } catch (error) {
         
        feedback(errorResponse(error), 'error');
      } finally {
        setLoading(false);
      }
    },
    [userTimezone],
  );

  //Checa atendimento pendent, possivel implementação futura

  //   const checkInclusion = useCallback(() => {
  //     if (leadsCall[0].idSituacaoDeAgenda !== 1) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   }, [leadsCall]);

  //   const handleIncludeCall = useCallback(() => {
  //     if (checkInclusion()) {
  //       setScheduleAvailability(undefined);
  //       setCustomerService({
  //         idLead: idLead,
  //         idSituacaoDeAgenda: leadsCall[0].idSituacaoDeAgenda,
  //         //usaAgendamento: false,
  //       });
  //     } else {
  //       feedback(
  //         `O atendimento atual possui agenda pendente.
  //           É necessário realizar, reagendar ou cancelar esta agenda para incluir
  //           um novo atendimento.`,
  //         'warning',
  //       );
  //     }
  //   }, [checkInclusion, idLead, leadsCall]);

  const handleAlterLeadsCall = useCallback(async () => {
    setLoading(true);

    try {
      await LeadService.putLeadsCall(customerService);

      feedback('Atendimento alterado com sucesso', 'success');

      getLeadsCall();
    } catch (error) {
       
      feedback(errorResponse(error), 'error');
    } finally {
      setLoading(false);
      handleCloseModal();
      setTimeout(() => {
        setTrigger(!trigger);
      }, 100);
    }
  }, [customerService, getLeadsCall, setTrigger, trigger]);

  //////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    getLeadsCall();
    getLeadSituations();
    getScheduleSituations();
    getScheduleAvailability(idDisponibilidade);
    getAvailabilities();
  }, [
    getLeadsCall,
    getScheduleSituations,
    getAvailabilities,
    getLeadSituations,
    getScheduleAvailability,
    idDisponibilidade,
  ]);

  useEffect(() => {
    customerService.idLead = idLead;
  }, [idLead, customerService]);

  const handleOpenModal = useCallback(() => {
    setOpenModal(true);
    setCustomerService({
      ...customerService,
      idSituacaoDeLead: leadSituations && leadSituations[0].id,
    });
  }, [leadSituations, customerService]);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Grid>
        <Button
          variant="outlined"
          size="small"
          onClick={() => {
            handleOpenModal(),
              setCustomerService({
                ...customerService,
                idSituacaoDeAgenda: Number(getAtendimento?.idSituacaoDeAgenda),
                id: getAtendimento?.idAtendimentoDoLead,
                atendimento: getAtendimento?.atendimento,
                usaAgendamento: getAtendimento?.usaAgendamento,
              });
          }}>
          Editar
        </Button>
      </Grid>

      <Dialog open={openModal} onClose={handleCloseModal} maxWidth="xl">
        <DialogTitle id="Editar atendimento">
          <Typography color="primary" variant="h6">
            Editar Agendamento
          </Typography>
        </DialogTitle>

        <DialogContent>
          <Grid container direction="column" xs style={{width: 600}}>
            <Grid container item xs>
              <Grid item xs>
                <FormControlLabel
                  label="Efetuar agendamento"
                  control={
                    <Checkbox
                      checked={customerService.usaAgendamento}
                      onChange={({target}) => {
                        setCustomerService({
                          ...customerService,
                          usaAgendamento: target.checked,
                        });
                      }}
                    />
                  }
                />
              </Grid>
            </Grid>

            {customerService.usaAgendamento && (
              <>
                <Grid container item xs>
                  <Grid item xs>
                    <Box marginTop={2}>
                      <AvailableSchedule
                        label="Agenda"
                        disabled={loading}
                        availabilities={schedule}
                        defaultValue={scheduleAvailability}
                        onChange={(value) => (
                          setScheduleAvailability(value),
                          setCustomerService({
                            ...customerService,
                            idDisponibilidadeDeAgenda:
                              value.idDisponibilidadeDeAgenda,
                            idSituacaoDeAgenda:
                              customerService.id &&
                              customerService.idDisponibilidadeDeAgenda !==
                                value.idDisponibilidadeDeAgenda
                                ? 2
                                : customerService.idSituacaoDeAgenda,
                          })
                        )}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </>
            )}

            <Grid container item xs>
              <Grid item xs>
                <Box marginTop={2}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Situação atendimento</InputLabel>
                    <Select
                      label="Situação atendimento"
                      value={customerService.idSituacaoDeAgenda}
                      onChange={({target}) =>
                        setCustomerService({
                          ...customerService,
                          idSituacaoDeAgenda: Number(target.value),
                        })
                      }>
                      {scheduleSituations?.map((item) => (
                        <MenuItem
                          key={item.id && item.id}
                          value={item.id && item.id}
                          disabled={item.id === 2}>
                          {item.nome}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>

            <Grid container item xs>
              <Grid item xs>
                <Box marginTop={2} marginBottom={2}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Atendimento</InputLabel>
                    <OutlinedInput
                      multiline
                      rows={4}
                      label="Atendimento"
                      type="string"
                      value={customerService.atendimento || ''}
                      onChange={({target}) =>
                        setCustomerService({
                          ...customerService,
                          atendimento: target.value,
                        })
                      }
                    />
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Box padding={2} marginBottom={1}>
            <Button variant="outlined" onClick={handleCloseModal}>
              cancelar
            </Button>
            <Button
              style={{marginLeft: 12}}
              color="primary"
              variant="contained"
              onClick={handleAlterLeadsCall}>
              Salvar
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};
