import {Box, makeStyles, Typography} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  triangulo: {
    width: 0,
    height: 0,
    borderTop: '17px solid transparent',
    borderBottom: '17px solid transparent',
    borderLeft: '17px solid',
    borderLeftColor: theme.palette.text.primary,
  },

  fundoSeta: {
    width: '75px',
    padding: '5px',
    borderTopLeftRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.common.white,
  },
}));

interface IProps {
  percentage: number;
}

export const Triangle: React.FC<IProps> = ({percentage}) => {
  const classes = useStyles();

  return (
    <Box display="flex">
      <Box zIndex={5}>
        <Typography className={classes.fundoSeta} align="center">
          {percentage}%
        </Typography>
      </Box>
      <Box className={classes.triangulo} zIndex={5} />
    </Box>
  );
};
