import React from 'react';

import {Box, useTheme} from '@material-ui/core';

interface IProps {
  banner?: string;
}

export const BannerImage: React.FC<IProps> = ({banner}) => {
  const theme = useTheme();

  return (
    <Box
      position="relative"
      width="100%"
      height={216}
      borderRadius={4}
      display="flex"
      alignItems="center"
      justifyContent="center"
      padding={2}
      style={{backgroundColor: 'white'}}>
      <Box
        display="flex"
        style={{border: '4px solid #414141'}}
        width="75%"
        height="100px"
        borderRadius={4}
        marginBottom={4}>
        <Box
          width="100%"
          height="25px"
          style={{backgroundColor: theme.palette.primary.main}}>
          {banner && (
            <img src={banner} alt="color" width="100%" height="25px" />
          )}
        </Box>
      </Box>

      <Box
        position="absolute"
        display="flex"
        style={{border: '4px solid #414141', backgroundColor: 'white'}}
        width="29%"
        height="70px"
        top="40%"
        left="22%"
        borderRadius={4}
        marginBottom={4}>
        <Box
          width="100%"
          height="20px"
          style={{backgroundColor: theme.palette.primary.main}}>
          {banner && (
            <img src={banner} alt="color" width="100%" height="20px" />
          )}
        </Box>
      </Box>

      <Box
        position="absolute"
        display="flex"
        alignItems="flex-end"
        justifyContent="center"
        style={{backgroundColor: '#414141'}}
        width="29%"
        height="35px"
        top="72%"
        left="22%"
        borderRadius={4}
        marginBottom={4}
      />

      <Box
        position="absolute"
        display="flex"
        style={{
          border: '4px solid #414141',
          borderBottom: '12px solid #414141',
          backgroundColor: 'white',
        }}
        width="13%"
        height="75px"
        top="45%"
        right="23%"
        borderRadius={4}
        marginBottom={4}>
        <Box
          width="100%"
          height="15px"
          style={{backgroundColor: theme.palette.primary.main}}>
          {banner && (
            <img src={banner} alt="color" width="100%" height="15px" />
          )}
        </Box>
        <Box
          width="40%"
          position="absolute"
          height="2px"
          borderRadius={4}
          bottom={-8}
          left={13}
          style={{backgroundColor: 'white'}}
        />
      </Box>
    </Box>
  );
};
