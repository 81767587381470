import {useMemo, useState} from 'react';
import {
  Box,
  Card,
  Grid,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  makeStyles,
  CardContent,
  createStyles,
} from '@material-ui/core';
import {TrendingUp} from '@material-ui/icons';

import {Triangle} from '../Triangle';
import {GeneralPerformance as GeneralPerformanceModal} from './../modals';

import {IOrigemLead} from 'shared/services/api/CRMDashboard';

interface IProps {
  origemLeads: IOrigemLead[];
  selectedPeriod: string;
  selectedEstablishment: string;
  selectedCampaing: string;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    table: {
      '& td': {
        borderBottomColor: '#d9eae9',
      },
    },

    firstRow: {
      '& th': {
        backgroundColor: '#d9eae9',
        borderBottom: 0,
      },
    },
    firstColumn: {
      borderTopLeftRadius: theme.shape.borderRadius,
      borderBottomLeftRadius: theme.shape.borderRadius,
    },
    lastColumn: {
      borderTopRightRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
    },
  }),
);

export const GeneralPerformance: React.FC<IProps> = ({
  origemLeads,
  selectedPeriod,
  selectedEstablishment,
  selectedCampaing,
}) => {
  const [openModal, setOpenModal] = useState(false);

  const classes = useStyles();

  const numeroAtendimentoGeral = useMemo(
    () =>
      origemLeads.reduce(
        (previousValue, currentValue) =>
          previousValue + currentValue.funil.numeroDeAtendimentos,
        0,
      ) || 0,
    [origemLeads],
  );

  const numeroConversoesGeral = useMemo(
    () =>
      origemLeads.reduce(
        (previousValue, currentValue) =>
          previousValue + currentValue.funil.numeroDeConversoes,
        0,
      ) || 0,
    [origemLeads],
  );

  const percentualConvertidoGeral = useMemo(
    () =>
      Number(
        ((numeroConversoesGeral * 100) / numeroAtendimentoGeral).toFixed(),
      ) || 0,
    [numeroAtendimentoGeral, numeroConversoesGeral],
  );

  return (
    <>
      <Card elevation={3}>
        <Box marginBottom={3}>
          <CardContent>
            <Grid container>
              <Grid container item>
                <Grid item>
                  <Box
                    marginLeft={2}
                    display="flex"
                    marginTop={1}
                    alignItems="center">
                    <Box marginRight={1} marginBottom={4}>
                      <TrendingUp fontSize="large" color="primary" />
                    </Box>

                    <Box display="flex" justifyContent="center">
                      <Box marginBottom={4}>
                        <Typography variant="h5">
                          <b>Desempenho geral</b>
                        </Typography>
                      </Box>
                      <Box marginLeft={105}>
                        <Button
                          color="primary"
                          variant="text"
                          onClick={() => setOpenModal(true)}>
                          Ver mais
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Box width="100%" marginLeft={2} marginRight={2}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow className={classes.firstRow}>
                      <TableCell className={classes.firstColumn} />
                      <TableCell align="center">Visitantes</TableCell>
                      <TableCell align="center" />
                      <TableCell align="center">Inscritos</TableCell>
                      <TableCell align="center" />
                      <TableCell align="center" className={classes.lastColumn}>
                        Conversões
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {origemLeads.length > 0 ? (
                      <>
                        {origemLeads.map((origemLead) => (
                          <TableRow key={origemLead.origemLeadDescricao}>
                            <TableCell>
                              <Typography>
                                <strong>
                                  {origemLead.origemLeadDescricao}
                                </strong>
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              {origemLead.origemLeadDescricao === 'Externo'
                                ? origemLead.funil.numeroDeVisitantes
                                : '-'}
                            </TableCell>
                            <TableCell>
                              {origemLead.origemLeadDescricao === 'Externo' && (
                                <Box display="flex" justifyContent="center">
                                  <Triangle
                                    percentage={Math.round(
                                      Number(
                                        origemLead.funil.percentualDeInscritoAgendado.toFixed(
                                          2,
                                        ),
                                      ) * 100,
                                    )}
                                  />
                                </Box>
                              )}
                            </TableCell>
                            <TableCell align="center">
                              {origemLead.funil.numeroDeAtendimentos}
                            </TableCell>
                            <TableCell align="center">
                              <Box display="flex" justifyContent="center">
                                <Triangle
                                  percentage={Math.round(
                                    Number(
                                      origemLead.funil.percentualConvertido.toFixed(
                                        2,
                                      ),
                                    ) * 100,
                                  )}
                                />
                              </Box>
                            </TableCell>
                            <TableCell align="center">
                              {origemLead.funil.numeroDeConversoes}
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell style={{border: 0}} />
                          <TableCell style={{border: 0}} />
                          <TableCell align="center">
                            <Typography>
                              <strong>Geral</strong>
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            {numeroAtendimentoGeral}
                          </TableCell>
                          <TableCell align="center">
                            <Box display="flex" justifyContent="center">
                              <Triangle
                                percentage={percentualConvertidoGeral}
                              />
                            </Box>
                          </TableCell>
                          <TableCell align="center">
                            {numeroConversoesGeral}
                          </TableCell>
                        </TableRow>
                      </>
                    ) : (
                      <TableRow>
                        <TableCell />
                        <TableCell align="center">-</TableCell>
                        <TableCell />
                        <TableCell align="center">-</TableCell>
                        <TableCell />
                        <TableCell align="center">-</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Box>
            </Grid>
          </CardContent>
        </Box>
      </Card>

      <GeneralPerformanceModal
        openModal={openModal}
        onclose={() => setOpenModal(false)}
        selectedPeriod={selectedPeriod}
        selectedEstablishment={selectedEstablishment ?? '0'}
        selectedCampaing={selectedCampaing ?? ''}
      />
    </>
  );
};
