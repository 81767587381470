import {IRequestResult} from 'shared/interfaces/IRequestResult';
import {Api} from 'shared/services/axiosConfig';

import {IComplementary} from '../ComplementaryService';

interface IEnrollmentControl {
  possuiListaDeEspera: boolean;
  visivelNoForm: boolean;
  numeroDeVagas: number;
  valorVagasRestante: number;
  valorListaDeEsperaAtual: number;
}

export interface IGetOffer {
  id: string;
  idCampanha: string;
  idCurso: string;
  nomeCurso: string;
  idCiclo: string;
  nomeCiclo: string;
  idTurno: string;
  nomeTurno: string;
  idEstabelecimento: string;
  nomeEstabelecimento: string;
  estabelecimentoCnpj: string;
  ativo: boolean;
  complementos1DaOferta: IComplementary[];
  complementos2DaOferta: IComplementary[];
  controleVagas: IEnrollmentControl | null;
}

export interface IPostOffer {
  ativo: boolean;
  idCampanha: string;
  idCurso: string;
  idCiclo: string;
  idTurno: string | null;
  cnpjEstabelecimento: string;
  idEstabelecimento: string;
  complementos1DaOferta: string[];
  complementos2DaOferta: string[];
  controleVagas: Omit<
    IEnrollmentControl,
    'valorVagasRestante' | 'valorListaDeEsperaAtual'
  > | null;
}

export interface IMultiPostOffer {
  idCampanha: string;
  idsEstabelecimentos: string[];
  idsCursosComCiclos: string[];
  idsTurnos: string[];
  idsComplementares1: string[];
  idsComplementares2: string[];
  controleVagas: Omit<
    IEnrollmentControl,
    'valorVagasRestante' | 'valorListaDeEsperaAtual'
  > | null;
}

const postMultiOffers = async (
  payload: IMultiPostOffer,
): Promise<IRequestResult> => {
  try {
    const response = await Api.post('/Campanha/multiplasofertas', payload);

    return {success: true, messages: [response.data]};
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const postOffer = async (payload: IPostOffer): Promise<IRequestResult> => {
  try {
    await Api.post('/Campanha/oferta', payload);

    return {success: true};
  } catch (error: any) {
    throw new Error(error.data.data);
  }
};

const putOffer = async (
  id: string,
  payload: Omit<IPostOffer, 'idCampanha, idCurso, idCiclo'>,
): Promise<IRequestResult> => {
  try {
    await Api.put(`/Campanha/oferta/${id}`, payload);

    return {success: true};
  } catch (error: any) {
    throw new Error(error.data.data);
  }
};

const deleteOffer = async (id: string): Promise<IRequestResult> => {
  try {
    await Api.delete(`/Campanha/oferta/${id}`);

    return {success: true};
  } catch (error: any) {
    throw new Error(error.data.data);
  }
};

const getOfferForCampaign = async (id: string): Promise<IGetOffer[]> => {
  try {
    const response = await Api.get(`/Campanha/ofertasporcampanha/${id}`);

    return response.data;
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

export const OfferService = {
  postOffer,
  postMultiOffers,
  getOfferForCampaign,
  putOffer,
  deleteOffer,
};
