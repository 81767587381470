import React from 'react';

import {IconButton, Tooltip} from '@material-ui/core';
import {Info} from '@material-ui/icons';

interface IProps {
  title: string;
}

export const InfoTooltip: React.FC<IProps> = ({title}) => {
  return (
    <Tooltip title={title}>
      <IconButton aria-label={title} color="primary">
        <Info fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};
