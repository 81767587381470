import React from 'react';
import {Box, Dialog, Grid, Paper, Typography} from '@material-ui/core';

import {UploadImage} from '../UploadImage';

interface IProps {
  image: string;
  width: number;
  height: number;
  imageId: string;
  openModal: boolean;
  aspect: number | undefined;
  maxWidth?: 'md' | 'lg' | undefined;

  onClose(): void;
  setImage(value: string): void;
}

export const ModalImage: React.FC<IProps> = ({
  image,
  width,
  height,
  aspect,
  imageId,
  onClose,
  setImage,
  openModal,
  maxWidth = 'md',
}) => {
  return (
    <Dialog open={openModal} onClose={onClose} maxWidth={maxWidth} fullWidth>
      <Grid container>
        <Grid item xs={12}>
          <Box component={Paper} p={3}>
            <Typography variant="h5" color="primary">
              <strong>Personalizar imagem</strong>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <UploadImage
            id={imageId}
            width={width}
            height={height}
            aspect={aspect}
            onClose={onClose}
            setImage={setImage}
            imagePreview={image}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};
