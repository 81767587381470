import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';

export const drawerWidth = 280;
export const drawerHeight = 1150;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: theme.spacing(35),
      flexShrink: 0,
      whiteSpace: 'nowrap',
      position: 'relative',
    },
    drawerOpen: {
      marginTop: theme.spacing(11.25),
      backgroundColor: theme.palette.primary.main,
      width: theme.spacing(35),
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      marginTop: theme.spacing(11.25),
      backgroundColor: theme.palette.primary.main,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      overflowY: 'hidden',
      width: theme.spacing(9),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
      [theme.breakpoints.down('sm')]: {
        width: '0',
        padding: '0',
      },
    },
    list: {
      minHeight: drawerHeight,
      height: '100%',
    },
    listItem: {
      height: theme.spacing(7),
      color: theme.palette.secondary.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    icons: {
      color: theme.palette.secondary.contrastText,
      marginLeft: theme.spacing(0.9),
    },
    iconSearch: {
      marginTop: 3.5,
    },
    iconInputSearch: {
      color: theme.palette.secondary.contrastText,
      marginLeft: -6.5,
    },
    inputSearch: {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: 8,
    },
    input: {
      '&::placeholder': {
        color: theme.palette.secondary.contrastText,
        opacity: 1,
      },
    },
    inputBoxSearch: {
      height: theme.spacing(11.25),
      color: theme.palette.secondary.contrastText,
      position: 'fixed',
      backgroundColor: theme.palette.primary.main,
      zIndex: theme.zIndex.drawer + 2,
      boxShadow: theme.shadows[3],
      top: theme.spacing(8),
    },
    inputBoxSearchOpen: {
      width: theme.spacing(35),
      zIndex: theme.zIndex.drawer + 2,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    inputBoxSearchClose: {
      zIndex: theme.zIndex.drawer + 2,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(9),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
      [theme.breakpoints.down('sm')]: {
        width: '0',
        padding: '0',
      },
    },
    listItemLogout: {
      height: theme.spacing(7),
      color: theme.palette.secondary.contrastText,
      position: 'fixed',
      backgroundColor: theme.palette.primary.main,
      bottom: 0,
      zIndex: theme.zIndex.drawer + 1,
      width: '100%',
      boxShadow: theme.shadows[6],
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    listItemLogoutOpen: {
      opacity: 1,
      backgroundColor: theme.palette.primary.main,
      width: theme.spacing(35),
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    listItemLogoutClose: {
      opacity: 1,
      backgroundColor: theme.palette.primary.main,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(9),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
      [theme.breakpoints.down('sm')]: {
        width: '0',
        padding: '0',
      },
    },
  }),
);
