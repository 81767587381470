import React from 'react';

import {useTheme, Box} from '@material-ui/core';

interface IProps {
  logo?: string;
}

export const LogoImage: React.FC<IProps> = ({logo}) => {
  const theme = useTheme();

  return (
    <Box
      position="relative"
      width="100%"
      height={216}
      borderRadius={4}
      display="flex"
      alignItems="center"
      justifyContent="center"
      padding={2}
      style={{backgroundColor: 'white'}}>
      <Box
        display="flex"
        padding={0.6}
        style={{border: '4px solid #414141'}}
        width="75%"
        height="100px"
        borderRadius={4}
        marginBottom={4}>
        <Box
          width="25px"
          height="25px"
          borderRadius={40}
          style={{backgroundColor: theme.palette.primary.main}}>
          {logo && (
            <img
              src={logo}
              alt="color"
              width="100%"
              style={{borderRadius: '40px'}}
            />
          )}
        </Box>
      </Box>

      <Box
        position="absolute"
        display="flex"
        padding={0.6}
        style={{border: '4px solid #414141', backgroundColor: 'white'}}
        width="29%"
        height="70px"
        top="40%"
        left="22%"
        borderRadius={4}
        marginBottom={4}>
        <Box
          width="20px"
          height="20px"
          borderRadius={2}
          style={{backgroundColor: theme.palette.primary.main}}>
          {logo && (
            <img
              src={logo}
              alt="color"
              width="100%"
              style={{borderRadius: '40px'}}
            />
          )}
        </Box>
      </Box>

      <Box
        position="absolute"
        display="flex"
        alignItems="flex-end"
        justifyContent="center"
        style={{backgroundColor: '#414141'}}
        width="29%"
        height="35px"
        top="72%"
        left="22%"
        borderRadius={4}
        marginBottom={4}
      />

      <Box
        position="absolute"
        display="flex"
        padding={0.6}
        style={{
          border: '4px solid #414141',
          borderBottom: '12px solid #414141',
          backgroundColor: 'white',
        }}
        width="13%"
        height="75px"
        top="45%"
        right="23%"
        borderRadius={4}
        marginBottom={4}>
        <Box
          width="17px"
          height="17px"
          borderRadius={2}
          style={{backgroundColor: theme.palette.primary.main}}>
          {logo && (
            <img
              src={logo}
              alt="color"
              width="100%"
              style={{borderRadius: '40px'}}
            />
          )}
        </Box>
        <Box
          width="40%"
          position="absolute"
          height="2px"
          borderRadius={4}
          bottom={-8}
          left={13}
          style={{backgroundColor: 'white'}}
        />
      </Box>
    </Box>
  );
};
