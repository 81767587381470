import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  Box,
  useTheme,
  TableRow,
  TableCell,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import {useNavigate} from 'react-router-dom';
import {Delete, Edit} from '@material-ui/icons';

import {useTitle, useUser, useDebounce} from 'shared/hooks';
import {
  IProfile,
  UserProfileService,
} from 'shared/services/api/UserProfileService';
import {feedback} from 'shared/services/alertService';
import {errorResponse} from 'shared/utils/errorResponse';
import {Header, ModalConfirm, Table} from 'shared/components';

import {useStyles} from './styles';

export const UserProfile: React.FC = () => {
  const [filter, setFilter] = useState('');
  const [loading, setLoading] = useState(false);
  const [idDeleted, setIdDeleted] = useState('');
  const [tableFiltered, setTableFiltered] = useState(0);
  const [userProfiles, setUserProfiles] = useState<IProfile[]>([]);
  const [openModalConfirmExclude, setOpenModalConfirmExclude] = useState(false);
  const [take,setTake] = useState(10);
  const [skip, setSkip] = useState(1);
  const [userCount, setUserCount] = useState(0);

  const theme = useTheme();
  const classes = useStyles();
  const navigate = useNavigate();
  const {setTitle} = useTitle();
  const {data: user} = useUser();
  const { debounce } = useDebounce();

  const permissions = useMemo(
    () => user?.permissoes.filter((i) => i.nome.includes('Perfil')),
    [user?.permissoes],
  );

  const canDelete = useMemo(
    () => !!permissions?.find((i) => i.nome.includes('Excluir')),
    [permissions],
  );

  const canRegister = useMemo(
    () => !!permissions?.find((i) => i.nome.includes('Incluir')),
    [permissions],
  );

  const canList = useMemo(
    () => !!permissions?.find((i) => i.nome.includes('Listar')),
    [permissions],
  );
  
  useEffect(() => {
    if (!canList) {
      navigate('/');
    }
  }, [canList, navigate]);

  const handleGetUserProfiles = useCallback( () => {
    setLoading(true);
    debounce(async() => {
      try {
        const data = await UserProfileService.getProfilesPagination(filter, tableFiltered, take, skip);
        if (data) {
          setUserProfiles(data.data);
          setUserCount(data.qtdTotalDeRegistros);
        }
      } catch (error) {
        feedback(errorResponse(error), 'error');
      } finally {
        setLoading(false);
      }
    });
  }, [filter, take, skip,tableFiltered, debounce]);

  const handleDelete = useCallback((id: string) => {
    setOpenModalConfirmExclude(true);

    setIdDeleted(id);
  }, []);

  const handleConfirmDeleted = useCallback(async () => {
    try {
      setLoading(true);
      await UserProfileService.deleteProfile(idDeleted);

      await handleGetUserProfiles();

      feedback('Perfil do usuário deletado com sucesso', 'success');
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
    setLoading(false);
    setIdDeleted('');
    setOpenModalConfirmExclude(false);
  }, [handleGetUserProfiles, idDeleted]);

  
  useEffect(() => setTitle('Perfil do usuário'), [setTitle]);

  
  useEffect(() => {
    handleGetUserProfiles();
  }, [handleGetUserProfiles]);

  return (
    <>
      <Header
        filter={filter}
        setFilter={setFilter}
        disabledButton={!canRegister}
        tableFiltered={tableFiltered}
        setTableFiltered={setTableFiltered}
        onClick={() => navigate('/perfil-do-usuario/novo')}
      />

      <Box marginTop={5} />

        <Table
          head={[
            {label: 'Ações', width: '140px'},
            {label: 'Perfil de usuário'},
            {label: 'Status', width: '140px'},
          ]}
          pagination={{
            count: Math.ceil(userCount / take),
            page: skip,
          }}
          loading={loading}
          take={take}
          length={userCount}
          rowsPerPage={userProfiles.length}
          onChangeTake={(value:number) => setTake(value)}
          onChangePage={(value:number) => setSkip(value)}>
          {
            userProfiles.map((item) => (
              <TableRow key={item.id}>
                <TableCell>
                  <IconButton
                    color="primary"
                    onClick={() => handleDelete(item.id)}
                    disabled={loading || item.administrador || !canDelete}>
                    <Delete />
                    {loading && idDeleted === item.id && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </IconButton>

                  <IconButton
                    style={{marginLeft: theme.spacing(1)}}
                    color="primary"
                    onClick={() =>
                      navigate(`/perfil-do-usuario/editar/${item.id}`)
                    }
                    disabled={loading || item.administrador}>
                    <Edit />
                  </IconButton>
                </TableCell>
                <TableCell>{item.nome}</TableCell>
                <TableCell>{item.ativo ? 'Ativo' : 'Inativo'}</TableCell>
              </TableRow>
            ))}
        </Table>

      <ModalConfirm
        opened={openModalConfirmExclude}
        onClick={handleConfirmDeleted}
        onClose={() => {
          setOpenModalConfirmExclude(false), setIdDeleted('');
        }}
        loading={loading}
      />
    </>
  );
};
