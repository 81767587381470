import {useMemo, useState} from 'react';
import {
  Box,
  Card,
  MenuItem,
  TextField,
  makeStyles,
  Typography,
  CardContent,
  createStyles,
  CardActionArea,
} from '@material-ui/core';

import {HorizontalBarChart} from '../charts';
import {LeadsBySituation as LeadsBySituationModal} from './../modals';

import {ILeadsPorSituacao} from 'shared/services/api/CRMDashboard';

const BASE_HEIGHT = 80;
const ITEM_HEIGHT = 40;

interface IProps {
  leadsPorSituacao: ILeadsPorSituacao[];
  selectedPeriod: string;
  selectedEstablishment: string;
  selectedCampaing: string;
}

const useStyle = makeStyles(() =>
  createStyles({
    card: {display: 'flex', flexDirection: 'column', height: '100%'},
    fullHeight: {height: '100%'},
  }),
);

export const LeadsBySituation: React.FC<IProps> = ({
  leadsPorSituacao,
  selectedPeriod,
  selectedEstablishment,
  selectedCampaing,
}) => {
  const [idFunil, setIdFunil] = useState(leadsPorSituacao[0]?.idFunil || '');
  const [openModal, setOpenModal] = useState(false);

  const classes = useStyle();

  const selectedFunil = useMemo(
    () => leadsPorSituacao.find((lead) => lead.idFunil === idFunil),
    [leadsPorSituacao, idFunil],
  );

  const labels = useMemo(
    () =>
      selectedFunil?.situacoes.map((situation) => situation.situacaoLead) || [],
    [selectedFunil?.situacoes],
  );
  const data = useMemo(
    () => selectedFunil?.situacoes.map((item) => item.valor) || [],
    [selectedFunil?.situacoes],
  );

  const height = useMemo(() => BASE_HEIGHT + ITEM_HEIGHT * data.length, [data]);

  return (
    <>
      <Card elevation={3} className={classes.card}>
        <Box
          mt={2}
          ml={2}
          mr={2}
          mb={0.5}
          display="flex"
          justifyContent="space-between">
          <Typography variant={'h6'}>Leads por funil</Typography>

          {leadsPorSituacao.length > 0 && (
            <TextField
              disabled={leadsPorSituacao.length === 1}
              select
              size="small"
              variant="outlined"
              value={idFunil}>
              {leadsPorSituacao.map((lead) => (
                <MenuItem
                  key={lead.idFunil}
                  value={lead.idFunil}
                  onClick={() => setIdFunil(lead.idFunil)}>
                  {lead.nome}
                </MenuItem>
              ))}
            </TextField>
          )}
        </Box>

        <CardActionArea
          onClick={() => setOpenModal(true)}
          className={classes.fullHeight}>
          <CardContent>
            <HorizontalBarChart
              key={`${selectedFunil?.idFunil}_${height}`}
              labels={labels}
              datasets={[
                {
                  label: 'Respondido',
                  data: data,
                },
              ]}
              options={{
                maintainAspectRatio: false,
                barPercentage: true,
                plugins: {
                  labels: {
                    render: 'percentage',
                    precision: 1,
                  },
                },
              }}
              width="100%"
              height={height}
              showDataLabels
              showAsPercentage
            />
          </CardContent>
        </CardActionArea>
      </Card>

      <LeadsBySituationModal
        openModal={openModal}
        onclose={() => setOpenModal(false)}
        selectedPeriod={selectedPeriod}
        selectedEstablishment={selectedEstablishment ?? '0'}
        selectedCampaing={selectedCampaing ?? ''}
        idFunil={idFunil}
      />
    </>
  );
};
