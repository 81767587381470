import React, {useCallback} from 'react';
import {Add} from '@material-ui/icons';
import {Skeleton} from '@material-ui/lab';
import {Box, Grid, MenuItem, useTheme} from '@material-ui/core';

import {Button} from '../Button';
import {InputSearch} from '../InputSearch';
import {CssTextField} from './styles';

interface IProps {
  filter?: string;
  loading?: boolean;
  filterLabel?: string;
  labelButton?: string;
  dataFilter?: string[];
  tableFiltered?: number;
  disabledButton?: boolean;
  hideButton?: boolean;
  startIcon?: React.ReactNode;
  onClick(): void;
  setFilter?(value: string): void;
  setTableFiltered?(value: number): void;
}

export const Header: React.FC<IProps> = ({
  filter,
  onClick,
  children,
  startIcon,
  setFilter,
  tableFiltered,
  loading = false,
  setTableFiltered,
  filterLabel = 'Pesquisar na tabela...',
  labelButton = 'Novo',
  disabledButton = false,
  hideButton = false,
  dataFilter = ['TODOS', 'ATIVOS', 'INATIVOS'],
}) => {
  const theme = useTheme();

  const handleTableFiltered = useCallback(
    (event) => {
      const {value} = event.target;
      setTableFiltered && setTableFiltered(value);
    },
    [setTableFiltered],
  );

  const skeleton = () => (
    <Grid container justify="space-between" alignItems="center">
      <Grid item xs={12} sm={6}>
        <Skeleton variant="rect" height={36} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box
          display="flex"
          justifyContent="space-between"
          marginLeft={3}
          alignItems="center">
          <Box width={160}>
            <Skeleton variant="rect" height={36} />
          </Box>
          {hideButton ? (
            <></>
          ) : (
            <>
              <Box width={160}>
                <Skeleton variant="rect" height={36} />
              </Box>
            </>
          )}
        </Box>
      </Grid>
    </Grid>
  );

  return loading ? (
    skeleton()
  ) : (
    <Grid container spacing={4} justify="space-between" alignItems="center">
      <Grid
        item
        xs={setTableFiltered ? 8 : 12}
        sm={setTableFiltered ? 9 : 12}
        md={6}>
        {setFilter && (
          <InputSearch
            variant="outlined"
            placeholder={filterLabel}
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
        )}
      </Grid>
      {setTableFiltered && (
        <Grid item xs={4} sm={3} md={2}>
          <Box width="100%">
            <CssTextField
              select
              fullWidth
              variant="outlined"
              value={tableFiltered}
              onChange={handleTableFiltered}
              size="small"
              defaultValue={0}
              style={{
                padding: 0,
                margin: 0,
                color: theme.palette.primary.main,
              }}>
              {dataFilter.map((item, index) => (
                <MenuItem key={index} value={index}>
                  {item}
                </MenuItem>
              ))}
            </CssTextField>
          </Box>
        </Grid>
      )}
      <Grid item xs={12} sm={12} md={setTableFiltered ? 4 : 6}>
        {hideButton ? (
          <></>
        ) : (
          <>
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              gridGap={24}>
              {children}
              <Box minWidth={150}>
                <Button
                  fullWidth
                  onClick={onClick}
                  label={labelButton}
                  disabled={disabledButton}
                  startIcon={
                    startIcon ? startIcon : <Add style={{color: '#F8F8F8'}} />
                  }
                />
              </Box>
            </Box>
          </>
        )}
      </Grid>
    </Grid>
  );
};
