import {Api} from 'shared/services/axiosConfig';

export interface IParcelas {
  tipoDeParcelas: TipoDeParcela[];
  situacoesDasParcelas: SituacoesDasParcela[];
}

export interface TipoDeParcela {
  codigoTipoParcela: string;
  descricao: string;
  selecionado: boolean;
}

export interface SituacoesDasParcela {
  codigoSituacaoParcela: string;
  descricao: string;
  selecionado: boolean;
}

const getConfigParcela = async (idConfigTaxa: string): Promise<IParcelas> => {
  try {
    const {data} = await Api.get(
      `campanha/configuracaotaxa/configuracoesparcela/${idConfigTaxa}`,
    );
    return data;
  } catch (error: any) {
    throw new Error(`Erro ao listar. ${error.data.data}`);
  }
};

export const ParcelaService = {
  getConfigParcela,
};
