import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {Stepper} from 'shared/components';
import {feedback} from 'shared/services/alertService';
import {CampaignService} from 'shared/services/api/CampaignService';
import {errorResponse} from 'shared/utils/errorResponse';

interface IProps {
  integrated: boolean;
  activeStep: number;
  idCampaign?: string;
  loading?: boolean;
  idEstabelecimento?: string;
  nivelEstabelecimentoDono?: string;
  isDisabled?: boolean;
  errorStep?: number;
}

export const StepperCampaign: React.FC<IProps> = ({
  integrated,
  activeStep,
  errorStep,
  idCampaign = '',
  loading = false,
  idEstabelecimento,
  nivelEstabelecimentoDono,
  isDisabled = false,
}) => {
  const navigate = useNavigate();
  const [currentIdEstabelecimento, setCurrentIdEstabelecimento] =
    useState(idEstabelecimento);
  const [currentNivelEstabelecimentoDono, setNivelEstabelecimentoDono] =
    useState(idEstabelecimento);

  const handleGetCampaign = useCallback(async () => {
    try {
      const data = await CampaignService.getCampaign({
        id: idCampaign,
        verificarPermissaoEditar: true,
      });

      if (data) {
        setCurrentIdEstabelecimento(data.idEstabelecimentoDono);
        setNivelEstabelecimentoDono(String(data.nivelEstabelecimentoDono));
      }
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
  }, [idCampaign]);

  useEffect(() => {
    if (
      idEstabelecimento === undefined ||
      nivelEstabelecimentoDono === undefined
    ) {
      handleGetCampaign();
    }
  }, [idEstabelecimento, nivelEstabelecimentoDono]);

  return integrated ? (
    <Stepper
      loading={loading}
      errorStep={errorStep}
      steps={
        idCampaign
          ? [
              {
                label: 'Dados gerais',

                onClick: () => navigate(`/campanha/editar/${idCampaign}`),
              },
              {
                label: 'Configuração de e-mails',
                onClick: () => navigate(`/campanha/email-config/${idCampaign}`),
              },
              {
                label: 'Funil',
                onClick: () =>
                  navigate(
                    `/campanha/funil/${idCampaign}/${currentIdEstabelecimento}/${currentNivelEstabelecimentoDono}`,
                  ),
              },
              {
                label: 'Ofertas',
                onClick: () => navigate(`/campanha/ofertas/${idCampaign}`),
              },
              {
                label: 'Ficha de inscrição',
                onClick: () =>
                  navigate(`/campanha/ficha-de-inscricao/${idCampaign}`),
              },
              {
                label: 'Personalização',
                onClick: () =>
                  navigate(`/campanha/personalizacao/${idCampaign}`),
              },
              {
                label: 'Integração',
                onClick: () => navigate(`/campanha/integracao/${idCampaign}`),
              },
            ]
          : [
              {label: 'Dados gerais'},
              {label: 'Configuração de e-mails'},
              {label: 'Funil'},
              {label: 'Ofertas'},
              {label: 'Ficha de inscrição'},
              {label: 'Personalização'},
              {label: 'Integração'},
            ]
      }
      activeStep={activeStep}
      isDisabled={isDisabled}
    />
  ) : (
    <Stepper
      errorStep={errorStep}
      loading={loading}
      steps={
        idCampaign
          ? [
              {
                label: 'Dados gerais',
                onClick: () => navigate(`/campanha/editar/${idCampaign}`),
              },
              {
                label: 'Configuração de e-mails',
                onClick: () => navigate(`/campanha/email-config/${idCampaign}`),
              },
              {
                label: 'Funil',
                onClick: () =>
                  navigate(
                    `/campanha/funil/${idCampaign}/${currentIdEstabelecimento}/${currentNivelEstabelecimentoDono}`,
                  ),
              },
              {
                label: 'Ofertas',
                onClick: () => navigate(`/campanha/ofertas/${idCampaign}`),
              },
              {
                label: 'Ficha de inscrição',
                onClick: () =>
                  navigate(`/campanha/ficha-de-inscricao/${idCampaign}`),
              },
              {
                label: 'Personalização',
                onClick: () =>
                  navigate(`/campanha/personalizacao/${idCampaign}`),
              },
            ]
          : [
              {label: 'Dados gerais'},
              {label: 'Configuração de e-mails'},
              {label: 'Funil'},
              {label: 'Ofertas'},
              {label: 'Ficha de inscrição'},
              {label: 'Personalização'},
            ]
      }
      activeStep={activeStep}
      isDisabled={isDisabled}
    />
  );
};
