import React, {useCallback, useEffect, useState, useMemo} from 'react';
import {
  Box,
  Grid,
  Paper,
  Table,
  Checkbox,
  MenuItem,
  TableRow,
  TextField,
  TableBody,
  TableCell,
  TableHead,
  IconButton,
  Typography,
  TableFooter,
  TableContainer,
  FormControlLabel,
} from '@material-ui/core';
import {useParams} from 'react-router-dom';
import {AddCircleRounded, Delete} from '@material-ui/icons';

import {
  ITypeMask,
  ITypeField,
  FormService,
  ITokenField,
  IOptionField,
  IValidationField,
  IValidationGroup,
  IFormatText,
} from 'shared/services/api/FormService';
import {Button, Modal} from 'shared/components';
import {useCampaign, useTerm} from 'shared/hooks';
import {feedback} from 'shared/services/alertService';
import {errorResponse} from 'shared/utils/errorResponse';

import {IsErrors} from './IsErrors';

interface IProps {
  opened: boolean;
  onClose(): void;
  section: string;
  fieldId?: string;
  isDefault: boolean;
  isEditing?: boolean;
  useSchedule?: boolean;
  defaultField?: ITokenField;
  validationGroups: IValidationGroup[];
}

export const ModalField: React.FC<IProps> = ({
  opened,
  onClose,
  fieldId,
  section,
  isDefault,
  defaultField,
  validationGroups,
  isEditing = true,
  useSchedule = false,
}) => {
  const [validations, setValidations] = useState<IValidationField[]>([]);
  const [typeField, setTypeField] = useState<ITypeField[]>([]);
  const [options, setOptions] = useState<IOptionField[]>([]);
  const [typeMask, setTypeMask] = useState<ITypeMask[]>([]);
  const [isUseErrors, setIsUseErrors] = useState(false);
  const [changeMask, setChangeMask] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [field, setField] = useState<ITokenField>({
    ativo: true,
    idSecaoDaFicha: section,
  } as ITokenField);
  const [formatTextField, setFormatTextField] = useState(false);
  const [textFormats, setTextFormats] = useState<IFormatText[]>([]);

  const {terms} = useTerm();
  const {id = '0'} = useParams<'id'>();
  const {getSections, sectionFiltered, sections, hasSubscribers} =
    useCampaign();

  const handleGetTypeMask = useCallback(async () => {
    try {
      const response = await FormService.getTypeMask();

      response && setTypeMask(response);
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
  }, []);

  const handleResetData = useCallback(() => {
    setField({
      ativo: true,
      idSecaoDaFicha: section,
    } as ITokenField);
    setValidations([]);
    setOptions([]);
    setIsUseErrors(false);
    setChangeMask(false);
    onClose();
    setSubmitted(false);
  }, [onClose, section]);

  const handleGetTypeField = useCallback(async () => {
    try {
      const response = await FormService.getTypeField();

      response && setTypeField(response);
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
  }, []);

  const handleGetTextFormats = useCallback(async () => {
    try {
      const response = await FormService.getTextFormatsOfCampaign();

      response && setTextFormats(response);
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
  }, []);

  const handleAddOption = useCallback(() => {
    setOptions((state) => [
      ...state,
      {
        ordem: state.length + 1,
        nome: '',
        codigoParaIntegracao: undefined,
      },
    ]);
  }, []);

  const handleAddValidation = useCallback(() => {
    setValidations((state) => [
      ...state,
      {idCondicaoParaValidacao: 0} as IValidationField,
    ]);
  }, []);

  const handleDeleteOption = useCallback((index) => {
    setOptions((state) => state.filter((_, i) => i !== index));
  }, []);

  const handleDeleteValidation = useCallback((index) => {
    setValidations((state) => state.filter((_, i) => i !== index));
  }, []);

  const handleChangeNameOption = useCallback(
    (event, index) =>
      setOptions((state) =>
        state.map((oldState, oldStateIndex) => {
          if (index === oldStateIndex) {
            oldState.nome = event.target.value;
          }
          return oldState;
        }),
      ),
    [],
  );

  const handleChangeCodForIntegrationOption = useCallback(
    (event, index) =>
      setOptions((state) =>
        state.map((oldState, oldStateIndex) => {
          if (index === oldStateIndex) {
            oldState.codigoParaIntegracao = event.target.value;
          }
          return oldState;
        }),
      ),
    [],
  );

  const handleConditionValidation = useCallback(
    (event, index) =>
      setValidations((state) =>
        state.map((oldState, oldStateIndex) => {
          if (index === oldStateIndex) {
            oldState.idCondicaoParaValidacao = event.target.value;
          }
          return oldState;
        }),
      ),
    [],
  );

  const handleConfigValidation = useCallback(
    (event, index) =>
      setValidations((state) =>
        state.map((oldState, oldStateIndex) => {
          if (index === oldStateIndex) {
            oldState.validacaoInicial = String(event.target.value);
          }
          return oldState;
        }),
      ),
    [],
  );

  const handleDateConfigValidation = useCallback(
    (date: Date | null, index) =>
      setValidations((state) =>
        state.map((oldState, oldStateIndex) => {
          if (index === oldStateIndex) {
            oldState.validacaoInicial = String(date);
          }
          return oldState;
        }),
      ),
    [],
  );

  const handleAdditionalConfigValidation = useCallback(
    (event, index) =>
      setValidations((state) =>
        state.map((oldState, oldStateIndex) => {
          if (index === oldStateIndex) {
            oldState.validacaoFinal = String(event.target.value);
          }
          return oldState;
        }),
      ),
    [],
  );

  const handleDateAdditionalConfig = useCallback(
    (date: Date | null, index) =>
      setValidations((state) =>
        state.map((oldState, oldStateIndex) => {
          if (index === oldStateIndex) {
            oldState.validacaoFinal = String(date);
          }
          return oldState;
        }),
      ),
    [],
  );

  const handleErrorMessageValidation = useCallback(
    (event, index) =>
      setValidations((state) =>
        state.map((oldState, oldStateIndex) => {
          if (index === oldStateIndex) {
            oldState.mensagem = event.target.value;
          }
          return oldState;
        }),
      ),
    [],
  );

  const fieldType = useMemo(
    () => field.idTipoDeCampo === 5 || field.idTipoDeCampo === 6,
    [field.idTipoDeCampo],
  );

  const sectionIds = useMemo(() => {
    if (!isDefault) {
      return sections.filter((item) => !item.idSecaoPredefinida);
    } else {
      return sectionFiltered;
    }
  }, [sectionFiltered, isDefault]);

  const handleUsedFieldName = useCallback(() => {
    let fields: Array<{name: string; id: string; idSection: string}> = [];

    sections.map((item) =>
      item.campos.map((i) => {
        fields = [
          ...fields,
          {
            id: i.idCampo,
            name: i.nomeCampo,
            idSection: item.idSecao,
          },
        ];
      }),
    );

    if (defaultField && fieldId) {
      return (
        fields
          .filter((i) => i.idSection === section)
          .filter((i) => i.id !== fieldId)
          .filter(
            (i) =>
              i.name?.toLowerCase().trim() === field.nome?.toLowerCase().trim(),
          ).length > 0
      );
    }

    return (
      fields
        .filter((i) => i.idSection === section)
        .filter(
          (i) =>
            i.name?.toLowerCase().trim() === field.nome?.toLowerCase().trim(),
        ).length > 0
    );
  }, [sections, fieldId, defaultField, section, field.nome]);

  const isUsedSchedule = useMemo(() => {
    let aux = false;
    sections.flatMap((sec) => {
      if (sec.campos.find((item) => item.idTipoDeCampo === 1)) {
        aux = true;
      }
    });
    return aux;
  }, [sections]);

  const isEditedSchedule = useMemo(() => {
    return isUsedSchedule && defaultField?.idTipoDeCampo === 1;
  }, [defaultField?.idTipoDeCampo, isUsedSchedule]);

  const handleCreateOrUpdateField = useCallback(async () => {
    if (field.idTipoDeCampo === 1 && !isEditedSchedule && isUsedSchedule) {
      setSubmitted(true);
      return;
    }

    if (!field.nome || field.nome.length === 0 || field.idTipoDeCampo === 0) {
      setSubmitted(true);
      return;
    }

    try {
      setLoading(true);
      const fieldSerialized = {
        ...field,
        opcoes: fieldType ? options : [],
        validacoes: isUseErrors ? validations : [],
      };

      defaultField && fieldId
        ? await FormService.updateTokenField(fieldId, {
            ...fieldSerialized,
            ordem: defaultField.ordem,
          })
        : await FormService.postTokenField(fieldSerialized);

      await getSections(id);
      handleResetData();
    } catch (error) {
      feedback(errorResponse(error), 'error');
    } finally {
      setLoading(false);
    }
  }, [
    field,
    isEditedSchedule,
    isUsedSchedule,
    fieldType,
    options,
    isUseErrors,
    validations,
    defaultField,
    fieldId,
    getSections,
    id,
    handleResetData,
  ]);

  useEffect(() => {
    if (defaultField) {
      setField(defaultField);
      setValidations(defaultField.validacoes);
      setOptions(defaultField.opcoes);
      defaultField?.validacoes?.length > 0 && setIsUseErrors(true);
    }
  }, [defaultField, section]);

  useEffect(() => {
    if (field.idTipoMascara && field.idTipoMascara !== 1) {
      const mask = typeMask.filter((item) => item.id === field.idTipoMascara)[0]
        .mascara;
      setField((state) => (state = {...state, mascara: String(mask)}));
    } else {
      if (defaultField) {
        if (field.idTipoMascara === 1 && changeMask) {
          setField((state) => (state = {...state, mascara: ''}));
        } else {
          setField(
            (state) => (state = {...state, mascara: defaultField.mascara}),
          );
        }
      } else {
        setField((state) => (state = {...state, mascara: ''}));
      }
    }
  }, [changeMask, defaultField, field.idTipoMascara, typeMask]);

  useEffect(() => {
    handleGetTypeMask();
    handleGetTextFormats();
  }, [handleGetTypeMask, handleGetTextFormats]);

  useEffect(() => {
    if (field?.tiposDeCampoRestrito?.length > 0) {
      setTypeField(field.tiposDeCampoRestrito);
    } else {
      handleGetTypeField();
    }
  }, [handleGetTypeField, field.tiposDeCampoRestrito]);

  useEffect(() => {
    setFormatTextField(field.idFormatoTexto ? true : false);
  }, [field.idFormatoTexto]);

  return (
    <Modal
      labelSaveButton={defaultField && fieldId ? 'Salvar' : 'Incluir'}
      disabled={!isEditing}
      labelCloseButton="Cancelar"
      onClick={handleCreateOrUpdateField}
      onClose={handleResetData}
      loading={loading}
      opened={opened}
      title={
        isEditing
          ? defaultField && fieldId
            ? 'Editar dados do campo'
            : 'Dados do campo'
          : 'Dados do campo'
      }
      maxWidth="md">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box display="flex" flexDirection="column" marginLeft={1}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={field.ativo}
                  disabled={!isEditing || field.obrigatorioCampoPredefinido}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setField(
                      (state) =>
                        (state = {...state, ativo: event.target.checked}),
                    );
                  }}
                />
              }
              label="Ativo"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={field.obrigatorio}
                  disabled={!isEditing || field.obrigatorioCampoPredefinido}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setField(
                      (state) =>
                        (state = {...state, obrigatorio: event.target.checked}),
                    );
                  }}
                />
              }
              label="Obrigatório"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={field.disponivelParaLead}
                  disabled={!isEditing || field.obrigatorioCampoPredefinido}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setField(
                      (state) =>
                        (state = {
                          ...state,
                          disponivelParaLead: event.target.checked,
                        }),
                    );
                  }}
                />
              }
              label={`Disponível para ${terms.termoLead}`}
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            disabled={!isEditing}
            error={(!field.nome && submitted) || handleUsedFieldName()}
            helperText={
              !field.nome && submitted
                ? 'Campo requerido'
                : handleUsedFieldName() &&
                  'O nome do campo deve ser único por seção da ficha de inscrição.'
            }
            value={field.nome}
            label="Nome do campo *"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setField(
                (state) => (state = {...state, nome: event.target.value}),
              );
            }}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            disabled={!isEditing}
            value={field.textoDeAjuda}
            label="Texto de ajuda"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setField(
                (state) =>
                  (state = {...state, textoDeAjuda: event.target.value}),
              );
            }}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            select
            fullWidth
            variant="outlined"
            label="Tipo de campo *"
            value={field.idTipoDeCampo}
            disabled={(defaultField && hasSubscribers) || !isEditing}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setChangeMask(false);
              setField(
                (state) =>
                  (state = {
                    ...state,
                    idTipoDeCampo: Number(event.target.value),
                  }),
              );
            }}
            error={
              (!field.idTipoDeCampo && submitted) ||
              (field.idTipoDeCampo === 1 && !isEditedSchedule && isUsedSchedule)
            }
            helperText={
              !field.idTipoDeCampo && submitted
                ? 'Campo requerido'
                : field.idTipoDeCampo === 1 &&
                  !isEditedSchedule &&
                  isUsedSchedule &&
                  'Já existe um campo de agenda na ficha de inscrição.'
            }>
            <MenuItem value={0} disabled />
            {typeField?.map((item) => {
              if (!useSchedule && item.id === 1) {
                return;
              } else {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.nome}
                  </MenuItem>
                );
              }
            })}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            label="Seção *"
            value={field.idSecaoDaFicha}
            disabled={isDefault || !isEditing}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setField(
                (state) =>
                  (state = {
                    ...state,
                    idSecaoDaFicha: event.target.value,
                  }),
              );
            }}
            fullWidth
            select>
            <MenuItem value={0} disabled />
            {sectionIds?.map((item) => (
              <MenuItem key={item.idSecao} value={item.idSecao}>
                {item.nomeSecao}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        {field.idTipoDeCampo && field.idTipoDeCampo === 9 && (
          <>
            <Grid item xs={12}>
              <Box marginLeft={1}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={!isEditing}
                      checked={field.usaMascara}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        setField(
                          (state) =>
                            (state = {
                              ...state,
                              usaMascara: event.target.checked,
                            }),
                        );
                      }}
                    />
                  }
                  label="Usar máscara"
                />
              </Box>
            </Grid>

            {field.usaMascara && (
              <>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    disabled={!isEditing}
                    error={!field.idTipoMascara && submitted}
                    helperText={
                      !field.idTipoMascara && submitted && 'Campo requerido'
                    }
                    value={field.idTipoMascara}
                    label="Tipo de máscara *"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setField({
                        ...field,
                        idTipoMascara: Number(event.target.value),
                      });
                      if (Number(event.target.value) === 1) {
                        setChangeMask(true);
                      } else {
                        setChangeMask(false);
                      }
                    }}
                    fullWidth
                    select>
                    <MenuItem value={0} disabled />
                    {typeMask?.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.nome}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    error={!field.mascara && submitted}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    helperText={
                      !field.mascara && submitted && 'Campo requerido'
                    }
                    value={field.mascara}
                    disabled={field.idTipoMascara !== 1 || !isEditing}
                    label="Conteúdo *"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      if (field.idTipoMascara === 1) {
                        setField({
                          ...field,
                          mascara: event.target.value,
                        });
                      }
                    }}
                    fullWidth
                  />
                </Grid>
              </>
            )}
          </>
        )}

        {!isDefault && field.idTipoDeCampo && fieldType && (
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              <strong>Opções:</strong>
            </Typography>

            <Box marginBottom={1} />

            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width="140px" height="42px">
                      Ações
                    </TableCell>
                    <TableCell>Nome</TableCell>
                    <TableCell width="200px" align="right">
                      Código de integração
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {options?.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <IconButton
                          color="primary"
                          onClick={() => handleDeleteOption(index)}>
                          <Delete />
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <TextField
                          disabled={!isEditing}
                          error={
                            (item.nome.length === 0 && submitted) ||
                            options
                              .filter((_, i) => i !== index)
                              .filter(
                                (i) =>
                                  i.nome.toLowerCase() ===
                                  item.nome.toLowerCase(),
                              ).length > 0
                          }
                          helperText={
                            item.nome.length === 0 && submitted
                              ? 'Campo requerido'
                              : options
                                  .filter((_, i) => i !== index)
                                  .filter(
                                    (i) =>
                                      i.nome.toLowerCase() ===
                                      item.nome.toLowerCase(),
                                  ).length > 0 &&
                                'O nome da opção deve ser único por campo.'
                          }
                          fullWidth
                          value={item.nome}
                          onChange={(e) => handleChangeNameOption(e, index)}
                          variant="outlined"
                          size="small"
                        />
                      </TableCell>
                      <TableCell align="right">
                        <TextField
                          disabled={!isEditing}
                          fullWidth
                          value={item.codigoParaIntegracao}
                          onChange={(e) =>
                            handleChangeCodForIntegrationOption(e, index)
                          }
                          variant="outlined"
                          type="number"
                          size="small"
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Table>
                <TableFooter>
                  <TableCell style={{position: 'relative'}}>
                    <Button
                      variant="text"
                      textTransform="uppercase"
                      disabled={!isEditing}
                      startIcon={<AddCircleRounded />}
                      label="Adicionar opção"
                      onClick={handleAddOption}
                    />
                  </TableCell>
                </TableFooter>
              </Table>
            </TableContainer>
          </Grid>
        )}

        {(field.idTipoDeCampo === 3 ||
          field.idTipoDeCampo === 7 ||
          field.idTipoDeCampo === 8 ||
          field.idTipoDeCampo === 9 ||
          field.idTipoDeCampo === 10) && (
          <Grid item xs={12}>
            <Box marginLeft={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isUseErrors}
                    disabled={!isEditing}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setIsUseErrors(event.target.checked);
                    }}
                  />
                }
                label="Validar resposta"
              />
            </Box>
          </Grid>
        )}

        {(field.idTipoDeCampo === 3 ||
          field.idTipoDeCampo === 7 ||
          field.idTipoDeCampo === 8 ||
          field.idTipoDeCampo === 9 ||
          field.idTipoDeCampo === 10) &&
          isUseErrors && (
            <IsErrors
              field={field}
              isEditing={isEditing}
              submitted={submitted}
              validations={validations}
              config={handleConfigValidation}
              addValidation={handleAddValidation}
              condition={handleConditionValidation}
              dateConfig={handleDateConfigValidation}
              deleteValidation={handleDeleteValidation}
              errorMessage={handleErrorMessageValidation}
              dateAdditionalConfig={handleDateAdditionalConfig}
              additionalConfig={handleAdditionalConfigValidation}
              validationConditions={
                validationGroups.filter(
                  (item) =>
                    item.idTipoDeCampoPermiteValidacao === field.idTipoDeCampo,
                )[0].condicoesParaValidacao
              }
            />
          )}

        {(field.idTipoDeCampo === 9 || field.idTipoDeCampo === 10) &&
          !field.usaMascara && (
            <Grid item xs={12}>
              <Box marginLeft={1}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formatTextField}
                      disabled={!isEditing}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        setFormatTextField(event.target.checked);
                      }}
                    />
                  }
                  label="Formatar campo"
                />
              </Box>
            </Grid>
          )}

        {formatTextField &&
          (field.idTipoDeCampo === 9 || field.idTipoDeCampo === 10) &&
          !field.usaMascara && (
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                label="Formar texto como"
                value={field.idFormatoTexto || 0}
                disabled={!isEditing}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setField({
                    ...field,
                    idFormatoTexto: Number(event.target.value),
                  });
                }}
                fullWidth
                select>
                <MenuItem value={0} disabled />
                {textFormats?.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.descricao}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}
      </Grid>
    </Modal>
  );
};
