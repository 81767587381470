import {Api} from 'shared/services/axiosConfig';

export interface IMenuOption {
  id: string;
  path: string;
  label: string;
  icon: string;
}

const getMenuOptions = async (): Promise<IMenuOption[]> => {
  try {
    const response = await Api.get('/menulateral');

    return response.data;
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

export const MenuOptionService = {
  getMenuOptions,
};
