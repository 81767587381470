import React from 'react';

import {IconButton} from '@material-ui/core';
import {CheckCircle, FiberManualRecord} from '@material-ui/icons';

interface IProps {
  color: string;
  colorPiker: string;
  disabled?: boolean;
  setColorPiker(value: string): void;
}

export const IconButtonColor: React.FC<IProps> = ({
  color,
  colorPiker,
  disabled = false,
  setColorPiker,
}) => {
  return (
    <IconButton onClick={() => setColorPiker(color)} disabled={disabled}>
      {colorPiker === color ? (
        <CheckCircle style={{color: color}} />
      ) : (
        <FiberManualRecord style={{color: color}} />
      )}
    </IconButton>
  );
};
