import {Api} from 'shared/services/axiosConfig';

export interface IUserPermission {
  id: string;
  nome: string;
}

export interface IUserContent {
  id: number;
  nome: string;
  timezone: number;
  permissoes: IUserPermission[];
  ehUsuarioMasterGvdasa: boolean;
  urlFormululario: string;
}

export interface IUserContext {
  sucesso: boolean;
  data?: IUserContent;
}

export interface IAdmUser {
  nome: string;
  login: string;
  ativo: boolean;
  idUsuarioAuth: number;
}

const getUser = async (): Promise<IUserContext> => {
  try {
    const {data} = await Api.get('/ContextoUsuario');
    return data;
  } catch (error) {
    return {} as IUserContext;
    // throw new Error('Erro ao carregar dados do usuário logado.');
  }
};

const getAdmUser = async (active?: boolean): Promise<IAdmUser[]> => {
  try {
    const {data} = await Api.get(
      `/UsuarioAdministrativo${
        active ? '?ativo=true' : active !== undefined ? '?ativo=false' : ''
      }`,
    );

    return data;
  } catch (error: any) {
    throw new Error('Erro ao listar usuários!');
  }
};

export const UserService = {
  getUser,
  getAdmUser,
};
