import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  MenuItem,
  DialogActions,
  Button,
  Tooltip,
} from '@material-ui/core';
import {NumberFormatValues, NumericFormat} from 'react-number-format';
import React, {useCallback, useEffect, useState} from 'react';
import {Skeleton} from '@material-ui/lab';
import ErrorIcon from '@material-ui/icons/Error';
import {
  FeeService,
  IBankAccount,
  IFinancialEvent,
  IPaymentInfo,
} from '../../../../shared/services/api/BillingFee';
import {feedback} from 'shared/services/alertService';
import {errorResponse} from 'shared/utils/errorResponse';

interface IProps {
  id: string;
  isOpen: boolean;
  isLoading: boolean;
  handleIsLoading: (value: boolean) => void;
  handleClose: () => void;
  titulo: string;
  possuiPix: boolean;
  possuiCartao: boolean;
  inativo: boolean;
  offerValue: string;
  idSituacaoValorOferta: number;
}

//type TCheckboxController = 2 | 1 | 0;
// type TCheckboxController = 'free' | 'offerValue' | null;

export const EditModal: React.FC<IProps> = ({
  id,
  titulo,
  isOpen,
  inativo,
  isLoading,
  handleIsLoading,
  handleClose,
  possuiCartao,
  possuiPix,
  offerValue,
  idSituacaoValorOferta,
}) => {
  const [moneyValue, setMoneyValue] = useState<string>(offerValue);
  const [checkboxController, setCheckboxController] = useState<number>(0);
  const [isPix, setIsPix] = useState(possuiPix);
  const [isSaving, setIsSaving] = useState(false);
  const [isCreditCard, setIsCreditCard] = useState(possuiCartao);
  const [paymentCardInfo, setPaymentCardInfo] = useState<IPaymentInfo[]>([]);
  const [paymentPixInfo, setPaymentPixInfo] = useState<IPaymentInfo[]>([]);
  const [financialEvent, setFinancialEvent] = useState<IFinancialEvent[]>([]);
  const [cardBankAccount, setCardBankAccount] = useState<IBankAccount[]>([]);
  const [pixBankAccount, setPixBankAccount] = useState<IBankAccount[]>([]);
  const [selectedPaymentCard, setSelectedPaymentCard] = useState<string>('');
  const [loadScreen, setLoadScreen] = useState(false);
  const [selectedPaymentPix, setSelectedPaymentPix] = useState<string>('');
  const [selectedFinancialEvent, setSelectedFinancialEvent] =
    useState<string>('');
  const [selectedCardBankAccount, setSelectedCardBankAccount] =
    useState<string>('');
  const [selectedPixdBankAccount, setSelectedPixBankAccount] =
    useState<string>('');
    
  useEffect(() => {
    setIsPix(possuiPix);
    setIsCreditCard(possuiCartao);
    setMoneyValue(offerValue);
    setCheckboxController(idSituacaoValorOferta);
  }, [isOpen]);

  const handleGetLocaisDePagamento = useCallback(async () => {
    setLoadScreen(true);
    try {
      const responseCard = await FeeService.getLocaisPagamento(id, 2);
      const responsePix = await FeeService.getLocaisPagamento(id, 1);
      setPaymentCardInfo(responseCard);
      setSelectedPaymentCard(
        responseCard.find((item) => item.selecionado)?.codigoBanco || '',
      );
      setPaymentPixInfo(responsePix);
      setSelectedPaymentPix(
        responsePix.find((item) => item.selecionado)?.codigoBanco || '',
      );
    } catch (error) {
      feedback(errorResponse(error), 'error');
    } finally {
      handleIsLoading(false);
      setLoadScreen(false);
    }
  }, [id]);

  const handleModalSubmit = async () => {
    setIsSaving(true);
    try {
      feedback('Salvando', 'loading');
      await FeeService.patchOferta({
        idOferta: id,
        possuiPix: isPix,
        possuiCartaoCredito: isCreditCard,
        valorOferta:
          checkboxController === 1 ? Number(moneyValue.replace(',', '.')) : 0,
        codigoBancoPix: isPix ? selectedPaymentPix : null,
        codigoBancoContaPix: isPix ? selectedPixdBankAccount : null,
        codigoBancoCartaoCredito: isCreditCard ? selectedPaymentCard : null,
        codigoBancoContaCartaoCredito: isCreditCard
          ? selectedCardBankAccount
          : null,
        codigoEvento:
          checkboxController === 2 || selectedFinancialEvent === ''
            ? null
            : selectedFinancialEvent,
        idSituacaoValorOferta: isNaN(checkboxController)
          ? 0
          : checkboxController,
      });
      handleClose();
      feedback('Salvo', 'success');
    } catch (error) {
      feedback(errorResponse(error), 'error');
    } finally {
      setIsSaving(false);
    }
  };
  const handleGetContasBancarias = useCallback(
    async (codigoBanco: string, idTipoTaxa) => {
      try {
        const response = await FeeService.getContasBancarias(
          id,
          codigoBanco,
          idTipoTaxa,
        );

        if (idTipoTaxa === 1) {
          setPixBankAccount(response);
          setSelectedPixBankAccount(
            response.find((item) => item.selecionado)?.codigoBancoConta || '',
          );
        } else {
          setCardBankAccount(response);
          setSelectedCardBankAccount(
            response.find((item) => item.selecionado)?.codigoBancoConta || '',
          );
        }
        return response || [];
      } catch (error) {
        if (idTipoTaxa === 1) {
          setPixBankAccount([]);
          setSelectedPixBankAccount('');
        } else {
          setCardBankAccount([]);
          setSelectedCardBankAccount('');
        }
        feedback(errorResponse(error), 'error');
      }
    },
    [id, selectedPaymentCard],
  );
  const handleGetEventosFinanceiros = useCallback(async () => {
    try {
      const response = await FeeService.getEventosFincanceiros(id);
      setFinancialEvent(response);
      setSelectedFinancialEvent(
        response.find((item) => item.selecionado)?.codigoEvento || '',
      );
    } catch (error) {
      feedback(errorResponse(error), 'error');
    } finally {
      handleIsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (checkboxController === 1) {
      setMoneyValue(offerValue);
    }
    if (checkboxController === 0) {
      setMoneyValue(offerValue);
    }
  }, [checkboxController]);

  useEffect(() => {
    if (isOpen && id) {
      handleIsLoading(true);
      handleGetLocaisDePagamento();
      handleGetEventosFinanceiros();
    }
  }, [isOpen, id]);

  useEffect(() => {
    if (selectedPaymentPix) {
      handleGetContasBancarias(selectedPaymentPix, 1);
    }
  }, [selectedPaymentPix]);

  useEffect(() => {
    if (selectedPaymentCard) {
      handleGetContasBancarias(selectedPaymentCard, 2);
    }
  }, [selectedPaymentCard]);

  return (
    <Dialog maxWidth="lg" open={isOpen} onClose={handleClose} fullWidth>
      <DialogTitle>
        <Box marginBottom={2}>
          <Typography variant="h5" color="primary">
            <strong>{titulo}</strong>
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box maxWidth={'40%'}>
          <Box marginBottom={2} display={'flex'} alignItems={'center'}>
            <Box width={'100%'} marginRight={1}>
              <NumericFormat
                fullWidth
                variant="outlined"
                label="Valor específico da oferta"
                value={moneyValue}
                disabled={checkboxController !== 1 || !inativo}
                onValueChange={(a: NumberFormatValues) =>
                  setMoneyValue(a.formattedValue.toString())
                }
                customInput={TextField}
                InputProps={{
                  startAdornment: checkboxController !== 2 ? 'R$' : null,
                }}
                decimalScale={2}
                thousandSeparator="."
                decimalSeparator=","
                fixedDecimalScale={true}
                allowNegative={false}
              />
            </Box>

            <Box>
              <Tooltip
                title={
                  <h3>
                    Se nenhuma opção for selecionada será utilizado o valor
                    geral da campanha.
                  </h3>
                }>
                <ErrorIcon color="primary" />
              </Tooltip>
            </Box>
          </Box>
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            marginBottom={2}>
            <FormControlLabel
              disabled={!inativo}
              control={
                <Checkbox
                  checked={checkboxController === 1}
                  onChange={() =>
                    setCheckboxController((prev) => (prev !== 1 ? 1 : 0))
                  }
                />
              }
              label="Usar valor específico para esta oferta"
            />
            <FormControlLabel
              disabled={!inativo}
              control={
                <Checkbox
                  checked={checkboxController === 2}
                  onClick={() => {
                    setCheckboxController((prev) => (prev !== 2 ? 2 : 0)),
                      setMoneyValue('');
                    setSelectedFinancialEvent('');
                  }}
                />
              }
              label="Isento"
            />
          </Box>
        </Box>
        <Box marginBottom={0} marginTop={3}>
          <Typography variant="h6">
            <strong>Formas e Locais de pagamento</strong>
          </Typography>
        </Box>
        <Box marginBottom={2}>
          <FormControlLabel
            disabled={!inativo}
            label={
              <Typography variant="subtitle1">
                <strong>Pix</strong>
              </Typography>
            }
            control={
              <Checkbox
                checked={isPix}
                onClick={() => {
                  setIsPix((prev) => !prev), setSelectedPixBankAccount('');
                }}
              />
            }
          />
        </Box>
        <Box display={'flex'} width={'100%'}>
          <Box marginBottom={2} width={'45%'} marginRight={4}>
            {isLoading ? (
              <Skeleton variant="rect" height={36} />
            ) : (
              <TextField
                fullWidth
                disabled={!isPix || !inativo}
                value={isPix ? selectedPaymentPix : ''}
                label="Local de pagamento pix"
                variant="outlined"
                select>
                {paymentPixInfo?.map((paymentInfo) => {
                  return (
                    <MenuItem
                      value={paymentInfo.codigoBanco}
                      onClick={() =>
                        setSelectedPaymentPix(paymentInfo.codigoBanco)
                      }
                      key={paymentInfo.codigoBanco}>
                      {paymentInfo?.nomeBanco}
                    </MenuItem>
                  );
                })}
              </TextField>
            )}
          </Box>
          <Box marginBottom={2} width={'45%'}>
            {isLoading ? (
              <Skeleton variant="rect" height={36} />
            ) : (
              <TextField
                fullWidth
                disabled={!inativo || !isPix || pixBankAccount.length === 0}
                label="Conta bancária"
                variant="outlined"
                value={isPix ? selectedPixdBankAccount : ''}
                select>
                {pixBankAccount?.map((bankAccount) => {
                  return (
                    <MenuItem
                      value={bankAccount.codigoBancoConta}
                      onClick={() =>
                        setSelectedPixBankAccount(bankAccount.codigoBancoConta)
                      }
                      key={bankAccount.codigoBancoConta}>
                      {bankAccount.nomeCedente}
                    </MenuItem>
                  );
                })}
              </TextField>
            )}
          </Box>
        </Box>
        <Box marginBottom={2}>
          <FormControlLabel
            disabled={!inativo}
            label={
              <Typography variant="subtitle1">
                <strong>Cartão de crédito</strong>
              </Typography>
            }
            control={
              <Checkbox
                checked={isCreditCard}
                onClick={() => {
                  setIsCreditCard((prev) => !prev),
                    setSelectedCardBankAccount('');
                }}
              />
            }
          />
        </Box>
        <Box display={'flex'} width={'100%'}>
          <Box marginBottom={2} width={'45%'} marginRight={4}>
            {isLoading ? (
              <Skeleton variant="rect" height={36} />
            ) : (
              <TextField
                fullWidth
                disabled={!isCreditCard || !inativo}
                label="Local de pagamento cartão"
                value={isCreditCard ? selectedPaymentCard : ''}
                variant="outlined"
                select>
                {paymentCardInfo.map((paymentInfo) => {
                  return (
                    <MenuItem
                      onClick={() =>
                        setSelectedPaymentCard(paymentInfo.codigoBanco)
                      }
                      value={paymentInfo.codigoBanco}
                      key={paymentInfo.codigoBanco}>
                      {paymentInfo?.nomeBanco}
                    </MenuItem>
                  );
                })}
              </TextField>
            )}
          </Box>
          <Box marginBottom={2} width={'45%'}>
            {isLoading ? (
              <Skeleton variant="rect" height={36} />
            ) : (
              <TextField
                fullWidth
                disabled={
                  !isCreditCard || cardBankAccount.length === 0 || !inativo
                }
                label="Conta bancária"
                variant="outlined"
                value={isCreditCard ? selectedCardBankAccount : ''}
                select>
                {cardBankAccount.map((bankAccount) => {
                  return (
                    <MenuItem
                      onClick={() =>
                        setSelectedCardBankAccount(bankAccount.codigoBancoConta)
                      }
                      value={bankAccount.codigoBancoConta}
                      key={bankAccount.codigoBancoConta}>
                      {bankAccount?.nomeCedente}
                    </MenuItem>
                  );
                })}
              </TextField>
            )}
          </Box>
        </Box>
        <Box marginBottom={2}>
          <Typography variant="h5">
            <strong>Evento Financeiro</strong>
          </Typography>
        </Box>
        <Box marginBottom={2} width={'45%'} marginRight={4}>
          {/* financialEvent */}
          {isLoading ? (
            <Skeleton variant="rect" height={36} />
          ) : (
            <TextField
              disabled={!inativo}
              fullWidth
              label="Evento"
              value={selectedFinancialEvent}
              variant="outlined"
              select>
              {financialEvent.map((event) => {
                return (
                  <MenuItem
                    onClick={() =>
                      setSelectedFinancialEvent(event.codigoEvento)
                    }
                    value={event.codigoEvento}
                    key={event.codigoEvento}>
                    {event?.descricaoEvento}
                  </MenuItem>
                );
              })}
            </TextField>
          )}
        </Box>
      </DialogContent>

      <Box marginBottom={1}>
        <DialogActions>
          <Button
            size="large"
            variant="outlined"
            style={{marginRight: 18}}
            color="primary"
            onClick={handleClose}>
            cancelar
          </Button>
          <Button
            disabled={isSaving || !inativo}
            size="large"
            color="primary"
            variant="contained"
            onClick={handleModalSubmit}>
            Salvar
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
