import React, {useEffect, useState, useCallback, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {Box, TableRow, TableCell, IconButton} from '@material-ui/core';
import {format} from 'date-fns';
import {useTitle, useUser} from 'shared/hooks';
import {Edit} from '@material-ui/icons';
import {feedback} from 'shared/services/alertService';
import {errorResponse} from 'shared/utils/errorResponse';
import {Header, ModalConfirm, Table} from 'shared/components';
import {CampaignService, ICampaign} from 'shared/services/api/CampaignService';
import {FinancialService} from '../../shared/services/api/Finalcial';

export const Financeiro: React.FC = () => {
  const [filter, setFilter] = useState('');
  const [loading, setLoading] = useState(false);
  const [noFiltered, setNoFiltered] = useState(true);
  const [idDuplicated, setIdDuplicated] = useState('');
  const [campaignCount, setCampaignCount] = useState(0);
  const [tableFiltered, setTableFiltered] = useState(0);
  const [activeFiltered, setActiveFiltered] = useState(0);
  const [campaign, setCampaign] = useState<ICampaign[]>([]);
  const [openModalConfirmDuplicate, setOpenModalConfirmDuplicate] =
    useState(false);

  const [take, setTake] = useState(10);
  const [skip, setSkip] = useState(1);
  const SomentePossuiTaxa = true;

  const {setTitle} = useTitle();
  const {data: user} = useUser();
  const navigate = useNavigate();

  const permissions = useMemo(
    () => user?.permissoes.filter((i) => i.nome.includes('Campanha')),
    [user?.permissoes],
  );

  const canList = useMemo(
    () => !!permissions?.find((i) => i.nome.includes('Listar')),
    [permissions],
  );

  const idList = useMemo(
    () => permissions?.find((i) => i.nome.includes('Listar'))?.id,
    [permissions],
  );

  useEffect(() => {
    if (!canList) {
      navigate('/');
    }
  }, [canList, navigate]);

  useEffect(() => {
    if (tableFiltered === 0) {
      setNoFiltered(true);
    } else {
      setNoFiltered(false);
    }
    setActiveFiltered(tableFiltered);
  }, [tableFiltered]);

  const handleGetCampaigns = useCallback(async () => {
    setLoading(true);
    try {
      const data = await FinancialService.getCampaignsWithPagination(
        take,
        skip,
        SomentePossuiTaxa,
      );
      if (data) {
        setCampaign(data.data);
        setCampaignCount(data.qtdTotalDeRegistros);
      }
    } catch (error) {
      feedback(errorResponse(error), 'error');
    } finally {
      setLoading(false);
    }
  }, [idList, skip, take]);

  useEffect(() => setTitle('Taxa de cobrança'), [setTitle]);

  useEffect(() => {
    handleGetCampaigns();
  }, [handleGetCampaigns]);

  const filteredCampaign = useMemo(() => {
    if (campaign) {
      if (!noFiltered) {
        return campaign.filter(
          (item) =>
            (item.nomeCompleto.toLowerCase().includes(filter.toLowerCase()) ||
              item.nomeEstabelecimentoDono
                .toLowerCase()
                .includes(filter.toLowerCase()) ||
              `${format(
                new Date(item.vigenciaInicial),
                'dd/MM/yyyy',
              )} a ${format(new Date(item.vigenciaFinal), 'dd/MM/yyyy')}`
                .toLowerCase()
                .includes(filter.toLowerCase()) ||
              item.nomeSituacaoDeCampanha
                .toLowerCase()
                .includes(filter.toLowerCase())) &&
            item.idSituacaoDeCampanha === activeFiltered,
        );
      }

      return campaign.filter(
        (item) =>
          item.nomeCompleto.toLowerCase().includes(filter.toLowerCase()) ||
          item.nomeEstabelecimentoDono
            .toLowerCase()
            .includes(filter.toLowerCase()) ||
          `${format(new Date(item.vigenciaInicial), 'dd/MM/yyyy')} a ${format(
            new Date(item.vigenciaFinal),
            'dd/MM/yyyy',
          )}`
            .toLowerCase()
            .includes(filter.toLowerCase()) ||
          item.nomeSituacaoDeCampanha
            .toLowerCase()
            .includes(filter.toLowerCase()),
      );
    } else {
      return [];
    }
  }, [campaign, noFiltered, filter, activeFiltered]);

  const handleConfirmDuplicate = useCallback(async () => {
    try {
      setLoading(true);
      const responseId = await CampaignService.postCampaignDuplicate(
        idDuplicated,
      );

      navigate(`/campanha/editar/${responseId}`);
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
    setLoading(false);
    setIdDuplicated('');
    setOpenModalConfirmDuplicate(false);
  }, [idDuplicated, navigate]);

  return (
    <>
      <Header
        filter={filter}
        loading={loading}
        setFilter={setFilter}
        tableFiltered={tableFiltered}
        setTableFiltered={setTableFiltered}
        disabledButton={true}
        hideButton={true}
        onClick={() => navigate(`/campanha/dados-gerais`)}
        dataFilter={['TODOS', 'EM CONFIGURAÇÃO', 'CONFIGURADA', 'ENCERRADA']}
      />
      <Box marginTop={5} />

      <Table
        loading={loading}
        head={[
          {label: 'Ações'},
          {label: 'Campanhas'},
          {label: 'Estabelecimento Dono'},
          {label: 'Vigência'},
          {label: 'Taxa de cobrança'},
          {label: 'Situação da campanha'},
        ]}
        pagination={{
          count: Math.ceil(campaignCount / take),
          page: skip,
        }}
        take={take}
        length={campaignCount}
        rowsPerPage={filteredCampaign.length}
        onChangePage={(value: number) => setSkip(value)}
        onChangeTake={(value: number) => {
          setTake(value);
          setSkip(1);
        }}>
        {campaign &&
          campaign.length > 0 &&
          filteredCampaign.map((item) => (
            <TableRow key={item.id}>
              <TableCell>
                <IconButton
                  color="primary"
                  onClick={() => navigate(`/taxa-de-cobranca/${item.id}`)}
                  disabled={loading}>
                  <Edit />
                </IconButton>
              </TableCell>
              <TableCell>{item.nomeCompleto}</TableCell>
              <TableCell>{item.nomeEstabelecimentoDono}</TableCell>
              <TableCell>
                {format(new Date(item.vigenciaInicial), 'dd/MM/yyyy')} a{' '}
                {format(new Date(item.vigenciaFinal), 'dd/MM/yyyy')}
              </TableCell>
              <TableCell>{item.nomeSituacaoTaxa}</TableCell>
              <TableCell>{item.nomeSituacaoDeCampanha}</TableCell>
            </TableRow>
          ))}
      </Table>

      <ModalConfirm
        title="Confirmação de duplicação"
        description="Tem certeza que deseja duplicar este registro?"
        labelSaveButton="Confirmar"
        opened={openModalConfirmDuplicate}
        onClick={handleConfirmDuplicate}
        onClose={() => {
          setOpenModalConfirmDuplicate(false), setIdDuplicated('');
        }}
        loading={loading}
      />
    </>
  );
};
