import {IRequestResult} from 'shared/interfaces/IRequestResult';
import {Api} from 'shared/services/axiosConfig';

export interface IShift {
  id: string;
  nome: string;
  ativo: boolean;
}

export interface IShiftsPagination {
  qtdTotalDeRegistros: number;
  data: IShift[];
}

const postShift = async (
  payload: Omit<IShift, 'id'>,
): Promise<IRequestResult> => {
  try {
    await Api.post('/turno', payload);

    return {success: true};
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const updateShift = async (
  id: string,
  payload: Omit<IShift, 'id'>,
): Promise<IRequestResult> => {
  try {
    await Api.put(`/turno/${id}`, payload);

    return {success: true};
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const deleteShift = async (id: string): Promise<IRequestResult> => {
  try {
    await Api.delete(`/turno/${id}`);

    return {success: true};
  } catch (error: any) {
    throw new Error(error.data.data);
  }
};

const getShifts = async (active = false): Promise<IShift[]> => {
  try {
    const response = await Api.get(active ? '/turno?ativo=true' : '/turno');

    return response.data;
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const getShiftsPagination = async (
  ativo: number,
  campoDePesquisa: string,
  take: number,
  skip: number,
): Promise<IShiftsPagination> => {
  try {
    const {data: response} = await Api.get(
      `turno/paginado?ativo=${
        ativo === 0 ? '' : ativo === 1 ? true : false
      }&campoDePesquisa=
      ${campoDePesquisa}&take=${take}&skip=${skip}`,
    );
    return response;
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

export const ShiftService = {
  updateShift,
  deleteShift,
  getShifts,
  getShiftsPagination,
  postShift,
};
