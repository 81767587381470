import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
import React, {useCallback, useEffect, useState} from 'react';
import {feedback} from 'shared/services/alertService';
import {EditLeadService, ITarefas} from 'shared/services/api/EditLeadService';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import {errorResponse} from 'shared/utils/errorResponse';

interface Iprops {
  idLead: string;
  trigger: boolean;
}

export const Tarefas: React.FC<Iprops> = ({idLead, trigger}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [LoadTarefas, setLoadTarefas] = useState(true);
  const [tarefas, setTarefas] = useState<ITarefas[]>();
  const [idTarefas, setIdTarefas] = useState('');

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const [openModalExcluir, setOpenModalEXcluir] = useState(false);
  const handleOpenModalExcluir = () => {
    setOpenModalEXcluir(true);
  };

  const handleCloseModalExcluir = () => {
    setOpenModalEXcluir(false);
  };

  useEffect(() => {
    async function getTarefas() {
      //setIsLoading(true);
      try {
        const response = await EditLeadService.getTarefas(idLead);
        setTarefas(response);
        setIsLoading(false);
      } catch (error: any) {
        feedback(error.Mensagem, 'error');
      }
    }
    getTarefas();
  }, [idLead, LoadTarefas, trigger]);

  const postFecharTarefa = useCallback(async () => {
    try {
      await EditLeadService.postFecharTarefa(
        {
          idTarefa: idTarefas,
          estaFechada: true,
          dataFechamento: new Date(),
        },
        idTarefas,
      );
      feedback('Tarefa finalizada com sucesso', 'success');
    } catch (error) {
      feedback(errorResponse(error), 'error');
    } finally {
      handleCloseModal();
      setLoadTarefas(!LoadTarefas);
    }
  }, [idTarefas, LoadTarefas]);

  const excluirTarefa = useCallback(async () => {
    try {
      await EditLeadService.deletarTarefa(idTarefas);
      feedback('Tarefa excluida com sucesso', 'success');
    } catch (error) {
      feedback(errorResponse(error), 'error');
    } finally {
      handleCloseModalExcluir();
      setLoadTarefas(!LoadTarefas);
    }
  }, [idTarefas, LoadTarefas]);

  return (
    <>
      {isLoading ? (
        <>
          <Grid xs>
            <Box marginTop={2}>
              <Skeleton variant="rect" width={'100%'} height={500} />
            </Box>
          </Grid>
        </>
      ) : (
        <>
          <Grid xs>
            <Box marginTop={2}>
              <TableContainer component={Paper} elevation={3}>
                <Table>
                  <TableHead style={{backgroundColor: '#ffff'}}>
                    <TableRow>
                      <TableCell>
                        <Typography style={{marginTop: 15}} variant="subtitle1">
                          <b>Tarefa</b>
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography style={{marginTop: 15}} variant="subtitle1">
                          <b>Tipo</b>
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography style={{marginTop: 15}} variant="subtitle1">
                          <b>Vence em:</b>
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography style={{marginTop: 15}} variant="subtitle1">
                          <b>Finalizada em:</b>
                        </Typography>
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {tarefas?.map((item, key) => (
                      <TableRow key={key}>
                        <TableCell>
                          <Box display={'flex'} alignContent={'flex'}>
                            {item.estaFechada ? (
                              <CheckCircleIcon
                                style={{marginRight: 4}}
                                color={'primary'}
                              />
                            ) : (
                              <RadioButtonUncheckedIcon
                                style={{marginRight: 4}}
                                color={'primary'}
                              />
                            )}
                            <Typography>{item.nome}</Typography>
                          </Box>
                        </TableCell>
                        <TableCell>
                          {item.obrigatoria === true ? (
                            <Typography style={{padding: 8}}>
                              Obrigatória
                            </Typography>
                          ) : (
                            <Typography style={{padding: 8}}>
                              Opcional
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell>
                          <Box>
                            {item.venceEm === '0001-01-01T00:00:00' ? (
                              <Typography>-</Typography>
                            ) : (
                              <Typography>
                                {item.venceEm
                                  .substring(0, 10)
                                  .split('-')
                                  .reverse()
                                  .join('/')
                                  .substring(0, 10)
                                  .split('-')
                                  .reverse()
                                  .join('/')}
                              </Typography>
                            )}
                          </Box>
                        </TableCell>
                        <TableCell>
                          {item.fechadaEm === '0001-01-01T00:00:00' ? (
                            <Typography>-</Typography>
                          ) : (
                            <Typography>
                              {item.fechadaEm
                                .substring(0, 10)
                                .split('-')
                                .reverse()
                                .join('/')
                                .substring(0, 10)
                                .split('-')
                                .reverse()
                                .join('/')}
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell>
                          <Box display={'flex'} alignItems={'center'}>
                            <Button
                              disabled={item.estaFechada}
                              color="primary"
                              variant="outlined"
                              onClick={() => (
                                setIdTarefas(item.id), handleOpenModal()
                              )}>
                              Finalizar
                            </Button>
                            <Box marginLeft={1}>
                              <Button
                                variant="contained"
                                onClick={() => (
                                  setIdTarefas(item.id),
                                  handleOpenModalExcluir()
                                )}>
                                Excluir
                              </Button>
                            </Box>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>

          <Dialog open={openModal} onClose={handleCloseModal}>
            <DialogTitle>
              <Typography variant="h6" color="primary">
                Finalizar tarefa
              </Typography>
            </DialogTitle>
            <DialogContent>
              Tem certeza que deseja finalizar a tarefa?
            </DialogContent>
            <DialogActions>
              <Box padding={2} marginBottom={1}>
                <Button variant="outlined" onClick={() => handleCloseModal()}>
                  cancelar
                </Button>
                <Button
                  style={{marginLeft: 12}}
                  color="primary"
                  variant="contained"
                  onClick={() => postFecharTarefa()}>
                  Salvar
                </Button>
              </Box>
            </DialogActions>
          </Dialog>

          <Dialog open={openModalExcluir} onClose={handleCloseModalExcluir}>
            <DialogTitle>
              <Typography variant="h6" color="primary">
                Excluir tarefa
              </Typography>
            </DialogTitle>
            <DialogContent>
              Tem certeza que deseja excluir a tarefa?
            </DialogContent>
            <DialogActions>
              <Box padding={2} marginBottom={1}>
                <Button
                  variant="outlined"
                  onClick={() => handleCloseModalExcluir()}>
                  cancelar
                </Button>
                <Button
                  style={{marginLeft: 12}}
                  color="primary"
                  variant="contained"
                  onClick={() => excluirTarefa()}>
                  Excluir
                </Button>
              </Box>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
};
