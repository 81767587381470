import React from 'react';

import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  TextField,
} from '@material-ui/core';
import {Modal} from 'shared/components';
import {IShift} from 'shared/services/api/ShiftService';

interface IProps {
  openModal: boolean;
  loading: boolean;
  submitted: boolean;
  nameVerify: boolean;
  title: string;
  onClose(): void;
  onClick(): void;
  shift: IShift;
  setShift(value: IShift): void;
}

export const ModalShift: React.FC<IProps> = ({
  openModal,
  title,
  loading,
  submitted,
  nameVerify,
  onClose,
  onClick,
  shift,
  setShift,
}) => {
  return (
    <Modal
      opened={openModal}
      maxWidth="sm"
      labelSaveButton="Salvar"
      labelCloseButton="Cancelar"
      title={title}
      loading={loading}
      onClose={onClose}
      onClick={onClick}>
      <FormControl component="fieldset" fullWidth>
        <FormControlLabel
          control={
            <Checkbox
              checked={shift.ativo}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setShift({...shift, ativo: event.target.checked});
              }}
            />
          }
          label="Ativo"
        />

        <Box marginTop={2} />

        <TextField
          variant="outlined"
          error={(!shift.nome && submitted) || nameVerify}
          helperText={
            !shift.nome
              ? submitted && 'Campo requerido'
              : nameVerify && 'O nome do Turno deve ser único'
          }
          value={shift.nome}
          label="Turno *"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setShift({...shift, nome: event.target.value});
          }}
          fullWidth
        />
      </FormControl>
    </Modal>
  );
};
