import React, {useEffect} from 'react';
import {
  Box,
  Grid,
  Checkbox,
  Typography,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import {Modal} from 'shared/components';

import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import {
  ILead,
  ILeadCategory,
  ITarefa,
} from 'shared/services/api/LeadSituationService';

interface IProps {
  openModal: boolean;
  loading: boolean;
  nameVerify: boolean;
  title: string;
  onClose(): void;
  categories: ILeadCategory[];
  lead: ILead;
}

export const ModalVisualization: React.FC<IProps> = ({
  openModal,
  title,
  loading,
  nameVerify,
  onClose,
  categories,
  lead,
}) => {
  
  return (
    <Modal
      opened={openModal}
      maxWidth="sm"
      title={title}
      loading={loading}
      onClose={onClose}
      onClick={() => {
        return;
      }}
      labelSaveButton={''}
      labelCloseButton={'Fechar'}>
      <FormControl component="fieldset" fullWidth>
        <FormControlLabel
          control={<Checkbox checked={lead.ativo} disabled />}
          label="Ativo"
        />

        <Box marginTop={2} />

        <Grid container item>
          <Box width="100%" display={'flex'}>
            <Grid item xs={2}>
              <FormControl size="small" variant="outlined" fullWidth>
                <InputLabel>Ordem</InputLabel>
                <OutlinedInput
                  label="Ordem"
                  type="string"
                  value={lead.ordem}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs>
              <Box marginLeft={2}>
                <FormControl size="small" variant="outlined" fullWidth>
                  <InputLabel>Etapa do Funil</InputLabel>
                  <OutlinedInput
                    label="Etapa do Funil"
                    type="string"
                    value={lead.nome}
                    disabled
                  />
                </FormControl>
              </Box>
            </Grid>
          </Box>
        </Grid>

        <Box marginTop={4} />

        <FormControl variant="outlined">
          <InputLabel>{lead.categoria.nome}</InputLabel>
          <Select value={lead.idCategDeSituacaoDeLead} disabled fullWidth>
            <MenuItem value={0} disabled>
              Selecione uma categoria
            </MenuItem>
            {categories?.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.nome}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box marginLeft={1} marginTop={4}>
          <Typography variant="subtitle1" color="primary">
            Notificações
          </Typography>

          <Typography variant="subtitle2" color="textSecondary">
            Ao entrar nessa etapa, notificar:
          </Typography>
          <Box width="100%" display={'flex'} justifyItems="space-between">
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={lead.notificarLead}
                    disabled
                    name="checked1"
                    color="primary"
                  />
                }
                label="Lead"
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={lead.notificarAtendenteAtual}
                    disabled
                    name="checked2"
                    color="primary"
                  />
                }
                label="Atendente Responsável"
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={lead.notificarTodosOsAtendentes}
                    disabled
                    name="checked3"
                    color="primary"
                  />
                }
                label="Todos os atendentes"
              />
            </Box>
          </Box>
        </Box>

        <Box marginLeft={1} marginTop={4}>
          <Typography variant="subtitle1" color="primary">
            Tarefas
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            Lista de tarefas que o atendente deverá realizar nessa etapa:
          </Typography>
        </Box>
        <Grid container item>
          <Grid>
            {lead.tarefas.length > 0 ? (
              lead.tarefas.map((tarefa, indice) => {
                return (
                  <Box
                    key={indice}
                    width="100%"
                    display={'flex'}
                    justifyItems="space-between"
                    marginTop={3}>
                    <Grid item lg>
                      <FormControl size="small" variant="outlined" fullWidth>
                        <InputLabel>Nova tarefa</InputLabel>
                        <OutlinedInput
                          label="Nova tarefa"
                          value={
                            lead.tarefas[indice]?.nome === '' ||
                            lead.tarefas[indice]?.nome === undefined
                              ? tarefa.nome
                              : lead.tarefas[indice]?.nome
                          }
                          disabled
                          fullWidth
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <Box marginLeft={2}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={tarefa.obrigatoria}
                              disabled
                              color="primary"
                            />
                          }
                          label="Obrigatória"
                        />
                      </Box>
                    </Grid>
                  </Box>
                );
              })
            ) : (
              <Box marginLeft={1} marginTop={1}>
                <Typography variant="subtitle1" color="textSecondary">
                  Nenhuma tarefa cadastrada no momento.
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </FormControl>
    </Modal>
  );
};
