import {Api} from 'shared/services/axiosConfig';

export interface IUnityEstablishment {
  cnpj: string;
  id: string;
  idAlternativoDoEstabelecimento: string;
  idComNome: string;
  idEstabelecimento: string;
  idEstabelecimentoPai: string | null;
  nome: string;
  numeroDoNivel: number;
  possuiTermoLgpd: boolean;
  textoTermoLgpd: string;
  unidades: IUnityEstablishment[];
}

export interface IEstablishmentResponse {
  estabelecimentos: Omit<IUnityEstablishment[], 'unidades'>;
  niveIDoEstabelecimentoQueIndicaMatricula: number;
}

export interface ITreeEstablishmentResponse {
  estabelecimentos: IUnityEstablishment[];
  niveIDoEstabelecimentoQueIndicaMatricula: number;
}

const getEstablishments = async (
  idPermissao?: string,
): Promise<IEstablishmentResponse> => {
  try {
    const {data} = await Api.get(
      `/Estabelecimento/listaemcascata?idPermissao=${
        idPermissao ? idPermissao : ''
      }`,
    );

    return data;
  } catch (error: any) {
    throw new Error(error.data.data);
  }
};

const getTreeEstablishments = async (
  idPermissao?: string,
): Promise<ITreeEstablishmentResponse> => {
  try {
    const {data} = await Api.get(
      `/Estabelecimento/listaemarvore?idPermissao=${
        idPermissao ? idPermissao : ''
      }`,
    );

    return data;
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

export const EstablishmentService = {
  getEstablishments,
  getTreeEstablishments,
};
