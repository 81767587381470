import React, {useState, useEffect, useCallback} from 'react';

import {
  Box,
  Grow,
  Radio,
  Button,
  Dialog,
  Divider,
  FormLabel,
  TextField,
  IconButton,
  RadioGroup,
  Typography,
  FormControl,
  DialogContent,
  InputAdornment,
  FormControlLabel,
} from '@material-ui/core';
import {Brightness1Rounded, Event} from '@material-ui/icons';
import {Calendar, Day} from '@material-ui/pickers';
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date';
import {format} from 'date-fns';
import {IScheduleAvailability} from 'shared/services/api/ScheduleAvailabilityService';

import {useStyles} from './style';

interface IAvailableScheduleProps {
  name?: string;
  label: string;
  error?: boolean;
  disabled?: boolean;
  helperText?: string | false;
  defaultValue?: IScheduleAvailability;
  availabilities: IScheduleAvailability[];
  onChange: (availabilities: IScheduleAvailability) => void;
}

export const AvailableSchedule: React.FC<IAvailableScheduleProps> = ({
  name,
  label,
  onChange,
  helperText,
  defaultValue,
  error = false,
  availabilities,
  disabled = false,
}) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());

  const [selectedOption, setSelectedOption] = useState<IScheduleAvailability>();
  const [availableOptions, setAvailableOptions] = useState<
    IScheduleAvailability[]
  >([]);

  const [selectedHour, setSelectedHour] = useState<string>();

  const isEqualsDate = useCallback(
    (date: IScheduleAvailability, day: MaterialUiPickersDate) => {
      const dateAvailable = new Date(date.iniciaEm);
      const currentDayVerification = day ? new Date(day) : false;

      if (!currentDayVerification) return false;

      return (
        dateAvailable.getDate() == currentDayVerification.getDate() &&
        dateAvailable.getMonth() == currentDayVerification.getMonth() &&
        dateAvailable.getFullYear() == currentDayVerification.getFullYear()
      );
    },
    [],
  );

  const renderDay = useCallback(
    (
      day: MaterialUiPickersDate,
      selectedDate: MaterialUiPickersDate,
      isInCurrentMonth: boolean,
      dayComponent: JSX.Element,
    ) => {
      const matchFound = availabilities.findIndex((date) =>
        isEqualsDate(date, day),
      );

      const isAvailable =
        isInCurrentMonth &&
        matchFound > -1 &&
        matchFound < availabilities.length
          ? true
          : false;

      const isSelectedDay =
        selectedDate && day && selectedDate.getDate() === day.getDate();

      return (
        <Day>
          {dayComponent}
          {isAvailable && (
            <div className={classes.availableDay}>
              <Brightness1Rounded
                color={isSelectedDay ? 'secondary' : 'primary'}
                className={classes.icon}
              />
            </div>
          )}
        </Day>
      );
    },
    [availabilities, classes.availableDay, classes.icon, isEqualsDate],
  );

  const handleSelectHour = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedHour((event.target as HTMLInputElement).value);
    },
    [],
  );

  const handleConfirmHour = useCallback(() => {
    if (!selectedHour) return false;

    const indexFound = availabilities.findIndex(
      (item) => item.idDisponibilidadeDeAgenda == selectedHour,
    );

    if (indexFound != -1) {
      setSelectedOption(availabilities[indexFound]);
      onChange(availabilities[indexFound]);
    }

    setIsOpen(false);
  }, [availabilities, onChange, selectedHour]);

  useEffect(() => {
    const matchesFiltered = availabilities
      .filter((date) => isEqualsDate(date, selectedDate))
      .sort(function (a, b) {
        return a.iniciaEm < b.iniciaEm ? -1 : a.iniciaEm > b.iniciaEm ? 1 : 0;
      });

    setAvailableOptions(matchesFiltered);

    if (defaultValue) {
      setSelectedHour(defaultValue.idDisponibilidadeDeAgenda);
      setSelectedOption(defaultValue);
    }
  }, [availabilities, defaultValue, isEqualsDate, selectedDate]);

  return (
    <>
      <TextField
        fullWidth
        name={name}
        error={error}
        label={label}
        variant="outlined"
        disabled={disabled}
        helperText={helperText}
        value={
          selectedOption
            ? `${format(
                new Date(selectedOption.iniciaEm),
                'dd/MM/yyyy HH:mm',
              )} às ${format(new Date(selectedOption.terminaEm), 'HH:mm')} - ${
                selectedOption.nomeEntrevistador
              }`
            : ''
        }
        onChange={() => setIsOpen(true)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <IconButton onClick={() => setIsOpen(true)} disabled={disabled}>
                <Event color={disabled ? 'inherit' : 'primary'} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <Dialog
        onClose={() => setIsOpen(false)}
        open={isOpen}
        className={classes.dialog}>
        <Box className={classes.dialogContent}>
          <DialogContent className={classes.dialogContent}>
            <Calendar
              disablePast
              date={selectedDate}
              renderDay={renderDay}
              onChange={setSelectedDate}
            />
          </DialogContent>
        </Box>
        <Divider />
        <DialogContent className={classes.hoursListContent}>
          <Typography variant="body1">
            {selectedDate
              ? `Dia ${selectedDate.getDate()}`
              : 'Nenhuma data selecionada'}
          </Typography>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              <Typography variant="caption">
                Escolha um horário disponível
              </Typography>
            </FormLabel>
            <RadioGroup
              aria-label="horários disponíveis"
              className={classes.radioGroup}
              value={selectedHour}
              onChange={handleSelectHour}>
              {availableOptions.length
                ? availableOptions.map((option, index) => {
                    const timeout = 1000 * index;

                    return (
                      <Grow
                        in
                        key={index}
                        style={{transformOrigin: '0 0 0'}}
                        {...{timeout: timeout}}>
                        <FormControlLabel
                          value={option.idDisponibilidadeDeAgenda}
                          control={<Radio />}
                          label={`${format(
                            new Date(option.iniciaEm),
                            'HH:mm',
                          )} - ${format(
                            new Date(option.terminaEm),
                            'HH:mm',
                          )} - ${option.nomeEntrevistador}`}
                        />
                      </Grow>
                    );
                  })
                : 'Não há dias disponíveis...'}
            </RadioGroup>
          </FormControl>
          <Box className={classes.buttonBox}>
            <Button
              size="small"
              color="primary"
              variant="outlined"
              onClick={() => setIsOpen(false)}>
              Cancelar
            </Button>
            <Button
              size="small"
              color="primary"
              variant="contained"
              onClick={handleConfirmHour}>
              Confirmar
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
