import React from 'react';

import {Box, IconButton} from '@material-ui/core';
import {FilterList, Close} from '@material-ui/icons';
import {Button} from 'shared/components';

import {FDrawer, useStyles} from './styles';

interface IProps {
  open: boolean;
  loading: boolean;
  countFilter: number;
  handleFilter(): void;
  setCleanFilter(): void;
  setOpen(value: boolean): void;
}

export const DrawerFilter: React.FC<IProps> = ({
  open,
  loading,
  setOpen,
  children,
  countFilter,
  handleFilter,
  setCleanFilter,
}) => {
  const {filterHeader, filterFooter, btnClose, body} = useStyles();

  return (
    <>
      <Button
        disabled={loading}
        label={String(countFilter)}
        variant="outlined"
        onClick={() => setOpen(!open)}
        startIcon={<FilterList />}
      />

      <FDrawer anchor="right" open={open}>
        <Box className={filterHeader}>
          <h2 style={{color: '#018781'}}>Filtros</h2>
          <Box className={btnClose}>
            <IconButton onClick={() => setOpen(!open)}>
              <Close />
            </IconButton>
          </Box>
        </Box>

        <Box className={body}>{children}</Box>

        <Box className={filterFooter}>
          <Button
            disabled={loading}
            label="Limpar"
            variant="outlined"
            onClick={setCleanFilter}
          />
          <Button disabled={loading} label="Filtrar" onClick={handleFilter} />
        </Box>
      </FDrawer>
    </>
  );
};
