/* eslint-disable @typescript-eslint/ban-types */
import React, {useCallback, useEffect, useState} from 'react';

import {
  DialogContentText,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  Dialog,
} from '@material-ui/core';
import {
  ConfirmationDialogService,
  ConfirmationDialogTypes,
} from 'shared/services/confirmation';

interface IDialogComponentState {
  type: ConfirmationDialogTypes;
  onConfirm?: Function;
  onCancel?: Function;
  isOpen: boolean;
  message: string;
}

export const ConfirmationDialogComponent: React.FC = () => {
  const [confirmation, setConfirmation] = useState<IDialogComponentState>({
    type: undefined,
    isOpen: false,
    message: '',
  });

  const showConfirmation = useCallback(
    (
      message: string,
      type: ConfirmationDialogTypes,
      onConfirm?: Function,
      onCancel?: Function,
    ) => {
      setConfirmation({message, type, isOpen: true, onConfirm, onCancel});
    },
    [],
  );

  useEffect(() => {
    const subscription = ConfirmationDialogService.subscribe((msg) =>
      showConfirmation(msg.message, msg.type, msg.onConfirm, msg.onCancel),
    );
    return () => {
      subscription.unsubscribe();
      setConfirmation({message: '', type: undefined, isOpen: false});
    };
  }, [showConfirmation]);

  const handleCloseConfirmation = useCallback(() => {
    setConfirmation({
      ...confirmation,
      isOpen: false,
    });

    if (confirmation.onCancel) {
      confirmation.onCancel();
    }
  }, [setConfirmation, confirmation]);

  const handleConfirmation = useCallback(() => {
    setConfirmation({
      ...confirmation,
      isOpen: false,
    });

    confirmation.onConfirm && confirmation.onConfirm();
  }, [confirmation]);

  return (
    <Dialog open={confirmation.isOpen} onClose={handleCloseConfirmation}>
      <DialogTitle>
        {confirmation.type === 'confirmation'
          ? 'Tem certeza?'
          : confirmation.type === 'delete'
          ? 'Apagar?'
          : 'Aviso'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{confirmation.message}</DialogContentText>
      </DialogContent>
      <DialogActions style={{minWidth: 300}}>
        <Button onClick={handleCloseConfirmation} color="primary">
          Cancelar
        </Button>
        <Button onClick={handleConfirmation} color="primary" autoFocus>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
