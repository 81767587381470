import { IAtendimentoXConversaoPorPeriodo } from "shared/services/api/CRMDashboard"

export const getDashboardData = (
  data: IAtendimentoXConversaoPorPeriodo[] | undefined,
  periodo: string,

) => {
  let convertidos: number[] = [];
  let atendidos: number[] = [];

  if (data === undefined) return {
    convertidos,
    atendidos
  };

const sumAtendidos: { [key: string]: number } = {};
const sumConvertidos: { [key: string]: number } = {};

const today = new Date();
const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
const currentYear = today.getFullYear();
const lastYear = today.getFullYear() - 1;

switch (Number(periodo)) {
  case 1:
    data.forEach((item) => {
      const date = new Date(item.data);
      const monthKey = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}`;
      if (!sumAtendidos[monthKey]) {
        sumAtendidos[monthKey] = 0;
      }
      sumAtendidos[monthKey] += item.atendidos;

      if (!sumConvertidos[monthKey]) {
        sumConvertidos[monthKey] = 0;
      }
      sumConvertidos[monthKey] += item.convertidos;
    });

    convertidos = Object.values(sumConvertidos);
    atendidos = Object.values(sumAtendidos);
    break;
    case 2: {
      const daysInMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();

      for (let i = 1; i <= daysInMonth; i++) {
        const dayKey = `${i}`;
        sumAtendidos[dayKey] = 0;
        sumConvertidos[dayKey] = 0;
      }

      data.forEach(item => {
        const date = new Date(item.data);
        const dayKey = `${date.getDate()}`;

        sumAtendidos[dayKey] += item.atendidos;
        sumConvertidos[dayKey] += item.convertidos;
      });

      convertidos = Object.values(sumConvertidos);
      atendidos = Object.values(sumAtendidos);
      break;
    }
    case 3: {
      const filteredData = data.filter(item => {
        const itemDate = new Date(item.data);
        return itemDate.getFullYear() === lastMonth.getFullYear() && itemDate.getMonth() === lastMonth.getMonth();
      });

      filteredData.forEach(item => {
        const date = new Date(item.data);
        const dayKey = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;

        if (!sumAtendidos[dayKey]) {
          sumAtendidos[dayKey] = 0;
        }
        sumAtendidos[dayKey] += item.atendidos;

        if (!sumConvertidos[dayKey]) {
          sumConvertidos[dayKey] = 0;
        }
        sumConvertidos[dayKey] += item.convertidos;
      });

      convertidos = Object.values(sumConvertidos);
      atendidos = Object.values(sumAtendidos);
      break;

    }
  case 4: {
    const firstDate = new Date(data[0].data);
    const lastDate = new Date(data[data.length - 1].data);
    const trimesterStart = new Date(firstDate);
    const trimesterEnd = new Date(lastDate);

    const currentDate = new Date(trimesterStart);
    while (currentDate <= trimesterEnd) {
      let sumConvertidosWeek = 0;
      let sumAtendidosWeek = 0;

      for (let i = 0; i < 7; i++) {
        const currentDay = new Date(currentDate.getTime() + (i * 24 * 60 * 60 * 1000));
        data.forEach(item => {
          const itemDate = new Date(item.data);
          if (itemDate.getDate() === currentDay.getDate() &&
              itemDate.getMonth() === currentDay.getMonth() &&
              itemDate.getFullYear() === currentDay.getFullYear()) {
            sumConvertidosWeek += item.convertidos;
            sumAtendidosWeek += item.atendidos;
          }
        });
      }

      convertidos.push(sumConvertidosWeek);
      atendidos.push(sumAtendidosWeek);
      currentDate.setDate(currentDate.getDate() + 7);
    }
    break
  }
  case 5: {
    for (let month = 0; month < 12; month++) {
      const monthKey = `${currentYear}-${(month + 1).toString().padStart(2, '0')}`;
      sumAtendidos[monthKey] = 0;
      sumConvertidos[monthKey] = 0;
    }

    data.forEach(item => {
      const date = new Date(item.data);
      const monthKey = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}`;

      sumAtendidos[monthKey] += item.atendidos;
      sumConvertidos[monthKey] += item.convertidos;
    });

    convertidos = Object.values(sumConvertidos);
    atendidos = Object.values(sumAtendidos);
    break
  }
  case 6: {
    for (let month = 0; month < 12; month++) {
      const monthKey = `${lastYear}-${(month + 1).toString().padStart(2, '0')}`;
      sumAtendidos[monthKey] = 0;
      sumConvertidos[monthKey] = 0;
    }

    data.forEach(item => {
      const date = new Date(item.data);
      const monthKey = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}`;

      if (date.getFullYear() === lastYear) {
        sumAtendidos[monthKey] += item.atendidos;
        sumConvertidos[monthKey] += item.convertidos;
      }
    });

    convertidos = Object.values(sumConvertidos);
    atendidos = Object.values(sumAtendidos);
    break;
  }
  default:
    break;
}

return {
  convertidos,
  atendidos
};

}
