import {Grid} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';

import {Overview} from './cards';

export const DashBoardSkeleton = () => (
  <>
    <Grid item xs={12} sm={6} md={3}>
      <Overview isLoading />
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
      <Overview isLoading />
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
      <Overview isLoading />
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
      <Overview isLoading />
    </Grid>

    <Grid item xs={12}>
      <Skeleton width="100%" height={280} variant="rect" />
    </Grid>

    <Grid item xs={6}>
      <Skeleton width="100%" height={300} variant="rect" />
    </Grid>
    <Grid item xs={6}>
      <Skeleton width="100%" height={300} variant="rect" />
    </Grid>
  </>
);
