import React from 'react';
import {
  Box,
  Grid,
  Checkbox,
  useTheme,
  MenuItem,
  TextField,
  FormControl,
  ListSubheader,
  FormControlLabel,
} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';

import {useTerm} from 'shared/hooks';
import {AutoComplete, Modal} from 'shared/components';
import {ICourseAndCycle} from 'shared/services/api/CourseService';
import {IUnityEstablishment} from 'shared/services/api/EstablishmentService';
import {IAdmUser, IInterviewer} from 'shared/services/api/InterviewerService';

interface IProps {
  title: string;
  sent: boolean;
  onClose(): void;
  onClick(): void;
  editing: boolean;
  loading: boolean;
  openModal: boolean;
  interviewers: IAdmUser[];
  interviewer: IInterviewer;
  availabilities: ICourseAndCycle[];
  setInterviewer(value: IInterviewer): void;
  establishments: Omit<IUnityEstablishment[], 'unidades'>;
}

export const Modals: React.FC<IProps> = ({
  sent,
  title,
  editing,
  loading,
  onClose,
  onClick,
  openModal,
  interviewer,
  interviewers,
  establishments,
  availabilities,
  setInterviewer,
}) => {
  const theme = useTheme();
  const {terms} = useTerm();

  return (
    <Modal
      maxWidth="lg"
      title={title}
      loading={loading}
      onClose={onClose}
      onClick={onClick}
      opened={openModal}
      disabled={editing && !interviewer.permiteEditar}
      labelSaveButton="Salvar"
      labelCloseButton="Cancelar">
      <FormControl component="fieldset" fullWidth>
        <Grid container component={Box} spacing={2}>
          <Grid item xs={12}>
            <FormControlLabel
              label="Ativo"
              control={
                <Checkbox
                  checked={interviewer.ativo}
                  disabled={loading || (editing && !interviewer.permiteEditar)}
                  onChange={({target}) => {
                    setInterviewer({...interviewer, ativo: target.checked});
                  }}
                />
              }
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            {loading ? (
              <Skeleton variant="rect" height={55} animation="wave" />
            ) : (
              <AutoComplete
                disableClearable
                filterSelectedOptions
                options={interviewers}
                disabled={loading || editing}
                getOptionLabel={(option) => option.nome}
                value={
                  interviewers.filter(
                    (i) => i.idUsuarioAuth === Number(interviewer.idUsuario),
                  )[0]
                }
                getOptionSelected={(option, value) =>
                  option.idUsuarioAuth === value.idUsuarioAuth
                }
                onChange={(_, value) =>
                  setInterviewer({
                    ...interviewer,
                    idUsuario: value.idUsuarioAuth,
                  })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={`${terms.termoEntrevistador} *`}
                    error={!interviewer.idUsuario && sent}
                    helperText={
                      !interviewer.idUsuario && sent && 'Campo requerido'
                    }
                  />
                )}
              />
            )}
          </Grid>

          <Grid item xs={12} sm={6}>
            {loading ? (
              <Skeleton variant="rect" height={55} animation="wave" />
            ) : (
              <TextField
                select
                fullWidth
                variant="outlined"
                label="Estabelecimento *"
                disabled={loading || editing}
                value={interviewer.idEstabelecimento}
                error={!interviewer.idEstabelecimento && sent}
                helperText={
                  !interviewer.idEstabelecimento && sent && 'Campo requerido'
                }
                onChange={({target}) => {
                  setInterviewer({
                    ...interviewer,
                    idEstabelecimento: target.value,
                  });
                }}>
                {editing ? (
                  <MenuItem value={interviewer.idEstabelecimento}>
                    {interviewer.nomeEstabelecimento}
                  </MenuItem>
                ) : (
                  establishments?.map((item) => {
                    if (item.idEstabelecimentoPai) {
                      return (
                        <MenuItem
                          key={item.idEstabelecimento}
                          value={item.idEstabelecimento}
                          style={{paddingLeft: theme.spacing(3)}}>
                          {item.idComNome}
                        </MenuItem>
                      );
                    } else {
                      return (
                        <ListSubheader key={item.idEstabelecimento}>
                          {item.idComNome}
                        </ListSubheader>
                      );
                    }
                  })
                )}
              </TextField>
            )}
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              label="Agendamento disponível para todos"
              control={
                <Checkbox
                  disabled={loading || (editing && !interviewer.permiteEditar)}
                  checked={interviewer.disponivelParaTodos}
                  onChange={({target}) => {
                    setInterviewer({
                      ...interviewer,
                      disponivelParaTodos: target.checked,
                    });
                  }}
                />
              }
            />
          </Grid>

          <Grid item xs={12}>
            {loading ? (
              <Skeleton variant="rect" height={55} animation="wave" />
            ) : (
              <AutoComplete
                multiple
                disableCloseOnSelect
                filterSelectedOptions
                options={availabilities}
                value={interviewer.disponibilidades}
                getOptionLabel={(option) => option.nome}
                getOptionSelected={(option, value) => option.id === value.id}
                disabled={
                  interviewer.disponivelParaTodos ||
                  loading ||
                  (editing && !interviewer.permiteEditar)
                }
                onChange={
                  (_, value) => {
                    setInterviewer({...interviewer, disponibilidades: value})
                  }
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Disponibilidades para agendamento *"
                    error={
                      !interviewer.disponivelParaTodos &&
                      !interviewer.disponibilidades?.length &&
                      sent
                    }
                    helperText={
                      !interviewer.disponivelParaTodos &&
                      !interviewer.disponibilidades?.length &&
                      sent &&
                      'Campo requerido'
                    }
                  />
                )}
              />
            )}
          </Grid>
        </Grid>
      </FormControl>
    </Modal>
  );
};
