import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    '@global': {
      '*::-webkit-scrollbar': {
        width: '0.7em',
      },
      '*::-webkit-scrollbar-track': {
        '-webkit-box-shadow': theme.palette.primary.main,
      },
      '*::-webkit-scrollbar-thumb': {
        backgroundColor: '#E0E0E0',
        borderRadius: 50,
        width: 12,
        maxHeight: 100,
      },
    },
    root: {
      display: 'flex',
    },
    content: {
      flexGrow: 1,
      margin: theme.spacing(12, 0),
    },
  }),
);
