import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  Box,
  useTheme,
  TableRow,
  TableCell,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import {Delete, Edit} from '@material-ui/icons';

import {
  Header,
  NoRegister,
  ModalConfirm,
  Table
} from 'shared/components';
import {
  CourseService,
  ICourseAndCycle,
} from 'shared/services/api/CourseService';
import {
  IInterviewer,
  InterviewerService,
} from 'shared/services/api/InterviewerService';
import {
  IUnityEstablishment,
  EstablishmentService,
} from 'shared/services/api/EstablishmentService';
import {feedback} from 'shared/services/alertService';
import {useTitle, useTerm, useUser, useDebounce} from 'shared/hooks';
import {errorResponse} from 'shared/utils/errorResponse';
import {UserService, IAdmUser} from 'shared/services/api/UserService';

import {Modals} from './Modal';
import {useStyles} from './styles';
import {useNavigate} from 'react-router-dom';

export const Interviewer: React.FC = () => {
  const [sent, setSent] = useState(false);
  const [filter, setFilter] = useState('');
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idDeleted, setIdDeleted] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [tableFiltered, setTableFiltered] = useState(0);
  const [dataTable, setDataTable] = useState<IInterviewer[]>([]);
  const [interviewers, setInterviewers] = useState<IAdmUser[]>([]);
  const [availabilities, setAvailabilities] = useState<ICourseAndCycle[]>([]);
  const [openModalConfirmExclude, setOpenModalConfirmExclude] = useState(false);
  const [establishments, setEstablishments] = useState<
    Omit<IUnityEstablishment[], 'unidades'>
  >([]);
  const [interviewer, setInterviewer] = useState<IInterviewer>(
    {} as IInterviewer,
  );
  const [take, setTake] = useState(10);
  const [skip, setSkip] = useState(1);
  const [interviewersCount, setInterviewersCount] = useState(0);

  const theme = useTheme();
  const {terms} = useTerm();
  const classes = useStyles();
  const navigate = useNavigate();
  const {setTitle} = useTitle();
  const {data: user} = useUser();
  const {debounce}= useDebounce();

  const permissions = useMemo(
    () => user?.permissoes.filter((i) => i.nome.includes('Entrevistador')),
    [user?.permissoes],
  );

  const canDelete = useMemo(
    () => !!permissions?.find((i) => i.nome.includes('Excluir')),
    [permissions],
  );

  const canRegister = useMemo(
    () => !!permissions?.find((i) => i.nome.includes('Incluir')),
    [permissions],
  );

  const canList = useMemo(
    () => !!permissions?.find((i) => i.nome.includes('Listar')),
    [permissions],
  );

  const permissionRegister = useMemo(
    () => permissions?.find((i) => i.nome.includes('Incluir')),
    [permissions],
  );

  const permissionList = useMemo(
    () => permissions?.find((i) => i.nome.includes('Listar')),
    [permissions],
  );

  const getDataTable = useCallback(() => {
    setLoading(true);
    debounce( async () => {
      try {
        const response = await InterviewerService.getInterviewersPagination({
          idPermissao: permissionList?.id},
          filter,
          tableFiltered,
          take,
          skip
        );
        if (response) {
          setDataTable(response.data);
          setInterviewersCount(response.qtdTotalDeRegistros);
        }
      } catch (error) {
        feedback(errorResponse(error), 'error');
      } finally {
        setLoading(false);
      }

    })
  }, [permissionList?.id, take, skip, filter, tableFiltered, debounce]);

  const getInterviewers = useCallback(async () => {
    try {
      const response = await UserService.getAdmUser(true);

      if (response) {
        setInterviewers(response);
      }
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
  }, []);

  const getEstablishments = useCallback(async () => {
    try {
      const response = await EstablishmentService.getEstablishments(
        permissionRegister?.id,
      );

      if (response) {
        setEstablishments(response.estabelecimentos);
      }
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
  }, [permissionRegister?.id]);

  const getAvailabilities = useCallback(async () => {
    try {
      const response = await CourseService.getCoursesAndCycles(true);

      if (response) {
        setAvailabilities(response);
      }
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
  }, []);

  const verifyReg = useCallback(() => {
    if (
      !interviewer.idUsuario ||
      !interviewer.idEstabelecimento ||
      (!interviewer.disponivelParaTodos &&
        !interviewer.disponibilidades?.length)
    ) {
      setSent(true);

      return false;
    }
    setSent(false);
    return true;
  }, [interviewer]);

  const handleUpdateReg = useCallback(async () => {
    if (verifyReg()) {
      setLoading(true);
      try {
        interviewer.disponibilidades?.map((item) => {
          item.idCurso = String(item.id).split('.')[0];
          item.idCiclo = String(item.id).split('.')[1];
        });

        await InterviewerService.updateInterviewer(interviewer.id, interviewer);

        await getDataTable();

        feedback(`Registro atualizado com sucesso.`, 'success');
        setOpenModal((state) => !state);
      } catch (error) {
        feedback(errorResponse(error), 'error');
      } finally {
        setLoading(false);
      }
    }
  }, [verifyReg, interviewer, getDataTable]);

  const handleCreateReg = useCallback(async () => {
    if (verifyReg()) {
      setLoading(true);
      try {
        interviewer.disponibilidades?.map((item) => {
          item.idCurso = String(item.id).split('.')[0];
          item.idCiclo = String(item.id).split('.')[1];
        });

        await InterviewerService.postInterviewer(interviewer);

        await getDataTable();

        feedback('Registro criado com sucesso', 'success');
        setOpenModal((state) => !state);
      } catch (error) {
        feedback(errorResponse(error), 'error');
      } finally {
        setLoading(false);
      }
    }
  }, [verifyReg, interviewer, getDataTable]);

  const handleDeleteLead = useCallback((id: string) => {
    setOpenModalConfirmExclude(true);

    setIdDeleted(id);
  }, []);

  const handleConfirmLeadDeleted = useCallback(async () => {
    try {
      setLoading(true);
      await InterviewerService.deleteInterviewer(idDeleted);

      await getDataTable();

      feedback(`${terms.termoEntrevistador} excluído com sucesso.`, 'success');
    } catch (error) {
      feedback(
        `Entrevistador não pode ser excluído pois já está em uso.`,
        'error',
      );
    }
    setLoading(false);
    setIdDeleted('');
    setOpenModalConfirmExclude(false);
  }, [idDeleted, getDataTable, terms.termoEntrevistador]);

  const handleGetInterviewer = useCallback(async (idEntrevistador: string) => {
    try {
      setLoading(true);
      const response = await InterviewerService.getInterviewer({
        idEntrevistador,
        verificarPermissaoEditar: true,
      });

      if (response) {
        setInterviewer(response);
      }
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
    setLoading(false);
  }, []);

  useEffect(() => setTitle(terms.termoEntrevistador), [setTitle, terms]);

  useEffect(() => {
    if (!canList) navigate('/');
  }, [canList, navigate]);

 
  useEffect(() => {
    getDataTable();
    getInterviewers();
    getEstablishments();
    getAvailabilities();
  }, [getDataTable, getInterviewers, getEstablishments, getAvailabilities]);

  return (
    <>
      <Header
        filter={filter}
        setFilter={setFilter}
        disabledButton={!canRegister}
        tableFiltered={tableFiltered}
        setTableFiltered={setTableFiltered}
        onClick={() => (
          setEditing(false),
          setOpenModal((state) => !state),
          setInterviewer({ativo: true} as IInterviewer)
        )}
      />

      <Box marginTop={5} />

        <Table
          head={[
            {label: 'Ações', width: '140px'},
            {label: 'Perfil de usuário', width: '50%'},
            {label: 'Unidade', width: '50%'},
            {label: 'Status', width: '100px'},
          ]}
          pagination={{
            count: Math.ceil(interviewersCount / take),
            page: skip,
          }}
          loading={loading}
          onChangeTake={(value:number) => setTake(value)}
          take={take}
          length={dataTable.length}
          rowsPerPage={dataTable.length}
          onChangePage={ (value: number) => setSkip(value)}>
          {
            dataTable.map((item) => (
              <TableRow key={item.id}>
                <TableCell>
                      <IconButton
                        color="primary"
                        disabled={loading || !canDelete}
                        onClick={() => handleDeleteLead(item.id)}>
                        <Delete />
                        {loading && idDeleted === item.id && (
                          <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                          />
                        )}
                      </IconButton>

                      <IconButton
                        style={{marginLeft: theme.spacing(1)}}
                        color="primary"
                        onClick={() => {
                          setEditing(true);
                          setOpenModal(true);
                          handleGetInterviewer(item.id);
                        }}
                        disabled={loading}>
                        <Edit />
                      </IconButton>
                    </TableCell>

                    <TableCell>{item.nomeUsuario}</TableCell>
                    <TableCell>{item.nomeEstabelecimento}</TableCell>
                    <TableCell>{item.ativo ? 'Ativo' : 'Inativo'}</TableCell>
              </TableRow>
            ))}
        </Table>

        <NoRegister data={dataTable.length} />
        <ModalConfirm
          loading={loading}
          opened={openModalConfirmExclude}
          onClick={handleConfirmLeadDeleted}
          onClose={() => (setOpenModalConfirmExclude(false), setIdDeleted(''))}
        />

        <Modals
          sent={sent}
          editing={editing}
          loading={loading}
          openModal={openModal}
          interviewer={interviewer}
          interviewers={interviewers}
          establishments={establishments}
          availabilities={availabilities}
          setInterviewer={setInterviewer}
          onClick={editing ? handleUpdateReg : handleCreateReg}
          title={`${editing ? 'Editar' : 'Cadastro de'} ${
            terms.termoEntrevistador
          }`}
        onClose={() => (setOpenModal((state) => !state), setSent(false))}
      />
    </>
  );
};
