import React from 'react';

import {
  Table,
  TableRow,
  TableCell,
  Typography,
  TableFooter,
} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';

interface IProps {
  data: number;
}

export const NoRegister: React.FC<IProps> = ({data}) => {
  return (
    <Table>
      <TableFooter>
        {data === 0 ? (
          <TableRow>
            <TableCell>
              <Typography variant="body2">
                Nenhum registro encontrado...
              </Typography>
            </TableCell>
            <TableCell />
            <TableCell />
          </TableRow>
        ) : (
          !data && (
            <>
              <TableRow>
                <TableCell>
                  <Skeleton variant="text" />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Skeleton variant="text" />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Skeleton variant="text" />
                </TableCell>
              </TableRow>
            </>
          )
        )}
      </TableFooter>
    </Table>
  );
};
