import {TextField} from '@material-ui/core';
import {createStyles, Theme, withStyles} from '@material-ui/core/styles';

export const CssTextField = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiSelect-root': {
        color: theme.palette.primary.main,
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: theme.palette.primary.main,
        },
      },
    },
  }),
)(TextField);
