import {IRequestResult} from 'shared/interfaces/IRequestResult';
import {Api} from 'shared/services/axiosConfig';

export interface ILeadCategory {
  id: number;
  nome: string;
}
export interface ILead {
  id: string;
  nome: string;
  categoria: ILeadCategory;
  idCategDeSituacaoDeLead: number;
  ativo: boolean;
  usuario: string;
  ordem: number;
  notificarLead: boolean;
  notificarAtendenteAtual: boolean;
  notificarTodosOsAtendentes: boolean;
  assuntoEmailNotificacao?: string;
  mensagemEmailNotificacao?: string;
  qtdeDiasAteEnvioNotificacao?: string;
  enviarNotificacao?: boolean;
  tarefas: ITarefa[];
}

export interface ILeadPagination {
  qtdTotalDeRegistros: number;
  data: ILead[];
}

interface ILeadPostPut {
  nome: string;
  ativo: boolean;
  idCategDeSituacaoDeLead: number;
}

export interface ITarefa {
  id: string;
  nome: string;
  obrigatoria: boolean;
}

export interface IMotivoPerda {
  idMotivoPerda: string;
  idLead: string;
}

const getEditLead = async (id: string, funnelId: string): Promise<ILead[]> => {
  try {
    const {data} = await Api.get(`/situacaolead/${funnelId}/${id}`);

    return data;
  } catch (error: any) {
    throw new Error(`Erro ao listar. ${error.data.data}`);
  }
};

const postLead = async (
  payload: ILead,
  funnelId: string,
): Promise<IRequestResult> => {
  try {
    await Api.post(`/situacaolead/${funnelId}`, payload);

    return {success: true};
  } catch (error: any) {
    throw new Error(error.data.data);
  }
};

const postMotivo = async (
  payload: IMotivoPerda,
  funnelId: string,
): Promise<IRequestResult> => {
  try {
    await Api.post('/situacaolead', payload);

    return {success: true};
  } catch (error: any) {
    throw new Error(error.data.data);
  }
};

const updateLead = async (
  id: string,
  payload: ILead,
  funnelId: string,
): Promise<IRequestResult> => {
  try {
    await Api.put(`/situacaolead/${funnelId}/${id}`, payload);

    return {success: true};
  } catch (error: any) {
    throw new Error(error.data.data);
  }
};

const deleteLead = async (
  id: string,
  funnelId: string,
): Promise<IRequestResult> => {
  try {
    await Api.delete(`/situacaolead/${funnelId}/${id}`);

    return {success: true};
  } catch (error: any) {
    throw new Error(error.data.data);
  }
};

const getLeads = async (
  funnelId: string,
  active?: boolean,
): Promise<ILead[]> => {
  try {
    const response = await Api.get(
      `/situacaolead/${funnelId}?ativo=${
        active ? 'true' : active !== undefined ? 'false' : ''
      }`,
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.data.data);
  }
};

const getLeadsPagination = async (
  ativo: number,
  campoDePesquisa: string,
  take: number,
  skip: number,
  funnelId: string,
): Promise<ILeadPagination> => {
  try {
    const {data: response} = await Api.get(
      `/situacaolead/${funnelId}/paginado?ativo=${
        ativo == 0 ? '' : ativo === 1 ? true : false
      }
        &campoDePesquisa=${campoDePesquisa}&take=${take}&skip=${skip}`,
    );

    return response;
  } catch (error: any) {
    throw new Error(error.data.data);
  }
};

const getLeadCategory = async (): Promise<ILeadCategory[] | undefined> => {
  try {
    const response = await Api.get('/categoriasituacaolead');

    return response.data;
  } catch (error: any) {
    throw new Error(error.data.data);
  }
};

export const LeadSituationService = {
  getLeadCategory,
  getLeadsPagination,
  updateLead,
  deleteLead,
  getLeads,
  postLead,
  getEditLead,
};
