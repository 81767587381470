import React from 'react';

import {Button as MuiButton, ButtonProps} from '@material-ui/core';

interface IProps extends ButtonProps {
  label?: string;
  variant?: 'contained' | 'outlined' | 'text';
  color?: 'default' | 'inherit' | 'primary' | 'secondary';
  error?: boolean;
  size?: 'small' | 'medium' | 'large';
  textTransform?: 'uppercase' | 'lowercase' | 'capitalize' | 'none';
}

export const Button: React.FC<IProps> = ({
  label,
  children,
  variant = 'contained',
  color = 'primary',
  size = 'medium',
  textTransform = 'none',
  error = false,
  ...props
}) => {
  return (
    <MuiButton
      {...props}
      variant={variant}
      color={color}
      size={size}
      style={{
        textTransform,
        color: error ? '#F50000' : undefined,
        borderColor: error ? '#F50000' : undefined,
        // minWidth: 120,
      }}>
      {label}
      {children}
    </MuiButton>
  );
};
