import React, {useState, createContext} from 'react';

export interface IContextDrawer {
  setDrawerOpen(value: boolean): void;
  drawerOpen: boolean;
}

export const DrawerContext = createContext<IContextDrawer>(
  {} as IContextDrawer,
);

export const DrawerProvider: React.FC = ({children}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <DrawerContext.Provider value={{drawerOpen, setDrawerOpen}}>
      {children}
    </DrawerContext.Provider>
  );
};
