import {Event} from '@material-ui/icons';
import {DatePicker} from '@material-ui/pickers';
import React, {useState} from 'react';
import {Modal} from 'shared/components';
import {format} from 'date-fns';
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date';
import {Box} from '@material-ui/core';
import {LeadService} from 'shared/services/api/LeadService';
import {errorResponse} from 'shared/utils/errorResponse';
import {feedback} from 'shared/services/alertService';

interface IProps {
  idLead: string;
  openModal: boolean;
  isLoading: boolean;
  onClose: () => void;
}

export const ModalEditPayment: React.FC<IProps> = ({
  idLead,
  openModal,
  isLoading,
  onClose,
}) => {
  const [vencimentoDate, setVencimentoDate] = useState(
    format(Date.now(), "yyyy-MM-dd'T'HH:mm:ss"),
  );

  const handleChangeFinalDate = async () => {
    feedback('Salvando', 'loading');
    try {
      await LeadService.patchIncreaseFinalDateByLeadId({
        idLead: idLead,
        novaDataVencimento: vencimentoDate,
      });
      feedback('Salvo com sucesso', 'success');
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
  };
  const handleConfirm = () => {
    handleChangeFinalDate();
    onClose();
  };

  return (
    <Modal
      opened={openModal}
      title={'Atualizar dados Lead'}
      loading={isLoading}
      onClick={handleConfirm}
      onClose={onClose}
      labelSaveButton="Salvar"
      labelCloseButton="Cancelar">
      <Box marginTop={1}>
        <DatePicker
          autoOk
          fullWidth
          disablePast
          label="Data"
          format="dd/MM/yyyy"
          animateYearScrolling
          inputVariant="outlined"
          value={vencimentoDate}
          InputProps={{endAdornment: <Event color="primary" />}}
          minDateMessage={'A data não deve ser anterior à data atual'}
          disabled={isLoading}
          onChange={(value: MaterialUiPickersDate) =>
            setVencimentoDate(
              format(new Date(value as Date), "yyyy-MM-dd'T'HH:mm:ss"),
            )
          }
        />
      </Box>
    </Modal>
  );
};
