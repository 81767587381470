import {useNavigate, useParams} from 'react-router-dom';
import {
  Box,
  Grid,
  Checkbox,
  useTheme,
  TextField,
  Typography,
  FormControlLabel,
  IconButton,
  Button,
  MenuItem,
} from '@material-ui/core';
import {Event} from '@material-ui/icons';
import {Skeleton} from '@material-ui/lab';
import {isBefore, isEqual} from 'date-fns';
import {DatePicker} from '@material-ui/pickers';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import {
  CampaignService,
  ISituationCampaign,
  ICampaign,
} from 'shared/services/api/CampaignService';
import {
  ISchoolPeriod,
  SchoolPeriodService,
} from 'shared/services/api/SchoolPeriodService';
import {
  IUnityEstablishment,
  EstablishmentService,
} from 'shared/services/api/EstablishmentService';
import {
  ITypeOfIntegration,
  TypeOfIntegrationService,
} from 'shared/services/api/TypeOfIntegrationService';
import {feedback} from 'shared/services/alertService';
import {errorResponse} from 'shared/utils/errorResponse';
import {FormService, ILeadData} from 'shared/services/api/FormService';
import {OfferService} from 'shared/services/api/OfferService';
import {InfoTooltip, Footer} from 'shared/components';
import {useTitle, useCampaign, useUser} from 'shared/hooks';
import {StepperCampaign} from 'pages/Campaign/components/StepperCampaign';
import {ModalLGPD} from './modals/ModalLGPD';
import {useCallback, useEffect, useMemo, useState} from 'react';

export const CampaignGeneralData: React.FC = () => {
  const [edit, setEdit] = useState(false);
  const [isEditing, setIsEditing] = useState(true);
  const [isConfigured, setIsConfigured] = useState(false);
  const [isConfiguredFicha, setIsConfiguredFicha] = useState(false);
  const [isConfiguredOferta, setIsConfiguredOferta] = useState(false);
  const [isContainOffer, setIsContainOffer] = useState(false);
  const [isIntegrated, setIsIntegrated] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEquivalenceOffer, setIsEquivalenceOffer] = useState(false);
  const [establishments, setEstablishments] = useState<
    Omit<IUnityEstablishment[], 'unidades'>
  >([]);
  const [situationOfCampaigns, setSituationOfCampaigns] = useState<
    ISituationCampaign[]
  >([]);
  const [typeOfIntegration, setTypeOfIntegration] = useState<
    ITypeOfIntegration[]
  >([]);
  const [schoolPeriod, setSchoolPeriod] = useState<ISchoolPeriod[]>([]);

  const [leadData, setLeadData] = useState<ILeadData>({
    idCampoEmailLead: '',
    idCampoIdentifLead: '',
    idCampoTelefoneLead: '',
    idCampoCandidatoLead: '',
    idCampoDocumentoDevedorTaxaLead: '',
    idCampoNomeDevedorTaxaLead: '',
  } as ILeadData);

  const [generalData, setGeneralData] = useState<ICampaign>({
    nome: '',
    vigenciaInicial: new Date(),
    vigenciaFinal: new Date(),
    idEstabelecimentoDono: '',
    nivelEstabelecimentoDono: 0,
    idSituacaoDeCampanha: 1,
    idTipoDeIntegracao: 0,
    ano: new Date().getFullYear(),
    idPeriodoLetivo: '',
    usaAgendamento: false,
    matriculaConfirmada: false,
    possuiEquivalenciaIntegracaoOferta: false,
    idMetricaGoogleAnalytics: '',
    disponibilizarTermoDeAceiteParaLead: false,
    textoTermoLgpd: '',
    possuiTaxa: false,
    possuiTermoLgpd: false,
    possuiEnvioFormularioSecundario: false,
  } as ICampaign);

  const theme = useTheme();
  const {setTitle} = useTitle();
  const {data: user} = useUser();
  const navigate = useNavigate();
  const {id = ''} = useParams<'id'>();
  const {setIsSchedule} = useCampaign();

  const [openModalLGPD, setModalLGPD] = useState(false);
  const [termoUnidade, setTermoUnidade] = useState(false);

  const [emailValidation, setEmailValidation] = useState<any>();
  const [errorStep, setErrorStep] = useState<number>();

  const [establishmentLevelNumber, setEstablishmentLevelNumber] = useState('');

  const handleModalLGPD = useCallback(() => {
    setModalLGPD(false);
  }, []);

  const handleOpenModalLGPD = useCallback(() => {
    setModalLGPD(true);
  }, []);

  const permissions = useMemo(
    () => user?.permissoes.filter((i: any) => i.nome.includes('Campanha')),
    [user?.permissoes],
  );

  const canList = useMemo(
    () => !!permissions?.find((i: any) => i.nome.includes('Listar')),
    [permissions],
  );

  const canEdit = useMemo(
    () => !!permissions?.find((i: any) => i.nome.includes('Editar')),
    [permissions],
  );

  const permissionListToCreate = useMemo(
    () => permissions?.find((i: any) => i.nome.includes('Incluir')),
    [permissions],
  );

  const handleGetEstablishment = useCallback(async () => {
    try {
      const response = await EstablishmentService.getEstablishments(
        permissionListToCreate?.id,
      );

      setEstablishments(response.estabelecimentos);
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
  }, [permissionListToCreate?.id]);

  const handleGetTypeOfIntegration = useCallback(async () => {
    try {
      const response = await TypeOfIntegrationService.getTypeOfIntegration();
      if (response) {
        setTypeOfIntegration(response);
      }
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
  }, []);

  const handleGetSituationOfCampaign = useCallback(async () => {
    try {
      const response = await CampaignService.getSituationOfCampaign();

      if (response) {
        setSituationOfCampaigns(response);
      }
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
  }, []);

  const handleGetSchoolPeriod = useCallback(async () => {
    try {
      const response = await SchoolPeriodService.getSchoolPeriod();

      if (response) {
        setSchoolPeriod(response);
      }
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
  }, []);

  const handleDateValidate = useMemo(() => {
    return !(
      isEqual(
        new Date(generalData.vigenciaFinal),
        new Date(generalData.vigenciaInicial),
      ) ||
      isBefore(
        new Date(generalData.vigenciaInicial),
        new Date(generalData.vigenciaFinal),
      )
    );
  }, [generalData.vigenciaFinal, generalData.vigenciaInicial]);

  const handleGetOffersAndFieldsOfCampaign = useCallback(async (id: string) => {
    try {
      const dataOffer = await OfferService.getOfferForCampaign(id);
      const dataFields = await FormService.getFieldsOfCampaign(id);

      if (dataOffer && dataFields) {
        setIsConfigured(dataOffer.length > 0 && dataFields.length > 0);
        setIsConfiguredFicha(dataFields.length > 0);
        setIsConfiguredOferta(dataOffer.length > 0);
        setIsContainOffer(dataOffer.length > 0);
      }
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
  }, []);

  const isCollegeIntegration = useMemo(
    () => generalData.idTipoDeIntegracao === 2,
    [generalData.idTipoDeIntegracao],
  );

  const handleGetConfigEmails = useCallback(
    async (id: string) => {
      try {
        const data = await CampaignService.getCampaign({
          id,
          verificarPermissaoEditar: true,
        });

        setGeneralData(data);
        setIsEditing(data.permiteEditar);
        setIsSchedule(data.usaAgendamento);
        setIsIntegrated(data.idTipoDeIntegracao !== 1);
        setIsEquivalenceOffer(
          data.possuiEquivalenciaIntegracaoOferta
            ? data.possuiEquivalenciaIntegracaoOferta
            : false,
        );
      } catch (error) {
        feedback(errorResponse(error), 'error');
      }
    },
    [setIsSchedule],
  );

  const handleTaxaAndLeadDataVerification = useCallback(() => {
    return (
      isEditing &&
      generalData.idSituacaoDeCampanha === 2 &&
      generalData.possuiTaxa &&
      (!leadData.idCampoDocumentoDevedorTaxaLead ||
        !leadData.idCampoDocumentoDevedorTaxaLead)
    );
  }, [
    generalData.idSituacaoDeCampanha,
    generalData.possuiTaxa,
    isEditing,
    leadData.idCampoDocumentoDevedorTaxaLead,
  ]);

  const handleSaveAndNextStep = useCallback(async () => {
    if (
      generalData.nome.length === 0 ||
      generalData.idEstabelecimentoDono === '' ||
      (isCollegeIntegration && !generalData.idPeriodoLetivo) ||
      generalData.idSituacaoDeCampanha === 0 ||
      generalData.idTipoDeIntegracao === 0
    ) {
      setSubmitted(true);
      return;
    }

    if (handleTaxaAndLeadDataVerification()) {
      setSubmitted(true);
      return;
    }

    if (!isConfigured && generalData.idSituacaoDeCampanha === 2) return;

    if (!edit && generalData.idSituacaoDeCampanha === 2) return;

    if (handleDateValidate) return;

    try {
      if (edit) {
        await CampaignService.putCampaignGeneralData(id, generalData);

        navigate(`/campanha/email-config/${id}`);
      } else {
        const response = await CampaignService.postCampaignGeneralData(
          generalData,
        );
        navigate(`/campanha/novo/email-config/${response.idCampanha}`);
      }
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
  }, [
    generalData,
    isCollegeIntegration,
    handleTaxaAndLeadDataVerification,
    isConfigured,
    edit,
    handleDateValidate,
    id,
    navigate,
  ]);

  const handleEmailsValidation = useCallback(async () => {
    try {
      const data = await CampaignService.getConfigEmails(id);
      setEmailValidation(data);
    } catch (error) {
      ('');
    }
  }, [id]);

  const handleGets = useCallback(async () => {
    setLoading(true);
    try {
      handleGetSituationOfCampaign();
      handleGetSchoolPeriod();
      handleGetTypeOfIntegration();
      await handleGetEstablishment();
      if (id) {
        setEdit(true);
        handleGetConfigEmails(id);
        await handleGetOffersAndFieldsOfCampaign(id);
      }
    } catch (error) {
      feedback(errorResponse(error), 'error');
    } finally {
      setLoading(false);
    }
  }, [
    handleGetSituationOfCampaign,
    handleGetSchoolPeriod,
    handleGetTypeOfIntegration,
    handleGetEstablishment,
    id,
    handleGetConfigEmails,
    handleGetOffersAndFieldsOfCampaign,
  ]);

  const handleGetLeadData = useCallback(async () => {
    try {
      const data = await FormService.getLeadData(id);
      setLeadData(data);
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
  }, [id]);

  useEffect(() => {
    if (edit && id) {
      handleGetLeadData();
    }
  }, [id, edit]);

  useEffect(() => {
    if (
      generalData.idSituacaoDeCampanha === 2 &&
      emailValidation === undefined
    ) {
      setErrorStep(1);
    } else if (
      (!edit && generalData.idSituacaoDeCampanha === 2) ||
      (!isConfiguredOferta && generalData.idSituacaoDeCampanha === 2)
    ) {
      setErrorStep(3);
    } else if (
      (!edit && generalData.idSituacaoDeCampanha === 2) ||
      (!isConfiguredFicha && generalData.idSituacaoDeCampanha === 2)
    ) {
      setErrorStep(4);
    } else setErrorStep(99);
  }, [generalData.idSituacaoDeCampanha, emailValidation, edit, isConfigured, isConfiguredFicha, isConfiguredOferta]);

  useEffect(() => {
    setTitle(
      `${edit ? 'Editar' : 'Nova'} Campanha ${generalData.nome} | Dados Gerais`,
    );
  }, [setTitle, edit, generalData.nome]);

  useEffect(() => {
    if (!canList) navigate('/');
  }, [canList, navigate]);

  useEffect(() => {
    handleGets();
    handleEmailsValidation();
  }, [handleGets, handleEmailsValidation]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <StepperCampaign
            errorStep={errorStep}
            integrated={isIntegrated}
            loading={loading}
            activeStep={0}
            idCampaign={id}
          />
          <Box marginBottom={1} />
        </Grid>

        <Grid item xs={12}>
          {loading ? (
            <Skeleton variant="rect" height={36} />
          ) : (
            <TextField
              fullWidth
              label="Campanha *"
              variant="outlined"
              disabled={!isEditing || !canEdit}
              value={generalData.nome}
              error={!generalData.nome && submitted}
              helperText={!generalData.nome && submitted && 'Campo requerido'}
              onChange={({target}) =>
                setGeneralData((state) => ({
                  ...state,
                  nome: target.value,
                }))
              }
            />
          )}
          <Box marginBottom={1} />
        </Grid>

        <Grid item xs={12} sm={8}>
          {loading ? (
            <Skeleton variant="rect" height={36} />
          ) : (
            <TextField
              fullWidth
              label="Estabelecimento da campanha *"
              variant="outlined"
              select
              disabled={isContainOffer || !isEditing || !canEdit}
              value={generalData.idEstabelecimentoDono}
              error={!generalData.idEstabelecimentoDono && submitted}
              helperText={
                !generalData.idEstabelecimentoDono &&
                submitted &&
                'Campo requerido'
              }
              onChange={({target}) => {
                const selectedItem = establishments.find(
                  (item) => item.idEstabelecimento === target.value,
                );
                setGeneralData((state) => ({
                  ...state,
                  idEstabelecimentoDono: target.value,
                }));
                setEstablishmentLevelNumber(
                  selectedItem?.numeroDoNivel.toString() ?? '',
                );
              }}>
              {establishments?.map((item) => {
                if (item.idEstabelecimentoPai) {
                  return (
                    <MenuItem
                      key={item.idEstabelecimento}
                      value={item.idEstabelecimento}
                      onClick={() => {
                        setTermoUnidade(item.possuiTermoLgpd);
                        setGeneralData((state) => ({
                          ...state,
                          textoTermoLgpd: item.textoTermoLgpd,
                          nivelEstabelecimentoDono: item.numeroDoNivel,
                        }));
                      }}
                      style={{paddingLeft: theme.spacing(3)}}>
                      {item.idComNome}
                    </MenuItem>
                  );
                } else {
                  return (
                    <MenuItem
                      key={item.idEstabelecimento}
                      value={item.idEstabelecimento}
                      onClick={() => {
                        setTermoUnidade(item.possuiTermoLgpd);
                        setGeneralData((state) => ({
                          ...state,
                          textoTermoLgpd: item.textoTermoLgpd,
                          nivelEstabelecimentoDono: item.numeroDoNivel,
                        }));
                      }}
                      style={{
                        color: theme.palette.primary.main,
                        fontWeight: 'bold',
                        fontSize: 16,
                      }}>
                      {item.idComNome}
                    </MenuItem>
                  );
                }
              })}
            </TextField>
          )}
          <Box marginBottom={1} />
        </Grid>

        <Grid item xs={12} sm={4}>
          {loading ? (
            <Skeleton variant="rect" height={36} />
          ) : (
            <TextField
              fullWidth
              label="Situação da campanha *"
              variant="outlined"
              disabled={!isEditing || !canEdit}
              error={
                (!generalData.idSituacaoDeCampanha && submitted) ||
                (!edit && generalData.idSituacaoDeCampanha === 2) ||
                (!isConfigured && generalData.idSituacaoDeCampanha === 2) ||
                handleTaxaAndLeadDataVerification()
              }
              helperText={
                generalData.idSituacaoDeCampanha === 2 &&
                emailValidation === undefined
                  ? 'Para uma campanha estar na situação ‘Configurada’, é necessário que emails estajam configurados'
                  : handleTaxaAndLeadDataVerification()
                  ? 'Para uma campanha que possui taxa estar na situação ‘Configurada’, é necessário que os parâmetros NomeDevedor e DocumentoDevedor estejam configurados na Ficha de Inscrição.'
                  : !generalData.idSituacaoDeCampanha && submitted
                  ? 'Campo requerido'
                  : ((!edit && generalData.idSituacaoDeCampanha === 2) ||
                      (!isConfigured &&
                        generalData.idSituacaoDeCampanha === 2)) &&
                    'Para uma campanha estar na situação ‘Configurada’, é necessário que as Ofertas e a Ficha de inscrição estejam configuradas.'
              }
              value={generalData.idSituacaoDeCampanha}
              onChange={({target}) =>
                setGeneralData((state) => ({
                  ...state,
                  idSituacaoDeCampanha: Number(target.value),
                }))
              }
              defaultValue={0}
              select>
              <MenuItem value={0} disabled />
              {situationOfCampaigns?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.nome}
                </MenuItem>
              ))}
            </TextField>
          )}
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box marginTop={-2}>
            {loading ? (
              <Skeleton variant="rect" height={36} />
            ) : (
              <DatePicker
                disableToolbar
                inputVariant="outlined"
                fullWidth
                disabled={!isEditing || !canEdit}
                error={handleDateValidate}
                InputProps={{endAdornment: <Event color="primary" />}}
                helperText={
                  handleDateValidate &&
                  'Vigência inicial deve ser menor ou igual a vigência final.'
                }
                format="dd/MM/yyyy"
                margin="normal"
                label="Vigência inicial"
                value={generalData.vigenciaInicial}
                onChange={(date: Date | null) => {
                  date &&
                    setGeneralData((state) => ({
                      ...state,
                      vigenciaInicial: new Date(new Date(date).setHours(3)),
                    }));
                }}
              />
            )}
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box marginTop={-2}>
            {loading ? (
              <Skeleton variant="rect" height={36} />
            ) : (
              <DatePicker
                disableToolbar
                fullWidth
                inputVariant="outlined"
                disabled={!isEditing || !canEdit}
                error={handleDateValidate}
                InputProps={{endAdornment: <Event color="primary" />}}
                helperText={
                  handleDateValidate &&
                  'Vigência final deve ser maior ou igual a vigência inicial.'
                }
                format="dd/MM/yyyy"
                margin="normal"
                label="Vigência final"
                value={generalData.vigenciaFinal}
                onChange={(date: Date | null) => {
                  date &&
                    setGeneralData((state) => ({
                      ...state,
                      vigenciaFinal: new Date(new Date(date).setHours(3)),
                    }));
                }}
              />
            )}
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box marginBottom={0.5} marginTop={-1}>
            {loading ? (
              <Skeleton variant="rect" height={26} width={200} />
            ) : (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={generalData.usaAgendamento}
                    disabled={!isEditing || !canEdit}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setGeneralData((state) => ({
                        ...state,
                        usaAgendamento: event.target.checked,
                      }));
                    }}
                  />
                }
                label={
                  <Box display="flex" alignItems="center" gridGap={4}>
                    <strong>Usa Agendamento</strong>
                    <InfoTooltip title="Possibilita o Lead marcar entrevistas a partir da ficha de inscrição." />
                  </Box>
                }
              />
            )}
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          {loading ? (
            <Skeleton variant="rect" height={36} />
          ) : (
            <TextField
              fullWidth
              label="Tipo de Integração *"
              variant="outlined"
              disabled={!isEditing || !canEdit}
              error={!generalData.idTipoDeIntegracao && submitted}
              helperText={
                !generalData.idTipoDeIntegracao &&
                submitted &&
                'Campo requerido'
              }
              value={generalData.idTipoDeIntegracao || ''}
              onChange={({target}) =>
                setGeneralData((state) => ({
                  ...state,
                  idTipoDeIntegracao: Number(target.value),
                }))
              }
              defaultValue={0}
              select>
              <MenuItem value={0} disabled />
              {typeOfIntegration?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.nome}
                </MenuItem>
              ))}
            </TextField>
          )}
        </Grid>

        <Grid item xs={6} sm={3}>
          {loading ? (
            <Skeleton variant="rect" height={36} />
          ) : (
            <TextField
              label={`Ano letivo ${isCollegeIntegration ? '*' : ''}`}
              variant="outlined"
              disabled={isEquivalenceOffer || !isEditing || !canEdit}
              fullWidth
              value={generalData.ano}
              type="number"
              error={isCollegeIntegration && !generalData.ano && submitted}
              helperText={
                isCollegeIntegration &&
                !generalData.ano &&
                submitted &&
                'Campo requerido'
              }
              onChange={({target}) =>
                setGeneralData((state) => ({
                  ...state,
                  ano: Number(target.value),
                }))
              }
            />
          )}
        </Grid>

        <Grid item xs={6} sm={3}>
          {loading ? (
            <Skeleton variant="rect" height={36} />
          ) : (
            <TextField
              fullWidth
              label={`Periodo letivo ${isCollegeIntegration ? '*' : ''}`}
              variant="outlined"
              disabled={isEquivalenceOffer || !isEditing || !canEdit}
              error={
                isCollegeIntegration &&
                !generalData.idPeriodoLetivo &&
                submitted
              }
              helperText={
                isCollegeIntegration &&
                !generalData.idPeriodoLetivo &&
                submitted &&
                'Campo requerido'
              }
              value={generalData.idPeriodoLetivo}
              onChange={({target}) => {
                setGeneralData((state) => ({
                  ...state,
                  idPeriodoLetivo: target.value,
                }));
              }}
              select>
              {schoolPeriod?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.nome}
                </MenuItem>
              ))}
            </TextField>
          )}
        </Grid>

        {isCollegeIntegration && (
          <Grid item xs={12} sm={12}>
            <Box>
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={generalData.possuiTaxa}
                      disabled={!isEditing || !canEdit}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        setGeneralData(
                          (oldGeneralData) =>
                            (oldGeneralData = {
                              ...oldGeneralData,
                              possuiTaxa: event.target.checked,
                            }),
                        );
                      }}
                    />
                  }
                  label={
                    <Box display="flex" alignItems="center" gridGap={4}>
                      <strong>Possui taxa</strong>
                      <InfoTooltip title="Ao marcar esta opção, você confirma a existência de uma taxa de cobrança associada a essa campanha." />
                    </Box>
                  }
                />
              </Box>
            </Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={generalData.matriculaConfirmada}
                  disabled={!isEditing || !canEdit}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setGeneralData(
                      (oldGeneralData) =>
                        (oldGeneralData = {
                          ...oldGeneralData,
                          matriculaConfirmada: event.target.checked,
                        }),
                    );
                  }}
                />
              }
              label={
                <Box display="flex" alignItems="center" gridGap={4}>
                  <strong>Confirmar matrícula automaticamente</strong>
                  <InfoTooltip title="Candidato será integrado com situação 'matrícula confirmada' automaticamente. Deixe desmarcado para integrar o aluno com situação 'matrícula realizada.'" />
                </Box>
              }
            />
          </Grid>
        )}

        {loading ? (
          <Skeleton variant="rect" height={36} />
        ) : (
          <>
            <Grid item xs={12}>
              <Box marginBottom={1} />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={generalData.disponibilizarTermoDeAceiteParaLead}
                    disabled={
                      (!termoUnidade && !generalData.possuiTermoLgpd) ||
                      !isEditing ||
                      !canEdit
                    }
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setGeneralData(
                        (oldGeneralData) =>
                          (oldGeneralData = {
                            ...oldGeneralData,
                            disponibilizarTermoDeAceiteParaLead:
                              event.target.checked,
                          }),
                      );
                    }}
                  />
                }
                label={
                  <Box
                    display="flex"
                    alignItems="center"
                    gridGap={20}
                    justifyContent={'space-between'}>
                    <strong>Disponibilizar termo de aceite para o lead</strong>
                    <Button
                      id="modal-LGPD"
                      variant="contained"
                      color="primary"
                      onClick={handleOpenModalLGPD}>
                      Visualizar
                    </Button>
                  </Box>
                }
              />
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <Typography variant="h5" color="primary">
            <strong>Integrações</strong>
          </Typography>
          <Box marginBottom={1} />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="subtitle1">
          <strong>Google Analytics</strong>
        </Typography>
        <Box marginBottom={2} />
      </Grid>

      <Grid item xs={7}>
        <Box
          marginBottom={3}
          display="flex"
          alignItems="flex-start"
          gridGap={8}>
          {loading ? (
            <Skeleton variant="rect" height={36} />
          ) : (
            <TextField
              fullWidth
              label="URL do site"
              variant="outlined"
              disabled
              value={user?.urlFormululario}
              helperText={
                'Utilize a URL acima para configurar o Fluxo de Dados, no gerenciamento de Propriedades do Google Analytics.'
              }
            />
          )}

          {loading ? (
            <></>
          ) : (
            <>
              <IconButton
                onClick={() =>
                  navigator.clipboard.writeText(String(user?.urlFormululario))
                }>
                <FileCopyIcon color="primary" />
              </IconButton>
            </>
          )}
        </Box>
      </Grid>

      <Grid item xs={7}>
        {loading ? (
          <Skeleton variant="rect" height={36} />
        ) : (
          <TextField
            fullWidth
            label="ID da Métrica"
            variant="outlined"
            value={generalData.idMetricaGoogleAnalytics}
            helperText={
              'Digite aqui ID da Métrica, obtido na página de  Detalhes do Fluxo. O ID da Métrica é composto da letra G seguido de dez caracteres. Ex: G-A123B456CD'
            }
            onChange={({target}) =>
              setGeneralData((state) => ({
                ...state,
                idMetricaGoogleAnalytics: target.value,
              }))
            }
          />
        )}
        <Box marginBottom={3} />
      </Grid>

      <Box marginY={14} />

      <Footer
        onCancel={() => navigate('/campanha')}
        onSaveLabel={isEditing ? 'Salvar e Avançar' : 'Avançar'}
        onSave={handleSaveAndNextStep}
        disabled={loading}
      />

      <ModalLGPD
        termoUnidade={termoUnidade}
        openModal={openModalLGPD}
        onclose={handleModalLGPD}
        possuiTermo={generalData.possuiTermoLgpd}
        textoLgpd={generalData.textoTermoLgpd}
      />
    </>
  );
};
