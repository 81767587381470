import React, {useEffect} from 'react';
import cloneDeep from 'lodash/cloneDeep';
import {
  Box,
  Grid,
  Checkbox,
  Typography,
  FormControl,
  FormControlLabel,
  TextField,
  MenuItem,
  Select,
  Button,
  IconButton,
} from '@material-ui/core';
import {InfoTooltip, Modal, RichText} from 'shared/components';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import {
  ILead,
  ILeadCategory,
  ITarefa,
} from 'shared/services/api/LeadSituationService';
interface IProps {
  openModal: boolean;
  loading: boolean;
  submitted: boolean;
  nameVerify: boolean;
  edit: boolean;
  title: string;
  onClose(): void;
  onClick(): void;
  categories: ILeadCategory[];
  lead: ILead;
  leadHandler: ILead;
  setLeadHandler: (prevState: ILead) => void;
}

export const ModalSituation: React.FC<IProps> = ({
  openModal,
  title,
  loading,
  submitted,
  nameVerify,
  edit,
  onClose,
  onClick,
  categories,
  lead,
  leadHandler,
  setLeadHandler,
}) => {
  useEffect(() => {
    if (openModal) {
      setLeadHandler(cloneDeep(lead));
    }
  }, [openModal]);

  const addNewTask = () => {
    const newTask: ITarefa = {
      id: '',
      nome: '',
      obrigatoria: false,
    };

    const newLead = {...leadHandler};
    newLead.tarefas.push(newTask);
    setLeadHandler(newLead);
  };

  const deleteTask = (index: number) => {
    const newLead = {...leadHandler};
    newLead.tarefas = newLead.tarefas.filter((_, i) => i !== index);
    setLeadHandler(newLead);
  };

  const handleCheckBoxChange = (indice: number) => {
    const newLead = {...leadHandler};
    newLead.tarefas = leadHandler.tarefas.map((task, index) => {
      if (index === indice) {
        return {nome: task.nome, obrigatoria: !task.obrigatoria} as ITarefa;
      }
      return task;
    });
    setLeadHandler(newLead);
  };

  const handleOrderChange = (value: string) => {
    const number = Number(value);
    if (!isNaN(number)) {
      setLeadHandler({
        ...leadHandler,
        ordem: number,
      });
    }
  };

  const handleNewTaskChange = (indice: number, name: string) => {
    const newLead = {...leadHandler};
    newLead.tarefas = leadHandler.tarefas.map((task, index) => {
      if (index === indice) {
        return {
          id: task.id,
          nome: name,
          obrigatoria: task.obrigatoria,
        } as ITarefa;
      }
      return task;
    });
    setLeadHandler(newLead);
  };

  return (
    <Modal
      opened={openModal}
      maxWidth="md"
      title={title}
      loading={loading}
      onClose={onClose}
      onClick={onClick}
      labelSaveButton={'Salvar'}
      labelCloseButton={'Cancelar'}>
      <FormControl component="fieldset" fullWidth>
        <FormControlLabel
          control={
            <Checkbox
              checked={leadHandler.ativo}
              onChange={() => {
                setLeadHandler({
                  ...leadHandler,
                  ativo: !leadHandler.ativo,
                });
              }}
            />
          }
          label="Ativo"
        />

        <Box marginTop={2} />

        <Grid container item>
          <Box width="100%" display={'flex'}>
            <Grid item xs={2}>
              <FormControl size="small" variant="outlined" fullWidth>
                <InputLabel>Ordem</InputLabel>
                <OutlinedInput
                  label="Ordem"
                  type="string"
                  value={leadHandler.ordem}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleOrderChange(event.target.value);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs>
              <Box marginLeft={2}>
                <FormControl size="small" variant="outlined" fullWidth>
                  <InputLabel>Etapa do Funil</InputLabel>
                  <OutlinedInput
                    label="Etapa do Funil"
                    type="string"
                    value={leadHandler.nome}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setLeadHandler({
                        ...leadHandler,
                        nome: String(event.target.value),
                      });
                    }}
                  />
                </FormControl>
              </Box>
            </Grid>
          </Box>
        </Grid>

        <Box marginTop={4} />

        <FormControl variant="outlined">
          <InputLabel>{lead.categoria.nome}</InputLabel>
          <Select
            error={!leadHandler.idCategDeSituacaoDeLead && submitted}
            value={leadHandler.idCategDeSituacaoDeLead}
            disabled={edit}
            onChange={({target}) => {
              setLeadHandler({
                ...leadHandler,
                idCategDeSituacaoDeLead: Number(target.value),
              });
            }}
            fullWidth>
            <MenuItem value={0} disabled>
              Selecione uma categoria
            </MenuItem>
            {categories?.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.nome}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box marginLeft={1} marginTop={4}>
          <Typography variant="subtitle1" color="primary">
            Notificações
          </Typography>

          <Typography variant="subtitle2" color="textSecondary">
            Ao entrar nessa etapa, notificar:
          </Typography>
          <Box width="100%" display={'flex'} justifyItems="space-between">
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={leadHandler.notificarLead}
                    onChange={() => {
                      setLeadHandler({
                        ...leadHandler,
                        notificarLead: !leadHandler.notificarLead,
                      });
                    }}
                    name="checked1"
                    color="primary"
                  />
                }
                label="Lead"
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={leadHandler.notificarAtendenteAtual}
                    onChange={() => {
                      setLeadHandler({
                        ...leadHandler,
                        notificarAtendenteAtual:
                          !leadHandler.notificarAtendenteAtual,
                      });
                    }}
                    name="checked2"
                    color="primary"
                  />
                }
                label="Atendente Responsável"
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={leadHandler.notificarTodosOsAtendentes}
                    onChange={() => {
                      setLeadHandler({
                        ...leadHandler,
                        notificarTodosOsAtendentes:
                          !leadHandler.notificarTodosOsAtendentes,
                      });
                    }}
                    name="checked3"
                    color="primary"
                  />
                }
                label="Todos os atendentes"
              />
            </Box>
          </Box>
        </Box>

        <Grid item>
          <Box marginLeft={1} marginTop={3}>
            <Typography variant="subtitle1" color="primary">
              Envio de e-mail
            </Typography>
          </Box>
          <Box display="flex" alignItems={'center'}>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={leadHandler.enviarNotificacao}
                    onChange={() => {
                      setLeadHandler({
                        ...leadHandler,
                        enviarNotificacao: !leadHandler.enviarNotificacao,
                      });
                    }}
                    name="checked3"
                    color="primary"
                  />
                }
                label="Enviar e-mail"
              />
            </Box>
            <InfoTooltip title="O e-mail será disparado quando lead chegar nesta etapa" />
          </Box>

          <Box display={'flex'} marginTop={1} marginBottom={1} width={'35%'}>
            <TextField
              label="Quantidade de dias"
              variant="outlined"
              fullWidth
              value={leadHandler.qtdeDiasAteEnvioNotificacao || ''}
              type="number"
              onChange={({target}) => {
                setLeadHandler({
                  ...leadHandler,
                  qtdeDiasAteEnvioNotificacao: target.value,
                });
              }}
              InputProps={{inputProps: {min: 0}}}
            />
            <InfoTooltip title="Quantidade de dias antes do agendamento que o e-mail deve ser enviado" />
          </Box>

          <Box marginTop={2} marginBottom={2}>
            <TextField
              fullWidth
              label="Assunto"
              variant="outlined"
              value={leadHandler.assuntoEmailNotificacao}
              onChange={({target}) => {
                setLeadHandler({
                  ...leadHandler,
                  assuntoEmailNotificacao: target.value,
                });
              }}
            />
          </Box>
        </Grid>

        <Grid item>
          <RichText
            value={leadHandler.mensagemEmailNotificacao || ''}
            onChange={(value) =>
              setLeadHandler({
                ...leadHandler,
                mensagemEmailNotificacao: value || '',
              })
            }
          />

          <Box marginBottom={3} />
        </Grid>

        <Box marginLeft={1} marginTop={4}>
          <Typography variant="subtitle1" color="primary">
            Tarefas
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            Adicione a lista de tarefas que o atendente deverá realizar nessa
            etapa:
          </Typography>
        </Box>
        <Grid container item>
          <Grid>
            {leadHandler.tarefas.map((tarefa, indice) => {
              return (
                <Box
                  key={indice}
                  width="100%"
                  display={'flex'}
                  justifyItems="space-between"
                  marginTop={3}>
                  <Grid item lg>
                    <FormControl size="small" variant="outlined" fullWidth>
                      <InputLabel>Nova tarefa</InputLabel>
                      <OutlinedInput
                        label="Nova tarefa"
                        value={
                          leadHandler.tarefas[indice]?.nome === '' ||
                          leadHandler.tarefas[indice]?.nome === undefined
                            ? tarefa.nome
                            : leadHandler.tarefas[indice]?.nome
                        }
                        onChange={(e) =>
                          handleNewTaskChange(indice, e.target.value)
                        }
                        fullWidth
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <Box marginLeft={2}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={tarefa.obrigatoria}
                            onChange={() => handleCheckBoxChange(indice)}
                            color="primary"
                          />
                        }
                        label="Obrigatória"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <Button onClick={() => deleteTask(indice)}>
                      <Box marginLeft={7} marginTop={1}>
                        <HighlightOffIcon htmlColor="red" />
                      </Box>
                    </Button>
                  </Grid>
                </Box>
              );
            })}
            <Box display="flex" marginTop={2} justifyItems="space-between">
              <IconButton onClick={addNewTask}>
                <Box marginRight={1}>
                  <AddCircleOutlineIcon color="primary" />
                </Box>
              </IconButton>
              <Box marginTop={0.5}>
                <Typography>Nova Tarefa</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </FormControl>
    </Modal>
  );
};
