import {
  Box,
  CircularProgress,
  FormControl,
  IconButton,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
} from '@material-ui/core';
import React, {useCallback, useEffect, useState} from 'react';
import {useDebounce, useTitle} from 'shared/hooks';
import {
  ILossReasons,
  LossReasonsService,
} from 'shared/services/api/LossReasonsService';
import {feedback} from 'shared/services/alertService';
import {errorResponse} from 'shared/utils/errorResponse';
import {Header, ModalConfirm, Table} from 'shared/components';
import {Delete, Edit} from '@material-ui/icons';
import useTheme from '@material-ui/core/styles/useTheme';
import {useStyles} from './styles';
import {ModalLossReasons} from './Modal';

export const LossReasons: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [take, setTake] = useState(10);
  const [lossReasons, setLossReasons] = useState<ILossReasons[]>([]);
  const [lossReasonsPaginated, setLossReasonsPaginated] = useState<
    ILossReasons[][]
  >([]);
  const [filter, setFilter] = useState('');
  const [skip, setSkip] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const newReasonDefault = {
    id: '',
    descricao: '',
    assuntoEmailNotificacao: '',
    mensagemEmailNotificacao: '',
    qtdeDiasAteEnvioNotificacao: 0,
    enviarNotificacao: false,
  };
  const [reasonHandler, setReasonHandler] =
    useState<ILossReasons>(newReasonDefault);
  const [openExcludeModal, setOpenExcludeModal] = useState(false);
  const title = {isEdit: 'Editar motivo de perda', default: 'Motivos de Perda'};
  const [modalTitle, setModalTitle] = useState(title.default);

  const {setTitle} = useTitle();
  const {debounce} = useDebounce();
  const {buttonProgress, select} = useStyles();
  const theme = useTheme();

  useEffect(() => {
    setTitle(title.default);
    handleGetLossReasons();
  }, []);

  useEffect(() => {
    setLossReasonsPaginated(
      sliceArrayIntoChunksAndFilter(lossReasons, take, filter),
    );
  }, [take, lossReasons, filter]);

  const sliceArrayIntoChunksAndFilter = (
    reasons: ILossReasons[],
    take: number,
    filter: string,
  ) => {
    const newLossReasons = [];
    for (let i = 0; i < reasons.length; i += take) {
      const chunk = reasons
        .filter((item) =>
          item.descricao.toLowerCase().includes(filter.toLowerCase()),
        )
        .slice(i, i + take);
      newLossReasons.push(chunk);
    }
    return newLossReasons;
  };

  const handleNewReasonEditPreset = (
    id: string,
    description?: string,
    assuntoEmailNotificacao?: string,
    mensagemEmailNotificacao?: string,
    qtdeDiasAteEnvioNotificacao?: '',
    enviarNotificacao?: boolean,
  ) => {
    setReasonHandler({
      id: id,
      descricao: description ? description : newReasonDefault.descricao,
      assuntoEmailNotificacao: assuntoEmailNotificacao || '',
      mensagemEmailNotificacao: mensagemEmailNotificacao || '',
      qtdeDiasAteEnvioNotificacao: qtdeDiasAteEnvioNotificacao || 0,
      enviarNotificacao: enviarNotificacao,
    });
  };

  const handleCloseModal = () => {
    if (isEditModal) {
      setIsEditModal(false);
      setModalTitle(title.isEdit);
    }
    setReasonHandler(newReasonDefault);
    setOpenModal(false);
  };

  const handleGetLossReasons = useCallback(() => {
    setLoading(true);

    debounce(async () => {
      try {
        const data = await LossReasonsService.getLossReasons();
        if (data) {
          setLossReasons(data);
          setLossReasonsPaginated(
            sliceArrayIntoChunksAndFilter(data, take, filter),
          );
        }
      } catch (error) {
        feedback(errorResponse(error), 'error');
      } finally {
        setLoading(false);
      }
    });
  }, [debounce, filter, take]);

  const handleUpdateLossReason = useCallback(() => {
    setLoading(true);
    debounce(async () => {
      try {
        await LossReasonsService.putLossReason(reasonHandler);
        handleGetLossReasons();
        feedback('Motivo de perda alterado com sucesso', 'success');
      } catch (error) {
        feedback(errorResponse(error), 'error');
      } finally {
        setOpenModal(false);
      }
    });
  }, [debounce, handleGetLossReasons, reasonHandler]);

  const handleCreateLossReason = useCallback(() => {
    setLoading(true);
    debounce(async () => {
      try {
        await LossReasonsService.postLossReason({
          descricao: reasonHandler.descricao,
        });
        handleGetLossReasons();
        feedback('Motivo de perda cadastrado com sucesso', 'success');
      } catch (error) {
        feedback(errorResponse(error), 'error');
      } finally {
        setOpenModal(false);
      }
    });
  }, [reasonHandler, handleGetLossReasons, debounce]);

  const handleDeleteLossReason = useCallback(() => {
    setLoading(true);
    debounce(async () => {
      try {
        await LossReasonsService.deleteLossReason(reasonHandler.id);
        handleGetLossReasons();
        feedback('Motivo de perda cadastrado com sucesso', 'success');
      } catch (error) {
        feedback(errorResponse(error), 'error');
      } finally {
        setOpenExcludeModal(false);
      }
    });
  }, [debounce, handleGetLossReasons, reasonHandler.id]);

  return (
    <>
      <Header
        filter={filter}
        setFilter={setFilter}
        onClick={() => {
          setOpenModal(true);
          setModalTitle(title.default);
          setReasonHandler(newReasonDefault);
        }}
      />

      <Box marginTop={5}>
        <Box marginBottom={2} display="flex" alignItems="center">
          <FormControl component="fieldset">
            <TextField
              inputProps={{className: select}}
              select
              variant="outlined"
              value={take}
              onChange={({target}) => {
                setTake(Number(target.value));
                setSkip(1);
              }}>
              {[10, 15, 20, 50].map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
          <Box marginLeft={2}>Linhas por página</Box>
        </Box>

        <Table
          length={lossReasons.length}
          rowsPerPage={lossReasons.length}
          head={[
            {label: 'Ações', width: '140px'},
            {label: 'Descrições', width: '100%'},
            {label: '', align: 'right'},
          ]}
          pagination={{
            count: Math.ceil(lossReasons.length / take),
            page: skip,
          }}
          loading={loading}
          onChangePage={(value: number) => setSkip(value)}>
          {lossReasonsPaginated[skip - 1]?.map((item) => (
            <TableRow key={item.id}>
              <TableCell>
                <IconButton
                  color="primary"
                  disabled={loading}
                  onClick={() => {
                    handleNewReasonEditPreset(item.id);
                    setOpenExcludeModal(true);
                  }}>
                  <Delete />
                  {loading && (
                    <CircularProgress size={24} className={buttonProgress} />
                  )}
                </IconButton>
                <IconButton
                  color="primary"
                  disabled={loading}
                  style={{marginLeft: theme.spacing(1)}}
                  onClick={() => {
                    handleNewReasonEditPreset(
                      item.id,
                      item.descricao,
                      item.assuntoEmailNotificacao,
                      item.mensagemEmailNotificacao,
                      item.qtdeDiasAteEnvioNotificacao,
                      item.enviarNotificacao,
                    );
                    setModalTitle(title.isEdit);
                    setIsEditModal(true);
                    setOpenModal(true);
                  }}>
                  <Edit />
                </IconButton>
              </TableCell>
              <TableCell>{item.descricao}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          ))}
        </Table>
      </Box>

      <ModalConfirm
        opened={openExcludeModal}
        onClick={handleDeleteLossReason}
        onClose={() => {
          setOpenExcludeModal(false);
        }}
        loading={loading}
      />

      <ModalLossReasons
        loading={loading}
        onClick={isEditModal ? handleUpdateLossReason : handleCreateLossReason}
        onClose={handleCloseModal}
        openModal={openModal}
        title={modalTitle}
        reasonHandler={reasonHandler}
        setReasonHandler={setReasonHandler}
      />
    </>
  );
};
