import React, {createContext, useCallback} from 'react';

import {SignOutService} from 'shared/services/api/SignOutService';

interface IAuthContextData {
  signOut: () => void;
}
export const AuthContext = createContext<IAuthContextData>(
  {} as IAuthContextData,
);

export const AuthProvider: React.FC = ({children}) => {
  const handleSignOut = useCallback(() => SignOutService.signOut(), []);

  return (
    <AuthContext.Provider value={{signOut: handleSignOut}}>
      {children}
    </AuthContext.Provider>
  );
};
