import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      padding: theme.spacing(1.625, 1),
      fontSize: '1rem',
      borderRadius: `${theme.spacing(2)}px`,
      minWidth: '0px',
    },

    buttonError: {
      borderColor: theme.palette.error.light,
      color: theme.palette.error.main,
      '&:hover': {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.light,
        backgroundColor: theme.palette.error.contrastText,
      },
    },

    recurrenceIcon: {
      color: theme.palette.secondary.contrastText,
      width: `${theme.spacing(2)}px`,
      display: 'flex',
      float: 'right',
    },
    subtitles: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      paddingRight: `${theme.spacing(6)}px`,
    },
    dotAvailable: {
      color: '#00B9AD',
      fontSize: '1.5rem',
      padding: `0 ${theme.spacing(0.5)}px 0 ${theme.spacing(2.5)}px`,
    },
    dotUnavailable: {
      color: '#005E5A',
      fontSize: '1.5rem',
      padding: `0 ${theme.spacing(0.5)}px 0 ${theme.spacing(2.5)}px`,
    },

    eventTitle: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '100%',
      marginLeft: '8px',
    },
  }),
);
