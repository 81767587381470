import {IRequestResult} from 'shared/interfaces/IRequestResult';
import {Api} from 'shared/services/axiosConfig';

export interface ICycle {
  id: string;
  nome: string;
  ativo: boolean;
}

export interface ICourse {
  id: string;
  nome: string;
  ativo: boolean;
  usaCiclo: boolean;
  ciclos: ICycle[];
}

export interface ICoursePagination {
  qtdTotalDeRegistros: number;
  data: ICourse[];
}

export interface ICourseAndCycle {
  id: string;
  nome: string;
}

const postCourse = async (
  payload: Omit<ICourse, 'id'>,
): Promise<IRequestResult> => {
  try {
    await Api.post('/cursos', payload);

    return {success: true};
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const updateCourse = async (
  id: string,
  payload: Omit<ICourse, 'id'>,
): Promise<IRequestResult> => {
  try {
    await Api.put(`/cursos/${id}`, payload);

    return {success: true};
  } catch (error: any) {
    throw new Error(error.data.data);
  }
};

const deleteCourse = async (id: string): Promise<IRequestResult> => {
  try {
    await Api.delete(`/cursos/${id}`);

    return {success: true};
  } catch (error: any) {
    throw new Error(error.data.data);
  }
};

const getCourses = async (active = false): Promise<ICourse[]> => {
  try {
    const response = await Api.get(
      active ? '/cursos?ativo=true' : '/cursos',
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const getCoursesPagination = async (
  ativo: number,
  campoDePesquisa: string,
  take: number,
  skip: number,
): Promise<ICoursePagination> => {
  try {
    const {data: response} = await Api.get(`/cursos/paginado?ativo=
    ${ativo === 0 ? '' : ativo === 1 ? true : false }&CampoDePesquisa=
    ${campoDePesquisa}&take=${take}&skip=${skip}`);

    return response;
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const getCoursesAndCycles = async (
  somenteCicloAtivo?: boolean,
): Promise<ICourseAndCycle[]> => {
  try {
    const response = await Api.get(
      `/cursos/cursocomciclos?somenteCicloAtivo=${
        somenteCicloAtivo ? somenteCicloAtivo : ''
      }`,
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const getCourse = async (id: string): Promise<ICourse> => {
  try {
    const response = await Api.get(`/cursos/${id}`);

    return response.data;
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

export const CourseService = {
  getCoursesAndCycles,
  deleteCourse,
  updateCourse,
  getCourses,
  getCoursesPagination,
  postCourse,
  getCourse,
};
