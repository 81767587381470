import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {ChromePicker} from 'react-color';
import {useNavigate, useParams} from 'react-router-dom';

import {Box, Fade, Grid, IconButton, Typography} from '@material-ui/core';
import {AddCircle, Image} from '@material-ui/icons';
import {StepperCampaign} from 'pages/Campaign/components/StepperCampaign';
import HeaderCC from 'shared/assets/cabecalho-cc.png';
import MessageCC from 'shared/assets/completo-cc.png';
import BaseboardCC from 'shared/assets/rodape-cc.png';
import {Button, Footer, IconButtonColor, RichText} from 'shared/components';
import {useTitle, useUser} from 'shared/hooks';
import {feedback} from 'shared/services/alertService';
import {CampaignService} from 'shared/services/api/CampaignService';
import {errorResponse} from 'shared/utils/errorResponse';
import {imageExtension} from 'shared/utils/imageExtension';

import {BannerImage, ColorImage, LogoImage, ModalImage} from './components';
import { addTargetBlank } from 'shared/utils/addTargetBlank';

export const CampaignPersonalization: React.FC = () => {
  const [isEditing, setIsEditing] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isIntegrated, setIsIntegrated] = useState(false);
  const [nameCampaign, setNameCampaign] = useState('');

  const [openColorPiker, setOpenColorPiker] = useState(false);
  const [openModalLogo, setOpenModalLogo] = useState(false);
  const [openModalBanner, setOpenModalBanner] = useState(false);

  const [logoImg, setLogoImg] = useState('');
  const [bannerImg, setBannerImg] = useState('');
  const [colorPiker, setColorPiker] = useState('#018781');
  const [header, setHeader] = useState('');
  const [baseboard, setBaseboard] = useState('');
  const [messageConfirm, setMessageConfirm] = useState('');

  const navigate = useNavigate();
  const {id = '0'} = useParams<'id'>();
  const {setTitle} = useTitle();
  const {data: user} = useUser();

  const permissions = useMemo(
    () => user?.permissoes.filter((i) => i.nome.includes('Campanha')),
    [user?.permissoes],
  );

  const canList = useMemo(
    () => !!permissions?.find((i) => i.nome.includes('Listar')),
    [permissions],
  );

  useEffect(() => {
    if (!canList) {
      navigate('/');
    }
  }, [canList, navigate]);

  const handleGetFormConfig = useCallback(async () => {
    try {
      const response = await CampaignService.getFormConfigCampaign(id);

      if (response) {
        setLogoImg(
          response.extensaoLogotipo && response.logotipoParaCabecalhoFormulario
            ? `data:image/${response.extensaoLogotipo};base64,${response.logotipoParaCabecalhoFormulario}`
            : '',
        );
        setBannerImg(
          response.extensaoBanner && response.bannerParaCabecalhoFormulario
            ? `data:image/${response.extensaoBanner};base64,${response.bannerParaCabecalhoFormulario}`
            : '',
        );
        setColorPiker(response.corBotao || '#018781');

        if (response.instrucaoParaCabecalhoFormulario) {
          setHeader(response.instrucaoParaCabecalhoFormulario);
        }
        if (response.instrucaoParaRodapeFormulario) {
          setBaseboard(response.instrucaoParaRodapeFormulario);
        }

        if (response.mensagemDeConclusaoFormulario) {
          setMessageConfirm(response.mensagemDeConclusaoFormulario);
        }
      }
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
  }, [id]);

  const handleGetCampaign = useCallback(async () => {
    try {
      const response = await CampaignService.getCampaign({
        id: id,
        verificarPermissaoEditar: true,
      });

      if (response) {
        setIsEditing(response.permiteEditar);
        setIsIntegrated(response.idTipoDeIntegracao !== 1);
        setNameCampaign(response.nome);
      }
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
  }, [id]);

  const handleCloseModal = useCallback(() => {
    setOpenModalLogo(false);
    setOpenModalBanner(false);
  }, []);

  const handleNextEvent = useCallback(async () => {
    if (!isEditing) {
      isIntegrated
        ? navigate(`/campanha/integracao/${id}`)
        : navigate('/campanha');
    }

    setLoading(true);
    try {
      await CampaignService.putFormConfigCampaign(id, {
        idCampanha: id,
        bannerParaCabecalhoFormulario: bannerImg.split(',')[1],
        logotipoParaCabecalhoFormulario: logoImg.split(',')[1],
        corBotao: colorPiker,
        instrucaoParaCabecalhoFormulario: addTargetBlank(header),
        instrucaoParaRodapeFormulario: addTargetBlank(baseboard),
        mensagemDeConclusaoFormulario: addTargetBlank(messageConfirm),
        extensaoBanner: imageExtension(bannerImg),
        extensaoLogotipo: imageExtension(logoImg),
      });

      isIntegrated
        ? await navigate(`/campanha/integracao/${id}`)
        : await navigate('/campanha');
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
    setLoading(false);
  }, [
    isEditing,
    isIntegrated,
    navigate,
    id,
    bannerImg,
    logoImg,
    colorPiker,
    header,
    baseboard,
    messageConfirm,
  ]);

  const handleGets = useCallback(async () => {
    setLoading(true);
    try {
      handleGetFormConfig();
      await handleGetCampaign();
    } catch (error) {
      feedback(errorResponse(error), 'error');
    } finally {
      setLoading(false);
    }
  }, [handleGetFormConfig, handleGetCampaign]);

  useEffect(() => {
    handleGets();
  }, [handleGets]);

  useEffect(() => {
    setTitle(`Campanha ${nameCampaign} | Personalização`);
  }, [setTitle, nameCampaign]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <StepperCampaign
            integrated={isIntegrated}
            loading={loading}
            activeStep={5}
            idCampaign={id}
          />

          <Box marginBottom={1} />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">Logo no cabeçalho</Typography>
          <Typography>
            Essa foto aparecerá na parte superior esquerda do formulário.
          </Typography>
          <Box marginY={3} />

          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <LogoImage logo={logoImg} />
            </Grid>
            <Grid item xs={3}>
              <Typography>
                Recomendamos usar uma imagem de até 4 MB com pelo menos 120 x
                120 pixels. Use um arquivo no formato PNG ou GIF (sem
                animações).
              </Typography>
              <Box marginY={2} />

              <Button
                fullWidth
                startIcon={<Image />}
                color="primary"
                label={!logoImg ? 'Escolher imagem' : 'Remover imagem'}
                error={!!logoImg}
                variant="outlined"
                disabled={!isEditing}
                onClick={
                  !logoImg ? () => setOpenModalLogo(true) : () => setLogoImg('')
                }
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">Banner no cabeçalho</Typography>
          <Typography>
            Essa imagem aparecerá na parte superior do formulário.
          </Typography>
          <Box marginY={3} />

          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <BannerImage banner={bannerImg} />
            </Grid>

            <Grid item xs={3}>
              <Typography>
                Para garantir os melhores resultados em todos os dispositivos,
                escolha uma imagem de até 6 MB com proporção mínima de 900 x 150
                pixels.
              </Typography>
              <Box marginY={2} />

              <Button
                fullWidth
                startIcon={<Image />}
                color="primary"
                label={!bannerImg ? 'Escolher imagem' : 'Remover imagem'}
                error={!!bannerImg}
                variant="outlined"
                disabled={!isEditing}
                onClick={
                  !bannerImg
                    ? () => setOpenModalBanner(true)
                    : () => setBannerImg('')
                }
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">Cor do botão</Typography>
          <Typography>
            Escolha a cor do botão que aparecerá na parte inferior do
            formulario.
          </Typography>
          <Box marginY={3} />

          <Grid container spacing={3} component={Box} position="relative">
            {openColorPiker && (
              <Fade in={openColorPiker}>
                <Box
                  position="absolute"
                  right={0}
                  onMouseLeave={() => setOpenColorPiker(false)}>
                  <ChromePicker
                    color={colorPiker}
                    onChange={(color) => setColorPiker(color.hex)}
                  />
                </Box>
              </Fade>
            )}

            <Grid item xs={12} sm={4}>
              <ColorImage color={colorPiker} />
            </Grid>

            <Grid item xs={3}>
              <Typography>
                Utilize o código hexadecimal para incluir uma cor de sua
                preferência não disponível.
              </Typography>

              <Box marginY={2}>
                <IconButtonColor
                  color="#018781"
                  setColorPiker={setColorPiker}
                  colorPiker={colorPiker}
                  disabled={!isEditing}
                />
                <IconButtonColor
                  color="#00B9AD"
                  setColorPiker={setColorPiker}
                  colorPiker={colorPiker}
                  disabled={!isEditing}
                />
                <IconButtonColor
                  color="#4285F4"
                  setColorPiker={setColorPiker}
                  colorPiker={colorPiker}
                  disabled={!isEditing}
                />
                <IconButtonColor
                  color="#F4405C"
                  setColorPiker={setColorPiker}
                  colorPiker={colorPiker}
                  disabled={!isEditing}
                />
                <IconButtonColor
                  color="#54BDD5"
                  setColorPiker={setColorPiker}
                  colorPiker={colorPiker}
                  disabled={!isEditing}
                />
                <IconButtonColor
                  color="#EB5333"
                  setColorPiker={setColorPiker}
                  colorPiker={colorPiker}
                  disabled={!isEditing}
                />

                <IconButton
                  onClick={() => setOpenColorPiker(true)}
                  disabled={!isEditing}>
                  <AddCircle />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">Instrução para o cabeçalho</Typography>
          <Typography>
            Mensagem de ajuda ao usuário no cabeçalho (abaixo do banner).
          </Typography>

          <Box marginY={3} />

          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <img src={HeaderCC} alt="color" width="100%" />

              <Box marginTop={3} />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <RichText
                disableMacros
                value={header}
                onChange={setHeader}
                disabled={!isEditing}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">Instrução para o rodapé</Typography>
          <Typography>Mensagem de ajuda ao usuário no rodapé.</Typography>

          <Box marginY={3} />

          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <img src={BaseboardCC} alt="color" width="100%" />

              <Box marginTop={3} />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <RichText
                disableMacros
                value={baseboard}
                onChange={setBaseboard}
                disabled={!isEditing}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">Mensagem de confirmação</Typography>
          <Typography>
            Mensagem de confirmação após o preenchimento do formulario.
          </Typography>
          <Box marginY={3} />

          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <img src={MessageCC} alt="color" width="100%" />

              <Box marginTop={3} />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <RichText
                disableMacros
                value={messageConfirm}
                onChange={setMessageConfirm}
                disabled={!isEditing}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Box marginY={14} />

      <Footer
        onCancel={() => navigate('/campanha')}
        onSave={handleNextEvent}
        onGoBack={() => navigate(`/campanha/ficha-de-inscricao/${id}`)}
        onSaveLabel={
          isEditing
            ? isIntegrated
              ? 'Salvar e Avançar'
              : 'Concluir'
            : 'Avançar'
        }
        disabled={loading}
      />

      <ModalImage
        width={120}
        height={120}
        aspect={2 / 2}
        imageId="logo"
        image={logoImg}
        setImage={setLogoImg}
        openModal={openModalLogo}
        onClose={handleCloseModal}
      />

      <ModalImage
        width={900}
        height={150}
        maxWidth="lg"
        aspect={6 / 1}
        imageId="banner"
        image={bannerImg}
        setImage={setBannerImg}
        onClose={handleCloseModal}
        openModal={openModalBanner}
      />
    </>
  );
};
