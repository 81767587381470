import {IRequestResult} from 'shared/interfaces/IRequestResult';
import {Api} from 'shared/services/axiosConfig';

export interface IAdmUser {
  idUsuarioAuth: number;
  login: string;
  nome: string;
}
export interface IAvailabilities {
  id: string;
  nome: string;
  idCurso?: string;
  idCiclo?: string;
}

export interface IInterviewer {
  id: string;
  idUsuario: string;
  nomeUsuario: string; //entrevistador
  idEstabelecimento: string;
  nomeEstabelecimento: string;
  disponivelParaTodos: boolean;
  disponibilidades: IAvailabilities[];
  ativo: boolean;
  permiteEditar: boolean;
}

export interface IInterviewerPagination {
  qtdTotalDeRegistros: number;
  data: IInterviewer[];
}

interface IGetInterviewers {
  idPermissao?: string;
  idEstabelecimento?: string;
}

interface IGetInterviewer {
  idEntrevistador: string;
  verificarPermissaoEditar: boolean;
}

const getAdmUserInterviewer = async (): Promise<IAdmUser[]> => {
  try {
    const {data} = await Api.get(
      '/UsuarioAdministrativo/UsuariosEntrevistadores',
    );

    return data;
  } catch (error: any) {
    throw new Error('Erro ao listar entrevistadores!');
  }
};

const getInterviewers = async (
  payload: IGetInterviewers,
): Promise<IInterviewer[]> => {
  try {
    const {data} = await Api.get(
      `/Entrevistador?idEstabelecimento=${
        payload.idEstabelecimento ? payload.idEstabelecimento : ''
      }&idPermissao=${payload.idPermissao ? payload.idPermissao : ''}`,
    );
    return data;
  } catch (error: any) {
    throw new Error('Erro ao listar entrevistadores!');
  }
};

const getInterviewer = async (
  payload: IGetInterviewer,
): Promise<IInterviewer> => {
  try {
    const {data} = await Api.get(
      `/Entrevistador/${payload.idEntrevistador}?verificarPermissaoEditar=${payload.verificarPermissaoEditar}`,
    );
    return data;
  } catch (error: any) {
    throw new Error('Erro ao listar entrevistadores!');
  }
};

const getInterviewersPagination = async (
  payload: IGetInterviewers,
  CampoDePesquisa: string,
  activo: number,
  take: number,
  skip: number,
): Promise<IInterviewerPagination> => {
  try {
    const {data} = await Api.get(
      `/Entrevistador/paginado?idEstabelecimento=${
        payload.idEstabelecimento ? payload.idEstabelecimento : ''
      }&idPermissao=${
        payload.idPermissao ? payload.idPermissao : ''
      }&take=${take}&skip=${skip}&campoDePesquisa=${CampoDePesquisa}&ativo=${activo === 0 ? '' : activo === 1 ? true : false}`,
    );
    return data;
  } catch (error: any) {
    throw new Error('Erro ao listar entrevistadores!');
  }
};
const deleteInterviewer = async (id: string): Promise<IRequestResult> => {
  try {
    await Api.delete(`/Entrevistador/${id}`);
    return {success: true};
  } catch (error: any) {
    throw new Error(`Erro ao tentar excluir o entrevistador. ${error.data.data}`);
  }
};

const updateInterviewer = async (
  id: string,
  data: IInterviewer,
): Promise<IRequestResult> => {
  try {
    await Api.put(`/Entrevistador/${id}`, data);

    return {success: true};
  } catch (error: any) {
    throw new Error(`Erro ao tentar editar o entrevistador. ${error.data.data}`);
  }
};

const postInterviewer = async (data: IInterviewer): Promise<IRequestResult> => {
  try {
    await Api.post('/Entrevistador', data);

    return {success: true};
  } catch (error: any) {
    throw new Error(`${error.data.data}`);
  }
};

export const InterviewerService = {
  getInterviewer,
  postInterviewer,
  getInterviewers,
  updateInterviewer,
  deleteInterviewer,
  getAdmUserInterviewer,
  getInterviewersPagination,
};
