import { IAtendimentoXConversaoPorPeriodo } from "shared/services/api/CRMDashboard";

export const getDashboardLabels = (
  periodo: string, 
  data: IAtendimentoXConversaoPorPeriodo[] | undefined
) => {
  if (data === undefined) return [];

  switch (Number(periodo)) {
    case 1: { // Todo o tempo
      const firstDate = new Date(data[0].data);
      const lastDate = new Date(data[data.length - 1].data);
      const labels = [];
      
      while (firstDate <= lastDate) {
        const month = firstDate.getMonth() + 1; // Adding 1 because getMonth() returns zero-based index
        const year = firstDate.getFullYear();
      
        labels.push(`${month.toString().padStart(2, '0')}/${year}`);
      
        // Move to the next month
        firstDate.setMonth(month);
      }
      
      return labels;
    }
    case 2: { // Mês atual
      return data.map(item => {
        const date = new Date(item.data);
        return `${date.getDate().toString()
          .padStart(2, '0')}/${(date.getMonth() + 1).toString()
            .padStart(2, '0')
        }`;
      }).filter((value, index, self) => self.indexOf(value) === index);
    }
    case 3: { // Mês anterior
      return data.map(item => {
        const date = new Date(item.data);
        return `${
          date.getDate().toString()
            .padStart(2, '0')}/${(date.getMonth() + 1).toString()
              .padStart(2, '0')
        }`;
      }).filter((value, index, self) => self.indexOf(value) === index);
    }
    case 4: { // Trimestre atual
      const labels = [];
      const firstDate = new Date(data[0].data);
      const lastDate = new Date(data[data.length - 1].data);
      const trimesterStart = new Date(firstDate);
      while (trimesterStart <= lastDate) {
        labels.push(`${
          trimesterStart.getDate().toString()
            .padStart(2, '0')}/${
              (trimesterStart.getMonth() + 1).toString().padStart(2, '0')
            }/${trimesterStart.getFullYear()
          }`);
        trimesterStart.setDate(trimesterStart.getDate() + 7);
      }
      return labels;
    }
    case 5: { // Ano atual
      const uniqueMonths = new Set(data.map(item => `${
        (new Date(item.data).getMonth() + 1).toString()
          .padStart(2, '0')}/${new Date(item.data).getFullYear()
      }`));
      return Array.from(uniqueMonths);
    }
    case 6: { // Ano anterior
      const uniqueMonths = new Set(data.map(item => `${
        (new Date(item.data).getMonth() + 1).toString()
          .padStart(2, '0')}/${new Date(item.data).getFullYear()
        }`));
      return Array.from(uniqueMonths);
    }
    default:
      return [];
  }
};