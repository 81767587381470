import {Api} from 'shared/services/axiosConfig';
import {format, addHours} from 'date-fns';

export interface IScheduleAvailability {
  idDisponibilidadeDeAgendaAtribuida: string | null;
  idDisponibilidadeDeAgenda: string;
  idEntrevistador: string;
  nomeEntrevistador: string;
  iniciaEm: string;
  terminaEm: string;
  observacao: string;
}

const getAvailabilities = async (
  idOferta: string,
  userTimezone: number,
  idDisponibilidadeDeAgendaAtribuida?: string,
): Promise<IScheduleAvailability[] | undefined> => {
  try {
    const {data} = await Api.get(
      `/disponibilidadedeagenda/disponibilidadeparalead?idOferta=${idOferta}&idDisponibilidadeDeAgendaAtribuida=${
        idDisponibilidadeDeAgendaAtribuida
          ? idDisponibilidadeDeAgendaAtribuida
          : ''
      }`,
    );

    const response: IScheduleAvailability[] = data.map(
      (item: IScheduleAvailability) =>
        ({
          ...item,
          iniciaEm: format(
            addHours(new Date(item.iniciaEm), userTimezone),
            "yyyy-MM-dd'T'HH:mm:ss",
          ),
          terminaEm: format(
            addHours(new Date(item.terminaEm), userTimezone),
            "yyyy-MM-dd'T'HH:mm:ss",
          ),
        } as IScheduleAvailability),
    );

    if (response) {
      return response;
    }
    return undefined;
  } catch (error) {
    return undefined;
  }
};

const getScheduleAvailability = async (
  idDisponibilidadeDeAgenda: string,
  userTimezone: number,
): Promise<IScheduleAvailability> => {
  try {
    const {data} = await Api.get(
      `/DisponibilidadeDeAgenda/${idDisponibilidadeDeAgenda}`,
    );

    const response: IScheduleAvailability = {
      ...data,
      iniciaEm: format(
        addHours(new Date(data.iniciaEm), userTimezone),
        "yyyy-MM-dd'T'HH:mm:ss",
      ),
      terminaEm: format(
        addHours(new Date(data.terminaEm), userTimezone),
        "yyyy-MM-dd'T'HH:mm:ss",
      ),
    };

    return response;
  } catch (error: any) {
    throw new Error(`Erro ao listar. ${error.mensagens}`);
  }
};

export const AvailabilitiesService = {
  getAvailabilities,
  getScheduleAvailability,
};
