export const FUNNEL_DEFAULT = {
  id: '',
  nome: '',
  status: '',
  estabelecimento: '',
  utilizado: '',
  selecionado: false,
};

export const FUNNEL_HANDLER_DEFAULT = {
  id: '',
  nivelEstabelecimentoDono: -1,
  nome: '',
  idEstabelecimento: '',
  desativar: false
};