import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  Box,
  TableRow,
  useTheme,
  TableCell,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import {Delete, Edit} from '@material-ui/icons';

import {useTitle, useUser, useDebounce} from 'shared/hooks';
import {feedback} from 'shared/services/alertService';
import {errorResponse} from 'shared/utils/errorResponse';
import {IShift, ShiftService} from 'shared/services/api/ShiftService';
import {Header, ModalConfirm, Table} from 'shared/components';

import {ModalShift} from './Modal';
import {useStyles} from './styles';
import {useNavigate} from 'react-router-dom';

export const Shift: React.FC = () => {
  const [filter, setFilter] = useState('');
  const [loading, setLoading] = useState(false);
  const [idDeleted, setIdDeleted] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [shifts, setShifts] = useState<IShift[]>([]);
  const [tableFiltered, setTableFiltered] = useState(0);
  const [openModalConfirmExclude, setOpenModalConfirmExclude] = useState(false);
  const defaultShift = {nome: '', ativo: true} as IShift;
  const [shift, setShift] = useState<IShift>(defaultShift);
  const [skip, setSkip] = useState(1);
  const [take, setTake] = useState(10);
  const [shiftsCount, setShiftsCount] = useState(0);
  const title = {isEdit: 'Editar Turno', default: 'Cadastro de Turno'};
  const [modalTitle, setModalTitle] = useState(title.default);
  const [nameVerify, setNameVerify] = useState(false);

  const theme = useTheme();
  const classes = useStyles();
  const {setTitle} = useTitle();
  const navigate = useNavigate();
  const {debounce} = useDebounce();
  const {data: user} = useUser();

  const permissions = useMemo(
    () => user?.permissoes.filter((i) => i.nome.includes('Turno')),
    [user?.permissoes],
  );

  const canDelete = useMemo(
    () => !!permissions?.find((i) => i.nome.includes('Excluir')),
    [permissions],
  );

  const canRegister = useMemo(
    () => !!permissions?.find((i) => i.nome.includes('Incluir')),
    [permissions],
  );

  const canEdit = useMemo(
    () => !!permissions?.find((i) => i.nome.includes('Editar')),
    [permissions],
  );

  const canList = useMemo(
    () => !!permissions?.find((i) => i.nome.includes('Listar')),
    [permissions],
  );

  useEffect(() => {
    if (!canList) {
      navigate('/');
    }
  }, [canList, navigate]);

  const handleVerifyNameShift = useCallback(() => {
    if (editModal) {
      const verification =
        shifts
          .filter((i) => i.id !== shift.id)
          .filter(
            (i) =>
              i.nome.toLowerCase().trim() === shift.nome.toLowerCase().trim(),
          ).length > 0;
      setNameVerify(verification);
      return verification;
    }
    const verification =
      shifts.filter(
        (i) => i.nome.toLowerCase().trim() === shift.nome.toLowerCase().trim(),
      ).length > 0;
    setNameVerify(verification);
    return verification;
  }, [editModal, shift.id, shift.nome, shifts]);

  const handleGetShift = useCallback(() => {
    setLoading(true);

    debounce(async () => {
      try {
        const data = await ShiftService.getShiftsPagination(
          tableFiltered,
          filter,
          take,
          skip,
        );
        if (data) {
          setShifts(data.data);
          setShiftsCount(data.qtdTotalDeRegistros);
        }
      } catch (error) {
        feedback(errorResponse(error), 'error');
      } finally {
        setLoading(false);
      }
    });
  }, [tableFiltered, filter, take, skip, debounce]);

  const handleDelete = useCallback((id: string) => {
    setOpenModalConfirmExclude(true);

    setIdDeleted(id);
  }, []);

  const handleConfirmDeleted = useCallback(async () => {
    try {
      setLoading(true);
      await ShiftService.deleteShift(idDeleted);

      await handleGetShift();

      feedback('Turno deletado com sucesso', 'success');
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
    setLoading(false);
    setIdDeleted('');
    setOpenModalConfirmExclude(false);
  }, [handleGetShift, idDeleted]);

  const handleResetDataModal = useCallback(() => {
    if (editModal) {
      setShift({
        ativo: true,
        nome: '',
      } as IShift);
      setTimeout(() => {
        setEditModal(false);
      }, 1000);
    }
  }, [editModal]);

  const handleCreateShift = useCallback(async () => {
    if (handleVerifyNameShift()) return;

    if (shift.nome.length === 0) {
      setSubmitted(true);

      return;
    } else {
      setLoading(true);
      try {
        await ShiftService.postShift({
          ativo: shift.ativo,
          nome: shift.nome,
        });

        await handleGetShift();

        feedback('Turno adicionado com sucesso', 'success');
      } catch (error) {
        feedback(errorResponse(error), 'error');
      }
      setLoading(false);
      setOpenModal(false);
      handleResetDataModal();
    }
    setSubmitted(false);
  }, [
    handleVerifyNameShift,
    shift.nome,
    shift.ativo,
    handleResetDataModal,
    handleGetShift,
  ]);

  const handleUpdateShift = useCallback(async () => {
    if (handleVerifyNameShift()) return;

    if (shift.nome.length === 0) {
      setSubmitted(true);

      return;
    } else {
      setLoading(true);
      try {
        await ShiftService.updateShift(shift.id, {
          ativo: shift.ativo,
          nome: shift.nome,
        });

        await handleGetShift();

        feedback('Turno atualizado com sucesso', 'success');
      } catch (error) {
        feedback(errorResponse(error), 'error');
      }
      setLoading(false);
      setOpenModal(false);
      handleResetDataModal();
    }
    setSubmitted(false);
  }, [
    handleGetShift,
    handleResetDataModal,
    handleVerifyNameShift,
    shift.ativo,
    shift.id,
    shift.nome,
  ]);

  useEffect(() => setTitle('Turno'), [setTitle]);

  useEffect(() => {
    handleGetShift();
  }, [handleGetShift]);

  const handleCloseModal = () => {
    if (editModal) {
      setEditModal(false);
      setModalTitle(title.isEdit);
    }
    setShift(defaultShift);
    setSubmitted(false);
    setOpenModal(false);
  };

  return (
    <>
      <Header
        filter={filter}
        setFilter={setFilter}
        disabledButton={!canRegister}
        tableFiltered={tableFiltered}
        setTableFiltered={setTableFiltered}
        onClick={() => {
          // setShift(defaultShift);
          setOpenModal(true);
          setModalTitle(title.default);
        }}
      />

      <Box marginTop={5} />

      <Table
        head={[
          {label: 'Ações', width: '140px'},
          {label: 'Turno'},
          {label: 'Status', width: '140px'},
        ]}
        pagination={{
          count: Math.ceil(shiftsCount / take),
          page: skip,
        }}
        loading={loading}
        length={shiftsCount}
        rowsPerPage={shifts.length}
        onChangeTake={(value: number) => setTake(value)}
        take={take}
        onChangePage={(value: number) => setSkip(value)}>
        {shifts.map((item) => (
          <TableRow key={item.id}>
            <TableCell>
              <IconButton
                color="primary"
                disabled={loading || !canDelete}
                onClick={() => handleDelete(item.id)}>
                <Delete />
                {loading && idDeleted === item.id && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </IconButton>

              <IconButton
                color="primary"
                disabled={loading || !canEdit}
                style={{marginLeft: theme.spacing(1)}}
                onClick={() => {
                  setEditModal(true),
                    setModalTitle(title.isEdit),
                    setShift(item);
                  setOpenModal(true);
                }}>
                <Edit />
              </IconButton>
            </TableCell>
            <TableCell>{item.nome}</TableCell>
            <TableCell>{item.ativo ? 'Ativo' : 'Inativo'}</TableCell>
          </TableRow>
        ))}
      </Table>

      <ModalConfirm
        opened={openModalConfirmExclude}
        onClick={handleConfirmDeleted}
        onClose={() => {
          setOpenModalConfirmExclude(false), setIdDeleted('');
        }}
        loading={loading}
      />

      {shift && (
        <ModalShift
          openModal={openModal}
          loading={loading}
          submitted={submitted}
          title={modalTitle}
          onClose={handleCloseModal}
          nameVerify={nameVerify}
          onClick={editModal ? handleUpdateShift : handleCreateShift}
          shift={shift}
          setShift={setShift}
        />
      )}
    </>
  );
};
