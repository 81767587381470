import React, {useCallback, useEffect, useState} from 'react';
import {
  Box,
  Grid,
  FormControl,
  TextField,
  ListSubheader,
  MenuItem,
} from '@material-ui/core';
import { useNavigate, useParams} from 'react-router-dom';

import {
  Footer,
} from 'shared/components';
import {feedback} from 'shared/services/alertService';
import {useTitle} from 'shared/hooks';
import {errorResponse} from 'shared/utils/errorResponse';
import {CampaignService} from 'shared/services/api/CampaignService';
import {StepperCampaign} from 'pages/Campaign/components/StepperCampaign';
import { IFunnel } from 'shared/services/api/FunnelService/types';
import { FunnelService } from 'shared/services/api/FunnelService';
import { FunnelSteps } from './components/FunnelSteps';
import { Skeleton } from '@material-ui/lab';

type Params = {
  id?: string;
  idEstabelecimento?: string;
  nivelEstabelecimentoDono?: string;
};

export const CampaignFunnel: React.FC = () => {
  const [isEditing, setIsEditing] = useState(true);
  const [isIntegrated, setIsIntegrated] = useState(false);
  const [nameCampaign, setNameCampaign] = useState('');
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const [funnels, setFunnels] = useState<IFunnel[]>([]);
  const [funnel, setFunnel] = useState<IFunnel>(
    {} as IFunnel
  );
  const [selectedFunnel, setSelectedFunnel] = useState<IFunnel>(
    {} as IFunnel
  );
  const {setTitle} = useTitle();
  const navigate = useNavigate();
  const {id = '0', idEstabelecimento, nivelEstabelecimentoDono} = useParams<Params>();

  const getFunnels = useCallback(async () => {
    try {
      const response = await FunnelService.getFunnelsById(
        idEstabelecimento,
        nivelEstabelecimentoDono,
        id
      );
      if (response) {
        setFunnels(response);
        setFunnel(
          response.find(funnel => funnel.selecionado === true) ??
          {} as IFunnel
        )
      }
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
  }, [
    id,
    idEstabelecimento,
    nivelEstabelecimentoDono
  ]);

  const handleSaveAndNextStep = async () => {
    navigate(`/campanha/ofertas/${id}`)
  }

  const handleSaveOnChange = async (
    selectedFunnelId: string,
    campaignId: string,
  ) => {
    try {
      setLoading(true);
      await CampaignService.patchFunnelToCampaign(
        selectedFunnelId,
        campaignId
      );
    } catch (error: any) {
      feedback(errorResponse(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  const handleGetCampaign = useCallback(async () => {
    try {
      const response = await CampaignService.getCampaign({
        id: id,
        verificarPermissaoEditar: true,
      });

      if (response) {
        setIsEditing(response.permiteEditar);
        setIsIntegrated(response.idTipoDeIntegracao !== 1);
        setNameCampaign(response.nome);
      }
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
  }, [id]);

  const handleGets = useCallback(async () => {
    setLoading(true);
    try {
      await handleGetCampaign();
      await getFunnels()
    } catch (error) {
      feedback(errorResponse(error), 'error');
    } finally {
      setLoading(false);
    }
  }, [
    handleGetCampaign,
    getFunnels
  ]);

  useEffect(() => {
    setTitle(`Campanha ${nameCampaign} | Funil`);
  }, [setTitle, nameCampaign]);

  useEffect(() => {
    handleGets();
  }, [handleGets]);

  useEffect(() => {
    if (funnels.length > 0) {
      const defaultFunnel = funnels.find(funnel => funnel.selecionado === true) ??
        {} as IFunnel;
      setSelectedFunnel(defaultFunnel);
      setFunnel(defaultFunnel);
    }
  }, [funnels]);

  useEffect(() => {
    if(Object.keys(funnel).length > 0) setIsDisabled(false);
  }, [funnel])

  const handleChildLoading = (isLoading: boolean) => {
    setLoading(isLoading);
  };

  return (
    <>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <StepperCampaign
          integrated={isIntegrated}
          loading={loading}
          activeStep={2}
          idCampaign={id}
          idEstabelecimento={idEstabelecimento}
          nivelEstabelecimentoDono={nivelEstabelecimentoDono}
          isDisabled={isDisabled}
        />
        <Box marginBottom={1} />
      </Grid>

      <Grid item xs={12} sm={12}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          gridGap={24}
        >
          {loading ? (
              <Skeleton variant="rect" width="100%" height={40} animation="wave"/>
            ) : (
            <FormControl variant="outlined" fullWidth>
                <TextField
                  fullWidth
                  select
                  size="small"
                  value={selectedFunnel.id}
                  label="Funil"
                  variant="outlined"
                  disabled={loading}
                  onChange={({ target }) => {
                    const selectedId = target.value;
                    const selected = funnels.find(funnel => funnel.id === selectedId) ?? {} as IFunnel;
                    setFunnel(selected);
                    setSelectedFunnel(selected);
                    handleSaveOnChange(
                      selected.id,
                      id
                    );
                  }}
                >
                  {funnels.map((funnel, index) => {
                    if (funnel.id) {
                      return (
                        <MenuItem key={index} value={`${funnel.id}`}>
                          {funnel.nome}
                        </MenuItem>
                      );
                    }
                    return (
                      <ListSubheader key={funnel.id}>
                        {funnel.nome}
                      </ListSubheader>
                    );
                  })}
                </TextField>
            </FormControl>
          )}
        </Box>
        <Box marginBottom={1} />
      </Grid>

      <Grid item xs={12} sm={12}>
        <FunnelSteps funnelId={funnel.id} onLoadingChange={handleChildLoading}/>
      </Grid>
    </Grid>

    <Box marginY={14} />

    <Footer
      onCancel={() => navigate('/campanha')}
      onSave={handleSaveAndNextStep}
      onGoBack={() => navigate(`/campanha/email-config/${id}`)}
      onSaveLabel={isEditing ? 'Salvar e Avançar' : 'Avançar'}
      disabled={loading || Object.keys(selectedFunnel).length === 0}
    />
  </>
  )
}
