import React, {useEffect, useRef} from 'react';

interface IFeedProps {
  idProduto: string;
  textoTitulo?: string;
  idFuncionalidade?: string;
  idTenant?: string;
  onErro?: (id: string) => void;
  onEnviarFeedback?: (id: string) => void;
  onFecharModal?: (id: string) => void;
}
export const FeedBack: React.FC<IFeedProps> = ({
  idProduto,
  onErro,
  onEnviarFeedback,
  onFecharModal,
  textoTitulo,
  idFuncionalidade,
  idTenant,
}) => {
  const elementRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const onEnviarFeedbackListener = (event: any) =>
      onEnviarFeedback && onEnviarFeedback(event.detail);
    const error = (event: any) => onErro && onErro(event.detail);
    const fecharModal = (event: any) =>
      onFecharModal && onFecharModal(event.detail);
    const ref = elementRef.current;
    if (ref) {
      ref.addEventListener('enviarFeedback', onEnviarFeedbackListener);
      ref.addEventListener('erro', error);
      ref.addEventListener('fecharModal', fecharModal);
      return () => {
        ref?.removeEventListener('enviarFeedback', onEnviarFeedbackListener);
        ref?.removeEventListener('erro', error);
        ref?.removeEventListener('fecharModal', fecharModal);
      };
    }
  }, [onErro, onEnviarFeedback, onFecharModal]);

  return React.createElement('feedback-component', {
    ref: elementRef,
    'id-produto': idProduto,
    'texto-titulo': textoTitulo,
    'id-funcionalidade': idFuncionalidade,
    'id-tenant': idTenant,
  });
};
