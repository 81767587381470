import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import React from 'react';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    latestSubscriber: {
      color: theme.palette.grey[600],
      '& span': {
        color: theme.palette.grey[800],
      },
    },

    nextInterviews: {
      color: theme.palette.grey[500],
      '& span': {
        color: theme.palette.grey[800],
      },
    },

    menuItem: {
      paddingLeft: theme.spacing(3),
    },

    fontMedium: {
      fontWeight: 500 || theme?.typography?.fontWeightMedium,
    },

    myInterviewsCheckBox: {
      padding: theme.spacing(0.75),
    },

    columnLeft: {
      borderTopLeftRadius: '50px',
      borderTopRightRadius: '50px',
      borderBottomLeftRadius: '50px',
      borderBottomRightRadius: '50px',
      background: theme.palette.primary.main,
    },

    slick: {
      display: 'grid',
    },

    tableHeader: {
      padding: theme.spacing(3),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
    },
  }),
);
