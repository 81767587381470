import React, {useState, createContext, useCallback, useEffect} from 'react';

import {
  IGeneralConfig,
  GeneralConfigService,
} from 'shared/services/api/GeneralConfigService';
import {IMenuOption, MenuOptionService} from 'shared/services/api/MenuService';

export interface IContextTerm {
  menuOptions: IMenuOption[] | undefined;
  terms: Omit<IGeneralConfig, 'id'>;
  handleGetTerms(): Promise<void>;
  handlePutTerms(payload: Omit<IGeneralConfig, 'id'>): void;
}

export const TermContext = createContext<IContextTerm>({} as IContextTerm);

export const TermProvider: React.FC = ({children}) => {
  const [menuOptions, setMenuOptions] = useState<IMenuOption[]>();

  const [terms, setTerms] = useState({
    termoEntrevistador: '',
    termoLead: '',
    termoCurso: '',
    termoCiclo: '',
    termoComplementar1: '',
    termoComplementar2: '',
    idSituacaoDeLeadInscrito: '',
    idSituacaoDeLeadIntegrado: '',
  });

  const handleGetMenuOption = useCallback(async () => {
    const data = await MenuOptionService.getMenuOptions();

    if (data) {
      setMenuOptions(data);
    }
  }, []);

  const handleSetTerms = useCallback(
    (data: IGeneralConfig | Omit<IGeneralConfig, 'id'>) => {
      setTerms(data);
    },
    [],
  );

  const handlePutTerms = useCallback(
    async (payload: Omit<IGeneralConfig, 'id'>) => {
      await GeneralConfigService.updateGeneralConfig(payload);

      await handleGetMenuOption();
    },
    [handleGetMenuOption],
  );

  const handleGetTerms = useCallback(async () => {
    const response = await GeneralConfigService.getGeneralConfig();

    await handleSetTerms(response);

    await handleGetMenuOption();
  }, [handleGetMenuOption, handleSetTerms]);

  useEffect(() => {
    handleGetTerms();
  }, [handleGetTerms]);

  return (
    <TermContext.Provider
      value={{
        menuOptions,
        terms,
        handleGetTerms,
        handlePutTerms,
      }}>
      {children}
    </TermContext.Provider>
  );
};
