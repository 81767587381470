import React, {useState} from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import {ptBR} from 'date-fns/locale';
import {SplashScreen} from 'shared/components';

import {AuthProvider} from './Auth';
import {CampaignProvider} from './Campaign';
import {DrawerProvider} from './Drawer';
import {TermProvider} from './Terms';
import {ThemesProvider} from './Theme';
import {TitleProvider} from './Title';
import {UserProvider} from './User';
import {LeadFilterProvider} from './Lead';
import {MacrosProvider} from './Macros';

export const AppProvider: React.FC = ({children}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
      <ThemesProvider>
        <TitleProvider>
          <UserProvider>
            {isLoading ? (
              <SplashScreen setIsLoading={setIsLoading} />
            ) : (
              <AuthProvider>
                <DrawerProvider>
                  <TermProvider>
                    <MacrosProvider>
                      <CampaignProvider>
                        <LeadFilterProvider>{children}</LeadFilterProvider>
                      </CampaignProvider>
                    </MacrosProvider>
                  </TermProvider>
                </DrawerProvider>
              </AuthProvider>
            )}
          </UserProvider>
        </TitleProvider>
      </ThemesProvider>
    </MuiPickersUtilsProvider>
  );
};
