import {Grid} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';

export const FiltersSkeleton = () => (
  <>
    <Grid item xs={12} sm={4}>
      <Skeleton variant="rect" height={40} animation="wave" />
    </Grid>
    <Grid item xs={12} sm={4}>
      <Skeleton variant="rect" height={40} animation="wave" />
    </Grid>
    <Grid item xs={12} sm={4}>
      <Skeleton variant="rect" height={40} animation="wave" />
    </Grid>
  </>
);
