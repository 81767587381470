import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  Box,
  Tab,
  Grid,
  Tabs,
  MenuItem,
  Container,
  TextField,
  Typography,
  FormControl,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
} from '@material-ui/core';
import {useNavigate} from 'react-router-dom';

import {
  ILead,
  LeadSituationService,
} from 'shared/services/api/LeadSituationService';
import {
  IGeneralConfig,
  IConfigByEstablishment,
} from 'shared/services/api/GeneralConfigService';
import {Footer, TabPanel} from 'shared/components';
import {feedback} from 'shared/services/alertService';
import {useTitle, useTerm, useUser} from 'shared/hooks';
import {errorResponse} from 'shared/utils/errorResponse';
import {EmailAccounts} from 'pages/GeneralConfig/EmailAccounts';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import {Edit} from '@material-ui/icons';

export const GeneralConfig: React.FC = () => {
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [leadIntegrated, setLeadIntegrated] = useState<ILead[]>([]);
  const [leadRegistered, setLeadRegistered] = useState<ILead[]>([]);
  const [helperText, setHelperText] = useState({
    termoComplementar1: false,
    termoComplementar2: false,
    idSituacaoDeLeadInscrito: false,
    idSituacaoDeLeadIntegrado: false,
  });
  const [dataTerms, setDataTerms] = useState<IGeneralConfig>({
    termoEntrevistador: '',
    termoLead: '',
    termoCurso: '',
    termoCiclo: '',
    termoComplementar1: '',
    termoComplementar2: '',
    idSituacaoDeLeadInscrito: '',
    idSituacaoDeLeadIntegrado: '',
  } as IGeneralConfig);
  const [configByEstablishment, setConfigByEstablishment] = useState<
    IConfigByEstablishment[]
  >([]);

  const navigate = useNavigate();
  const {setTitle} = useTitle();
  const {terms, handleGetTerms, handlePutTerms} = useTerm();
  const {data: user} = useUser();

  const permissions = useMemo(
    () => user?.permissoes.filter((i) => i.nome.includes('Configuração')),
    [user?.permissoes],
  );

  const canEdit = useMemo(
    () => !!permissions?.find((i) => i.nome.includes('Editar')),
    [permissions],
  );

  const canList = useMemo(
    () => !!permissions?.find((i) => i.nome.includes('Listar')),
    [permissions],
  );

  useEffect(() => {
    if (!canList) {
      navigate('/');
    }
  }, [canList, navigate]);

  const handleVerifyComplementary = useCallback(() => {
    return (
      dataTerms?.termoComplementar1?.length > 0 &&
      dataTerms?.termoComplementar2?.length > 0 &&
      dataTerms?.termoComplementar1?.toLowerCase().trim() ===
        dataTerms?.termoComplementar2?.toLowerCase().trim()
    );
  }, [dataTerms.termoComplementar1, dataTerms.termoComplementar2]);

  const handleSaveGeneralConfig = useCallback(async () => {
    if (handleVerifyComplementary()) return;

    // if (
    //   !(
    //     dataTerms.termoCurso &&
    //     dataTerms.termoCiclo &&
    //     dataTerms.termoLead &&
    //     dataTerms.termoEntrevistador &&
    //     dataTerms.idSituacaoDeLeadInscrito &&
    //     dataTerms.idSituacaoDeLeadIntegrado
    //   )
    // ) {
    //   setSubmitted(true);
    //   return;
    // }

    try {
      setLoading(true);
      await handlePutTerms(dataTerms);
      feedback('Termos atualizados com sucesso', 'success');
    } catch (error) {
      feedback(errorResponse(error), 'error');
    } finally {
      setSubmitted(false);
      setLoading(false);
    }
  }, [handleVerifyComplementary, dataTerms, handlePutTerms]);

  const a11yProps = useCallback((index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }, []);

  const handleChange = useCallback(
    // eslint-disable-next-line
    (_: React.ChangeEvent<{}>, newValue: number) => {
      setValue(newValue);
    },
    [],
  );

  useEffect(() => setTitle('Configurações Gerais'), [setTitle]);

  useEffect(() => {
    setDataTerms(terms);
  }, [terms]);

  useEffect(() => {
    setDataTerms(
      (state) =>
        (state = {
          ...state,
          configuracoesEstabelecimento: configByEstablishment,
        }),
    );
  }, [configByEstablishment]);

  useEffect(() => {
    handleGetTerms();
  }, [handleGetTerms]);

  return (
    <>
      <Container>
        <Tabs value={value} onChange={handleChange} textColor="primary">
          <Tab label="Configurações gerais" {...a11yProps(0)} />
          <Tab label="Contato e LGPD" {...a11yProps(1)} />
        </Tabs>
      </Container>
      <TabPanel value={value} index={0}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" color="primary">
              <strong>Termos</strong>
            </Typography>

            <Box marginBottom={1} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl component="fieldset" fullWidth>
              <TextField
                variant="outlined"
                disabled={!canEdit}
                value={dataTerms.termoCurso}
                label='Nome para o termo "Curso" *'
                error={!dataTerms.termoCurso && submitted}
                helperText={
                  !dataTerms.termoCurso && submitted && 'Campo requerido'
                }
                onChange={({target}) =>
                  setDataTerms(
                    (state) =>
                      (state = {
                        ...state,
                        termoCurso: target.value,
                      }),
                  )
                }
              />

              <Box marginBottom={3} />

              <TextField
                variant="outlined"
                disabled={!canEdit}
                error={handleVerifyComplementary()}
                value={dataTerms.termoComplementar1}
                label='Nome para o termo "Complementar 1"'
                onMouseEnter={() =>
                  setHelperText(
                    (state) => (state = {...state, termoComplementar1: true}),
                  )
                }
                onMouseLeave={() =>
                  setHelperText(
                    (state) => (state = {...state, termoComplementar1: false}),
                  )
                }
                helperText={
                  handleVerifyComplementary()
                    ? 'Os termos “Complementar1” e “Complementar2” devem ser diferentes'
                    : helperText.termoComplementar1 &&
                      'Após informado, um cadastro de informações vinculado oferta de campanhas será disponibilizado.'
                }
                onChange={({target}) =>
                  setDataTerms(
                    (state) =>
                      (state = {
                        ...state,
                        termoComplementar1: target.value,
                      }),
                  )
                }
              />

              <Box marginBottom={3} />

              <TextField
                variant="outlined"
                disabled={!canEdit}
                value={dataTerms.termoLead}
                label='Nome para o termo "Lead" *'
                error={!dataTerms.termoLead && submitted}
                helperText={
                  !dataTerms.termoLead && submitted && 'Campo requerido'
                }
                onChange={({target}) =>
                  setDataTerms(
                    (state) =>
                      (state = {
                        ...state,
                        termoLead: target.value,
                      }),
                  )
                }
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl component="fieldset" fullWidth>
              <TextField
                variant="outlined"
                disabled={!canEdit}
                value={dataTerms.termoCiclo}
                label='Nome para o termo "Ciclo" *'
                error={!dataTerms.termoCiclo && submitted}
                helperText={
                  !dataTerms.termoCiclo && submitted && 'Campo requerido'
                }
                onChange={({target}) =>
                  setDataTerms(
                    (state) =>
                      (state = {
                        ...state,
                        termoCiclo: target.value,
                      }),
                  )
                }
              />

              <Box marginBottom={3} />

              <TextField
                variant="outlined"
                disabled={!canEdit}
                error={handleVerifyComplementary()}
                value={dataTerms.termoComplementar2}
                label='Nome para o termo "Complementar 2"'
                onMouseEnter={() =>
                  setHelperText(
                    (state) => (state = {...state, termoComplementar2: true}),
                  )
                }
                onMouseLeave={() =>
                  setHelperText(
                    (state) => (state = {...state, termoComplementar2: false}),
                  )
                }
                helperText={
                  handleVerifyComplementary()
                    ? 'Os termos “Complementar1” e “Complementar2” devem ser diferentes'
                    : helperText.termoComplementar2 &&
                      'Após informado, um cadastro de informações vinculado oferta de campanhas será disponibilizado.'
                }
                onChange={({target}) =>
                  setDataTerms(
                    (state) =>
                      (state = {
                        ...state,
                        termoComplementar2: target.value,
                      }),
                  )
                }
              />

              <Box marginBottom={3} />

              <TextField
                variant="outlined"
                disabled={!canEdit}
                value={dataTerms.termoEntrevistador}
                label='Nome para o termo "Entrevistador" *'
                error={!dataTerms.termoEntrevistador && submitted}
                helperText={
                  !dataTerms.termoEntrevistador &&
                  submitted &&
                  'Campo requerido'
                }
                onChange={({target}) =>
                  setDataTerms(
                    (state) =>
                      (state = {
                        ...state,
                        termoEntrevistador: target.value,
                      }),
                  )
                }
              />
            </FormControl>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <EmailAccounts
          canEdit={canEdit}
          configByEstablishment={configByEstablishment}
          setConfigByEstablishment={setConfigByEstablishment}
        />
      </TabPanel>

      <Box marginY={14} />

      <Footer
        disabled={loading || !canEdit}
        onSave={handleSaveGeneralConfig}
        onCancel={() => navigate('/funil')}
      />
    </>
  );
};
