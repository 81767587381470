import React, {useCallback, useState} from 'react';
import {
  Box,
  Button,
  Dialog,
  Typography,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import CloseIcon from '@material-ui/icons/Close';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import {
  CRMDashboardService,
  IGeneralPerformance,
} from 'shared/services/api/CRMDashboard';

import {
  DataGrid,
  GridColDef,
  GridColumnsPanel,
  GridToolbarExport,
  GridRenderCellParams,
  GridToolbarContainer,
} from '@mui/x-data-grid';

interface IProps {
  openModal: boolean;
  onclose(): void;
  selectedPeriod: string;
  selectedEstablishment: string;
  selectedCampaing: string;
}

export const GeneralPerformance: React.FC<IProps> = ({
  openModal,
  onclose,
  selectedPeriod,
  selectedEstablishment,
  selectedCampaing,
}) => {
  const [loading, setLoading] = useState(true);
  const [generalPerformance, setGeneralPerformance] = useState<
    IGeneralPerformance[]
  >([]);

  const GeneralPerformanceList = useCallback(async () => {
    setLoading(true);
    CRMDashboardService.getGeneralPerformance(
      selectedPeriod,
      selectedEstablishment,
      selectedCampaing,
    )
      .then((response) => setGeneralPerformance(response))
      .finally(() => setLoading(false));
  }, [selectedPeriod, selectedEstablishment, selectedCampaing]);

  const columns: GridColDef[] = [
    {
      field: 'lead',
      headerName: 'Lead',
      flex: 1,
    },

    {
      field: 'formularioRecebido',
      headerName: 'Inscrito',
      flex: 1,
      renderCell: (params: GridRenderCellParams) =>
        params.row.formularioRecebido === 'true' ? (
          <Box display={'flex'} alignItems={'center'}>
            <FiberManualRecordIcon color="secondary" />
          </Box>
        ) : (
          <Box display={'flex'} alignItems={'center'}>
            <ClearIcon color="disabled" />
          </Box>
        ),
    },
    {
      field: 'emAtendimento',
      headerName: 'Em Atendimento',
      flex: 1,
      renderCell: (params: GridRenderCellParams) =>
        params.row.emAtendimento === 'true' ? (
          <Box display={'flex'} alignItems={'center'}>
            <FiberManualRecordIcon color="secondary" />
          </Box>
        ) : (
          <Box display={'flex'} alignItems={'center'}>
            <ClearIcon color="disabled" />
          </Box>
        ),
    },
    {
      field: 'convertido',
      headerName: 'Convertido',
      flex: 1,
      renderCell: (params: GridRenderCellParams) =>
        params.row.convertido === 'true' ? (
          <Box display={'flex'} alignItems={'center'}>
            <FiberManualRecordIcon color="secondary" />
          </Box>
        ) : (
          <Box display={'flex'} alignItems={'center'}>
            <ClearIcon color="disabled" />
          </Box>
        ),
    },
    {
      field: 'atendidoPor',
      headerName: 'Atendido por',
      flex: 1,
    },
    {
      field: 'ultimoAtendimentoEm',
      headerName: 'Última interação em',
      flex: 1,
      renderCell: (params: GridRenderCellParams) =>
        `${String(params.row.ultimoAtendimentoEm)
          .substring(0, 10)
          .split('-')
          .reverse()
          .join('/')}`,
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="xl"
        open={openModal}
        onClose={onclose}
        TransitionProps={{
          onEntering: GeneralPerformanceList,
        }}
        aria-labelledby="Leads por situação"
        aria-describedby="Leads por situação">
        <DialogTitle id="Leads por situação">
          <Box width="100%" display={'flex'} justifyContent="space-between">
            <Box>
              <Typography variant="inherit" color="primary">
                Desempenho Geral
              </Typography>
            </Box>
            <Box>
              <Button color="primary" onClick={onclose}>
                <CloseIcon />
              </Button>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DataGrid
            loading={loading}
            columns={columns}
            rows={generalPerformance}
            getRowId={(row) => row.idLead}
            autoHeight
            pageSize={7}
            components={{
              Toolbar: CustomToolbar,
              ColumnsPanel: GridColumnsPanel,
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
