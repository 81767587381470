import {
  Box,
  Button,
  Dialog,
  Typography,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import React, {useCallback, useEffect, useState} from 'react';
import CloseIcon from '@material-ui/icons/Close';

import {
  DataGrid,
  GridColDef,
  GridColumnsPanel,
  GridRenderCellParams,
  GridToolbarContainer,
  GridToolbarExport,
} from '@mui/x-data-grid';
import { ILinhaDoTempo } from 'shared/services/api/EditLeadService';
import { getCardsDateTime } from 'shared/utils/getCardsDateTime';

interface IProps {
  openModal: boolean;
  onClose(): void;
  timeline: ILinhaDoTempo[] | undefined;
}

interface IModalData {
  dataHora: string;
  descricao: string;
  situacao: string;
  atendente: string;
  id: string;
}

export const ModalSeeMoreCards: React.FC<IProps> = ({
  openModal, 
  onClose,
  timeline
}) => {
  const [modalData, setModalData] = useState<IModalData[]>([])
  const columns: GridColDef[] = [
    {
      field: 'dataHora',
      headerName: 'Data',
      flex: 1
    },
    {
      field: 'descricao',
      headerName: 'Descrição',
      flex: 1,
    },
    {
      field: 'situacao',
      headerName: 'Situação',
      flex: 1,
    },
    {
      field: 'atendente',
      headerName: 'Atendente',
      flex: 1,
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  
  useEffect(() => {
    setModalData(
      timeline?.map(item => ({
        dataHora: getCardsDateTime(item.dataHora),
        descricao: item.descricao,
        situacao: item.situacao,
        atendente: item.atendente,
        id: `${item.dataHora}${Math.random()}`
      })) || []
    )
  }, [timeline])

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="xl"
        open={openModal}
        onClose={onClose}
        onEntering={() => modalData}
        aria-labelledby="Linha do Tempo"
        aria-describedby="Linha do Tempo">
        <DialogTitle id="Linha do Tempo">
          <Box width="100%" display={'flex'} justifyContent="space-between">
            <Box>
              <Typography variant="inherit" color="primary">
                Linha do Tempo
              </Typography>
            </Box>
            <Box>
              <Button color="primary" onClick={onClose}>
                <CloseIcon />
              </Button>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DataGrid
            columns={columns}
            rows={modalData}
            getRowId={(row) => `${row.id}`}
            autoHeight
            pageSize={10}
            components={{
              Toolbar: CustomToolbar,
              ColumnsPanel: GridColumnsPanel,
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
