import {
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
import {Typography} from '@mui/material';
import {StripedList} from 'pages/Lead/components';
import React, {useState, useEffect} from 'react';
import {useTheme} from 'shared/hooks';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import EmailIcon from '@material-ui/icons/Email';
import {feedback} from 'shared/services/alertService';
import {Edit} from '@material-ui/icons';
import {ModalEditPayment} from './Modal';
import {IPaymentInfo, LeadService} from 'shared/services/api/LeadService';
import {errorResponse} from 'shared/utils/errorResponse';

export const Payment: React.FC<{idLead: string}> = ({idLead}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [paymentInfo, setPaymentInfo] = useState<IPaymentInfo>(
    {} as IPaymentInfo,
  );
  const [isOpenModal, setIsOpenModal] = useState(false);
  const {theme} = useTheme();

  const isPaid = () => {
    return paymentInfo?.situacaoLinkPagamentoTaxa === 2;
  };

  const isPending = () => {
    return paymentInfo?.situacaoLinkPagamentoTaxa === 1;
  };

  const handleTitleVerifier = (situation: number) => {
    switch (situation) {
      case 1:
        return 'Pendente';
      case 2:
        return 'Realizado';
      case 3:
        return 'Vencido';
    }
  };

  const handleGetLeadPaymentInfo = async () => {
    setIsLoading(true);
    try {
      const response = await LeadService.getPaymentInfoByLeadId(idLead);
      setPaymentInfo(response);
    } catch (error) {
      feedback(errorResponse(error), 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSendEmail = async () => {
    feedback('Carregando...', 'loading');
    try {
      await LeadService.postSendPaymentEmailByLeadId(idLead);
      feedback('E-mail enviado', 'success');
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
  };

  useEffect(() => {
    if (!isOpenModal) {
      handleGetLeadPaymentInfo();
    }
  }, [idLead, isOpenModal]);

  return (
    <>
      {isLoading || !paymentInfo ? (
        <>
          <Grid xs>
            <Box marginTop={2}>
              <Skeleton variant="rect" width={'100%'} height={500} />
            </Box>
          </Grid>
        </>
      ) : (
        <>
          <Grid xs={12}>
            {isPending() && (
              <>
                <Box marginBottom={2}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell width="10%">E-mail</TableCell>
                          <TableCell width="10%">Copiar</TableCell>
                          <TableCell width="100%">URL</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key={11}>
                          <TableCell>
                            <Box
                              display={'flex'}
                              alignItems={'center'}
                              justifyContent={'start'}
                              paddingLeft={1}>
                              <IconButton>
                                <EmailIcon
                                  cursor={'pointer'}
                                  color="primary"
                                  onClick={handleSendEmail}
                                />
                              </IconButton>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box
                              display={'flex'}
                              alignItems={'center'}
                              justifyContent={'start'}
                              paddingLeft={1}>
                              <IconButton>
                                <FileCopyIcon
                                  width={'100px'}
                                  cursor={'pointer'}
                                  color="primary"
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      paymentInfo?.linkPagamento || '',
                                    );
                                    feedback(
                                      'URL copiada para sua área de transferência!',
                                      'success',
                                    );
                                  }}
                                />
                              </IconButton>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <TextField
                              disabled
                              fullWidth
                              size="small"
                              value={paymentInfo?.linkPagamento}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </>
            )}

            {!isPaid() && (
              <ModalEditPayment
                idLead={idLead}
                isLoading={isLoading}
                onClose={() => setIsOpenModal(false)}
                openModal={isOpenModal}
              />
            )}

            <Box>
              <TableContainer
                component={Paper}
                elevation={3}
                style={{overflow: 'hidden'}}>
                <Box
                  paddingX={2}
                  marginTop={2}
                  marginBottom={4}
                  display={'flex'}
                  justifyContent={'space-between'}>
                  <Typography
                    variant="h4"
                    fontWeight={600}
                    color={
                      paymentInfo?.situacaoLinkPagamentoTaxa === 2
                        ? theme.palette.primary.main
                        : theme.palette.text.primary
                    }>
                    {`Pagamento ${handleTitleVerifier(
                      paymentInfo?.situacaoLinkPagamentoTaxa,
                    )}`}
                  </Typography>
                  {!isPaid() && (
                    <IconButton onClick={() => setIsOpenModal(true)}>
                      <Edit color="primary" />
                    </IconButton>
                  )}
                </Box>

                <Grid container xs={12}>
                  <StripedList paymentInfo={paymentInfo} />
                </Grid>
              </TableContainer>
            </Box>
          </Grid>
        </>
      )}
    </>
  );
};
