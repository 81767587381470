import {Api} from 'shared/services/axiosConfig';

export interface ITypeOfIntegration {
  id: number;
  nome: string;
}

const getTypeOfIntegration = async (): Promise<ITypeOfIntegration[]> => {
  try {
    const response = await Api.get('/TipoDeIntegracao');

    return response.data;
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

export const TypeOfIntegrationService = {
  getTypeOfIntegration,
};
