/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useState, useMemo, useCallback} from 'react';
import {
  Box,
  Grid,
  Button,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import {WatchLater, Cached, Event} from '@material-ui/icons';
import {Skeleton} from '@material-ui/lab';
import {DatePicker, TimePicker} from '@material-ui/pickers';
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date';

import FullCalendar, {
  EventInput,
  DatesSetArg,
  EventClickArg,
  EventContentArg,
} from '@fullcalendar/react';
import listPlugin from '@fullcalendar/list';
import localePtBr from '@fullcalendar/core/locales/pt-br';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import {
  format,
  isPast,
  isEqual,
  addHours,
  isBefore,
  addMinutes,
} from 'date-fns';
import clsx from 'clsx';

import {useTerm} from 'shared/hooks';
import {Modal, ModalConfirm} from 'shared/components';
import {feedback} from 'shared/services/alertService';
import {isSameOrAfter} from 'shared/utils/isSameOrAfter';
import {errorResponse} from 'shared/utils/errorResponse';

import {
  IEvent,
  IFilterCalendar,
  CalendarAvailabilityService,
} from 'shared/services/api/CalendarAvailabilityService';
import {ICampaign} from 'shared/services/api/CampaignService';
import {IInterviewer} from 'shared/services/api/InterviewerService';

import './styles.css';
import {useStyles} from '../../styles';
import {EventDialog} from '../EventDialog';

interface IEventsCalendar {
  loading: boolean;
  canEdit: boolean;
  canDelete: boolean;
  canRegister: boolean;
  events?: EventInput[];
  campaigns: ICampaign[];
  filter: IFilterCalendar;
  interviewers: IInterviewer[];
  userTimezone: number;
  handleGetAvailabilities(): void;
  setLoading(value: boolean): void;
  setFilter(filter: IFilterCalendar): void;
  setGetAvailabilities(value: boolean): void;
}

export const Calendar: React.FC<IEventsCalendar> = ({
  events,
  filter,
  loading,
  canEdit,
  canDelete,
  setFilter,
  campaigns,
  setLoading,
  canRegister,
  interviewers,
  userTimezone,
  setGetAvailabilities,
  handleGetAvailabilities,
}) => {
  const [sent, setSent] = useState(false);
  const [editing, setEditing] = useState(false);
  const [actionType, setActionType] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [eventOpen, setEventOpen] = useState(false);
  const [errorTimes, setErrorTimes] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [allRecurrences, setRecurrences] = useState(false);
  const [currentEvent, setCurrentEvent] = useState<IEvent>({} as IEvent);

  const {terms} = useTerm();
  const {
    button,
    subtitles,
    eventTitle,
    buttonError,
    dotAvailable,
    dotUnavailable,
    recurrenceIcon,
  } = useStyles();

  const handleClose = useCallback(() => {
    setSent(false);
    setErrorTimes(false);
    setModalOpen(false);
  }, []);

  const renderEventContent = useCallback(
    (eventContent: EventContentArg) => {
      return (
        <Box padding={0.25}>
          <b>{eventContent.timeText}&nbsp;</b>
          <br />
          <i
            className={eventTitle}
            style={{
              whiteSpace:
                eventContent.view.type === 'dayGridMonth' ? 'nowrap' : 'unset',
            }}>
            {eventContent.event.title}
          </i>
          {eventContent.event.extendedProps.idTipoDeRecorrencia === 2 ? (
            <Cached className={recurrenceIcon} />
          ) : (
            ''
          )}
        </Box>
      );
    },
    [eventTitle, recurrenceIcon],
  );

  const handleNewAppointment = useCallback(() => {
    if (canRegister) {
      setEditing(false);
      setCurrentEvent({
        idEntrevistador:
          filter.interviewers && filter.interviewers.split(',').length === 1
            ? filter.interviewers
            : interviewers.length === 1
            ? interviewers[0].id
            : undefined,
        idCampanha: filter.campaign ? filter.campaign : undefined,
        idEstabelecimento: filter.establishment,
        idTipoDeRecorrencia: 1,
        start: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
        iniciaEm: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
        end: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
        terminaEm: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
        repetirAte: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
        maximoDeLeads: 1,
      } as IEvent);
      setErrorTimes(false);
      setModalOpen(true);
    } else {
      feedback(`Usuário sem permissão de inclusão.`, 'error');
    }
  }, [canRegister, interviewers, filter]);

  const handleEventClick = useCallback(
    async ({event}: EventClickArg) => {
      const props = event.extendedProps;

      setEditing(true);

      try {
        const response = await CalendarAvailabilityService.getAvailabilityById(
          props.idDisponibilidadeDeAgenda,
        );
        setCurrentEvent({
          ...response,
          iniciaEm: response.iniciaEm
            ? format(
                addHours(new Date(response.iniciaEm), userTimezone),
                "yyyy-MM-dd'T'HH:mm:ss",
              )
            : response.iniciaEm,
          terminaEm: response.terminaEm
            ? format(
                addHours(new Date(response.terminaEm), userTimezone),
                "yyyy-MM-dd'T'HH:mm:ss",
              )
            : response.terminaEm,
        });
      } catch (error) {
         
        feedback(errorResponse(error), 'error');
      } finally {
        // setLoading(false);
        setEventOpen(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentEvent],
  );

  const checkFields = useCallback(() => {
    if (!currentEvent.idEntrevistador || !currentEvent.idCampanha) {
      setSent(true);
      return false;
    }
    setSent(false);
    return true;
  }, [currentEvent]);

  const checkTimes = useCallback(() => {
    const after: boolean = isBefore(
      new Date(currentEvent.terminaEm),
      new Date(currentEvent.iniciaEm),
    );
    const equal: boolean = isEqual(
      new Date(currentEvent.iniciaEm),
      new Date(currentEvent.terminaEm),
    );
    if (after || equal) {
      feedback(
        `"Hora final" obrigatoriamente tem de ser maior que "Hora inicial"!`,
        'warning',
      );
      setErrorTimes(true);
      return false;
    }
    setErrorTimes(false);
    return true;
  }, [currentEvent]);

  const checkRecorrencia = useCallback(() => {
    if (currentEvent.idTipoDeRecorrencia === 2) {
      if (
        !currentEvent.repeteDomingo &&
        !currentEvent.repeteSegundaFeira &&
        !currentEvent.repeteTercaFeira &&
        !currentEvent.repeteQuartaFeira &&
        !currentEvent.repeteQuintaFeira &&
        !currentEvent.repeteSextaFeira &&
        !currentEvent.repeteSabado
      ) {
        feedback(`Você deve selecionar ao menos um dia da semana!`, 'error');
        setSent(true);
        return false;
      } else {
        setSent(false);
        return true;
      }
    }
    setSent(false);
    return true;
  }, [currentEvent]);

  const hasErrorRecorrencia = useMemo(() => {
    if (currentEvent.idTipoDeRecorrencia === 2) {
      if (
        !currentEvent.repeteDomingo &&
        !currentEvent.repeteSegundaFeira &&
        !currentEvent.repeteTercaFeira &&
        !currentEvent.repeteQuartaFeira &&
        !currentEvent.repeteQuintaFeira &&
        !currentEvent.repeteSextaFeira &&
        !currentEvent.repeteSabado
      )
        return true;
    }
    return false;
  }, [
    currentEvent.idTipoDeRecorrencia,
    currentEvent.repeteDomingo,
    currentEvent.repeteQuartaFeira,
    currentEvent.repeteQuintaFeira,
    currentEvent.repeteSabado,
    currentEvent.repeteSegundaFeira,
    currentEvent.repeteSextaFeira,
    currentEvent.repeteTercaFeira,
  ]);

  const setDatesForReq = useCallback(
    (dataSet: DatesSetArg) => {
      setFilter({
        ...filter,
        start: format(dataSet.start, "yyyy/MM/dd'T'00:00:00"),
        end: format(dataSet.end, "yyyy/MM/dd'T'23:59:59"),
      });
      setGetAvailabilities(true);
    },
    [filter, setFilter, setGetAvailabilities],
  );

  const skeleton = () => {
    return (
      <>
        <Grid container wrap="nowrap">
          <Skeleton width="30%" height={50} component={Box} marginRight={25} />
          <Skeleton width="30%" height={50} component={Box} marginRight={20} />
          <Skeleton width="10%" height={50} />
        </Grid>

        <Grid container wrap="nowrap">
          <Skeleton
            variant="rect"
            width="100%"
            height={800}
            component={Box}
            marginTop={2}
            animation="wave"
          />
        </Grid>
      </>
    );
  };

  const handleSaveNewEvent = useCallback(async () => {
    if (checkFields() && checkTimes() && checkRecorrencia()) {
      setLoading(true);
      try {
        await CalendarAvailabilityService.postAvailabilities({
          ...currentEvent,
          iniciaEm: currentEvent.iniciaEm
            ? format(
                addHours(new Date(currentEvent.iniciaEm), -userTimezone),
                "yyyy-MM-dd'T'HH:mm:ss",
              )
            : currentEvent.iniciaEm,
          terminaEm: currentEvent.terminaEm
            ? format(
                addHours(new Date(currentEvent.terminaEm), -userTimezone),
                "yyyy-MM-dd'T'HH:mm:ss",
              )
            : currentEvent.terminaEm,
        });
        setModalOpen(false);
        handleGetAvailabilities();
        feedback(`Agendamento realizado com sucesso!`, 'success');
      } catch (error) {
         
        feedback(errorResponse(error), 'error');
      } finally {
        setLoading(false);
      }
    }
  }, [
    checkTimes,
    setLoading,
    checkFields,
    currentEvent,
    userTimezone,
    checkRecorrencia,
    handleGetAvailabilities,
  ]);

  const handleCheckEdition = useCallback(
    (value: number) => {
      if (value === 1 && currentEvent.qtdeDeLeadsRealizadosNestaAgenda) {
        setRecurrences(false);
        feedback(
          'Esta agenda já foi realizada e não pode ser editada.',
          'error',
        );
      } else if (value === 1 && currentEvent.qtdeDeLeadsPendentesNestaAgenda) {
        setRecurrences(false);
        setOpenModalConfirm(true);
      } else if (
        value === 2 &&
        currentEvent.qtdeDeLeadsVinculadosNestaAgendaESeguintes &&
        isSameOrAfter(new Date(currentEvent.iniciaEm), new Date())
      ) {
        setRecurrences(false);
        feedback(
          `Algumas disponibilidades da série possuem agendamento e por isso a
          manutenção é permitida somente individualmente.`,
          'error',
        );
      } else if (
        value === 3 &&
        currentEvent.qtdeDeLeadsVinculadosTodasAgendas
      ) {
        feedback(
          `Algumas disponibilidades da série possuem agendamento e por isso a
          manutenção é permitida somente individualmente.`,
          'error',
        );
      } else {
        setModalOpen(true);
      }
    },
    [currentEvent],
  );

  const handleSetActionType = useCallback(
    (value: number) => {
      if (value === 3) {
        allRecurrences && currentEvent.inicioRecorrencia
          ? (currentEvent.iniciaEm = currentEvent.inicioRecorrencia)
          : currentEvent.iniciaEm;
      }
      setActionType(value);
      setCurrentEvent((state) => (state = {...state, tipoEdicao: value}));

      handleCheckEdition(value);
    },
    [allRecurrences, currentEvent, handleCheckEdition],
  );

  const handleSaveExistEvent = useCallback(async () => {
    if (checkTimes() && checkRecorrencia()) {
      setLoading(true);
      try {
        await CalendarAvailabilityService.putAvailabilities({
          ...currentEvent,
          iniciaEm: currentEvent.iniciaEm
            ? format(
                addHours(new Date(currentEvent.iniciaEm), -userTimezone),
                "yyyy-MM-dd'T'HH:mm:ss",
              )
            : currentEvent.iniciaEm,
          terminaEm: currentEvent.terminaEm
            ? format(
                addHours(new Date(currentEvent.terminaEm), -userTimezone),
                "yyyy-MM-dd'T'HH:mm:ss",
              )
            : currentEvent.terminaEm,
        });

        setModalOpen(false);
        setEventOpen(false);
        handleGetAvailabilities();
        feedback(`Agendamento alterado com sucesso!`, 'success');
      } catch (error) {
         
        feedback(errorResponse(error), 'error');
      } finally {
        setLoading(false);
      }
    }
  }, [
    checkTimes,
    checkRecorrencia,
    setLoading,
    currentEvent,
    userTimezone,
    handleGetAvailabilities,
  ]);

  return (
    <>
      <ModalConfirm
        title="Atenção!"
        loading={loading}
        opened={openModalConfirm}
        labelSaveButton="Continuar"
        onClose={() => setOpenModalConfirm(false)}
        onClick={() => (setOpenModalConfirm(false), setModalOpen(true))}>
        <Typography>
          Esta disponibilidade já possui agendamento. Ao alterar a data ou
          horário, os respectivos agendamentos serão atualizados.
        </Typography>
        <br />
        <Typography>Deseja editar mesmo assim?</Typography>
      </ModalConfirm>

      <EventDialog
        loading={loading}
        event={currentEvent}
        opened={eventOpen}
        canEdit={canEdit}
        setRecurrences={setRecurrences}
        canDelete={canDelete}
        setLoading={(value) => setLoading(value)}
        setEventOpen={() => setEventOpen(!eventOpen)}
        handleGetAvailabilities={handleGetAvailabilities}
        setActionType={(value: number) => handleSetActionType(value)}
      />

      <Modal
        maxWidth="md"
        labelSaveButton="Salvar"
        labelCloseButton="Cancelar"
        title={`${
          editing ? 'Editando' : 'Criando nova'
        } disponibilidade de agenda`}
        loading={loading}
        opened={modalOpen}
        onClose={handleClose}
        onClick={editing ? handleSaveExistEvent : handleSaveNewEvent}>
        <Box marginTop={2} />

        <Grid container component={Box} spacing={2}>
          <Grid item xs={12}>
            <TextField
              select
              fullWidth
              disabled={loading}
              variant="outlined"
              label={`${terms.termoEntrevistador} *`}
              error={!currentEvent.idEntrevistador && sent}
              helperText={
                !currentEvent.idEntrevistador && sent && 'Campo obrigatório'
              }
              value={currentEvent.idEntrevistador}
              onChange={({target}) => {
                setCurrentEvent(
                  (state) =>
                    (state = {...state, idEntrevistador: target.value}),
                );
              }}>
              {interviewers.map((item) =>
                item.ativo || item.id === currentEvent.idEntrevistador ? (
                  <MenuItem key={item.id} value={item.id}>
                    {item.nomeUsuario}
                  </MenuItem>
                ) : null,
              )}
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <TextField
              select
              fullWidth
              label="Campanha *"
              variant="outlined"
              disabled={loading || editing}
              value={currentEvent.idCampanha}
              error={!currentEvent.idCampanha && sent}
              helperText={
                !currentEvent.idCampanha && sent && 'Campo obrigatório'
              }
              onChange={({target}) =>
                setCurrentEvent(
                  (state) => (state = {...state, idCampanha: target.value}),
                )
              }>
              {campaigns.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.nomeCompleto}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={3}>
            <DatePicker
              autoOk
              fullWidth
              disablePast
              label="Data *"
              format="dd/MM/yyyy"
              animateYearScrolling
              inputVariant="outlined"
              value={currentEvent.iniciaEm}
              InputProps={{endAdornment: <Event color="primary" />}}
              minDateMessage={'A data não deve ser anterior à data atual'}
              disabled={
                loading ||
                isPast(addMinutes(new Date(currentEvent.iniciaEm), 5))
              }
              onChange={(value: MaterialUiPickersDate) =>
                setCurrentEvent(
                  (state) =>
                    (state = {
                      ...state,
                      iniciaEm: format(
                        new Date(value as Date),
                        "yyyy-MM-dd'T'HH:mm:ss",
                      ),
                      terminaEm: `${format(
                        new Date(value as Date),
                        "yyyy-MM-dd'T'",
                      )}${format(
                        new Date(currentEvent.terminaEm),
                        'HH:mm:ss',
                      )}`,
                    }),
                )
              }
            />
          </Grid>

          <Grid item xs={3}>
            <TimePicker
              autoOk
              fullWidth
              ampm={false}
              disabled={loading}
              label="Hora inicial *"
              inputVariant="outlined"
              format="HH:mm"
              value={currentEvent.iniciaEm}
              InputProps={{endAdornment: <WatchLater color="primary" />}}
              TextFieldComponent={(params) => (
                <TextField {...params} error={errorTimes} />
              )}
              onChange={(value: MaterialUiPickersDate) =>
                setCurrentEvent(
                  (state) =>
                    (state = {
                      ...state,
                      iniciaEm: format(
                        new Date(value as Date),
                        "yyyy-MM-dd'T'HH:mm:ss",
                      ),
                    }),
                )
              }
            />
          </Grid>

          <Grid item xs={3}>
            <TimePicker
              autoOk
              fullWidth
              ampm={false}
              disabled={loading}
              label="Hora final *"
              inputVariant="outlined"
              format="HH:mm"
              value={currentEvent.terminaEm}
              InputProps={{endAdornment: <WatchLater color="primary" />}}
              TextFieldComponent={(params) => (
                <TextField {...params} error={errorTimes} />
              )}
              onChange={(value: MaterialUiPickersDate) =>
                setCurrentEvent(
                  (state) =>
                    (state = {
                      ...state,
                      terminaEm: `${format(
                        new Date(currentEvent.iniciaEm),
                        "yyyy-MM-dd'T'",
                      )}${format(new Date(value as Date), 'HH:mm:ss')}`,
                    }),
                )
              }
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              select
              fullWidth
              label="Repetir *"
              variant="outlined"
              disabled={loading || editing}
              defaultValue={1}
              value={
                currentEvent.tipoEdicao === 1
                  ? 1
                  : currentEvent.idTipoDeRecorrencia
              }
              onChange={({target}) =>
                setCurrentEvent(
                  (state) =>
                    (state = {
                      ...state,
                      idTipoDeRecorrencia: Number(target.value),
                    }),
                )
              }>
              <MenuItem value={1}>Nunca</MenuItem>
              <MenuItem value={2}>Dias da semana</MenuItem>
            </TextField>
          </Grid>

          {currentEvent.tipoEdicao !== 1 &&
            currentEvent.idTipoDeRecorrencia === 2 && (
              <>
                <Grid item xs={1}>
                  <Button
                    fullWidth
                    color="primary"
                    disabled={loading}
                    className={clsx(button, {
                      [buttonError]: sent && hasErrorRecorrencia,
                    })}
                    variant={
                      currentEvent.repeteDomingo ? 'contained' : 'outlined'
                    }
                    onClick={() =>
                      setCurrentEvent(
                        (state) =>
                          (state = {
                            ...state,
                            repeteDomingo: !currentEvent.repeteDomingo,
                          }),
                      )
                    }>
                    D
                  </Button>
                </Grid>
                <Grid item xs={1}>
                  <Button
                    fullWidth
                    color="primary"
                    disabled={loading}
                    className={clsx(button, {
                      [buttonError]: sent && hasErrorRecorrencia,
                    })}
                    variant={
                      currentEvent.repeteSegundaFeira ? 'contained' : 'outlined'
                    }
                    onClick={() =>
                      setCurrentEvent(
                        (state) =>
                          (state = {
                            ...state,
                            repeteSegundaFeira:
                              !currentEvent.repeteSegundaFeira,
                          }),
                      )
                    }>
                    S
                  </Button>
                </Grid>
                <Grid item xs={1}>
                  <Button
                    fullWidth
                    color="primary"
                    disabled={loading}
                    className={clsx(button, {
                      [buttonError]: sent && hasErrorRecorrencia,
                    })}
                    variant={
                      currentEvent.repeteTercaFeira ? 'contained' : 'outlined'
                    }
                    onClick={() =>
                      setCurrentEvent(
                        (state) =>
                          (state = {
                            ...state,
                            repeteTercaFeira: !currentEvent.repeteTercaFeira,
                          }),
                      )
                    }>
                    T
                  </Button>
                </Grid>
                <Grid item xs={1}>
                  <Button
                    fullWidth
                    color="primary"
                    disabled={loading}
                    className={clsx(button, {
                      [buttonError]: sent && hasErrorRecorrencia,
                    })}
                    variant={
                      currentEvent.repeteQuartaFeira ? 'contained' : 'outlined'
                    }
                    onClick={() =>
                      setCurrentEvent(
                        (state) =>
                          (state = {
                            ...state,
                            repeteQuartaFeira: !currentEvent.repeteQuartaFeira,
                          }),
                      )
                    }>
                    Q
                  </Button>
                </Grid>
                <Grid item xs={1}>
                  <Button
                    fullWidth
                    color="primary"
                    disabled={loading}
                    className={clsx(button, {
                      [buttonError]: sent && hasErrorRecorrencia,
                    })}
                    variant={
                      currentEvent.repeteQuintaFeira ? 'contained' : 'outlined'
                    }
                    onClick={() =>
                      setCurrentEvent(
                        (state) =>
                          (state = {
                            ...state,
                            repeteQuintaFeira: !currentEvent.repeteQuintaFeira,
                          }),
                      )
                    }>
                    Q
                  </Button>
                </Grid>
                <Grid item xs={1}>
                  <Button
                    fullWidth
                    color="primary"
                    disabled={loading}
                    className={clsx(button, {
                      [buttonError]: sent && hasErrorRecorrencia,
                    })}
                    variant={
                      currentEvent.repeteSextaFeira ? 'contained' : 'outlined'
                    }
                    onClick={() =>
                      setCurrentEvent(
                        (state) =>
                          (state = {
                            ...state,
                            repeteSextaFeira: !currentEvent.repeteSextaFeira,
                          }),
                      )
                    }>
                    S
                  </Button>
                </Grid>
                <Grid item xs={1}>
                  <Button
                    fullWidth
                    color="primary"
                    disabled={loading}
                    className={clsx(button, {
                      [buttonError]: sent && hasErrorRecorrencia,
                    })}
                    variant={
                      currentEvent.repeteSabado ? 'contained' : 'outlined'
                    }
                    onClick={() =>
                      setCurrentEvent(
                        (state) =>
                          (state = {
                            ...state,
                            repeteSabado: !currentEvent.repeteSabado,
                          }),
                      )
                    }>
                    S
                  </Button>
                </Grid>

                <Grid item xs={5}>
                  <DatePicker
                    autoOk
                    fullWidth
                    disablePast
                    disabled={loading}
                    animateYearScrolling
                    format="dd/MM/yyyy"
                    inputVariant="outlined"
                    label="Até *"
                    value={currentEvent.repetirAte}
                    InputProps={{endAdornment: <Event color="primary" />}}
                    onChange={(value: MaterialUiPickersDate) =>
                      setCurrentEvent(
                        (state) =>
                          (state = {
                            ...state,
                            repetirAte: format(
                              new Date(value as Date),
                              "yyyy-MM-dd'T'HH:mm:ss",
                            ),
                          }),
                      )
                    }
                  />
                </Grid>
              </>
            )}

          <Grid item xs={12}>
            <TextField
              fullWidth
              type="number"
              variant="outlined"
              disabled={loading}
              value={currentEvent.maximoDeLeads}
              InputProps={{inputProps: {min: 1}}}
              label="Quantidade máxima de entrevistados"
              onChange={({target}) =>
                setCurrentEvent(
                  (state) =>
                    (state = {
                      ...state,
                      maximoDeLeads:
                        Number(target.value) < 1 ? 1 : Number(target.value),
                    }),
                )
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              rows={4}
              multiline
              fullWidth
              disabled={loading}
              variant="outlined"
              label="Observações"
              value={currentEvent.observacao}
              onChange={({target}) =>
                setCurrentEvent(
                  (state) => (state = {...state, observacao: target.value}),
                )
              }
            />
          </Grid>
        </Grid>
      </Modal>

      {loading && skeleton()}
      <div style={{visibility: loading ? 'hidden' : 'visible'}}>
        <FullCalendar
          plugins={[
            listPlugin,
            dayGridPlugin,
            timeGridPlugin,
            interactionPlugin,
          ]}
          customButtons={{new: {text: `Novo`, click: handleNewAppointment}}}
          headerToolbar={{
            start:
              'today dayGridMonth,timeGridWeek,timeGridDay,listWeek prev,next',
            center: 'title',
            right: 'new',
          }}
          eventDisplay=""
          events={events}
          weekends={true}
          selectMirror={true}
          dayMaxEvents={true}
          locale={localePtBr}
          datesSet={setDatesForReq}
          handleWindowResize={true}
          initialView="timeGridWeek"
          eventClick={handleEventClick}
          eventContent={renderEventContent}
        />
        <div className={subtitles}>
          <Typography className={dotAvailable}>●</Typography>Disponível
          <Typography className={dotUnavailable}>●</Typography>Agendado
        </div>
      </div>
    </>
  );
};
