import {Api} from 'shared/services/axiosConfig';

export interface IIntegrationCollege {
  idOrigemMatricula: number;
}

const getIntegrationCollege = async (): Promise<IIntegrationCollege> => {
  try {
    const {data} = await Api.get(`/OrigemMatricula/IntegracaoGVCollege`);

    return data;
  } catch (error: any) {
    throw new Error('Erro ao obter origem de matricula!');
  }
};

export const SourceRecordService = {
  getIntegrationCollege,
};
