import {useCallback, useEffect, useMemo, useState} from 'react';
import {
  Box,
  Grid,
  Tooltip,
  Checkbox,
  TextField,
  Typography,
  FormHelperText,
  FormControlLabel,
} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
import {Error} from '@material-ui/icons';

import {useNavigate, useParams} from 'react-router-dom';

import {feedback} from 'shared/services/alertService';
import {errorResponse} from 'shared/utils/errorResponse';
import {useTerm, useTitle, useUser} from 'shared/hooks';
import {Footer, RichText, InfoTooltip} from 'shared/components';

import {StepperCampaign} from 'pages/Campaign/components/StepperCampaign';

import {
  CampaignService,
  ICampaign,
  IConfigEmail,
} from 'shared/services/api/CampaignService';
import {IGetOffer, OfferService} from 'shared/services/api/OfferService';

export const EmailConfig: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [campaign, setCampaign] = useState({
    possuiTaxa: false,
    usaAgendamento: false,
  } as ICampaign);
  const [emailConfig, setEmailConfig] = useState<IConfigEmail>({
    assuntoEmailParaConfirmacaoPagamentoTaxa: '',
    assuntoEmailParaLinkFormularioSecundario: '',
    assuntoEmailParaMudancaAgenda: '',
    assuntoEmailParaPagamentoTaxa: '',
    assuntoEmailParaTerminoInscricao: '',
    enviarEmailTaxaNaInscricao: false,
    mensagemEmailParaConfirmacaoPagamentoTaxa: '',
    mensagemEmailParaLinkFormularioSecundario: '',
    mensagemEmailParaMudancaAgenda: '',
    mensagemEmailParaPagamentoTaxa: '',
    mensagemEmailParaProximidadeAgenda: '',
    mensagemEmailParaTerminoInscricao: '',
    qtdeDiasEnvioEmailParaProximidadeAgenda: 0,
    assuntoEmailParaConflitoAgenda: '',
    assuntoEmailParaProximidadeAgenda: '',
    listaEmailEnvioParaConflitoAgenda: '',
    mensagemEmailParaConflitoAgenda: '',
    assuntoEmailParaVagaOcupada: '',
    mensagemEmailParaVagaOcupada: '',
    assuntoEmailParaEntradaVagaAposListaEspera: '',
    mensagemEmailParaEntradaVagaAposListaEspera: '',
  });
  const [offersByCampaign, setOffersByCampaign] = useState<IGetOffer[]>([]);

  const navigate = useNavigate();
  const {terms} = useTerm();
  const {setTitle} = useTitle();
  const {id = ''} = useParams<'id'>();
  const {data: user} = useUser();
  const url = window.location.href;

  const handleGetConfigEmails = useCallback(
    async (id: string) => {
      setLoading(true);
      try {
        const data = await CampaignService.getCampaign({
          id,
          verificarPermissaoEditar: true,
        });
        setCampaign(data);

        if (!url.includes('novo') && data) {
          const response = await CampaignService.getConfigEmails(id);
          setEmailConfig(response);
        }
      } catch (error) {
        feedback(errorResponse(error), 'error');
      } finally {
        setLoading(false);
      }
    },
    [url],
  );

  const handleGetOffersOfCampaign = useCallback(async (id: string) => {
    try {
      const data = await OfferService.getOfferForCampaign(id);

      setOffersByCampaign(data);
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
  }, []);

  const permissions = useMemo(
    () => user?.permissoes.filter((i: any) => i.nome.includes('Campanha')),
    [user?.permissoes],
  );

  const canList = useMemo(
    () => !!permissions?.find((i: any) => i.nome.includes('Listar')),
    [permissions],
  );

  const canEdit = useMemo(
    () => !!permissions?.find((i: any) => i.nome.includes('Editar')),
    [permissions],
  );

  const edit = useMemo(() => !!id, [id]);

  const isEditing = useMemo(
    () => campaign.permiteEditar,
    [campaign.permiteEditar],
  );

  const validateEndRegistration = useMemo(
    () => !emailConfig.mensagemEmailParaTerminoInscricao && submitted,
    [emailConfig.mensagemEmailParaTerminoInscricao, submitted],
  );

  const validateMockedEmailValue = useMemo(
    () =>
      campaign.possuiTaxa &&
      !emailConfig.mensagemEmailParaPagamentoTaxa &&
      submitted,
    [
      campaign.possuiTaxa,
      emailConfig.mensagemEmailParaPagamentoTaxa,
      submitted,
    ],
  );

  const validateChangeSchedule = useMemo(
    () =>
      campaign.usaAgendamento &&
      !emailConfig.mensagemEmailParaMudancaAgenda &&
      submitted,
    [
      campaign.usaAgendamento,
      emailConfig.mensagemEmailParaMudancaAgenda,
      submitted,
    ],
  );

  const validateProximitySchedule = useMemo(
    () =>
      campaign.usaAgendamento &&
      !emailConfig.mensagemEmailParaProximidadeAgenda &&
      submitted,
    [
      campaign.usaAgendamento,
      emailConfig.mensagemEmailParaProximidadeAgenda,
      submitted,
    ],
  );

  const validateConflictSchedule = useMemo(
    () =>
      campaign.usaAgendamento &&
      !emailConfig.mensagemEmailParaConflitoAgenda &&
      submitted,
    [
      campaign.usaAgendamento,
      emailConfig.mensagemEmailParaConflitoAgenda,
      submitted,
    ],
  );

  const validateConfirmPayment = useMemo(
    () =>
      campaign.possuiTaxa &&
      !emailConfig.mensagemEmailParaConfirmacaoPagamentoTaxa &&
      submitted,
    [
      campaign.possuiTaxa,
      emailConfig.mensagemEmailParaConfirmacaoPagamentoTaxa,
      submitted,
    ],
  );

  const possuiControleVagas = useMemo(
    () => offersByCampaign.some((offer) => offer.controleVagas !== null),
    [offersByCampaign],
  );

  const showError = (data: any, isCheckboxValidated?: boolean) => {
    if (!isCheckboxValidated) {
      return false;
    }

    if (submitted) {
      return !data;
    }

    return false;
  };

  const handleSaveAndNextStep = useCallback(async () => {
    if (
      emailConfig.assuntoEmailParaTerminoInscricao === '' ||
      (emailConfig.assuntoEmailParaMudancaAgenda === '' &&
        campaign.usaAgendamento)
    ) {
      setSubmitted(true);
      return;
    }

    if (
      campaign.usaAgendamento &&
      (!emailConfig.mensagemEmailParaMudancaAgenda ||
        !emailConfig.mensagemEmailParaTerminoInscricao ||
        !emailConfig.mensagemEmailParaConflitoAgenda)
    ) {
      setSubmitted(true);
      return;
    }

    if (
      emailConfig.assuntoEmailParaProximidadeAgenda.length > 0 &&
      emailConfig.mensagemEmailParaProximidadeAgenda === '' &&
      emailConfig.qtdeDiasEnvioEmailParaProximidadeAgenda === 0
    ) {
      setSubmitted(true);
      return;
    }

    if (
      campaign.possuiTaxa &&
      (!emailConfig.assuntoEmailParaPagamentoTaxa ||
        !emailConfig.mensagemEmailParaPagamentoTaxa)
    ) {
      setSubmitted(true);
      return;
    }

    if (
      campaign.possuiTaxa &&
      (!emailConfig.assuntoEmailParaConfirmacaoPagamentoTaxa ||
        !emailConfig.mensagemEmailParaConfirmacaoPagamentoTaxa)
    ) {
      setSubmitted(true);
      return;
    }

    if (
      emailConfig.assuntoEmailParaConflitoAgenda.length > 0 &&
      emailConfig.listaEmailEnvioParaConflitoAgenda === '' &&
      emailConfig.mensagemEmailParaConflitoAgenda === ''
    ) {
      setSubmitted(true);
      return;
    }

    if (
      campaign.usaAgendamento &&
      (!emailConfig.assuntoEmailParaProximidadeAgenda ||
        !(emailConfig.qtdeDiasEnvioEmailParaProximidadeAgenda > 0) ||
        !emailConfig.mensagemEmailParaProximidadeAgenda)
    ) {
      setSubmitted(true);
      return;
    }

    if (
      campaign.usaAgendamento &&
      (!emailConfig.assuntoEmailParaConflitoAgenda ||
        !emailConfig.listaEmailEnvioParaConflitoAgenda)
    ) {
      setSubmitted(true);
      return;
    }

    if (
      possuiControleVagas &&
      (!emailConfig.assuntoEmailParaVagaOcupada ||
        !emailConfig.mensagemEmailParaVagaOcupada)
    ) {
      setSubmitted(true);
      return;
    }

    try {
      if (!url.includes('novo')) {
        await CampaignService.putConfigEmails(id, emailConfig);

        navigate(
          `/campanha/funil/${id}/${campaign.idEstabelecimentoDono}/${campaign.nivelEstabelecimentoDono}`,
        );
      } else {
        await CampaignService.postConfigEmails(id, emailConfig);
        navigate(
          `/campanha/funil/${id}/${campaign.idEstabelecimentoDono}/${campaign.nivelEstabelecimentoDono}`,
        );
      }
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
  }, [
    campaign.idEstabelecimentoDono,
    campaign.nivelEstabelecimentoDono,
    campaign.possuiTaxa,
    campaign.usaAgendamento,
    emailConfig,
    id,
    navigate,
    possuiControleVagas,
    url,
  ]);

  useEffect(() => {
    setTitle(
      `${edit ? 'Editar' : 'Nova'} Campanha ${campaign.nome} | Dados Gerais`,
    );
  }, [campaign.nome, edit, setTitle]);

  useEffect(() => {
    if (!canList) navigate('/');
  }, [canList, navigate]);

  useEffect(() => {
    handleGetConfigEmails(id);
    handleGetOffersOfCampaign(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StepperCampaign
            integrated={false}
            loading={loading}
            activeStep={1}
            idCampaign={id}
          />
          <Box marginBottom={1} />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" color="primary">
            <strong>Inscrição</strong>
          </Typography>
          <Box marginBottom={1} />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle1">
            <strong>
              E-mail a ser enviado ao {terms.termoLead} quando finalizar a
              inscrição *
            </strong>
          </Typography>
          <Box marginBottom={1} />
        </Grid>

        <Grid item xs={7}>
          {loading ? (
            <Skeleton variant="rect" height={36} />
          ) : (
            <TextField
              fullWidth
              label="Assunto"
              variant="outlined"
              disabled={!isEditing || !canEdit}
              error={
                emailConfig.assuntoEmailParaTerminoInscricao === '' && submitted
              }
              helperText={
                emailConfig.assuntoEmailParaTerminoInscricao === '' &&
                submitted &&
                'Campo requerido'
              }
              value={emailConfig.assuntoEmailParaTerminoInscricao}
              onChange={({target}) => {
                setEmailConfig((state) => ({
                  ...state,
                  assuntoEmailParaTerminoInscricao: target.value,
                }));
              }}
            />
          )}
        </Grid>

        <Grid item xs={5} />

        <Grid item xs={7}>
          {loading ? (
            <Skeleton variant="rect" height={108} />
          ) : (
            <>
              <RichText
                disableFilePicker
                disablePasteDataImages
                value={emailConfig.mensagemEmailParaTerminoInscricao}
                disabled={!isEditing || !canEdit}
                onChange={(value) =>
                  setEmailConfig((state) => ({
                    ...state,
                    mensagemEmailParaTerminoInscricao: value,
                  }))
                }
              />
              {validateEndRegistration && (
                <FormHelperText error>Campo requerido</FormHelperText>
              )}
            </>
          )}

          <Box marginBottom={3} />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" color="primary">
            <strong>Agendamento</strong>
          </Typography>
          <Box marginBottom={1} />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle1">
            <strong>
              E-mail a ser enviado ao {terms.termoLead} quando ocorrer uma
              alteração no seu agendamento {campaign.usaAgendamento ? '*' : ''}
            </strong>
          </Typography>
          <Box marginBottom={1} />
        </Grid>

        <Grid item xs={7}>
          {loading ? (
            <Skeleton variant="rect" height={36} />
          ) : (
            <TextField
              fullWidth
              label="Assunto"
              variant="outlined"
              disabled={!isEditing || !canEdit}
              value={emailConfig.assuntoEmailParaMudancaAgenda}
              error={showError(
                emailConfig.assuntoEmailParaMudancaAgenda,
                campaign.usaAgendamento,
              )}
              helperText={
                !emailConfig.assuntoEmailParaMudancaAgenda &&
                submitted &&
                campaign.usaAgendamento &&
                'Campo requerido'
              }
              onChange={({target}) => {
                setEmailConfig((state) => ({
                  ...state,
                  assuntoEmailParaMudancaAgenda: target.value,
                }));
              }}
            />
          )}
        </Grid>

        <Grid item xs={5} />

        <Grid item xs={7}>
          {loading ? (
            <Skeleton variant="rect" height={108} />
          ) : (
            <>
              <RichText
                disableFilePicker
                disablePasteDataImages
                value={emailConfig.mensagemEmailParaMudancaAgenda}
                disabled={!isEditing || !canEdit}
                onChange={(value) =>
                  setEmailConfig((state) => ({
                    ...state,
                    mensagemEmailParaMudancaAgenda: value,
                  }))
                }
              />
              {validateChangeSchedule && (
                <FormHelperText error>Campo requerido</FormHelperText>
              )}
            </>
          )}
          <Box marginBottom={3} />
        </Grid>

        <Grid item xs={5} />

        <Grid item xs={12}>
          <Typography variant="subtitle1">
            <strong>
              E-mail a ser enviado ao {terms.termoLead} avisando da proximidade
              do agendamento {campaign.usaAgendamento ? '*' : ''}
            </strong>
          </Typography>
          <Box marginBottom={1} />
        </Grid>

        <Grid item xs={7}>
          {loading ? (
            <Skeleton variant="rect" height={36} />
          ) : (
            <TextField
              fullWidth
              label="Assunto"
              variant="outlined"
              disabled={!isEditing || !canEdit}
              value={emailConfig.assuntoEmailParaProximidadeAgenda}
              error={showError(
                emailConfig.assuntoEmailParaProximidadeAgenda,
                campaign.usaAgendamento,
              )}
              onChange={({target}) => {
                setEmailConfig((state) => ({
                  ...state,
                  assuntoEmailParaProximidadeAgenda: target.value,
                }));
              }}
            />
          )}
        </Grid>

        <Grid item xs={5} />

        <Grid item xs={4}>
          {loading ? (
            <Skeleton variant="rect" height={36} />
          ) : (
            <Box display="flex" alignItems="flex-start" gridGap={8}>
              <TextField
                label={`Quantidade de dias ${
                  emailConfig.assuntoEmailParaProximidadeAgenda ? '*' : ''
                }`}
                variant="outlined"
                disabled={
                  !isEditing ||
                  !emailConfig.assuntoEmailParaProximidadeAgenda ||
                  !canEdit
                }
                fullWidth
                value={emailConfig.qtdeDiasEnvioEmailParaProximidadeAgenda}
                type="number"
                error={
                  emailConfig.assuntoEmailParaProximidadeAgenda.length > 0 &&
                  showError(
                    emailConfig.qtdeDiasEnvioEmailParaProximidadeAgenda,
                    campaign.usaAgendamento,
                  )
                }
                helperText={
                  emailConfig.assuntoEmailParaProximidadeAgenda.length > 0 &&
                  showError(
                    emailConfig.qtdeDiasEnvioEmailParaProximidadeAgenda,
                    campaign.usaAgendamento,
                  ) &&
                  'A quantidade de dias deve ser maior que zero'
                }
                onChange={({target}) =>
                  setEmailConfig((state) => ({
                    ...state,
                    qtdeDiasEnvioEmailParaProximidadeAgenda: Number(
                      target.value,
                    ),
                  }))
                }
                InputProps={{inputProps: {min: 0}}}
              />
              <InfoTooltip title="Quantidade de dias antes do agendamento que o e-mail deve ser enviado." />
            </Box>
          )}
        </Grid>

        <Grid item xs={8} />

        <Grid item xs={7}>
          {loading ? (
            <Skeleton variant="rect" height={108} />
          ) : (
            <>
              <RichText
                disableFilePicker
                disablePasteDataImages
                value={emailConfig.mensagemEmailParaProximidadeAgenda}
                disabled={!isEditing || !canEdit}
                onChange={(value) =>
                  setEmailConfig((state) => ({
                    ...state,
                    mensagemEmailParaProximidadeAgenda: value,
                  }))
                }
              />
              {validateProximitySchedule && (
                <FormHelperText error>Campo requerido</FormHelperText>
              )}
            </>
          )}
          <Box marginBottom={3} />
        </Grid>

        <Grid item xs={5} />

        <Grid item xs={12}>
          <Typography variant="subtitle1">
            <strong>
              E-mail a ser enviado quando ocorrer uma inscrição num agendamento
              já utilizado {campaign.usaAgendamento ? '*' : ''}
            </strong>
          </Typography>
          <Box marginBottom={1} />
        </Grid>

        <Grid item xs={7}>
          {loading ? (
            <Skeleton variant="rect" height={36} />
          ) : (
            <TextField
              disabled={!isEditing || !canEdit}
              error={showError(
                emailConfig.listaEmailEnvioParaConflitoAgenda,
                campaign.usaAgendamento,
              )}
              helperText={
                showError(
                  emailConfig.listaEmailEnvioParaConflitoAgenda,
                  campaign.usaAgendamento,
                )
                  ? 'Campo requerido'
                  : undefined
              }
              fullWidth
              label="E-mail"
              variant="outlined"
              value={emailConfig.listaEmailEnvioParaConflitoAgenda}
              onChange={({target}) =>
                setEmailConfig((state) => ({
                  ...state,
                  listaEmailEnvioParaConflitoAgenda: target.value,
                }))
              }
            />
          )}
        </Grid>

        <Grid item xs={7}>
          {loading ? (
            <Skeleton variant="rect" height={36} />
          ) : (
            <TextField
              fullWidth
              label="Assunto"
              disabled={!isEditing || !canEdit}
              variant="outlined"
              value={emailConfig.assuntoEmailParaConflitoAgenda}
              error={showError(
                emailConfig.assuntoEmailParaConflitoAgenda,
                campaign.usaAgendamento,
              )}
              helperText={
                showError(
                  emailConfig.assuntoEmailParaConflitoAgenda,
                  campaign.usaAgendamento,
                )
                  ? 'Campo requerido'
                  : undefined
              }
              onChange={({target}) => {
                setEmailConfig((state) => ({
                  ...state,
                  assuntoEmailParaConflitoAgenda: target.value,
                }));
              }}
            />
          )}
        </Grid>

        <Grid item xs={5} />

        <Grid item xs={7}>
          {loading ? (
            <Skeleton variant="rect" height={108} />
          ) : (
            <>
              <RichText
                disableFilePicker
                disablePasteDataImages
                value={emailConfig.mensagemEmailParaConflitoAgenda}
                disabled={!isEditing || !canEdit}
                onChange={(value) =>
                  setEmailConfig((state) => ({
                    ...state,
                    mensagemEmailParaConflitoAgenda: value,
                  }))
                }
              />
              {validateConflictSchedule && (
                <FormHelperText error>Campo requerido</FormHelperText>
              )}
            </>
          )}
          <Box marginBottom={3} />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" color="primary">
            <strong>Formulário secundário</strong>
          </Typography>
          <Box marginBottom={1} />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle1">
            <strong>
              E-mail a ser enviado ao Lead quando chegar no formulário
              secundário
            </strong>
          </Typography>
          <Box marginBottom={1} />
        </Grid>

        <Grid item xs={7}>
          {loading ? (
            <Skeleton variant="rect" height={36} />
          ) : (
            <TextField
              fullWidth
              label="Assunto"
              disabled={!isEditing || !canEdit}
              variant="outlined"
              value={emailConfig.assuntoEmailParaLinkFormularioSecundario}
              onChange={({target}) => {
                setEmailConfig((state) => ({
                  ...state,
                  assuntoEmailParaLinkFormularioSecundario: target.value,
                }));
              }}
            />
          )}
        </Grid>

        <Grid item xs={5} />

        <Grid item xs={7}>
          {loading ? (
            <Skeleton variant="rect" height={108} />
          ) : (
            <>
              <RichText
                disableFilePicker
                disablePasteDataImages
                value={emailConfig.mensagemEmailParaLinkFormularioSecundario}
                disabled={!isEditing || !canEdit}
                onChange={(value) =>
                  setEmailConfig((state) => ({
                    ...state,
                    mensagemEmailParaLinkFormularioSecundario: value,
                  }))
                }
              />
            </>
          )}
          <Box marginBottom={3} />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" color="primary">
            <strong>Pagamento</strong>
          </Typography>
          <Box marginBottom={1} />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle1">
            <strong>
              E-mail com o link de pagamento a ser enviado ao Lead quando
              finalizar a inscrição
            </strong>
          </Typography>
          <Box />
        </Grid>

        <Grid item xs={7}>
          {loading ? (
            <Skeleton variant="rect" height={36} />
          ) : (
            <FormControlLabel
              control={
                <Checkbox
                  checked={emailConfig.enviarEmailTaxaNaInscricao}
                  disabled={!isEditing || !canEdit || !campaign.possuiTaxa}
                  onChange={(e) => {
                    setEmailConfig((state) => ({
                      ...state,
                      enviarEmailTaxaNaInscricao: e.target.checked,
                    }));
                  }}
                />
              }
              label={
                <Box display="flex" alignItems="center" gridGap={4}>
                  <strong>Enviar e-mail abaixo com o link de pagamento</strong>
                  <Tooltip
                    title={
                      <h3>
                        Se desmarcado esta opção e a campanha possuir taxa, você
                        terá que configurar a macro do link de pagamento no
                        e-mail de confirmação de inscrição.
                      </h3>
                    }>
                    <Error color="primary" />
                  </Tooltip>
                </Box>
              }
            />
          )}
        </Grid>

        <Grid item xs={7}>
          {loading ? (
            <Skeleton variant="rect" height={36} />
          ) : (
            <TextField
              fullWidth
              label="Assunto"
              disabled={!isEditing || !canEdit}
              variant="outlined"
              value={emailConfig.assuntoEmailParaPagamentoTaxa}
              error={showError(
                emailConfig.assuntoEmailParaPagamentoTaxa,
                campaign.possuiTaxa,
              )}
              helperText={
                showError(
                  emailConfig.assuntoEmailParaPagamentoTaxa,
                  campaign.possuiTaxa,
                )
                  ? 'Campo requerido'
                  : undefined
              }
              onChange={({target}) => {
                setEmailConfig((state) => ({
                  ...state,
                  assuntoEmailParaPagamentoTaxa: target.value,
                }));
              }}
            />
          )}
        </Grid>

        <Grid item xs={5} />

        <Grid item xs={7}>
          {loading ? (
            <Skeleton variant="rect" height={108} />
          ) : (
            <>
              <RichText
                disableFilePicker
                disablePasteDataImages
                value={emailConfig.mensagemEmailParaPagamentoTaxa}
                disabled={!isEditing || !canEdit}
                onChange={(value) =>
                  setEmailConfig((state) => ({
                    ...state,
                    mensagemEmailParaPagamentoTaxa: value,
                  }))
                }
              />
              {validateMockedEmailValue && (
                <FormHelperText error>Campo requerido</FormHelperText>
              )}
            </>
          )}
          <Box marginBottom={3} />
        </Grid>

        <Grid item xs={5} />

        <Grid item xs={12}>
          <Typography variant="subtitle1">
            <strong>
              E-mail a ser enviado ao Lead quando ocorrer confirmação de
              pagamento da taxa {campaign.possuiTaxa ? '*' : ''}
            </strong>
          </Typography>
          <Box marginBottom={1} />
        </Grid>

        <Grid item xs={7}>
          {loading ? (
            <Skeleton variant="rect" height={36} />
          ) : (
            <TextField
              fullWidth
              label="Assunto"
              disabled={!isEditing || !canEdit}
              variant="outlined"
              value={emailConfig.assuntoEmailParaConfirmacaoPagamentoTaxa}
              error={showError(
                emailConfig.assuntoEmailParaConfirmacaoPagamentoTaxa,
                campaign.possuiTaxa,
              )}
              helperText={
                showError(
                  emailConfig.assuntoEmailParaConfirmacaoPagamentoTaxa,
                  campaign.possuiTaxa,
                )
                  ? 'Campo requerido'
                  : undefined
              }
              onChange={({target}) => {
                setEmailConfig((state) => ({
                  ...state,
                  assuntoEmailParaConfirmacaoPagamentoTaxa: target.value,
                }));
              }}
            />
          )}
        </Grid>

        <Grid item xs={5} />

        <Grid item xs={7}>
          {loading ? (
            <Skeleton variant="rect" height={108} />
          ) : (
            <>
              <RichText
                disableFilePicker
                disablePasteDataImages
                value={emailConfig.mensagemEmailParaConfirmacaoPagamentoTaxa}
                disabled={!isEditing || !canEdit}
                onChange={(value) =>
                  setEmailConfig((state) => ({
                    ...state,
                    mensagemEmailParaConfirmacaoPagamentoTaxa: value,
                  }))
                }
              />
              {validateConfirmPayment && (
                <FormHelperText error>Campo requerido</FormHelperText>
              )}
            </>
          )}
          <Box marginBottom={3} />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" color="primary">
            <strong>Controle de vagas</strong>
          </Typography>
          <Box marginBottom={1} />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle1">
            <strong>
              E-mail a ser enviado quando ocorrer uma inscrição em uma oferta
              que teve todas as suas vagas ocupadas{' '}
              {possuiControleVagas ? '*' : ''}
            </strong>
          </Typography>
          <Box marginBottom={1} />
        </Grid>

        <Grid item xs={7}>
          {loading ? (
            <Skeleton variant="rect" height={36} />
          ) : (
            <TextField
              fullWidth
              label="Assunto"
              disabled={!isEditing || !canEdit}
              variant="outlined"
              value={emailConfig.assuntoEmailParaVagaOcupada}
              error={showError(
                emailConfig.assuntoEmailParaVagaOcupada,
                possuiControleVagas,
              )}
              helperText={
                showError(
                  emailConfig.assuntoEmailParaVagaOcupada,
                  possuiControleVagas,
                )
                  ? 'Campo requerido'
                  : undefined
              }
              onChange={({target}) => {
                setEmailConfig((state) => ({
                  ...state,
                  assuntoEmailParaVagaOcupada: target.value,
                }));
              }}
            />
          )}
        </Grid>

        <Grid item xs={5} />

        <Grid item xs={7}>
          {loading ? (
            <Skeleton variant="rect" height={108} />
          ) : (
            <>
              <RichText
                disableFilePicker
                disablePasteDataImages
                value={emailConfig.mensagemEmailParaVagaOcupada}
                disabled={!isEditing || !canEdit}
                onChange={(value) =>
                  setEmailConfig((state) => ({
                    ...state,
                    mensagemEmailParaVagaOcupada: value,
                  }))
                }
              />
              {possuiControleVagas &&
                submitted &&
                !emailConfig.mensagemEmailParaVagaOcupada && (
                  <FormHelperText error>Campo requerido</FormHelperText>
                )}
            </>
          )}
          <Box marginBottom={3} />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle1">
            <strong>
              E-mail a ser enviado quando o lead passou de lista de espera para
              vaga
            </strong>
          </Typography>
          <Box marginBottom={1} />
        </Grid>

        <Grid item xs={7}>
          {loading ? (
            <Skeleton variant="rect" height={36} />
          ) : (
            <TextField
              fullWidth
              label="Assunto"
              disabled={!isEditing || !canEdit}
              variant="outlined"
              value={emailConfig.assuntoEmailParaEntradaVagaAposListaEspera}
              onChange={({target}) => {
                setEmailConfig((state) => ({
                  ...state,
                  assuntoEmailParaEntradaVagaAposListaEspera: target.value,
                }));
              }}
            />
          )}
        </Grid>

        <Grid item xs={5} />

        <Grid item xs={7}>
          {loading ? (
            <Skeleton variant="rect" height={108} />
          ) : (
            <>
              <RichText
                disableFilePicker
                disablePasteDataImages
                value={emailConfig.mensagemEmailParaEntradaVagaAposListaEspera}
                disabled={!isEditing || !canEdit}
                onChange={(value) =>
                  setEmailConfig((state) => ({
                    ...state,
                    mensagemEmailParaEntradaVagaAposListaEspera: value,
                  }))
                }
              />
            </>
          )}
          <Box marginBottom={3} />
        </Grid>
      </Grid>

      <Footer
        onCancel={() => navigate('/campanha')}
        onSaveLabel={isEditing ? 'Salvar e Avançar' : 'Avançar'}
        onGoBack={() => navigate(`/campanha/editar/${id}`)}
        onSave={handleSaveAndNextStep}
        disabled={loading}
      />
    </>
  );
};
