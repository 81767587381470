import React, {useEffect, useState} from 'react';

import {Routes} from 'routes';
import {AppProvider} from 'shared/contexts';
import {Environment} from 'shared/env';
import ReactGA from 'react-ga';

export const App: React.FC = () => {
  ReactGA.initialize('G-R362CYQHYM');

  useEffect(() => {
    const scriptFeedBack = document.createElement('script');
    scriptFeedBack.type = 'module';
    scriptFeedBack.src = `${Environment.URL_WC_FEEDABACK}`;
    scriptFeedBack.crossOrigin = 'anonymous';

    document.body.appendChild(scriptFeedBack);

    return () => {
      document.body.appendChild(scriptFeedBack);
    };
  }, []);

  return (
    <AppProvider>
      <Routes />
    </AppProvider>
  );
};
