import React, {useEffect, useState} from 'react';
import {Box, Grid, MenuItem, TextField, ListSubheader} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import GroupIcon from '@material-ui/icons/Group';
import CachedIcon from '@material-ui/icons/Cached';
import EventNoteIcon from '@material-ui/icons/EventNote';
import DescriptionIcon from '@material-ui/icons/Description';

import {
  LeadsWhitout,
  ActiveCampaing,
  ConversionRate,
  LeadsWithoutConfig,
  ServiceTimeAverage,
  ServiceTimeAverageConfig,
} from './components/modals';
import {
  Overview,
  LeadsBySituation,
  SubscriberConversion,
  GeneralPerformance as GeneralPerformanceCard,
  ConversionByCampaing as ConversionByCampaingCard,
} from './components/cards';

import {FiltersSkeleton} from './components/FiltersSkeleton';
import {DashBoardSkeleton} from './components/DashboardSkeleton';

import {
  CRMDashboardService,
  IDashboard,
  IFiltro,
  ICampanha,
} from 'shared/services/api/CRMDashboard';

export interface IConversionsXAtendidosData {
  atendidos: number[];
  convertidos: number[];
}

export const Dashboard: React.FC = () => {
  const [isLoadingFilters, setisLoadingFilters] = useState(true);
  const [loading, setLoading] = useState(true);

  const [selectedEstablishment, setSelectedEstablishment] = useState('');
  const [selectedCampaing, setSelectedCampaing] = useState('');
  const [selectedPeriod, setSelectedPeriod] = useState('1');

  const [dataFilter, setDataFilter] = useState<IFiltro>();

  const [filterCampaign, setFilterCampaign] = useState<ICampanha[]>([]);

  const [dashboard, setDashboard] = useState<IDashboard>();

  const [openModalActiveCampaing, setModalActiveCampaing] = useState(false);
  const [openModalserviceTimeAverage, setModalServiceTimeAverage] =
    useState(false);
  const [openModalConversionRate, setModalConversionRate] = useState(false);
  const [openModalLeadsWhitout, setModalLeadsWhitout] = useState(false);
  const [openModalLeadsWhitoutConfig, setModalLeadsWhitoutConfig] =
    useState(false);
  const [openModalServiceTimeAverageConfig, setModalServiceTimeAverageConfig] =
    useState(false);

  useEffect(() => {
    async function getDashboard() {
      setLoading(true);
      try {
        const response = await CRMDashboardService.getDashboard(
          selectedEstablishment,
          selectedCampaing,
          selectedPeriod,
        );

        setDashboard(response);
      } finally {
        setLoading(false);
      }
    }
    getDashboard();
  }, [selectedEstablishment, selectedCampaing, selectedPeriod]);

  useEffect(() => {
    async function getFiltro() {
      setisLoadingFilters(true);
      try {
        const response = await CRMDashboardService.getFiltro();
        setDataFilter(response);
      } finally {
        setisLoadingFilters(false);
      }
    }
    getFiltro();
  }, []);

  return (
    <>
      <Grid container spacing={1}>
        {isLoadingFilters ? (
          <FiltersSkeleton />
        ) : dataFilter ? (
          <>
            <Grid item xs={12} sm={4}>
              <TextField
                select
                fullWidth
                size="small"
                value={selectedEstablishment}
                label="Estabelecimento"
                variant="outlined"
                disabled={loading}
                onChange={({target}) => {
                  setSelectedEstablishment(target.value);
                  setSelectedCampaing('');
                }}>
                <MenuItem
                  value={'0'}
                  key={'0.0'}
                  onClick={() => setFilterCampaign([])}>
                  Todos
                </MenuItem>
                {dataFilter.estabelecimentos.map((establishment, index) =>
                  establishment.id ? (
                    <MenuItem
                      key={index}
                      value={establishment.id}
                      onClick={() =>
                        setFilterCampaign(establishment.campanhas)
                      }>
                      {establishment.nome}
                    </MenuItem>
                  ) : (
                    <ListSubheader key={establishment.id}>
                      {establishment.nome}
                    </ListSubheader>
                  ),
                )}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                select
                fullWidth
                size="small"
                value={selectedCampaing}
                label="Campanha"
                variant="outlined"
                disabled={loading}
                onChange={({target}) => setSelectedCampaing(target.value)}>
                <MenuItem value={'0'}>Todos</MenuItem>
                {filterCampaign.map((item, index) =>
                  item.id ? (
                    <MenuItem key={index} value={item.id}>
                      {item.nome}
                    </MenuItem>
                  ) : (
                    <ListSubheader key={item.id}>{item.nome}</ListSubheader>
                  ),
                )}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                select
                fullWidth
                size="small"
                value={selectedPeriod}
                label="Período"
                variant="outlined"
                disabled={loading}
                onChange={({target}) => setSelectedPeriod(target.value)}>
                {dataFilter.periodos.map((period, index) =>
                  period.idPeriodo ? (
                    <MenuItem key={index} value={period.idPeriodo}>
                      {period.descricao}
                    </MenuItem>
                  ) : (
                    <ListSubheader key={period.idPeriodo}>
                      {period.descricao}
                    </ListSubheader>
                  ),
                )}
              </TextField>
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <Alert severity="error">Não foram encontrados filtros.</Alert>
          </Grid>
        )}
      </Grid>

      <Box mb={2} />

      <Grid container spacing={3} alignItems="stretch">
        {loading ? (
          <DashBoardSkeleton />
        ) : dashboard ? (
          <>
            <Grid item xs={12} sm={6} md={3}>
              <Overview
                title={`${dashboard.taxaDeConversao.toFixed()}%`}
                description="Taxa de conversão"
                color={dashboard.taxaDeConversaoCor}
                icon={<CachedIcon fontSize="large" fillOpacity="50%" />}
                onClickConfig={() => setModalConversionRate(true)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Overview
                title={`${dashboard.numeroDeCampanhasAtivas}`}
                description="Total de campanhas ativas agora"
                color={dashboard.numeroDeCampanhasAtivasCor}
                icon={<DescriptionIcon fontSize="large" fillOpacity="50%" />}
                onClick={() => setModalActiveCampaing(true)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Overview
                title={`${dashboard.tempoMedioEntreAtendimentos} ${
                  dashboard.tempoMedioEntreAtendimentos === 1 ? 'Dia' : 'Dias'
                }`}
                description="Tempo médio de conversão"
                color={dashboard.tempoMedioEntreAtendimentosCor}
                icon={<EventNoteIcon fontSize="large" fillOpacity="50%" />}
                onClick={() => setModalServiceTimeAverage(true)}
                onClickConfig={() => setModalServiceTimeAverageConfig(true)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Overview
                title={`${dashboard.numeroLeadsSemInteracao} Leads`}
                description="Sem interação há mais de uma semana"
                color={dashboard.numeroLeadsSemInteracaoCor}
                icon={<GroupIcon fontSize="large" fillOpacity="50%" />}
                onClick={() => setModalLeadsWhitout(true)}
                onClickConfig={() => setModalLeadsWhitoutConfig(true)}
              />
            </Grid>

            <Grid item xs={12}>
              <GeneralPerformanceCard
                origemLeads={dashboard.origemLeads}
                selectedPeriod={selectedPeriod}
                selectedEstablishment={selectedEstablishment ?? '0'}
                selectedCampaing={selectedCampaing ?? ''}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <LeadsBySituation
                leadsPorSituacao={dashboard.leadsPorSituacao}
                selectedPeriod={selectedPeriod}
                selectedEstablishment={selectedEstablishment ?? '0'}
                selectedCampaing={selectedCampaing ?? ''}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ConversionByCampaingCard
                conversaoPorCampanha={dashboard.conversaoPorCampanha}
                selectedPeriod={selectedPeriod}
                selectedEstablishment={selectedEstablishment ?? '0'}
                selectedCampaing={selectedCampaing ?? ''}
              />
            </Grid>

            <Grid item xs={12}>
              <SubscriberConversion
                atendimentoXConversaoPorPeriodo={
                  dashboard.atendimentoXConversaoPorPeriodo
                }
                selectedPeriod={selectedPeriod}
                selectedEstablishment={selectedEstablishment ?? '0'}
                selectedCampaing={selectedCampaing ?? ''}
              />
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <Alert severity="error">
              Não foram encontrados dados de dashboard.
            </Alert>
          </Grid>
        )}
      </Grid>

      <ConversionRate
        openModal={openModalConversionRate}
        onclose={() => setModalConversionRate(false)}
      />

      <ActiveCampaing
        openModal={openModalActiveCampaing}
        onclose={() => setModalActiveCampaing(false)}
        establishment={selectedEstablishment ?? '0'}
      />

      <ServiceTimeAverage
        openModal={openModalserviceTimeAverage}
        onclose={() => setModalServiceTimeAverage(false)}
        selectedPeriod={selectedPeriod}
        selectedEstablishment={selectedEstablishment ?? '0'}
        selectedCampaing={selectedCampaing ?? ''}
      />
      <ServiceTimeAverageConfig
        openModal={openModalServiceTimeAverageConfig}
        onclose={() => setModalServiceTimeAverageConfig(false)}
      />

      <LeadsWhitout
        openModal={openModalLeadsWhitout}
        onclose={() => setModalLeadsWhitout(false)}
        selectedPeriod={selectedPeriod}
        selectedEstablishment={selectedEstablishment ?? '0'}
        selectedCampaing={selectedCampaing ?? ''}
      />
      <LeadsWithoutConfig
        openModal={openModalLeadsWhitoutConfig}
        onclose={() => setModalLeadsWhitoutConfig(false)}
      />
    </>
  );
};
