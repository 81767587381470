import React, {useState, useCallback, useEffect} from 'react';
import clsx from 'clsx';
import {useParams} from 'react-router-dom';
import {
  Box,
  Paper,
  Table,
  Collapse,
  TableRow,
  useTheme,
  TableBody,
  TableCell,
  TableHead,
  IconButton,
  TableContainer,
} from '@material-ui/core';
import {
  Edit,
  Delete,
  ExpandMore,
  DragIndicator,
  AddCircleRounded,
} from '@material-ui/icons';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';

import {
  FormService,
  IFormFields,
  IFormSection,
  ISituacaoFicha,
  ITokenField,
  IValidationGroup,
} from 'shared/services/api/FormService';
import {useCampaign} from 'shared/hooks';
import {feedback} from 'shared/services/alertService';
import {Button, ModalConfirm} from 'shared/components';
import {errorResponse} from 'shared/utils/errorResponse';

import {useStyles} from '../styles';
import {ModalField} from './ModalField';
import {ModalSection} from '../ModalSection';

interface IProps {
  isEditing: boolean;
  useSchedule: boolean;
  onCloseModal: () => void;
  handleGetFields: () => void;
  dataSection: IFormSection;
  validationGroups: IValidationGroup[];
  formSteps: ISituacaoFicha[];
}

export const CollapseInfo: React.FC<IProps> = ({
  isEditing,
  useSchedule,
  dataSection,
  onCloseModal,
  handleGetFields,
  validationGroups,
  formSteps
}) => {
  const [openModalConfirmExclude, setOpenModalConfirmExclude] = useState(false);
  const [openIncludeFieldModal, setOpenIncludeFieldModal] = useState(false);
  const [openEditFieldModal, setOpenEditFieldModal] = useState(false);
  const [field, setField] = useState<ITokenField>({} as ITokenField);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [hoverSectionId, setHoverSectionId] = useState('');
  const [fields, setFields] = useState(dataSection.campos);
  const [isSection, setIsSection] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [idDeleted, setIdDeleted] = useState('');
  const [loading, setLoading] = useState(false);
  const [hoverId, setHoverId] = useState('');
  const [fieldId, setFieldId] = useState('');
  const [hover, setHover] = useState(false);

  const theme = useTheme();
  const {getSections} = useCampaign();
  const {id = '0'} = useParams<'id'>();
  const {expand, dragIn, iconDrag, expandOpen} = useStyles();

  const handleExpandClick = useCallback(() => {
    setExpanded((state) => !state);
  }, []);

  const handleDelete = useCallback((id: string, isSection: boolean) => {
    setOpenModalConfirmExclude(true);

    setIsSection(isSection);
    setIdDeleted(id);
  }, []);

  const handleConfirmDeleted = useCallback(async () => {
    try {
      setLoading(true);
      if (isSection) {
        await FormService.deleteFormSection(idDeleted);
      } else {
        await FormService.deleteTokenField(idDeleted);
      }

      await getSections(id);
      await handleGetFields();

      feedback('Seção de formulário deletado com sucesso', 'success');
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
    setLoading(false);
    setIdDeleted('');
    setOpenModalConfirmExclude(false);
  }, [getSections, id, idDeleted, isSection]);

  const handleCloseModal = useCallback(async () => {
    setOpenIncludeFieldModal(false);
    setOpenEditFieldModal(false);
    setOpenEditModal(false);
    setOpenModalConfirmExclude(false);
    setIdDeleted('');

    onCloseModal();
  }, [onCloseModal]);

  const handleOnDragEnd = useCallback(
    async (result) => {
      if (!result.destination) return;

      const items = Array.from(fields);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      setFields(items);

      const payload = {
        items: items.map((item, index) => {
          return {
            ordem: index + 1,
            idCampo: item.idCampo,
          };
        }),
      };

      try {
        await FormService.updateOrderField(payload);
      } catch (error) {
        feedback(errorResponse(error), 'error');
      }
    },
    [fields],
  );

  const handleOpenEditFieldModal = useCallback(
    (data: IFormFields) => {
      setFieldId(data.idCampo);
      setField({
        obrigatorioCampoPredefinido: data.obrigatorioCampoPredefinido,
        ativo: data.ativo,
        obrigatorio: data.obrigatorio,
        idSecaoDaFicha: dataSection.idSecao,
        disponivelParaLead: data.disponivelParaLead,
        idTipoDeCampo: data.idTipoDeCampo,
        idCampoPredefinido: null,
        nome: data.nomeCampo,
        usaMascara: data.usaMascara,
        idTipoMascara: data.idTipoMascara || 0,
        textoDeAjuda: data.textoDeAjuda || '',
        mascara: data.mascara,
        opcoes: data.opcoes,
        validacoes: data.validacoes,
        tiposDeCampoRestrito: data.tiposDeCampoRestrito,
        ordem: data.ordem,
        idFormatoTexto: data.idFormatoTexto,
      });

      setOpenEditFieldModal(true);
    },
    [dataSection.idSecao],
  );

  useEffect(() => setFields(dataSection.campos), [dataSection.campos]);

  return (
    <>
      <Table>
        <TableBody>
          <TableRow
            onMouseEnter={() => {
              setHover(true), setHoverSectionId(dataSection.idSecao);
            }}
            onMouseLeave={() => {
              setHover(false), setHoverSectionId('');
            }}>
            <TableCell width="14%">
              <DragIndicator
                className={iconDrag}
                style={{
                  opacity:
                    hover && hoverSectionId === dataSection.idSecao ? 1 : 0,
                }}
              />
              <IconButton
                color="primary"
                disabled={dataSection.idSecaoPredefinida === 1 || !isEditing}
                onClick={() => handleDelete(dataSection.idSecao, true)}>
                <Delete />
              </IconButton>
              <IconButton
                style={{marginLeft: theme.spacing(1)}}
                color="primary"
                disabled={loading || !isEditing}
                onClick={() => setOpenEditModal(true)}>
                <Edit />
              </IconButton>
            </TableCell>
            <TableCell width="30%">{dataSection.nomeSecao}</TableCell>
            <TableCell width="20%">
              {dataSection.situacaoLeadEnvioForm}
            </TableCell>
            <TableCell width="20%">
              {dataSection.nomeSecaoPredefinida}
            </TableCell>
            <TableCell width="20%">
              {dataSection.apresentarNoFormulario ? 'Sim' : 'Não'}
            </TableCell>
            <TableCell width="14%">{dataSection.maximoColunas}</TableCell>
            <TableCell width="6%" align="right">
              <IconButton
                className={clsx(expand, {
                  [expandOpen]: expanded,
                })}
                onClick={handleExpandClick}>
                <ExpandMore />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Box
          padding={3}
          borderTop={`1px solid ${
            expand ? theme.palette.secondary.main : 'transparent'
          }`}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width="140px" height="42px">
                    Ações
                  </TableCell>
                  <TableCell width="25%">Campo</TableCell>
                  <TableCell width="25%">Tipo de campo</TableCell>
                  {dataSection.idSecaoPredefinida && (
                    <TableCell width="25%">Origem</TableCell>
                  )}
                  <TableCell
                    width={dataSection.idSecaoPredefinida ? '10%' : '20%'}>
                    Obrigatório
                  </TableCell>
                  <TableCell
                    width={dataSection.idSecaoPredefinida ? '10%' : '15%'}>
                    Disponível
                  </TableCell>
                  <TableCell width="100px">Status</TableCell>
                </TableRow>
              </TableHead>

              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId={dataSection.nomeSecao}>
                  {(provided) => (
                    <TableBody
                      {...provided.droppableProps}
                      ref={provided.innerRef}>
                      {provided.placeholder}

                      {fields.map((item, index) =>
                        dataSection.idSecaoPredefinida ? (
                          <TableRow
                            key={index}
                            style={{zIndex: theme.zIndex.appBar - 1}}
                            component={Paper}
                            ref={provided.innerRef}>
                            <TableCell style={{position: 'relative'}}>
                              <Box display="flex" gridGap={4}>
                                <IconButton
                                  color="primary"
                                  disabled={
                                    !isEditing ||
                                    !!dataSection.idSecaoPredefinida
                                  }
                                  onClick={() =>
                                    handleDelete(item.idCampo, false)
                                  }>
                                  <Delete />
                                </IconButton>

                                <IconButton
                                  style={{marginLeft: theme.spacing(1)}}
                                  color="primary"
                                  disabled={loading}
                                  onClick={() =>
                                    handleOpenEditFieldModal(item)
                                  }>
                                  <Edit />
                                </IconButton>
                              </Box>
                            </TableCell>

                            <TableCell>{item.nomeCampo}</TableCell>
                            <TableCell>{item.nomeTipoDeCampo}</TableCell>
                            <TableCell>{item.nomeCampoPredefinido}</TableCell>
                            <TableCell>
                              {item.obrigatorio ? 'Sim' : 'Não'}
                            </TableCell>
                            <TableCell>
                              {item.disponivelParaLead ? 'Sim' : 'Não'}
                            </TableCell>
                            <TableCell>
                              {item.ativo ? 'Ativo' : 'Inativo'}
                            </TableCell>
                          </TableRow>
                        ) : (
                          <Draggable
                            key={item.idCampo}
                            draggableId={String(item.idCampo)}
                            index={index}>
                            {(provided) => (
                              <TableRow
                                style={{zIndex: theme.zIndex.appBar - 1}}
                                onMouseEnter={() => {
                                  setHover(true), setHoverId(item.idCampo);
                                }}
                                onMouseLeave={() => {
                                  setHover(false), setHoverId('');
                                }}
                                component={Paper}
                                className={dragIn}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}>
                                <TableCell style={{position: 'relative'}}>
                                  <DragIndicator
                                    className={iconDrag}
                                    style={{
                                      opacity:
                                        hover && hoverId === item.idCampo
                                          ? 1
                                          : 0,
                                    }}
                                  />
                                  <Box display="flex" gridGap={4}>
                                    <IconButton
                                      color="primary"
                                      disabled={
                                        !!dataSection.idSecaoPredefinida ||
                                        !isEditing
                                      }
                                      onClick={() =>
                                        handleDelete(item.idCampo, false)
                                      }>
                                      <Delete />
                                    </IconButton>

                                    <IconButton
                                      style={{marginLeft: theme.spacing(1)}}
                                      color="primary"
                                      disabled={loading}
                                      onClick={() =>
                                        handleOpenEditFieldModal(item)
                                      }>
                                      <Edit />
                                    </IconButton>
                                  </Box>
                                </TableCell>

                                <TableCell>{item.nomeCampo}</TableCell>
                                <TableCell>{item.nomeTipoDeCampo}</TableCell>
                                <TableCell>
                                  {item.obrigatorio ? 'Sim' : 'Não'}
                                </TableCell>
                                <TableCell>
                                  {item.disponivelParaLead ? 'Sim' : 'Não'}
                                </TableCell>
                                <TableCell>
                                  {item.ativo ? 'Ativo' : 'Inativo'}
                                </TableCell>
                              </TableRow>
                            )}
                          </Draggable>
                        ),
                      )}
                    </TableBody>
                  )}
                </Droppable>
              </DragDropContext>
            </Table>
          </TableContainer>

          {!dataSection.idSecaoPredefinida && (
            <Box display="flex" justifyContent="flex-end" marginTop={1}>
              <Button
                textTransform="uppercase"
                startIcon={<AddCircleRounded />}
                label="Adicionar campo"
                variant="text"
                onClick={() => setOpenIncludeFieldModal(true)}
              />
            </Box>
          )}
        </Box>
      </Collapse>

      <ModalField
        useSchedule={useSchedule}
        onClose={handleCloseModal}
        section={dataSection.idSecao}
        opened={openIncludeFieldModal}
        validationGroups={validationGroups}
        isDefault={!!dataSection.idSecaoPredefinida}
      />

      <ModalField
        fieldId={fieldId}
        defaultField={field}
        isEditing={isEditing}
        useSchedule={useSchedule}
        onClose={handleCloseModal}
        opened={openEditFieldModal}
        section={dataSection.idSecao}
        validationGroups={validationGroups}
        isDefault={!!dataSection.idSecaoPredefinida}
      />

      <ModalConfirm
        loading={loading}
        onClose={handleCloseModal}
        onClick={handleConfirmDeleted}
        opened={openModalConfirmExclude}
      />

      <ModalSection
        open={openEditModal}
        order={dataSection.ordem}
        onClose={handleCloseModal}
        sectionId={dataSection.idSecao}
        formSteps={formSteps}
        section={{
          idSituacaoLead: dataSection.idSituacaoLead,
          apresentarNoFormulario: dataSection.apresentarNoFormulario,
          idCampanha: id,
          idSecaoPredefinida: dataSection.idSecaoPredefinida,
          maximoDeColunas: dataSection.maximoColunas,
          nome: dataSection.nomeSecao,
          ehFichaComplementar: dataSection.ehFichaComplementar ?? false,
          descricao: dataSection.descricao,
        }}
      />
    </>
  );
};
