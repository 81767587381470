import {IRequestResult} from 'shared/interfaces/IRequestResult';
import {Api} from 'shared/services/axiosConfig';

export interface IConfigByEstablishment {
  id: string;
  idEstabelecimento: string;
  idEstruturadoEstabelecimento: string;
  nomeEstabelecimento: string;
  idContaDeEmail: number;
  nomeContaDeEmail: string;
  email: string;
  telefone1: string;
  telefone2: string;
  site: string;
  possuiTermoLgpd: boolean;
  textoTermoLgpd: string;
}

export interface IGeneralConfig {
  id?: string;
  termoEntrevistador: string;
  termoLead: string;
  termoCurso: string;
  termoCiclo: string;
  termoComplementar1: string;
  termoComplementar2: string;
  idSituacaoDeLeadInscrito: string;
  idSituacaoDeLeadIntegrado: string;
  configuracoesEstabelecimento?: IConfigByEstablishment[];
}

export interface IEmailAccount {
  idConta: number;
  nomeConta: string;
}

const updateGeneralConfig = async (
  payload: Omit<IGeneralConfig, 'id'>,
): Promise<IRequestResult> => {
  try {
    await Api.put('/configuracaogeral', payload);

    return {success: true};
  } catch (error: any) {
    throw new Error(error.data.data);
  }
};

const getGeneralConfig = async (): Promise<IGeneralConfig> => {
  try {
    const response = await Api.get('/configuracaogeral');

    return response.data;
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const getConfigByEstablishment = async (): Promise<
  IConfigByEstablishment[]
> => {
  try {
    const {data} = await Api.get('/ConfiguracaoPorEstabelecimento');

    return data;
  } catch (error: any) {
    throw new Error(`Erro ao listar. ${error.mensagens}`);
  }
};

const getEmailAccounts = async (
  idEstabelecimento?: string,
): Promise<IEmailAccount[]> => {
  try {
    const {data} = await Api.get(
      `/ContasEmail?idEstabelecimento=${
        idEstabelecimento ? idEstabelecimento : ''
      }`,
    );
    return data;
  } catch (error: any) {
    throw new Error(`Erro ao listar. ${error.mensagens}`);
  }
};

export const GeneralConfigService = {
  getGeneralConfig,
  getEmailAccounts,
  updateGeneralConfig,
  getConfigByEstablishment,
};
