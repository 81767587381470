import {createStyles, makeStyles, Theme} from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    baseboard: {
      marginTop: theme.spacing(3),
      display: 'flex',
      alignItems: 'center',
      paddingRight: theme.spacing(2),
    },

    select: {
      minWidth: '30px',
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
    },
  }),
);
