import {Api} from 'shared/services/axiosConfig';

const getCampaignsWithPagination = async (
  take: number,
  skip: number,
  SomentePossuiTaxa?: boolean,
): Promise<any> => {
  try {
    const {data} = await Api.get(
      `/Campanha/paginado?SomentePossuiTaxa=${SomentePossuiTaxa}
      &take=${take}&skip=${skip}`,
    );

    return data;
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

export const FinancialService = {
  getCampaignsWithPagination,
};
