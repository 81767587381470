import React from 'react';

import {Box, Paper, Divider, Typography} from '@material-ui/core';

import AccessTimeIcon from '@material-ui/icons/AccessTime';
import PlaceIcon from '@material-ui/icons/Place';
import EventIcon from '@material-ui/icons/Event';
import PhoneIcon from '@material-ui/icons/Phone';
import PersonIcon from '@material-ui/icons/Person';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';

import {ILatestSubscribers} from 'shared/services/api/HomeService';

import {useStyles} from '../styles/styles';

interface ILatestSubscribersProps {
  latestSubscriber: ILatestSubscribers;
}

export const LatestSubscriberCard: React.FC<ILatestSubscribersProps> = ({
  latestSubscriber,
}) => {
  const styles = useStyles();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      height="100%"
      paddingX={3.5}
      paddingY={4}
      component={Paper}>
      <Typography variant="h6" className={styles.fontMedium} />
      <Typography variant="h6">{latestSubscriber.identificacaoLead}</Typography>
      <Box marginY={3}>
        <Box
          display="flex"
          alignItems="center"
          marginBottom={2}
          className={styles.latestSubscriber}>
          <PersonIcon fontSize="small" />
          <Box marginLeft={1}>
            <Typography>
              Candidato: <span>{latestSubscriber.nomeCandidato}</span>
            </Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          marginBottom={2}
          className={styles.latestSubscriber}>
          <AccessTimeIcon fontSize="small" />
          <Box marginLeft={1}>
            <Typography>
              Data/hora inscrição: <span>{latestSubscriber.dataInscricao}</span>
            </Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          className={styles.latestSubscriber}>
          <PlaceIcon fontSize="small" />
          <Box marginLeft={1}>
            <Typography>
              Local: <span>{latestSubscriber.nomeEstabelecimento}</span>
            </Typography>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box marginY={3}>
        <Box
          display="flex"
          alignItems="center"
          marginBottom={2}
          className={styles.latestSubscriber}>
          <EventIcon fontSize="small" />
          <Box marginLeft={1}>
            <Typography>
              Data/hora agendada: <span>{latestSubscriber.dataAgenda}</span>
            </Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          marginBottom={2}
          className={styles.latestSubscriber}>
          <PhoneIcon fontSize="small" />
          <Box marginLeft={1}>
            <Typography>
              Contato: <span>{latestSubscriber.telefoneLead}</span>
            </Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          className={styles.latestSubscriber}>
          <AlternateEmailIcon fontSize="small" />
          <Box marginLeft={1}>
            <Typography>
              E-mail: <span>{latestSubscriber.emailLead}</span>
            </Typography>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box marginTop={3}>
        <Box
          display="flex"
          alignItems="center"
          className={styles.latestSubscriber}>
          <LocalOfferIcon fontSize="small" />
          <Box marginLeft={1}>
            <Typography>
              Oferta: <span>{latestSubscriber.nomeOferta}</span>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
